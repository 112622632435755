<template>
    <BaseDialog
        v-model="open"
        :max-width="400"
        :title="title"
        :custom-actions="true"
        :eager="true"
    >
        <template v-slot:content>
            <v-form v-model="valid" ref="form" @submit.prevent="done">
                <v-text-field
                    v-if="translatable"
                    :text-area="textArea"
                    ref="translatableField"
                    :allowBlank="minLength < 1"
                    :label="label"
                    v-model="translatableMap.en"
                    :valid-input-function="validInputFunction"
                    data-testid="tpTextFieldTranslatableField"
                    :rules="[inputCheckTranslatable]"
                ></v-text-field>
                <v-text-field
                    v-else-if="!textArea"
                    v-model="input"
                    :label="label"
                    :type="type"
                    :placeholder="placeholder"
                    :counter="maxLength"
                    :maxLength="maxLength"
                    :rules="[inputCheck]"
                    data-testid="tpTextField"
                ></v-text-field>
                <v-textarea
                    v-else
                    v-model="input"
                    :label="label"
                    :type="type"
                    :placeholder="placeholder"
                    :counter="maxLength"
                    :maxLength="maxLength"
                    :rules="[inputCheck]"
                    data-testid="tpTextarea"
                ></v-textarea>
            </v-form>
        </template>
        <template v-slot:actions>
            <v-btn
                color="primary"
                elevation="1"
                :disabled="!valid"
                @click="done"
                data-testid="tpDoneAction"
            >
                {{ doneActionText }}
            </v-btn>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/popup/BaseDialog.vue';
import { useGlobalModals } from '@/composables/useGlobalModals';
import { isEmpty, isNotEmpty } from '@/lib/helpers/Validator';

export default {
    name: 'ThePrompt',
    components: {
        BaseDialog
    },
    data() {
        return {
            valid: false,
            input: '',
            open: false,
            title: '',
            label: '',
            minLength: 1,
            maxLength: 255,
            maxCount: null,
            type: 'text',
            allowBlank: false,
            doneActionText: 'Save',
            placeholder: '',
            doneFunction: null,
            validInputFunction: null,
            itemToChange: null,
            translatable: false,
            textArea: false
        }
    },
    mounted() {
        useGlobalModals().setPrompt(this);
    },
    methods: {
        inputCheckTranslatable() {
            if (!this.input) {
                return false;
            }

            if (!this.input.en) {
                return false;
            }

            const input = this.input.en;


            if (!this.allowBlank && isEmpty(input)) {
                return this.$t('generic.inputRequired');
            }

            if (input !== null) {
                if (input.length < this.minLength) {
                    return this.$tc('generic.inputLongerThan', this.minLength, {length: this.minLength});
                }
            }

            if (this.validInputFunction !== null) {
                const errorMessage = this.validInputFunction(this.input)
                if (errorMessage !== null) {
                    return errorMessage
                }
            }

            return true
        },
        inputCheck() {
            if (!this.allowBlank && isEmpty(this.input)) {
                return this.$t('generic.inputRequired');
            }

            let input = null;
            switch(typeof this.input) {
                case 'string':
                    input = this.input.trim();
                    break;
                case 'number':
                    input = this.input.toString();
                    break;
                default:
                    input = null;
            }

            if (input !== null) {
                if (this.maxCount !== null && parseInt(input) > this.maxCount) {
                    return this.$tc('generic.countLessThan', this.maxCount, {length: this.maxCount});
                }

                if (input.length < this.minLength) {
                    return this.$tc('generic.inputLongerThan', this.minLength, {length: this.minLength});
                }

                if (input.length > this.maxLength) {
                    return this.$tc('generic.inputShorterThan', this.maxLength, {length: this.maxLength});
                }
            }

            if (this.validInputFunction !== null) {
                const errorMessage = this.validInputFunction(this.input)
                if (errorMessage !== null) {
                    return errorMessage
                }
            }

            return true;
        },
        show(title, label, input, doneFunction, options, validInputFunction = null) {
            this.title = title;
            this.label = label;
            this.input = input;
            this.doneFunction = doneFunction;
            this.validInputFunction = validInputFunction

            const hasOptions = isNotEmpty(options);

            this.minLength = hasOptions && isNotEmpty(options.minLength) ? options.minLength : 1;
            this.maxLength = hasOptions && isNotEmpty(options.maxLength) ? options.maxLength : 255;
            this.maxCount = hasOptions && isNotEmpty(options.maxCount) ? options.maxCount : null;
            this.allowBlank = hasOptions && isNotEmpty(options.allowBlank) ? options.allowBlank : false;
            this.type = hasOptions && isNotEmpty(options.type) ? options.type : 'text';
            this.placeholder = hasOptions && isNotEmpty(options.placeholder) ? options.placeholder : '';
            this.translatable = hasOptions && isNotEmpty(options.translatable) ? options.translatable : false;
            this.doneActionText = hasOptions && isNotEmpty(options.doneActionText) ? options.doneActionText :
                this.$t('generic.create');
            this.itemToChange = hasOptions && isNotEmpty(options.itemToChange) ? options.itemToChange : null;
            this.textArea = hasOptions && isNotEmpty(options.textArea) ? options.textArea : false;

            if (this.translatable) {
                this.translatableMap = input;
            }

            this.open = true;
            this.$refs.form.resetValidation();
        },
        done() {
            if (!this.$refs.form.validate()) {
                return;
            }

            this.open = false;
            // this.doneFunction(this.translatable ? this.$refs.translatableField.getMap() : this.input, this.itemToChange); Removed translatablefield
            this.doneFunction(this.input, this.itemToChange);
        }
    }
}
</script>