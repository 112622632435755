<template>
    <BaseActionMenu
            v-model="showMenu"
            :title="title"
            :persistent="false"
    >
        <template v-slot:actions>
            <slot name="itemActions" :item="item"></slot>
            <BaseActionItem
                    :title="$t('generic.delete')"
                    icon="mdi-delete-outline"
                    color="error"
                    @itemClick="deleteItem"
            ></BaseActionItem>
        </template>
    </BaseActionMenu>
</template>

<script>
import BaseActionMenu from "../base/action/BaseActionMenu.vue";
import BaseActionItem from "../base/action/BaseActionItem.vue";
import { useContextStore } from '@/stores/context.js';
import { useInteractiveMenuPagesStore } from '@/stores/content/interactiveMenuPages.js';
import { useDocumentsStore } from '@/stores/content/documents.js';
import { useImagesStore } from '@/stores/content/images.js';
import { useVideosStore } from '@/stores/content/videos.js';
import { useLogosStore } from '@/stores/content/logos.js';
import { useFontsStore } from '@/stores/content/fonts.js';
import { useWayfindingIconsStore } from '@/stores/content/wayfindingIcons.js';
import { useTablesStore } from '@/stores/content/tables.js';
import { useTemplatesStore } from '@/stores/content/templates.js';
import { useMenuListsStore } from '@/stores/content/menuLists.js';
import { usePlaylistsStore } from '@/stores/content/playlists.js';
import { useGlobalModals } from '@/composables/useGlobalModals';
import { isEmpty, isNotEmpty } from '@/lib/helpers/Validator';

export default {
    name: "FolderStructureItemActions",
    components: {BaseActionItem, BaseActionMenu},
    // mixins: [contentUsageMixin], <-- Get strange errors when using this mixin here
    props: {
        value: Boolean,
        selected: Object,
        itemType: String,
        mainItemField: String,
        item: {
            required: true
        }
    },
    computed: {
        globalModals() {
            return useGlobalModals();
        },
        menuLists() {
            return useMenuListsStore().items;
        },
        menuPages() {
            return useInteractiveMenuPagesStore().withPath;
        },
        documents() {
            return useDocumentsStore().items;
        },
        currentMode() {
            return useContextStore().mode;
        },
        showMenu: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        title() {
            if (isNotEmpty(this.selected)) {
                return this.$tc('folder.selected', Object.keys(this.selected).length,
                        {amount: Object.keys(this.selected).length, type: this.itemType});
            } else {
                if (isEmpty(this.item)) {
                    return '';
                }
                return this.item[this.mainItemField];
            }
        }
    },
    methods: {
        getStoreByName(name) {
            if(typeof name !== 'string') {
                console.error('Invalid store name: ' + name);
                return null;
            }
            switch(name.toLowerCase()) {
                case 'documents':
                    return useDocumentsStore();
                case 'images':
                    return useImagesStore();
                case 'videos':
                    return useVideosStore();
                case 'logos':
                    return useLogosStore();
                case 'fonts':
                    return useFontsStore();
                case 'wayfindingicons':
                    return useWayfindingIconsStore();
                case 'tables':
                    return useTablesStore();
                case 'templates':
                    return useTemplatesStore();
                case 'playlists':
                    return usePlaylistsStore();
                default:
                    console.error('Unknown store name: ' + name);
                    return null;
            }
        },
        deleteItem() {
            let amount = Object.keys(this.selected).length;
            amount = amount === 0 ? 1 : amount;
            const itemsWithUsages = [];

            if (isNotEmpty(this.selected)) {
                for (const key in this.selected) {
                    if (this.selected.hasOwnProperty(key) && this.hasAnyUsages(this.selected[key].id)) {
                        const mp = this.resourceUsageMenuPages(this.selected[key].id);
                        const docs = this.resourceUsageDocuments(this.selected[key].id);
                        const ds = this.resourceUsageDataStores(this.selected[key].id);

                        let nameString = '&bull; <b style="font-weight: bold;">';
                        nameString += (typeof this.selected[key].name === 'object' ? this.selected[key].name.en : this.selected[key].name);
                        nameString += ":</b>";

                        let usages = "";
                        if (mp.length > 0) {
                            usages += ", Menu-Pages: " + mp.join(', ');
                        }
                        if (docs.length > 0) {
                            usages += ", Documents: " + docs.join(', ');
                        }
                        if (ds.length > 0) {
                            usages += ", Static data-sources: " + ds.join(', ');
                        }

                        usages = usages.substring(2);

                        itemsWithUsages.push(nameString + ' ' + usages);
                    }
                }
            } else {
                if (this.hasAnyUsages(this.item.id)) {
                    itemsWithUsages.push(this.item.name);
                }
            }

            if (itemsWithUsages.length > 0) {
                this.globalModals.getConfirmModal().deleteWithCustomText(this.$tc('generic.deleteWithType', amount, {type: this.itemType.toLowerCase()}),
                        this.$t('resources.potentialUsageFoundInItems') + "<br><br>" + itemsWithUsages.join('<br>') + '<br><br />' +
                        this.$tc('generic.deleteWithType', amount, {type: this.itemType.toLowerCase()}) + "?",
                        () => {
                            this.doDeleteSelected(amount);
                        },
                        () => {
                        }
                );
            } else {
                if (this.currentMode !== 'sharing') {
                    this.globalModals.getConfirmModal().delete(this.itemType.toLowerCase(),
                            amount, () => {
                                this.doDeleteSelected(amount);
                            }, () => {
                                return false;
                            });
                } else {
                    this.globalModals.getConfirmModal().deleteWithConfirmAndCustomText(this.$tc('generic.deleteWithType', amount, {type: this.itemType.toLowerCase()}),
                            this.$t('sharingContext.deleteWarning'), this.$tc('sharingContext.deleteConfirm', amount, {type: this.itemType.toLowerCase()}), () => {
                                this.doDeleteSelected(amount)
                            }, () => {
                            })
                }
            }
        },
        doDeleteSelected(amount) {
            let deletePromises = [];
            if (isNotEmpty(this.selected)) {
                for (let key in this.selected) {
                    // noinspection JSUnfilteredForInLoop
                    const i = this.selected[key];
                    let deletePromise = this.getStoreByName(i.moduleName).delete({item: i});
                    deletePromises.push(deletePromise);
                }
            } else {
                let deletePromise = this.getStoreByName(this.item.moduleName).delete({item: structuredClone(this.item)})
                    .catch((error) => {
                        // console.error('Error deleting item: ', error);
                    });
                deletePromises.push(deletePromise);
            }

            Promise.all(deletePromises)
                    .then(() => {
                        this.globalModals.getNotification().show('success', this.$tc('folder.deleting', amount, {amount: amount}));

                        for (let prop in this.selected) {
                            if (this.selected.hasOwnProperty(prop)) {
                                delete this.selected[prop];
                            }
                        }
                    })
        },

        hasAnyUsages(id) {
            return this.resourceUsageMenuPages(id).length > 0 ||
                    this.resourceUsageDocuments(id).length > 0 ||
                    this.resourceUsageDataStores(id).length > 0;
        },
        resourceUsageMenuPages(id) {
            const pages = [];
            this.menuPages.forEach(item => {
                if (item.config.indexOf(id) >= 0) {
                    pages.push(item.nameWithPath);
                }
            });
            return pages;
        },
        resourceUsageDataStores(id) {
            const menuLists = [];
            this.menuLists.forEach(item => {
                if (JSON.stringify(item.data).indexOf(id) >= 0) {
                    menuLists.push(item.name);
                }
            });
            return menuLists;
        },
        resourceUsageDocuments(id) {
            const documents = [];
            this.documents.forEach(item => {
                if (item.content.indexOf(id) >= 0) {
                    documents.push(item.name);
                }
            });
            return documents;
        }
    }
}
</script>