import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';

const thisModule = 'owners';

// Initialize the BaseCrudPlugin for this store
/* This store is not really in use... the only view using it is not active */
export const useOwnersStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });
    return store;
});
