// TODO (AHS): When would we actually want to lowercase characters by force?
//             Do we actually need this?
// AT: I removed almost all use of this now
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.capitalizeFirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};
