import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { useHospitalityLocationsStore } from '@/stores/hospitality/hospitalityLocations.js';
import { computed } from 'vue';

const thisModule = 'roomAccounts';

// Initialize the BaseCrudPlugin for this store

export const useRoomAccountsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withLocation = computed(()=> {
        const data = [];
        const locationsIdMap = useHospitalityLocationsStore().idMapByGetter('withLocation');

        if (Array.isArray(store.items.value)) {
            store.items.value.forEach((item) => {
                if(locationsIdMap.hasOwnProperty(item.locationId)) {
                    const clonedItem = structuredClone(item);
                    clonedItem.locationTitle = locationsIdMap[item.locationId].fullTitle;
                    data.push(clonedItem);
                }
            });
            Array.sort(data, 'locationTitle', true);
        }
        return data;
    });

    return store;
});
