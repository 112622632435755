<template>
    <div>
        <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" data-testid="mmuVerticalDots">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list class="the-user-menu">
                <div
                    v-if="!ppdsThemeActive()"
                >
                    <v-subheader>{{ $t('userMenu.theme') }}</v-subheader>
                    <v-list-item style="height: 40px">
                        <v-switch
                            :label="$t('userMenu.dark')"
                            v-model="darkMode"
                            data-testid="mmuDarkTheme"
                        ></v-switch>
                    </v-list-item>
                    <v-list-item style="height: 40px">
                        <v-switch
                            :label="$t('userMenu.pinMenu')"
                            v-model="pinMenu"
                            data-testid="mmuPinMenu"
                        ></v-switch>
                    </v-list-item>
                    <v-list-item>
                        <v-switch
                            :label="$t('userMenu.timeDisplay')"
                            v-model="amPmDisplay"
                            data-testid="mmuTimeDisplay"
                        >
                        </v-switch>
                    </v-list-item>
                </div>

                <v-list-item :hidden="true">
                    <v-btn text @click="resetHints">
                        {{ $t('userMenu.resetHints') }}
                    </v-btn>
                </v-list-item>
                <BaseSizedBox :height="12"></BaseSizedBox>
                <BaseSizedBox :height="15"></BaseSizedBox>
                <v-list-item
                    @click="openAccountEditor"
                    data-testid="mmuAccountSettings"
                >
                    <v-list-item-action>
                        <v-icon>account_circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{
                        $t('userMenu.settings')
                    }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="doLogout" data-testid="mmuLogout">
                    <v-list-item-action>
                        <v-icon>exit_to_app</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{
                        $t('userMenu.logOut')
                    }}</v-list-item-title>
                </v-list-item>
            </v-list>
            <div class="versionText">{{ getVersionString() }}</div>
        </v-menu>
        <UserAccountEditor v-model="accountEditor.show"></UserAccountEditor>
    </div>
</template>

<script>
import BaseSizedBox from '@/components/base/BaseSizedBox.vue';
import UserAccountEditor from './UserAccountEditor.vue';
import { useAuthStore } from '@/stores/auth.js';
import { useGlobalModals } from '@/composables/useGlobalModals';
import { useKeycloak } from '@/composables/useKeycloak';
import Storage from '@/lib/helpers/Storage.js';
import { isNotEmpty } from '@/lib/helpers/Validator';
import { useAppStateStore } from '@/stores/appState';
import PortalTheme from '@/lib/theme/PortalTheme';
import { nextTick } from 'vue';

export default {
    name: 'MainMenuUser',
    components: {
        UserAccountEditor,
        BaseSizedBox
    },
    data() {
        return {
            accountEditor: {
                show: false,
                record: {}
            },
            darkMode: useAppStateStore().darkMode,
            pinMenu: useAppStateStore().pinMenu,
            amPmDisplay: useAppStateStore().amPmDisplay,
            ignoreSettingsUpdates: false
        };
    },
    watch: {
        pinMenu(value) {
            useAppStateStore().setPinMenu(value);
        },
        darkMode(value) {
            if(!this.ignoreSettingsUpdates) {
                useAppStateStore().setDarkMode(value);
            }
        },
        amPmDisplay(value) {
            if(!this.ignoreSettingsUpdates) {
                useAppStateStore().setAmPmDisplay(value);
            }
        },
        userAccountStoreInit(value) {
            if (value) {
                nextTick(() => {
                    /* Ensure the setting store deals with this first */
                    this.ignoreSettingsUpdates = true;
                    this.darkMode = useAppStateStore().darkMode;
                    this.amPmDisplay = useAppStateStore().amPmDisplay;
                    this.ignoreSettingsUpdates = false;
                });
            }
        }
    },
    computed: {
        globalModals() {
            return useGlobalModals();
        },
        authStore() {
            return useAuthStore();
        },
        userAccountStoreInit() {
            return useAuthStore().initialized;
        },
        username() {
            if (isNotEmpty(this.authStore.account)) {
                const contactInfo = this.authStore.account.contactInformation;

                if (isNotEmpty(contactInfo) && isNotEmpty(contactInfo.name)) {
                    return contactInfo.name; // User name
                } else {
                    return this.authStore.account.name; // Account name
                }
            } else {
                return '';
            }
        }
    },
    methods: {
        ppdsThemeActive() {
            return PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS;
        },
        async doLogout() {
            this.globalModals.getConfirmModal().show(
                this.$t('userMenu.logOut') + '?',
                this.$t('userMenu.confirmLogout'),
                async () => {
                    sessionStorage.clear();
                    await this.authStore.logout();
                    await this.authStore.logoutKeycloak(useKeycloak());
                },
                () => {}
            );
        },
        getVersionString() {
            return import.meta.env.VITE_GIT_HASH;
        },
        openAccountEditor() {
            this.accountEditor = {
                show: true
            };
        },
        resetHints() {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);

                if (key.startsWith('help.')) {
                    Storage.set(key, null);
                }
            }

            this.globalModals.getNotification().show('info', this.$t('userMenu.hintsReset'));
        }
    }
};
</script>

<style lang="scss">
.the-user-menu {
    padding-top: 15px;

    .v-subheader {
        height: 20px !important;
    }

    .language-menu {
        width: 100%;

        > div > div {
            width: 100%;
        }
    }
}

.versionText {
    color: gray;
    background: var(--v-background-base);
    text-align: center;
    font-size: 13px;
    font-style: italic;
}
</style>
