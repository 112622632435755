import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';

const thisModule = 'contextTypes';

// Initialize the BaseCrudPlugin for this store

export const useContextTypesStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.names = computed(() => {
            return store.items.value.filter(function (item) {
                return item.isSystemContext === false
            }).map(function (item) {
                return item.id
            });
    });

    store.namesIncludingSystem = computed(() => {
        return store.items.value.map(function (item) {
            return item.id
        });
    });

    store.namesOfAdministrativeTypes = computed(() => {
        return store.items.value.filter(function (item) {
            return item.isAdministrative === true
        }).map(function (item) {
            return item.id
        });
    });

    store.contextTypesManagedBy = (contextTypeName) => {
        const correctContextTypeName = contextTypeName.capitalize()
        return store.items.value.filter(function (item) {
            return item.managedBy.findIndex((name) => name === correctContextTypeName) !== -1
        }).map(function (item) {
            return item.id
        });
    };

    return store;
});
