import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useLicensesStore } from '@/stores/admin/licenses.js';
import _ from 'lodash';

const thisModule = 'overviewContexts';
const currentDate = new Date();
// Initialize the BaseCrudPlugin for this store

export const useOverviewContextsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });
    store.setEnableFetchRetry(true);


    store.withStatus = computed(() => {
        const data = store.items.value;

        if (Array.isArray(data)) {
            data.forEach((entry) => {
                for (const product in entry.products) {
                    const item = entry.products[product];
                    /**
                     * TODO - move mapping into overview serverside?
                     * @type {number}
                     */
                    item.creDate = item.activationDate;
                    item.contractRefs = [];
                    item.maxCastsLic = 0;
                    item.maxClientsLic = 0;
                    item.maxSignageLic = 0;
                    item.licenses = [];
                    item.pmsVendor = '';
                    item.flightLicenses = '';

                    switch (product) {
                        case 'mirage':
                            item.tvTypes = {};
                            item.tvMod = {};
                            item.tvFw = {};
                            item.sigMod = {};
                            item.sigClientTypes = {};
                            item.sigFw = [];
                            item.roomCnt = parseInt(_.get(item, 'hospLocStat.totLocs', 0));
                            item.occupied = parseInt(_.get(item, 'hospLocStat.occLocs', 0));
                            item.cliStat = structuredClone(item.cliStat);

                            break;
                        case 'ctrl':
                        case 'enterprise':
                            item.occupied = isNaN(item.occ) ? 0 : item.occ;
                            item.maxClientsLic = item.maxCli;
                            item.numberOfClients = isNaN(item.cliCnt) ? 0 : item.cliCnt;
                            item.clientsOnline = isNaN(item.cliOnl) ? 0 : item.cliOnl;
                            item.offlineClients = item.numberOfClients - parseInt(item.clientsOnline);
                            break;
                        case 'chromecast':
                            item.occupied = isNaN(item.occ) ? 0 : item.occ;
                            item.maxClientsLic = item.maxCli;
                            item.maxCastsLic = item.maxClientsLic;
                            item.maxClientsLic = 0;
                            item.numberOfClients = isNaN(item.cliCnt) ? 0 : item.cliCnt;
                            item.numberOfCasts = isNaN(item.cliCnt) ? 0 : item.cliCnt;
                            item.clientsOnline = isNaN(item.cliOnl) ? 0 : item.cliOnl;
                            item.offlineClients = item.numberOfClients - parseInt(item.clientsOnline);
                            break;
                    }
                }
            });
            return data;
        }
        return [];
    });


    store.applyLicenses = ((contexts) => {
        if(!useLicensesStore().isLoaded) {
            console.log("Licenses store not loaded yet");
            return;
        }
        const licensesByTargetMap = useLicensesStore().byTargetMap;
        contexts.forEach(item => {
            if(!item.products.hasOwnProperty("mirage")) {
                //console.log("Skipping item without mirage: ", item);
                return;
            }
            item.contractRefs = [];
            item.maxCastsLic = 0;
            item.maxClientsLic = 0;
            item.maxSignageLic = 0;
            item.licenses = [];
            item.pmsVendor = '';
            item.flightLicenses = '';

            const id = item.facId || item.id;
            if (licensesByTargetMap.hasOwnProperty(id)) {

                item.licenses = Array.from(licensesByTargetMap[id].filter (lic => {
                    const toDate = new Date(lic.toDate).setHours(11, 59,59);
                    if (currentDate <= toDate) {
                        return lic.productName;
                    }
                }), lic => lic.productName );

                licensesByTargetMap[id].forEach(license => {
                    const toDate = new Date(license.toDate).setHours(11, 59,59);
                    if (license.contractRef) {
                        item.contractRefs.push(license.contractRef);
                    }
                    if (Array.isArray(license.featureProvisions) && currentDate <= toDate) {
                        license.featureProvisions.forEach((featureProvision) => {
                            switch (featureProvision.feature) {
                                case 'Clients':
                                case 'CastManagement':
                                    if (Array.isArray(featureProvision.resourceProvisions)) {
                                        featureProvision.resourceProvisions.forEach((resourceProvision) => {
                                            if (Array.isArray(resourceProvision.limits)) {
                                                resourceProvision.limits.forEach((limit) => {
                                                    //console.log(resourceProvision.resource + " " + limit.limit + ":" + limit.value);
                                                    if (resourceProvision.resource === 'Clients' && limit.limit === 'TvClientCount') {
                                                        item.maxClientsLic += limit.value;
                                                    } else if (resourceProvision.resource === 'Clients' && limit.limit === 'SignageClientCount') {
                                                        item.maxSignageLic += limit.value;
                                                    } else if (resourceProvision.resource === 'RegisteredCasts' && limit.limit === 'Count') {
                                                        item.maxCastsLic += limit.value;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                    break;
                                case 'Pms':
                                    if (Array.isArray(featureProvision.configurationProvisions)) {
                                        featureProvision.configurationProvisions.forEach((configurationProvision) => {
                                            if (configurationProvision.configurationName === 'PmsVendor' && configurationProvision.values.length > 0) {
                                                item.pmsVendor = configurationProvision.values[0];
                                            }
                                        });
                                    }
                                    break;
                                case 'Flights':
                                    let prefix = 'Iata Codes: ';
                                    if (!item.flightLicenses.includes(prefix)) {
                                        item.flightLicenses = prefix;
                                    }

                                    let foundIataCodes = false;

                                    featureProvision.resourceProvisions.forEach(resourceProvision => {
                                        if (resourceProvision.resource === 'Flights') {
                                            if (resourceProvision.limits[0].limit === 'IataCode' && Array.isArray(resourceProvision.limits[0].stringValues) && resourceProvision.limits[0].stringValues.length > 0) {
                                                item.flightLicenses = item.flightLicenses + resourceProvision.limits[0].stringValues[0] + ' ';
                                                foundIataCodes = true;
                                            }
                                        }
                                    });

                                    if (!foundIataCodes) {
                                        item.flightLicenses += 'None';
                                    }
                            }
                        });
                    }

                });
            //} else {
                //console.log("Could not find license for id: " + item.id);
            }
        });
    });

    store.idMapWithStatus = computed(() => {
        return Map.createFromArray(store.withStatus.value, 'id');
    });

    return store;
});
