import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import AuthManager from '@/lib/helpers/AuthManager.js';
import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';
import Storage from '@/lib/helpers/Storage.js';
import apis from '@/lib/api/index.js';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";
import i18n from '@/i18n';

const resCheck = responseSuccessCheck;
const keepAliveRequestIntervalMs = 5 * 60 * 1000; // every 5 minutes

export const useAuthStore = defineStore('auth', () => {
    const initializing = ref(true);
    const isAuthenticated = ref(false);
    const authToken = ref(AuthManager.getAuthenticationToken());
    const sessionId = ref('');
    const account = ref({});
    const error = ref(null);
    const keepSessionAliveTimer = ref(null);

    async function tryAutoLogin() {
        if (AuthManager.getAuthenticationToken() === null) {
            initializing.value = false;
            return false;
        }
        const response = await apis['session'].get('/validate');
        if (resCheck(response)) {
            sessionId.value = response.data.sessionId;
            account.value = response.data.account;
            isAuthenticated.value = true;
            initializing.value = false;
            onSessionStateUpdated();
            return true;
        } else {
            isAuthenticated.value = false;
            initializing.value = false;
            onSessionStateUpdated();
            return false;
        }
    }

    async function validateLogin() {
        let token = Storage.get('keycloakToken');
        let apiEndpoint = "/extauth";
        const response = await apis['session'].post({ token }, apiEndpoint);
        if (resCheck(response)) {
            sessionId.value = response.data.sessionId;
            account.value = response.data.account;
            isAuthenticated.value = true;
            authToken.value = response.data.token;
            AuthManager.setAuthenticationToken(response.data.token);
            AuthManager.setEnableAutoSSO(true);
            initializing.value = false;
            onSessionStateUpdated();
            return true;
        } else {
            isAuthenticated.value = false;
            initializing.value = false;
            error.value = response.data;
            onSessionStateUpdated();
            return false;
        }
    }

    async function login(credentials) {
        const result = { success: false, errorMessage: '' };
        if (isEmpty(credentials) || isEmpty(credentials.name) || isEmpty(credentials.password)) {
            result.errorMessage = i18n.t('auth.noCredentials');
            return result;
        }

        const response = await apis['session'].post(credentials);
        if (response.status < 400) {
            const authResponse = response.data;
            if (isNotEmpty(authResponse)) {
                result.success = true;
                sessionId.value = authResponse.sessionId;
                account.value = authResponse.account;
                isAuthenticated.value = true;
                authToken.value = authResponse.token;
                AuthManager.setAuthenticationToken(authResponse.token);
                onSessionStateUpdated();
                return result;
            }
        }
        result.errorMessage = i18n.t('auth.loginNotAvailable');
        isAuthenticated.value = false;
        onSessionStateUpdated();
        return result;
    }

    async function logout() {
        await apis['session'].delete("");
        AuthManager.logout();
        sessionId.value = '';
        account.value = {};
        onSessionStateUpdated();
    }

    async function logoutKeycloak(keycloak) {
        AuthManager.setEnableAutoSSO(false);
        await keycloak.logout();
    }

    function onSessionStateUpdated() {
        if (isAuthenticated.value && keepSessionAliveTimer.value === null) {
            keepSessionAliveTimer.value = window.setInterval(async () => {
                try {
                    const response = await apis['session'].get(`/keepAlive`);
                    if (!resCheck(response)) {
                        console.log("Session no longer authorized, dispatching logout");
                        await logout();
                    }
                } catch (error) {
                    console.log("Session update FAILED");
                    await logout();
                }
            }, keepAliveRequestIntervalMs);
        } else if (!isAuthenticated.value && keepSessionAliveTimer.value !== null) {
            window.clearInterval(keepSessionAliveTimer.value);
            keepSessionAliveTimer.value = null;
        }
    }

    const accountRootContextId = computed(() => {
        return account.value.contextId;
    });


    return {
        accountRootContextId,
        initializing,
        isAuthenticated,
        authToken,
        sessionId,
        account,
        error,
        tryAutoLogin,
        validateLogin,
        login,
        logout,
        logoutKeycloak,
        onSessionStateUpdated
    };
});
