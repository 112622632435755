import { ref } from 'vue';
import moment from 'moment';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";
import apis from '@/lib/api/index.js';
import { isEmpty } from '@/lib/helpers/Validator';

const resCheck = responseSuccessCheck;

export const createServiceConfigPlugin = (moduleName, fetchEndpoint = '', cacheKey = null) => {
    return ({ store }) => {

        if(!apis.hasOwnProperty(moduleName)) {
            console.error(`API not found for service config module: ${moduleName}`);
            return;
        }
        store.api = apis[moduleName];
        store.name = moduleName;
        store.processing = ref(false);
        store.fetching = ref(false);
        store.fetched = ref(null);
        store.error = ref(null);
        store.consecutiveErrorCnt = ref(0);
        store.config = ref({});

        store.configValue = (valueName, nullValue = null) => {
            const config = store.config.value;

            if (isEmpty(config)) {
                return nullValue;
            }

            return isEmpty(config[valueName]) ? nullValue : config[valueName];
        }

        store.fetchSingle = async ({ id, pathExtension = '', requestConfig = {} }) => {
            store.processing.value = true;
            const response = await store.api.get(pathExtension, requestConfig);
            if (resCheck(response)) {
                store.fetchSuccess(response.data);
            } else {
                store.error.value = response.data;
                store.processing.value = false;
            }
            return response;
        }

        store.fetchSuccess = (data) => {
            store.config.value = data;
            store.fetched.value = moment();
            store.processing.value = false;
            store.consecutiveErrorCnt.value = 0;
        };

        store.fetch = async ({ pathExtension = null, requestConfig = {} }) => {
            store.processing.value = true;
            const response = await store.api.get(pathExtension, requestConfig);
            if (resCheck(response)) {
                store.fetchSuccess(response.data);
            } else {
                store.error.value = response.data;
                store.processing.value = false;
            }
            return response;
        };

        store.update = async ({ item, requestConfig = {} }) => {
            store.processing.value = true;
            const response = await store.api.put(item, item.id, requestConfig);
            if (resCheck(response)) {
                store.updateSuccess(response.data);
            } else {
                store.error.value = response.data;
                store.processing.value = false;
            }
            return response;
        };

        store.updateSuccess = (updatedItem) => {
            store.config.value = updatedItem;
            store.processing.value = false;
        }

        store.reset = () => {
            store.processing.value = false;
            store.fetching.value = false;
            store.fetched.value = null;
            store.error.value = null;
            store.consecutiveErrorCnt.value = 0;
            store.config.value = {};
        };

    };
};
