import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import apis from '@/lib/api/index.js';
import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';
import { useKeycloak } from '@/composables/useKeycloak';
import { useAuthStore } from '@/stores/auth.js';

/**
 * NOTE - this store only deals with the current logged in users account only.
 * It uses the 'accouts' API same as the accounts store, but it's named userAccounts
 * @type {{darkMode: boolean, language: string, welcomeDisplayed: boolean, acceptedEulaVersion: number, notifications: string}}
 */
const defaultSettings = {
    darkMode: false,
    language: 'en',
    welcomeDisplayed: false,
    acceptedEulaVersion: 0,
    notifications: 'all'
};

const apiModule = 'accounts';

export const useUserAccountStore = defineStore('userAccount', () => {
    const initialized = ref(false);
    const fetching = ref(false);
    const account = ref({ settings: defaultSettings });
    const auth = useAuthStore();
    const name = 'userAccount';

    const setting = computed(() => (key) => {
        return account.value.settings.hasOwnProperty(key) ? account.value.settings[key] : null;
    });

    async function saveSetting(data) {
        const settings = Array.isArray(data) ? data : [data];
        let hasUpdate = false;

        for (const setting of settings) {
            if (!account.value.settings.hasOwnProperty(setting.key) || account.value.settings[setting.key] !== setting.val) {
                hasUpdate = true;
                account.value.settings[setting.key] = setting.val;
            }
        }
        if (!hasUpdate) return;

        if (isEmpty(auth.account.id)) {
            console.error("Cannot save settings - no account ID");
            return;
        }
        await apis[apiModule].patch({ settings: account.value.settings }, auth.account.id);
    }

    async function saveNameAndPhone(data) {
        const { name, phone } = data;
        if (!name) {
            console.error("Cannot save contact info - name missing");
            return;
        }

        const response = await apis[apiModule].patch({
            contactInformation: { name, phone }
        }, auth.account.id);

        if (response.status < 400 && isNotEmpty(response.data)) {
            response.data.settings = { ...defaultSettings, ...response.data.settings };
            account.value = response.data;
        }
        return response;
    }

    async function changeEmail(data) {
        const { email, password } = data;
        if (!email || !password) {
            console.error("Cannot save contact info - data missing");
            return;
        }

        const response = await apis[apiModule].patch({ name: email, password }, auth.account.id);

        if (response.status < 400 && isNotEmpty(response.data)) {
            response.data.settings = { ...defaultSettings, ...response.data.settings };
            account.value = response.data;
        }
        return response;
    }

    async function changePassword(data) {
        const { oldPassword, newPassword } = data;
        if (!oldPassword || !newPassword) {
            console.error("Cannot update password - data missing");
            return;
        }

        const response = await apis[apiModule].patch({
            password: oldPassword,
            passwordCredentials: { password: newPassword }
        }, auth.account.id);

        if (response.status < 400 && isNotEmpty(response.data)) {
            response.data.settings = { ...defaultSettings, ...response.data.settings };
            account.value = response.data;
        }
        return response;
    }

    async function fetch() {

        if (isEmpty(auth.account.id)) {
            return { success: false, errorMessage: 'UserId not provided - unable to get settings' };
        }

        const response = await apis[apiModule].get('/' + auth.account.id);
        if (response.status < 400 && isNotEmpty(response.data)) {
            response.data.settings = { ...defaultSettings, ...response.data.settings };
            const token = useKeycloak().tokenParsed;
            response.data.contactInformation = {
                name: token.name,
                phone: token.phone_number,
                email: token.email
            };
            account.value = response.data;
            initialized.value = true;
            return { success: true };
        }

        return { success: false, errorMessage: 'An error occurred - please try again later' };
    }

    function clearSettings() {
        account.value = { settings: defaultSettings };
        initialized.value = false;
    }

    return {
        name,
        initialized,
        fetching,
        account,
        setting,
        saveSetting,
        saveNameAndPhone,
        changeEmail,
        changePassword,
        fetch,
        clearSettings
    };
});
