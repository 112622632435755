<template>
    <BaseDialog
        v-if="desktop"
        v-model="show"
        :custom-actions="customActions"
        :title="title"
        :max-width="maxWidth"
        :custom-class="customClass"
        :hide-all-actions="hideAllActions"
        :list="list"
        :hide-cancel-action="hideCancelAction"
        :done-action-text="doneActionText"
        :done-action-disabled="doneActionDisabled"
        @done="$emit('done')"
        :persistent="persistent"
        :processing="processing"
        :eager="eager"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>
        <template v-slot:content>
            <slot name="content"></slot>
        </template>
    </BaseDialog>

    <BaseBottomSheet
        v-else
        v-model="show"
        :custom-actions="customActions"
        :hide-all-actions="hideAllActions"
        :title="title"
        :list="list"
        :custom-class="customClass"
        :done-action-text="doneActionText"
        @done="$emit('done')"
        :eager="eager"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>
        <template v-slot:content>
            <slot name="content"></slot>
        </template>
    </BaseBottomSheet>
</template>

<script>
    // Note: this component puts bottom sheet and dialog together, selecting the right one for the currently used device

    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import BaseDialog from "@/components/base/popup/BaseDialog.vue";
    import BaseBottomSheet from "@/components/base/popup/BaseBottomSheet.vue";
    import { useAppStateStore } from '@/stores/appState';

    export default {
        name: 'BaseModal',
        mixins: [VModelShowMixin],
        components: { BaseBottomSheet, BaseDialog },
        props: {
            title: { required: true, type: String },
            persistent: { default: false, type: Boolean },
            customClass: { default: '', type: String },
            customActions: { default: false, type: Boolean },
            hideAllActions: { default: false, type: Boolean },
            hideCancelAction: { default: false, type: Boolean },
            maxWidth: { default: 500, type: Number | String },
            list: { default: false, type: Boolean },
            doneActionText: { type: String, required: false },
            doneActionDisabled: { type: Boolean, default: false },
            processing: { default: false, type: Boolean },
            eager: { default: false, type: Boolean }
        },
        computed: {
            desktop() {
                return useAppStateStore().desktop;
            }
        }
    }
</script>