import { defineStore } from 'pinia';
import { createBaseContextStorePlugin } from '@/plugins/store/baseContextStorePlugin';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";

const resCheck = responseSuccessCheck;
const thisModule = 'domains';

// Initialize the BaseCrudPlugin for this store

export const useDomainsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseContextStorePlugin(thisModule);
    plugin({ store });

    store.createWithResources = (data) => {
        const {item, pathExtension, requestConfig = {}} = data;

        store.processing.value = true;

        let actualPathExtension = `/createWithResources`
        if(pathExtension !== undefined && pathExtension !== null) {
            actualPathExtension= actualPathExtension + pathExtension
        }

        return new Promise(async (resolve) => {
            const response = await store.api.post(item, actualPathExtension, requestConfig);
            if (resCheck(response)) {
                store.createSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            resolve(response);
        });
    };

    store.updateWithResources = (data) => {
        const {item, pathExtension, requestConfig = {}} = data;

        store.processing.value = true;

        let actualPathExtension = `/withResources`
        if (pathExtension !== undefined && pathExtension !== null) {
            actualPathExtension = actualPathExtension + pathExtension
        }

        return new Promise(async (resolve) => {
            const response = await store.api.putWithPathExtension(item, item.id, actualPathExtension,requestConfig);
            if (resCheck(response)) {
                store.updateSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            resolve(response);
        });
    };


    return store;
});
