<template>
    <v-tooltip
        bottom
        v-if="!ppdsThemeActive()"
    >
        <template
            v-slot:activator="{on}"
            content-class="noLinebreaks"
        >
            <v-btn
                v-on="on"
                icon
                @click.stop="openHelp"
            >
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </template>
        {{ getTooltipText() }}
    </v-tooltip>
</template>

<script>
    import UrlManager from '@/lib//helpers/UrlManager.js';
    import PortalTheme from '@/lib/theme/PortalTheme';

    export default {
        name: 'BaseHelpButton',
        props: {
            path: {
                type: String,
                required: true
            },
            tooltipText: {
                type: String,
                required: false
            }
        },
        methods: {
            ppdsThemeActive() {
                return PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS;
            },
            openHelp() {
                const url = UrlManager.getSupportPortalUrl() + (this.path.startsWith('/') ? '' : '/') + this.path;
                window.open(url, '_blank');
            },
            getTooltipText() {
                return this.tooltipText || this.$t('help.openDocumentation');
            }
        }
    }
</script>

<style lang="scss">
.noLinebreaks {
    white-space: nowrap;
}
</style>
