import { isNotEmpty } from '@/lib/helpers/Validator';

const responseSuccessCheck = (response) => {
    if (Array.isArray(response)) {
        let success = true;

        response.forEach((res) => {
            if (isNotEmpty(res) && res.status >= 400) {
                success = false;
            }
        });

        return success;
    } else {
        return isNotEmpty(response) && response.status < 400;
    }
};

export { responseSuccessCheck };
