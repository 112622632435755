import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';

const thisModule = 'management';

// Initialize the BaseCrudPlugin for this store

export const useManagementStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.byUpcoming = computed(() => {
        return store.items
            .filter(function (item) {
                return new Date(item.toUtc) > new Date();
            });
    });

    store.bySeverity = (severity) => {
        const severityLower = severity.toLowerCase();
        return store.sorted.value.filter(msg => {
            return msg.severity.toLowerCase() === severityLower;
        });
    };

    return store;
});
