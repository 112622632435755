import Keycloak from 'keycloak-js';

let kcUrl = import.meta.env.VITE_KEYCLOAK_URL_DEFAULT;
let kcDomain = import.meta.env.VITE_KEYCLOAK_REALM_DEFAULT;
let kcClient = import.meta.env.VITE_KEYCLOAK_CLIENT;
console.log("ClientID: " + kcClient);
const hostName = window.location.hostname;
if (hostName.includes('wave')) {
    kcUrl = import.meta.env.VITE_KEYCLOAK_URL_PPDS;
    kcDomain = import.meta.env.VITE_KEYCLOAK_REALM_PPDS;
}
else if (hostName.includes('sys')) {
    kcUrl = import.meta.env.VITE_KEYCLOAK_URL_SYSTEM;
    kcDomain = import.meta.env.VITE_KEYCLOAK_REALM_SYSTEM;
}
else if (hostName.includes('uniguest')) {
    kcUrl = import.meta.env.VITE_KEYCLOAK_URL_UNIGUEST;
    kcDomain = import.meta.env.VITE_KEYCLOAK_REALM_UNIGUEST;
}

const keycloak = new Keycloak({
    url: kcUrl,
    realm: kcDomain,
    clientId: kcClient
});

export function useKeycloak() {
    return keycloak;
};
