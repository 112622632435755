/* Estonian translations */
export default {
    "generic": {
        "set": "Seadma",
        "onboard": "Pardal",
        "view": "Vaade",
        "open": "Ava",
        "days": "päeva",
        "minutes": "minutit",
        "log": "Logi",
        "event": "Sündmus",
        "eventReason": "Põhjus",
        "occuredAt": "Alates",
        "lastKeepalive": "Viimati nähtud",
        "allocated": "Allocated",
        "active": "Aktiivne",
        "inactive": "Mitteaktiivne",
        "firmware": "Püsivara",
        "from": "Alates",
        "to": "Kuni",
        "ipValidationErrorMsg": "Peab sisaldama kehtivat IP aadressi",
        "networks": "Võrk",
        "ipAddress": "IP Address",
        "host": "Host",
        "total": "Kokku",
        "add": "Lisa",
        "all": "Kõik",
        "addWithType": "Lisa {type} | Lisa {type}",
        "agent": "Agent | Agendid",
        "signageScreen": "Infoekraan",
        "agentStatus": "Agendi staatus",
        "delete": "Kustuta",
        "deleteAll": "Kustuta kõik",
        "deleteWithType": "Kustuta {type} | Kustuta {amount} {type}",
        "deleteConfirm": "Oled kindel ja soovid kustutada {type}? | Oled kindel ja soovid kustutada {amount} / {type}?",
        "deleteConfirmWithIdentifier": "Oled kindel ja soovid kustutada {type} {id}?",
        "deleted": "{type} kustutatud | {type} kustutatud",
        "deleteFailed": "Ei saanud kustutada {type} | Ei saanud kustutada {type}",
        "deletedWithIdentifier": "{id} kustutatud",
        "deletedWithTypeAndIdentifier": "{type} {id} kustutatud",
        "deleteUserConfirmation": "Olen kindel ja soonin kustutada {type}. Olen kindel ja soonin kustutada {type} | Olen kindel ja soonin kustutada {amount} {type}.  Hilisemalt taastamine {type} ei ole võimalik.",
        "fetchFailed": "Viga andmeid ei leitud {itemName}",
        "imported": "{cnt} {type} import õnnestus | {cnt} {type} import õnnestus",
        "saved": "{type} salvestamine õnnestus | {type} salvestamine õnnestus",
        "updated": "{type} uuendamine õnnestus | {type} uuendamine õnnestus",
        "created": "{type} loodud | {type} loodud",
        "selectType": "Märgista {type}",
        "manageType": "Manageeri {type}",
        "itemReverted": "Tagasi pööratud | Tagasi pööratud",
        "valueDuplicate": "{value} juba eksisteerib",
        "remove": "Eemalda",
        "disable": "Keela",
        "disabled": "Keelatud",
        "download": "Lae alla",
        "enable": "Luba",
        "enabled": "Lubatud",
        "systemDefault": "Tehasesäte",
        "removeWithType": "Eemalda {type} | Eemalda {type}",
        "removeConfirm": "Oled kindel ja soovid eemaldada {type}? | Oled kindel ja soovid eemaldada {type}?",
        "removeConfirmWithTarget": "Oled kindel ja soovid eemaldada {type} sellest {target}? | Oled kindel ja soovid eemaldada {type} selelst {target}?",
        "group": "Grupp | Grupid",
        "rename": "Nmeta ümber",
        "renameWithType": "Nmeta ümber {type}",
        "renamedTo": "{type} ümber nimetatud {name}",
        "create": "Loo uus",
        "createToolTip": "Loo uus ja ära sulge",
        "createAndClose": "Loo uus & sulge",
        "createWithType": "Loo uus {type}",
        "createNew": "Loo uus",
        "createNewWithType": "Loo uus {type} | Loo uus {type}",
        "takeEffectImmediatly": "See jõustub koheselt",
        "newWithType": "Uus {type} | Uus {type}",
        "actions": "Tegevus",
        "yes": "Jah",
        "no": "Ei",
        "none": "Puudub",
        "move": "Nihuta",
        "loading": "Laadimine",
        "unknown": "Tundmatu",
        "typeToSearch": "Alusta otsingut",
        "enterSearchTerm": "Sisesta otsitav",
        "noSearchMatches": "Otsing ei andnud tulemust",
        "cancel": "Tühista",
        "close": "Sulge",
        "gotIt": "OK",
        "required": "Nõutud",
        "numberRequired": "Number on nõutud",
        "fieldRequired": "Väli on nõutud",
        "inputRequired": "Sisend on nõutud",
        "integration": "Integratsioon | Integratsioon",
        "selectionRequired": "Märgistus on nõutud",
        "nameRequired": "Nimi on nõutud",
        "inputLongerThan": "Sistus peab olema pikem, kui {length} tähemärki| Sistus peab olema pikem, kui {length} tähemärki",
        "countLessThan": "Kogus peab olema vähem, kui {length}",
        "inputShorterThan": "Sisetus peab oplema lühem, kui {length} tähemärki",
        "nameInvalidFormat": "Nimi peab sisaldama ainult tähti, numbreid ja tühikuid",
        "madeYet": "{type} ei ole veel loodud | {type} ei ole veel loodud",
        "noMoreItemsAvailable": "{type} ei ole saadaval | {type} ei ole saadaval",
        "searchCriteria": "{type} ei kattu otsinguga",
        "search": "Otsi",
        "save": "Salvesta",
        "saveAndClose": "Salvesta & Sulge",
        "saveWithType": "Salvesta {type} | Salvesta {type}s",
        "editItem": "Muuda sisu | Muuda sisu",
        "edit": "Muuda | Muuda",
        "editWithType": "Muuda {type} | Muuda {type}",
        "shareName": "Jaga {type}",
        "action": "Tegevus | Tegevus",
        "refresh": "Värskenda",
        "reset": "Nulli",
        "resetRefresh": "Nulli ja värskenda {type}",
        "lostChanges": "Kõik muudatused lähevad kaotsi",
        "noAvailable": "{type} Ei ole saadaval ",
        "channelTable": "Kanalite tabel",
        "done": "Tehtud",
        "name": "Nimi",
        "nickname": "Hüüdnimi",
        "externalId": "Väline Id",
        "crmRef": "CRM Ref",
        "industry": "Tööstulik",
        "filename": "Originaal failinimi",
        "content": "Sisu",
        "fullscreen": "Täisekraan",
        "exitFullscreen": "Välju täisekraanilt",
        "copy": "Kopeeri",
        "copyToClipboard": "Kopeeri vahemällu",
        "copiedToClipboard": "Sisu on kopeeritud vahemällu",
        "copyToSite": "Kopeeri saidile",
        "preview": "Eelvaade",
        "reload": "Lae uuesti",
        "unsaved": "Salvestamata info läheb kaotsi",
        "type": "Tüüp | Tüüp",
        "text": "Tekst",
        "tag": "Tag | Tags",
        "item": "Sisu | Sisu",
        "title": "Tiitel",
        "fullTitle": "Täispikk tiitel",
        "editor": "Editeeria",
        "number": "Number | Numbrid",
        "top": "Üles",
        "left": "Vasakule",
        "width": "Laius",
        "height": "Kõrgus",
        "zIndex": "z-index",
        "field": "Väli | Väljad",
        "key": "Key | Keys",
        "description": "Kirjeldus",
        "setup": "Setup",
        "status": "Staatus | Staatus",
        "multiple": "Mitu",
        "default": "Tehasesäte",
        "general": "Peamine",
        "configuration": "Seaded",
        "config": "Sätted",
        "select": "Vali",
        "addAll": "Lisa kõik",
        "removeAll": "Eemalda kõik",
        "previous": "Eelmine",
        "next": "Järgmine",
        "finish": "Valmis",
        "nameLonger": "Nimi peab olema pikem, kui  {length} tähemärki",
        "selectAll": "Märgmista kõik",
        "deselectAll": "Eemalda märgistus",
        "code": "Kood",
        "continue": "Jätka",
        "stay": "Jää",
        "back": "Tagasi",
        "language": "Keel",
        "languageName": "Keele nimetus",
        "languageCode": "Keele kood",
        "reseller": "Edasimüüja | Edasimüüja",
        "resource": "Ressurss | Ressurss",
        "override": "Ületama | Ületama",
        "insufficientPrivileges": "Puuduvad õigused",
        "theme": "Teema | Teemad",
        "distributor": "Edasimüüja | Edasimüüja",
        "domain": "Domeen | Domeenid",
        "preregister": "Uuesti registreerima",
        "pleaseConfirm": "Kinnita",
        "switchTo": "Lülita ümber...",
        "home": "Esileht",
        "lastLoggedIn": "Viimati sisselogitud",
        "summary": "Kokkuvõte",
        "noData": "Andmed puuduvad...",
        "vendor": "Tootja | Tootja",
        "model": "Mudel | Mudelid",
        "user": "Kasutaja",
        "schedule": "Ajastamine | Ajastamine",
        "revert": "Pööra tagasi",
        "revertAllChanges": "Pööra kõik muudatused tagasi?",
        "revertNoChanges": "Tagasi pööramiseks andmed puuduvad",
        "channels": "Kanal | Kanalid",
        "deployment": "Arendus | Arendused",
        "filter": "Filter",
        "accept": "Kinnita",
        "api": "API",
        "timeZone": "Ajatsoon  | Ajatsoonid",
        "register": "Registreeri",
        "caseSensitive": "Tundlik",
        "optional": "Ei ole nõutud",
        "errorOccurred": "Viga",
        "on": "On",
        "off": "Off",
        "standby": "Puhkereziim",
        "idle": "Ootel",
        "notSet": "Ei ole seatud",
        "exportCSV": "Export CSV",
        "importCSV": "Import CSV",
        "success": "OK",
        "uploaded": "Lae üles",
        "hasUnsavedChangesHeader": "Salvestamata muudatused",
        "hasUnsavedChangesMessage": "Muudatusi ei salvestatud.",
        "defaultErrorMsg": "Viga",
        "notes": "Märkmed",
        "genericNotes": "Peamised märkmed",
        "internalNotes": "Sisemised märkmed",
        "whitelabelDomain": "Domeeni valge nimekiri",
        "createNewSchedule": "Loo uus ajastus",
        "addToSchedule": "Lisa olemasolevale ajastusele",
        "readOnly": "Ainult lugemiseks",
        "information": "informatsioon",
        "custom": "Custom",
        "url": "URL",
        "realm": "Keycloak realm",
        "noResult": "Tulemused puuduvad | Tulemused puuduvad",
        "sharingContext": "Teenusepakkuja | Teenusepakkuja",
        "sharingSuccess": "Jagamise uuendused",
        "sharingError": "Ei saa jagamisi uuendada",
        "lastPublished": "Viimati avaldatud",
        "notPublished": "{0} on hetkel avaldatud",
        "itemType": "{0}",
        "shareAll": "Kõik",
        "shareSubGroup": "Alamgrupp",
        "shareSingle": "Üksik"
    },
    "validation": {
        "characterMaxLength": "Ei saa olla rohkem, kui {value} tähemärki",
        "valueHigherThan": "Väärtus peab olema suurem, kui {value}",
        "valueHigherThanOrEqualTo": "Väärtus peab olema suurem või võrdne, kui {value}",
        "valueLowerOrEqualTo": "Väärtus peab olema väiksem või võrdne, kui  {value}",
        "powerOnOverrideFrom": "Peab olema ennem,kui  'Override to'",
        "powerOnOverrideTo": "Peab oleme peale 'Override from"
    },
    "help": {
        "getSupportTitle": "Contact Support",
        "getSiteInformationTitle": "Saidi informatsioon",
        "notAvailable": "Ei ole saadaval",
        "openDocumentation": "Ava dokumentatsioon",
        "getSupportBody": "<span style=\"font-weight: bold;\">E-mail:</span> {email}<br><span style=\"font-weight: bold;\">Phone:</span> {phone}<br><br>{text}",
        "notAvailableInHomeScreen": "Support informationei ole saadadval.<br>Lülitu ümber saidi lehele.",
        "documentation": "Dokumentatsioon",
        "contactSupport": "Contact support",
        "mobileNavigationTip": "Navigeerimispaneeli avamiseks, vajuta üles vasalule nupule."
    },
    "eula": {
        "updated": {
            "title": "EULA Uuendatud.",
            "text": "<span style='font-size: 16px;font-style: italic;'>Oleme muutnud kastutustingimisi, palun neid lugeda.</span> <br><br>"
        }
    },
    "welcome": {
        "title": "Teretulemast {user}",
        "languageHeader": "Keel",
        "languageText": "Teretulmast Cloud Management Portaali.<br><br>See on digitaalse pinna seadmete ja sisuhalduse peamine keskus, levitajate, edasimüüjate, saidiomanike ja kasutajate pääsupunkt.<br><br>Alustage eelistatud keele valimisega:",
        "homeScreenText": "Olenevalt teie kontost logitakse teid sisse saidi juhtpaneelile või portaali juhtpaneelile ja see on teie tulevane avakuva.<br><br><div style=\"font-weight: bold;\"> Saidi juhtpaneel</div>Tavalise ühe saidi juurdepääsuga kasutajate puhul suunatakse teid süsteemi sisselogimisel otse oma saidi armatuurlauale.<br>Selles alguses on teil ülevaade installikuvadest, statistika ja haldusteave.\r Teile saadaolevad konkreetsed menüüelemendid, ressursid ja valikud võivad olenevalt teie konto tüübist, aktiivsetest litsentsidest ja antud juurdepääsutasemest erineda.<br><br><div style=\"font-weight: bold;\">Portaali juhtpaneel</div >Edasimüüjate, saidiomanike ja saidi kasutajate jaoks, kellel on juurdepääs enam kui ühele saidile, on portaali armatuurlaud platvormi esialgne lähtepunkt.<br>Siin saate hallata ressursse ja kasutada kõigi oma saitide kombineeritud statistikat, luua kasutajaid, seadistage uusi installatsioone, kontrollige litsentsimise üksikasju ja palju muid haldustoiminguid.<br>Platvormi selle haldusala konkreetsed menüüelemendid ja valikud sõltuvad teie konto tüübist, aktiivsetest litsentsidest ja antud juurdepääsutasemest.<br><br> >Saate alati jõuda portaali armatuurlauale, valides selle mis tahes lehe vasakpoolsest ülemisest kontekstimenüüst. Samast ülemisest vasakpoolsest kontekstimenüüst pääsete juurde ka kõikidele oma saitidele, et töötada iga teie konto jaoks saadaoleva installiga.",
        "siteText": "Kui olete ühe saidi kasutaja, logitakse teid pärast süsteemi sisselogimist automaatselt oma saidile sisse. Mitme saidi juurdepääsuga kasutajate puhul valite vasakpoolses ülanurgas kontekstimenüüst saidi konteksti.<br>Teie juurdepääsutase määrab, millised ressursid on teile saidi kontekstis saadaval."
    },
    "language": {
        "english": "English",
        "norwegian": "Norwegian",
        "swedish": "Swedish",
        "finnish": "Finnish",
        "russian": "Russian",
        "italian": "Italian",
        "french": "French",
        "german": "German",
        "spanish": "Spanish",
        "atLeastOne": "Vähemalt 1 keel peab olema valitud"
    },
    "date": {
        "today": "Täna",
        "yesterday": "Eile",
        "daysAgo": "{days} möödunud päeva",
        "dateRequired": "Kuupäev on nõutud",
        "dateNotSet": "Ei ole seatud",
        "timeRequired": "Aeg on nõutud",
        "timeFormat": "Aja formaat hh:mm",
        "amPmTimeFormat": "Aja formaat h:mm AM/PM",
        "daily": "Iga päev",
        "weekly": "Nädalati",
        "time": "Aeg",
        "date": "Kuupäev",
        "timeOfDay": "Time of day",
        "monday": "Esmaspäev",
        "tuesday": "Teisipäev",
        "wednesday": "Kolmapäev",
        "thursday": "Neljapäev",
        "friday": "Reede",
        "saturday": "Laupäev",
        "sunday": "Pühapäev",
        "startTime": "Algusaeg",
        "endTime": "Lõppaeg",
        "weekday": "Nädalati",
        "endTimeAfter": "Lõppaeg peab olem peale algusaega",
        "duration": "Kestvus",
        "inSeconds": "sekundites"
    },
    "router": {
        "agents": "Agendid",
        "documents": "Dokumentid",
        "resource": "Ressurss",
        "resources": "Resusrssi",
        "hospitality": "Hospitality",
        "dashboard": "Esipaneel",
        "media": "Meedia",
        "housekeeping": "Majapidamine",
        "screens": "Ekraanid",
        "tv": "TV",
        "playlists": "Playlists",
        "signage": "Infoekraan",
        "sites": "Saidid",
        "customersAndLicenses": "Kliendid ja litsentsid",
        "sitesAndLicenses": "Saidid ja litsentsid",
        "externalSystems": "Välised süsteemid",
        "reseller": "Edasimüüja",
        "resellers": "Edasimüüjad",
        "manageResellers": "Edasimüüjate haldamine",
        "distributor": "Turustaja",
        "distributors": "Turustajad",
        "domain": "Domeen",
        "domains": "Domeenid",
        "usageReport": "Kasutuse raport",
        "accessReport": "Juurdepääsu raport",
        "resellerUsersAndAccess": "Kasutajad & Juurdepääsud",
        "distributorUsersAndAccess": "Kasutajad & Juurdepääsud",
        "resellerThemes": "Teemad",
        "distributorThemes": "Teemad",
        "content": "Sisu",
        "admin": "Admin",
        "administration": "Administration",
        "system": "Süsteem",
        "rooms": "Toad",
        "virtualRemoteClients": "VRC Sessioonid",
        "housekeepingRooms": "Toad",
        "locations": "Asukoht",
        "locationSetup": "Asukoha määramine",
        "guests": "Külalised",
        "guestMessages": "Sõnumid",
        "booking": "Sisse registreerimine",
        "wakeups": "Äratus",
        "config": "Seadistus",
        "screensConfig": "Seadistus",
        "housekeepingConfig": "Seadistus",
        "streamAllocatorConfig": "Seadistus",
        "configuration": "Seadistus",
        "issues": "Probleem",
        "scheduledTasks": "Ajastamine",
        "screen": "Ekraan",
        "screenConfigurations": "Seadistus",
        "tvChannels": "TV Kanalid",
        "tvScreens": "TV Ekraanid",
        "tvScreensTheme": "TV Ekraanid",
        "signageScreens": "Infoekraanid",
        "signageOverview": "Ülevaade",
        "signageSchedules": "Ajastamine",
        "signageConfig": "Seadistus",
        "event": "Sündmus",
        "events": "Sündmused",
        "wayfinding": "Suunad",
        "wayfindingIcons": "Suunad",
        "logos": "Logod",
        "integrationLocations": "Integratsioonid",
        "eventConfig": "Seadistus",
        "schedules": "Ajastamine",
        "schedulemapping": "Ajastamise määrandug",
        "installCodes": "Install koodid",
        "interactivePages": "TV lehed",
        "templatePages": "Märgistuste mallid",
        "channelTables": "Kanalite nimistu",
        "mediaChannels": "Meedia kanalid",
        "templates": "API näidislehed",
        "apiTemplates": "API näidislehed",
        "pages": "Lehed",
        "imageresources": "Pildid",
        "videoresources": "Videod",
        "fontresources": "Fondid",
        "contexts": "Sisu",
        "sharingContexts": "Ressurssid",
        "accounts": "Kontod",
        "access": "Juurdepääs",
        "themeImport": "Teemad",
        "themes": "Teemad",
        "usersAndAccess": "Kasutajad & Juurdepääsud",
        "userAccounts": "Kasutajakontod",
        "apiAccounts": "API Konto",
        "owners": "Omanik",
        "areas": "Piirkond",
        "licenses": "Litsents",
        "products": "Tooted",
        "roles": "Rollid",
        "roleManagement": "Rollid",
        "privilegeSets": "Juurdepääsu tasemed",
        "requiredPermissions": "Teil pole selle vaate jaoks vajalikke õigusi",
        "systemConfig": "Süsteemi seaded",
        "tvSearch": "TV Otsing",
        "pmsConfiguration": "PMS Seadistamine",
        "pmsLogEntries": "PMS Logid",
        "resourceSharing": "Jaga",
        "resourcePublishing": "Avalda",
        "publishAndShare": "Avalda & Jaga",
        "themePreview": "Eelvaade",
        "watermark": "Vesimärk",
        "castManagement": "Cast Manageerimine",
        "chromecasts": "Google Casts",
        "chromecastConfig": "Google Cast seadistamine",
        "unregisteredCasts": "Registreerimata Casts",
        "activeCastSessions": "Aktiivne Sessioon",
        "castConfiguration": "Seaded",
        "castStatus": "Staatus",
        "castStatistics": "Statistika",
        "castEventLogs": "Sündmuste logi",
        "streamAllocator": "Striiming",
        "streamManagement": "Stiiming",
        "streamAllocatorLog": "Striimingu logi",
        "deletedContexts": "Kustuta sisu",
        "duplicateRegisteredClients": "Dubleerivad registreeritud kliendid"
    },
    "appbar": {
        "helpAndSupport": "Abi & Support",
        "notifications": "Notifikatsioon",
        "languages": "Keelevalik",
        "settings": "Sätted & Utiliidid",
        "contextSelector": "Vali sait",
        "availableSites": "Saadaolevad saidid",
        "availableCustomers": "Saadaolevad klindid",
        "availableThemes": "Saaddaolevad teemad",
        "searchSites": "Otsi saite",
        "searchCustomers": "Otsi kliente",
        "searchThemes": "Otsi teemasid",
        "availableSharingContexts": "Saadaolevad ressursside pakkujad",
        "searchSharingContexts": "Otsi ressursside pakkujad"
    },
    "admin": {
        "admin": "Admin",
        "account": "Konto | Kontod",
        "accountRemoved": "Konto on eemaldatud",
        "accountRemoveFailed": "Kontot ei saanud eemaldada",
        "accountCreated": "Uus konto lisatud",
        "accountSaved": "Konto andmed salvestatud",
        "accountType": "Konto tüüp",
        "accountEnabled": "Konto on lubatud",
        "accountAccess": "Konto juurdepääs",
        "apiAccount": "API konto | API kontod",
        "deleteAccount": "Kustuta konto",
        "removeAccount": "Eemlada konto",
        "removeAccessRights": "Eemalda õigused",
        "removeAccessRightsInfo": "Eemalda selle konto õigused",
        "disableAPIAccount": "Keela API konto",
        "enableAPIAccount": "Luba API konto",
        "addApiInfo": "Uus API konto lubatakse automaatselt juurdepääsuks",
        "accountApiState": "\r\nSee API konto on praegu {state}",
        "accountApiStateWithContextId": "\r\n63 / 5 000\r\nTõlke tulemused\r\nTõlketulemus\r\nSelle API konto on kontekstId {contextId} jaoks praegu {state}",
        "disableApiAccountTitle": "Keela API konto?",
        "disableApiAccountWarning": "API konto keelamisel kustutatakse juurdepääsuluba süsteemist jäädavalt. Saate konto uuesti lubada, kuid see loob uue märgi.",
        "disableApiAccountConfirm": "Kinnitage, et soovite juurdepääsuluba jäädavalt kustutada.",
        "deleteUserAccountConfirm": "Kinnitage, et soovite selle konto sellest eemaldada",
        "deleteUserAccountTitle": "Eemalda kasutajakonto?",
        "deleteUserAccountSuccess": "KAsutajakonto eemldatud",
        "deleteApiAccountSuccess": "API konto kustutatud",
        "deleteAccountGenericFailed": "Kirjet ei saanud kustutada!",
        "removedUserAccessSuccess": "Kasutaja juurdepääs edukalt eemaldatud",
        "removedUserAccessFailed": "Kasutaja juurdepääsu eemaldamine ebaõnnestus",
        "saveAccountFailed": "Kirjet ei saanud salvestada!",
        "accountDataSaved": "Konto andmed on salvestatud",
        "apiTokenWarning": "Seda märki näete ainult üks kord! Kopeerige see märk:",
        "apiTokenTitle": "API juurdepääsuluba",
        "customer": "Klient | Kliendid",
        "role": "Roll | Rollid",
        "privilegeSet": "Privileegide komplekt | Privileegide komplektid",
        "accountsWithRole": "Kontod, millele on määratud '{role}'",
        "rolesWithProduct": "Rollid tootega „{product}”",
        "site": "Sait | Saidid",
        "system": "Süsteem | Süsteemid",
        "country": "Riik | Riigid",
        "area": "Piirkond | Piirkonnad",
        "brand": "Kaubamärk | Kaubamärgid",
        "screensLimit": "Ekraanide piirang",
        "licensed": "Litsenseeritud",
        "sitesLimit": "Saidi limiit",
        "deleteCustomer": "Kustuta klient",
        "deleteSite": "Kustuta sait",
        "deleteLegacyWarningText": "HOIATUS. Välise süsteemi viite kustutamine on toiming, mida ei saa taastada.<br>See toiming ei mõjuta välist süsteemi, kuid viide eemaldatakse ja teie konto ei näe enam selle süsteemi olekuandmeid.< br>ÜHTEGI NEIST ÜKSUST EI SAA TAASTADA.",
        "deleteSiteWarningText": "HOIATUS. Saidi kustutamine on toiming, mida ei saa taastada.<br>Saidi kustutamine kustutab ka kõik seotud andmed, sealhulgas: ekraanid, menüülehed, pildid/fondid, mallid, dokumendid, ruumid, litsentsid ja palju muud.<br>PUUDUB. NEIST Elementidest SAAB TAASTADA.",
        "deleteSiteWarningTextPPDS": "HOIATUS. Kliendi kustutamine on toiming, mida ei saa taastada.<br>Kliendi kustutamisel kustutatakse ka kõik seotud andmed, sealhulgas: ekraanid, mallid, pildid/fondid, ajakavad ja palju muud.<br>NEIST ÜKSUST EI SAA TAASTADA.",
        "deleteSiteConfirmationLabel": "Kustutamise lubamiseks sisestage sellele tekstiväljale \"{title}\".",
        "copyResources": "Kopeerige ressursse",
        "copyResourcesToSite": "Kopeeri ressursid asukohta {title}",
        "copyResourcesFromTo": "Kas kopeerida kõik ressursid saidilt {from} saidile {to}?",
        "moveSiteFromTo": "Kas teisaldada sait edasimüüjalt {from} edasimüüjale {to}?",
        "moveSite": "Teisalda sait {title}",
        "provisioned": "Ettenähtud",
        "maxSizeProvisioned": "Max. ette nähtud saite",
        "addReseller": "Lisa edasimüüja",
        "context": "Kontekst | Kontekstid",
        "contextType": "Konteksti tüüp | Konteksti tüübid",
        "systemRole": "Süsteemi roll | Süsteemi rollid",
        "owner": "Omanik | Omanikud",
        "license": "Litsentsid | Litsents | Litsentsid",
        "totalLicenses": "Litsentse kokku",
        "feature": "Funktsioon | Funktsioonid",
        "changeSite": "Muuda saiti",
        "product": "Toode | Tooted",
        "itemId": "Sisu ID",
        "access": "Juurdepääs | Juurdepääsud",
        "reseller": "Edasimüüjad | Edasimüüja | Edasimüüjad",
        "noResellerAccess": "Teil pole juurdepääsu ühelegi edasimüüjale",
        "noDistributorAccess": "Teil pole juurdepääsu ühelegi levitajale",
        "distributor": "Edasimüüjad | Edasimüüja | Levitajad",
        "domain": "Domeenid | Domeen | Domeenid",
        "resource": "Ressurss | Ressurssid",
        "accountName": "Konto nimi",
        "displayName": "Kuvatav nimi",
        "email": "Email",
        "phone": "Telefon",
        "formDisabledSSO": "Kasutajakonto seaded on keelatud, kuna teie praegune seanss on sisse logitud välise ühekordse sisselogimise teenusepakkuja kaudu",
        "created": "Loodud",
        "password": "Parool",
        "repeatPassword": "Korda parooli",
        "passwordHint": "Parool peab sisaldama vähemalt 8 tähemärki, väike- ja suurtähtede kombinatsiooni, numbreid ja erimärke",
        "addNewAccess": "Lisa uus juurdepääs",
        "newAccount": "Uus kasutajakonto",
        "addAccount": "Lisage {type} konto",
        "newApiAccount": "Uus API konto",
        "editApiAccount": "Muuda API kontot",
        "accessFetchFailed": "Kontole juurdepääsu toomine ebaõnnestus",
        "accessUpdateFailed": "Kontole juurdepääsu seadistamine ebaõnnestus",
        "unknownAccess": "Tundmatu juurdepääs",
        "accessToFeatures": "Juurdepääs funktsioonile {features} | Juurdepääs {features} funktsioonidele",
        "passwordLength": "Parool peab olema 8 tähemärki pikk",
        "passwordMatch": "salasõnad peavad kattuma",
        "contextAccess": "Konteksti juurdepääs",
        "noFeaturesAccess": "Sellele juurdepääsule pole lisatud ühtegi funktsiooni",
        "removeAccess": "Eemalda juurdepääs",
        "home": "Esileht",
        "read": "Loe",
        "create": "Loos uus",
        "update": "Uuenda",
        "delete": "Kustuta",
        "oneAccessRelation": "Nõutav on vähemalt üks juurdepääsuseos",
        "editLicense": "Muuda litsentsi",
        "contractRef": "Lepingu ref",
        "activationDate": "Aktiveerimise kuup.",
        "expirationDate": "Aegumise kuup.",
        "noFeaturesSelected": "Ühtegi funktsiooni pole veel valitud",
        "availableFeatures": "Saadaolevad funktsioonid",
        "expirationAfter": "Aegumiskuupäev peab olema pärast aktiveerimiskuupäeva",
        "newLicense": "Uus litsents",
        "userAccount": "Kasutajakonto",
        "userAdminError": "Kasutaja administraatoriõigusi ei saanud lisada",
        "createUserAccount": "Looge uus kasutajakonto",
        "noAvailableUsers": "Saadaolevaid kasutajakontosid pole",
        "selectUser": "Valige kasutajakonto",
        "selectUserHelp": "Valige siit kasutaja, kui soovite anda talle administraatorijuurdepääsu valitud kontekstile ja selle uue litsentsi funktsioonidele.<br><br>Kui see kontekst antakse hoolduseks ja seadistamiseks partnerile üle, saate luua uue admin kasutaja siin.<br><br>Võite valida ka oma kasutaja, kui soovite selle uue konteksti jaoks täielikke administraatoriõigusi.<br><br>Või võite selle vahelejätmiseks jätta välja tühjaks samm täielikult.",
        "createNewContext": "Loo uus kontekst",
        "noAvailableContexts": "Saadaolevaid kontekste pole",
        "selectContext": "Valige kontekst",
        "selectContextHelp": "Kontekst võib olla ettevõtte CTRL-i eksemplar või ressursihalduri tööruum ja see on litsentsi jaoks vajalik.<br><br>Valige või looge see kohe.",
        "createNewSite": "Looge uus sait",
        "noAvailableSites": "Saadaolevaid saite pole",
        "selectSite": "Valige sait",
        "selectSiteHelp": "Sait on füüsiline asukoht, milles on kontekst olemas – näiteks hotell.<br><br>Selles etapis saate valida või luua saidi, mida selle uue litsentsi jaoks kasutada.",
        "deploymentInfo": "Standardne ettevõtte juurutamine koos kuuluva saidiga",
        "managedBy": "Haldaja",
        "deployedAt": "Kasutusele võetud alates",
        "locatedIn": "Asub ",
        "belongsTo": "Kuulub",
        "streetAddress": "Tänava aadress",
        "postalCode": "Postikood",
        "contactName": "Kontakti nimi",
        "contactEmail": "Kontaki email",
        "contactPhone": "Kontakti telefon",
        "contactInfo": "Kontakti info",
        "defaultLanguage": "Keel",
        "availableLanguages": "Saadavala keeled",
        "createAccount": "Loo konto",
        "createArea": "Loo piirkond",
        "createLicense": "Loo litsents",
        "createDistributor": "Create distributor",
        "createDomain": "Loo domeen",
        "createReseller": "Loo edasimüüja",
        "lastChange": "Viimane muudatus",
        "createOwner": "Loo omanik",
        "createProduct": "Loo toode",
        "createSite": "Loo sait",
        "createCustomer": "Loo klient",
        "createResource": "Loo ressurss",
        "createTheme": "Loo teema",
        "createSharingContext": "Looge ressursipakkuja",
        "createRole": "Loo roll",
        "roleName": "Rolli nimi",
        "newRole": "Uus roll",
        "newPrivilegeSet": "Uus õiguste komplekt",
        "newDistributor": "Uus turustaja",
        "newDomain": "Uus domeen",
        "selectRole": "Select roles",
        "noRolesAccess": "Sellele juurdepääsule pole rolle lisatud",
        "removeRole": "Remove",
        "accessToRoles": "{rollid} roll | {roles} rollid",
        "availableRole": "Available role | Available roles",
        "limits": "Limit | Limits",
        "oldPassword": "Vana parool",
        "newPassword": "Uus parool",
        "repeatNewPassword": "Korda uut parooli",
        "vendorConfig": "Tarnija seaded",
        "usingCustomSupportInfo": "Kohandatud tugiteave",
        "myAccount": "Minu konto",
        "userSaveFailedGeneral": "Nimi peaks sisaldama ainult tähti, numbreid ja ühte tühikut",
        "userSaveFailedEmail": "Kasutaja e-posti aadressi ei saanud värskendada",
        "userChangeEmailHeader": "E-posti aadressi muutmiseks peate sisestama oma parooli",
        "updateEmail": "Värskenda e-posti",
        "userSaveFailedPassword": "Kasutaja parooli ei saanud värskendada",
        "userUpdatePasswordTitle": "Uuenda parooli",
        "userUpdatePasswordDescription": "Kui see õnnestub, logitakse välja kõik teie praeguse kasutaja aktiivsed seansid. Kas jätkata?",
        "changeYourPassword": "Muutke oma parool",
        "changePassword": "Muuda parooli",
        "share": "Jaga",
        "addNewShare": "Jaga...",
        "addApiAccount": "Lisa API konto",
        "addUserAccount": "Lisa kasutajakonto",
        "resellerLicenseInfoSite": "Edasimüüjad saavad pakkuda saite ja litsentse. Sa juhid",
        "resellerLicenseInfoCustomer": "Edasimüüjad saavad pakkuda kliente ja litsentse. Sa juhid",
        "distributorLicenseInfo": "Turustajad saavad varustada edasimüüjaid. Sa juhid",
        "newReseller": "Uus edasimüüja",
        "editAccessFor": "Muuda juurdepääsu",
        "editAccessToReseller": "Redigeerimine Juurdepääs edasimüüjale",
        "distributorAccess": "Edasimüüja juurdepääs",
        "domainAccess": "Juurdepääs domeenile",
        "resellerAccess": "Edasimüüja juurdepääs",
        "themeAccess": "Juurdepääs teemale",
        "siteAccess": "Juurdepääs saidile",
        "deleteDistributorText": "Levitaja saab kustutada ainult siis, kui tal pole edasimüüjaid ega ühtegi teemat.<br>Kinnitage, et soovite selle levitaja kustutada?",
        "deleteDistributorFailedText": "Turustajat ei saanud kustutada: {details}",
        "distributorHasProvisionedContext": "seal on edasimüüjad ja/või turustajaga seotud teemad",
        "deleteDomainText": "Domeeni saab kustutada ainult siis, kui sellel pole levitajaid ega teemasid.<br>Kinnitage, et soovite selle domeeni kustutada?",
        "deleteDomainFailedText": "Domeeni ei saanud kustutada: {details}",
        "domainHasProvisionedContext": "domeeniga on seotud ette nähtud levitajad ja/või teemad",
        "externalSystemsHeading": "Siin saab redigeerida välist süsteemiteavet",
        "createPrivilegeSet": "Loo õiguste komplekt",
        "privilegeSetName": "Õiguste komplekti nimi",
        "systemPrivilegeSet": "Süsteemi õigused on määratud",
        "noPrivilegeSetsAdded": "Ühtegi õiguste komplekti pole lisatud",
        "chooseTypePrivilegeSet": "Enne privileegide komplektide valimist valige konteksti tüüp",
        "forceSSO": "Sunni SSO",
        "forceSSOHint": "Kasutage seda, kui soovite piirata kasutaja juurdepääsu konkreetse kolmanda osapoole SSO pakkujaga",
        "idp": "Sunni SSO",
        "copyRole": "Kopeeri olemasolevast rollist",
        "roleProductsHint": "See roll on saadaval ainult valitud toodete jaoks",
        "roleProductDisabledHint": "Kehtib ainult süsteemiväliste juurutusrollide puhul",
        "copyOfName": "{name} koopia",
        "contextPrivilegeSetSelectionTitle": "Juurdepääsu valik",
        "contextPrivilegeSelectionDescription": "Valige, millistele portaali menüüdele ja alammenüüdele sellel rollil on juurdepääs",
        "deletedContext": "Kustutatud kontekst",
        "finalizeDelete": "Lõpeta kustutamine",
        "undeleteConfirmMsg": "Kas olete kindel, et soovite selle konteksti kustutada? | Kas olete kindel, et soovite nende {amount} kontekstide kustutamise tagasi võtta?",
        "undelete": "Taasta kustutamine",
        "undeleteSuccess": "Kontekst kustutatud | {amount} konteksti kustutatud",
        "undeleteFailed": "Kustutamise tühistamine ebaõnnestus",
        "finalizeDeleteConfirmMsg": "Kas olete kindel, et soovite selle konteksti kustutamise lõpule viia? | Kas olete kindel, et soovite nende {amount} konteksti kustutamise lõpule viia?",
        "finalizeDeleteSuccess": "Konteksti kustutamine lõpetatud | {amount} konteksti kustutamine lõpetatud",
        "finalizeDeleteFailed": "Kustutamise lõpetamine ebaõnnestus",
        "activateSite": "Aktiveeri sait",
        "activateSiteDescription": "Saidi aktiveerimine eemaldab kõik vesimärgid ja käivitab arveldusplaani",
        "activateSiteConfirmTex": "Saan aru, et see toiming on lõplik",
        "siteActivated": "Sait on aktiveeritud",
        "duplicateRegisteredClients": "Registreeritud kliendi duplikaat",
        "userId": "Kasutaja ID",
        "copiedAuthToken": "Auth token kopeeritud",
    },
    "hospitality": {
        "hospitality": "Hospitality",
        "room": "Tuba | Toad",
        "additionalRooms": "Lisatoad",
        "occupancy": "Täituvus",
        "guest": "Külaline | Külalised",
        "wakeup": "Äratus | Äratused",
        "guestMessage": "Sõnumid | Sõnum | Sõnumid",
        "stay": "Jää | Jääb",
        "account": "Konto | Kontod",
        "virtualRemoteClients": {
            "session": "sessioon | sessioonid",
            "roomName": "Tuba",
            "screenName": "TV",
            "sessionId": "Sessiooni ID",
            "remoteClientId": "VRC ID",
            "created": "Loodud alates",
            "tvOnline": "TV Online",
            "vrcOnline": "VRC Online"
        },
        "config": {
            "chromecastCheckoutLabel": "Google Cast Checkout",
            "chromecastCheckoutExplanation": "Lähtestage toaga seotud Google Castid külalise väljaregistreerimisel.",
            "chromecastFacilityIdLabel": "Google Casti rajatise ID",
            "chromecastTokenLabel": "Google Casti tunnuskood",
            "serviceMenuCodeLabel": "Teenindusmenüü kood",
            "nightTimeToggleLabel": "Õine aeg",
            "nightTimeInfo": "Ajavahemik, mil telerid ei lülitu sisse ühegi sündmuse jaoks, välja arvatud ärkamised.",
            "nightTimeFromLabel": "Öine aeg alates",
            "nightTimeToLabel": "Öine aeg kuni"
        }
    },
    "dashboard": {
        "welcome": "Tere tulemast {name}",
        "signedInto": "Olete praegu saidile {site} sisse logitud",
        "occupancyLastNextWeek": "Täituvus eelmisel ja järgmisel nädalal",
        "upcomingWakeups": "Eelseisvad ärkamised",
        "mediaChannels": "Meediakanalid on määratletud",
        "activeInstallCodes": "Aktiivsed installikoodid!",
        "newIssuesLastDays": "Uued numbrid on viimased {days} päeva",
        "newLastDays": "Uus viimase {days} päeva jooksul",
        "account": "Kasutajakonto | Kasutajakontod",
        "apiAccount": "API konto | API kontod",
        "occupancyPercent": "Täituvus (%)",
        "sectionHelp": "Sulgege ja avage armatuurlaua jaotised, klõpsates nende vastavatel päistel",
        "filterSites": "Filtreeri saite",
        "filterCustomers": "Klientide filtreerimine",
        "clickOnMap": "Üksikasjade vaatamiseks klõpsake kaardil üksusel",
        "displayingData": "Andmete kuvamine saidi {amount} kohta | Kuvatakse andmed {amount} saidi kohta",
        "noFeaturesActivated": "Funktsioone pole aktiveeritud...",
        "clearSelection": "Tühista märgistus",
        "details": "Detailid",
        "openExternal": "Ava haldusportaal",
        "openSite": "Ava sait",
        "openSiteError": "{type} tüüpi saiti ei saa avada",
        "openTvPreview": "Avage teleri eelvaade",
        "notManaged": "Pole hallatud",
        "vacant": "Vaba",
        "managementAccess": "Juurdepääs haldusele",
        "mergeCCs": "Ainult eraldiseisvad CC-d",
        "mapView": "Kaardi vaade",
        "listView": "Nimekirja vaade",
        "stats": "Statistika",
        "map": "Kaart",
        "list": "Nimekiri",
        "noReportGenerated": "Aruannet ei loodud",
        "buildReport": "Loo aruanne",
        "rebuildReport": "Ümberehita aruanne",
        "generateStatisticsReport": "Loo statistikaaruanne",
        "processingData": "Andmete töötlemine...",
        "configureFields": "Konfigureerige väljad",
        "downloadSelected": "Valitud allalaadimine",
        "searchAllFields": "Otsige kõigist nähtavatest väljadest",
        "cluster": "Kaster",
        "installedTvs": "Paigaldtud TV-d",
        "product": "TOODE",
        "filter": "FILTER",
        "hideDemos": "Peida demo",
        "hideWhitelabeled": "Peida valge nimekiri",
        "hideWatermarked": "Peida vesimärk",
        "licenseInfo": "Litsentside info",
        "tvLicenses": "TV Litsentsid",
        "signageLicenses": "Signage Litsentsid",
        "castLicenses": "Cast Litsentsid",
        "products": "Tooted"
    },
    "drawer": {
        "userSettings": "Kasutaja seaded",
        "contextButtonHelp": "Click on the hamburger menu in the top bar to switch to another site or to the home screen",
        "mainHelp": "Selle laiendamiseks hõljutage kursorit vasakpoolset menüüd, selles menüüs on praeguse saidi või avakuva peamised navigeerimiselemendid"
    },
    "userMenu": {
        "theme": "Teema",
        "dark": "Tume",
        "notification": "Teavitus | Teavitused",
        "pinMenu": "Pin menüü",
        "all": "Kõik",
        "success": "OK!",
        "info": "Info",
        "resetHints": "Lähtesta vihjed",
        "settings": "Konto seaded",
        "logOut": "Logi välja",
        "confirmLogout": "Palu nkinnita",
        "hintsReset": "Vihjed on lähtestatud",
        "timeDisplay": "12h kell"
    },
    "reorderList": {
        "dragDropHelp": "Loendiüksuste ümberjärjestamiseks pukseerige"
    },
    "baseTable": {
        "selected": "{selected} valitud {type} | {selected} valitud {type}",
        "enableMultiselect": "Luba mitmikvalimine",
        "disableMultiselect": "Keela mitmikvalimine",
        "searchLabel": "Otsi kõigi nähtavate väljade järgi",
        "searchTooltip": "Stringi märksõnad, kasutades '&'",
        "export": "Ekspordi Excelisse"
    },
    "channelTables": {
        "channelTable": "Telekanalite tabel | Telekanalite tabelid",
        "radioChannelTable": "Raadiokanalite tabel | Raadiokanalite tabelid",
        "channelTableGroup": "Kanalitabeli rühm | Kanalitabeli rühmad",
        "tableGroup": "Tabelirühm | Tabelirühmad",
        "dragDropHere": "Pukseerige kanalid siia",
        "newName": "Uus nimi",
        "availableChannels": "Saadaolevad kanalid",
        "addAllChannels": "Lisage kõik kanalid",
        "selectedChannels": "Valitud kanalid",
        "removeAllChannels": "Eemaldage kõik kanalid",
        "unknownChannel": "Tundmatu kanal",
        "noGroups": "Selles tabelis pole rühmi",
        "table": "Tabel | Tabelid",
        "clickToChange": "Tabeli muutmiseks klõpsake siin",
        "createFirst": "Looge oma esimene kanalite tabel",
        "headerSelectHint": "Muuda muutmiseks teise tabeli valimiseks klõpsake päises tabeli nimel",
        "dragDropChannels": "Lohista, et muuta nimekrija",
        "channelTableName": "Kanali tabeli nimi",
        "new": "Uus",
        "deleteText": "Kas kustutada kanalitabel „{name}”?<br>Veenduge, et seda kanalitabelit ei kasutata üheski ekraanikonfiguratsioonis.",
        "saveGroupError": "Gruppi ei saa salvestada",
        "saveGroupChannelsError": "Viga kanalite salvestamisel rühma",
        "saveChannelsError": "Viga kanalite salvestamisel",
        "appendToGroupError": "Viga rühma salvestamisel",
        "createGroupError": "Viga grupi loomisel",
        "createdGroup": "Grupp {name} loodud",
        "deletedGroup": "Grupp {name} on kustutatud",
        "channelsSaved": "Kanalid on salvestatud"
    },
    "event": {
        "defaultEventValues": "Sündmuse vaikeväärtused | Sündmuse vaikeväärtused",
        "eventDeleteSuccess": "Sündmuse kustutamine õnnestus",
        "eventDeleteFailed": "Sündmuse kustutamine ebaõnnestus",
        "createEvent": "Loo sündmus",
        "editEvent": "Muuda sündmust",
        "deleteEvent": "Kustuta sündmus",
        "eventPrivate": "Privaatne",
        "startTime": "Algusaeg",
        "startDate": "Algus kuupäev",
        "endTime": "Lõpuaeg",
        "endDate": "Lõppkuupäev",
        "eventHeader": "Sündmused – {date}",
        "eventPrivateHint": "(peida pealkiri ja korraldaja juhiste ja koosolekute ekraanidel)",
        "eventInFuture": "Sündmus peab olema tulevikus",
        "eventEndBeforeStart": "Üritus peab lõppema pärast algusaega",
        "eventOverlappingDescription": "Selles asukohas on juba kattuv sündmus. Kas olete kindel, et soovite selle sündmuse luua?",
        "eventOverlapping": "Kattuvad sündmused",
        "copyEvent": "Kopeeri sündmus {name}",
        "copyEventToLocation": "Kopeerige sündmus asukohta",
        "makeCopyEvent": "Tehke sündmusest koopia",
        "privateTitle": "Privaatne pealkiri",
        "noLocations": "Ühtegi asukohta ei leitud",
        "wayfindingConfiguration": "Suuna konfiguratsioon | Suuna konfiguratsioonid",
        "saveWayfindingConfig": "Salvesta suuna konfiguratsioon",
        "saveWayfindingConfigFailed": "",
        "integrationLocations": "Integratsiooni asukoht | Integratsiooni asukohad",
        "manageLogos": "Hallake logosid",
        "selectLogo": "Valige logo",
        "replaceLogo": "Asendage logo",
        "removeLogo": "Eemalda logo",
        "manageIcons": "Ikoonide haldamine",
        "eventRetention": "Sündmuse säilitamine",
        "eventRetentionDescription": "Päevade arv, mille jooksul sündmusi pärast lõpetamist süsteemis säilitatakse.",
        "maxEventDuration": "Kestus",
        "maxEventDurationDescription": "Ürituse maksimaalne kestus broneerimisel toasl",
        "applyLogoByHost": "Rakenda logo hostinime järgi",
        "logoMappingDesc": "Rakendage sündmuse loomisel automaatselt logo hosti nime alusel. Ärge unustage lisada logole Host Mapping, et see toimiks.",
        "hostMapping": "Hosti kaardistamine",
        "addHostMapping": "Lisa hosti kaardistamine",
        "failedToSaveMapping": "Kaardistuse salvestamine ebaõnnestus",
        "mappingAlreadyExists": "Logol on hosti kaardistus juba olemas"
    },
    "tvMenu": {
        "tvMenuPage": "Teleri menüüleht | Teleri menüülehed",
        "menuPage": "Menüü leht | Menüülehed",
        "subtitle": "Subtiitrid | Subtiitrid",
        "audioLanguage": "Heli keel | Heli keeled",
        "subtitleLanguage": "Subtiitrite keel | Subtiitrite keeled",
        "epgMenu": "EPG menüü | EPG menüüd",
        "offlineMenu": "Võrguühenduseta menüü | Võrguühenduseta menüüd",
        "externalInput": "Väline sisestusmenüü | Välissisendi menüüd",
        "wakeupMenu": "Äratuse tellimusmenüü | Äratuse tellimise menüüd",
        "defaultWakeupAlarmMenu": "Äratusalarmi vaikemenüü",
        "powerOnMenu": "Power on menüü | Power on menüüd",
        "messageNotificationMenu": "Sõnumiteadete menüü",
        "messageDisplayMenu": "Sõnumi kuvamise menüü"
    },
    "documents": {
        "page": "Lehet | Lehed",
        "document": "Dokument | Dokumendid",
        "selectPage": "Valige leht"
    },
    "resources": {
        "resource": "Ressurss | Ressursid",
        "font": "Font | Fondid",
        "image": "Image | Images",
        "video": "Video | Videod",
        "playlist": "Esitusloend | Esitusloendid",
        "template": "Mall | Mallid",
        "compositeMenuPages": "Signage mall | Signage mallid",
        "logo": "Logo | Logod",
        "wayfindingIcon": "Suuna ikoon | Suuna ikoonid",
        "uploadedYet": "Ühtegi {type}-i pole veel üles laaditud",
        "selected": "{amount} {type} valitud | Valitud on {amount} {type}",
        "copyUrl": "Kopeeri veebiaadress URL",
        "copyResources": "Kas kopeerida ressurss sihtkohta {dest}? | Kas kopeerida {count} ressurssi sihtkohta {dest}?",
        "moveResources": "Kas teisaldada ressurss kausta {dest}? | Kas teisaldada {count} ressurssi kausta {dest}?",
        "moveResource": "Kas teisaldada {src} kausta {dst}?",
        "copying": "Kopeerin",
        "copyToFolderSuccess": "{resourceName} kopeerimine kausta {folderName} õnnestus",
        "copyToFolderFailure": "{resourceName} kopeerimine kausta {folderName} ebaõnnestus",
        "copyFinishedWithError": "Lõppenud veaga",
        "copySuccessDescription": "Ressurss {amount} on edukalt kopeeritud asukohta {context} | {amount} ressurssi kopeeriti edukalt asukohta {context}",
        "circularReferenceErrorMsg": "{amount} {type} sisaldab ringviiteid, muutke/eemaldage need salvestamise lubamiseks.",
        "failedToCopy": "Kopeerimine ebaõnnestus",
        "downloadFile": "Allalaaditud fail | Allalaaditud failid",
        "downloadingName": "{name} allalaadimine",
        "downloadFailed": "Allalaadimine ebaõnnestus!",
        "selectImage": "Vali pilt",
        "selectFont": "Vali font",
        "selectVideo": "Vali video",
        "newPlaylist": "Uus esitusloend",
        "upload": "Laadi üles",
        "uploadMedia": "Laadige meedia üles",
        "uploadResource": "Laadige ressurss üles",
        "uploadFileType": "Laadi üles {type} | Laadi üles {type}(t)",
        "uploadSuccessful": "Üleslaadimine õnnestus – {type} on praegu töötlemisel",
        "uploadError": "Viga üleslaadimisel – proovige uuesti",
        "replaceResource": "Asenda {resource}",
        "replaceResourceTitle": "Asenda {resource}: {name}",
        "replaceResourceDescription": "HOIATUS! asendatud üksused on endiselt pilves vahemällu salvestatud, seega kulub aega, enne kui uus pilt ekraanidel kuvatakse.<br><br>Kas soovite kindlasti asendada praeguse {ressurse} allikaga <br>\"{name}\" \"?",
        "resourceReplaced": "{Resource} asendatud",
        "resourceReplacedFailed": "{Resource} asendamine ebaõnnestus",
        "editImageBefore": "Redigeerige pilti enne selle üleslaadimist",
        "uploaderTitle": "Laadige üles {type}",
        "unsupportedFile": "Tüüpi {type} faile ei toetata",
        "unsupportedFileSize": "Failid ei tohi olla suuremad kui {size}",
        "unsupportedFileNameAndSize": "Fail {fileName} ei tohi olla suurem kui {size}",
        "exceedingMaxPdfPages": "PDF-is on ületatud maksimaalne {limit} lehekülg",
        "wrongType": "Üleslaadimiseks on lubatud ainult {type}",
        "quickBrownFox": "Kiire pruunrebane hüppab üle laisa koera",
        "addOneOrMore": "Lisage pildid, videod, fondid ja PDF-id, mida soovite üles laadida",
        "addOneOrMoreExcludeVideos": "Lisage pildid, fondid ja PDF-id, mida soovite üles laadida",
        "addType": "Lisage {type}, mille soovite üles laadida | Lisage {type}(id), mida soovite üles laadida",
        "pressUpload": "Lisatud failide süsteemi salvestamiseks vajutage nuppu Laadi üles",
        "rotateLeft": "Pööra 45 kraadi vasakule",
        "rotateRight": "Pööra 45 kraadi paremale",
        "crop": "Kärbi",
        "addResources": "Lisa ressursse",
        "uploadSuccessfulHover": "Üleslaadimine õnnestus",
        "addDocument": "Lisa dokument",
        "addTemplate": "Lisa mall",
        "potentialUsageFound": "Leiti järgmine potentsiaalne kasutus:",
        "potentialUsageFoundInItems": "Üksuste jaoks leiti potentsiaalne kasutus:",
        "folderNotEmpty": "Kaust ei ole tühi",
        "deleteFolderWithUsage": "Need kaustad sisaldavad potentsiaalselt kasutatavaid üksusi",
        "deleteFolderWarning": "Selle kausta kustutamisel kustutatakse jäädavalt ka kogu selle sisu, sealhulgas alamkaustad ja ressursid.",
        "deleteFolderConfirm": "Kinnitan, et soovin selle kausta ja selle sisu jäädavalt kustutada",
        "newPlaylistFromPdf": "uus esitusloend pdf-ist",
        "displayDuration": "Nöita kestust",
        "pdfUploadFolderError": "PDF-i meediumikausta loomine ebaõnnestus, üleslaadimine juurmeediumi juurkausta",
        "tag": "Silt | Sildid",
        "addTag": "Lisa silt",
        "transcodeVideo4K": "Video on 4K-kvaliteediga saadaval varsti pärast üleslaadimist",
        "transcodeVideoHD": "Video on HD-kvaliteediga saadaval varsti pärast üleslaadimist"
    },
    "mediaChannels": {
        "mediaChannels": "meediakanalid",
        "mediaChannel": "Meediakanal | Meediakanalid",
        "globalMediaChannels": "Ülemaailmsed meediakanalid",
        "globalMapping": "Globaalne kaardistamine",
        "address": "Aadress",
        "port": "Port",
        "serviceId": "Service ID",
        "frequency": "Sagedus (kHz)",
        "modulation": "Modulatsioon",
        "symbolRate": "SymRate (kHz)",
        "bandwidth": "Ribalaius",
        "satelliteId": "Satelliidi ID",
        "polarization": "Polarisatsioon",
        "programNumber": "Programmi number",
        "majorNumber": "Peamise kanali number",
        "minorNumber": "Väikese kanali number",
        "type": "Tüüp",
        "audioOnly": "Ainult heli",
        "createChannel": "Looge kanal {type}",
        "save": "Salvestage kanalid",
        "saveTooltip": "See salvestab igat tüüpi kanaleid",
        "clickToChange": "Kanali tüübi muutmiseks klõpsake siin",
        "reset": "Kanalite lähtestamine ja värskendamine",
        "types": "Kanali tüübid",
        "webRadioSearch": "Interneti-raadio otsing",
        "unknownBitrate": "Tundmatu",
        "placeholderChannelName": "Kanali nimi",
        "updateFailed": "Meediakanalite värskendamine ebaõnnestus",
        "deleteFailed": "Kanalit ei saanud kustutada!",
        "createFailed": "Kanalit ei saanud luua!",
        "updateSuccess": "Meediakanalid uuendatud!",
        "deleteSuccess": "Meediakanal kustutatud!",
        "createSuccess": "Meediakanal loodud!",
        "validateFailed": "Tüüpi {type} kanalil on tühi nimi"
    },
    "templates": {
        "templateErrorMessages": {
            "deserializationErrorMsg": "Template Engine ei saanud API andmeid sõeluda. Kontrollige fikseeritud parameetri / testimise / API konnektori sätteid, et tagada õigete andmete toomine."
        },
        "dataFetchErrorMessages": {
            "unknownHost": "URL on API-st andmete toomisel teadmata. Selle põhjuseks võib olla API konnektori URL-i ja/või fikseeritud/testitee parameetri kirjaviga.",
            "protocolError": "HTTP-protokolli viga API-st andmete toomisel. Selle põhjuseks võib olla API konnektori URL-i ja/või fikseeritud/testitee parameetri kirjaviga.",
            "unknownError": "Viga API andmete toomisel. Kontrollige API konnektori / fikseeritud / testimisparameetrite sätteid.",
            "code300ErrorMsg": "API tagastas koodi 300 – mitu valikut, mis näitab, et taotletud ressursi jaoks on mitu valikut. Kontrollige testi / fikseeritud parameetrite sätteid.",
            "code400ErrorMsg": "API tagastas koodi 400 – vigane taotlus, API ei saanud vale süntaksi tõttu päringust aru. Kontrollige konnektori / testimise / fikseeritud parameetrite sätteid.",
            "code401ErrorMsg": "API tagastas koodi 401 – volitamata, teil pole õigust seda taotlust esitada. Kontrollige API konnektorist õigeid päiseid",
            "code403ErrorMsg": "API tagastas olekukoodi 403 – keelatud. See võib tähendada, et konnektor või fikseeritud parameetrid on seadistatud vale, kehtetu või aegunud volitusega.",
            "code404ErrorMsg": "API tagastas olekukoodi 404 – ei leitud. See võib tähendada, et URL on kehtiv, kuid ressurssi ennast pole. API-d võivad ka selle vastuse 403 asemel saata, et varjata ressursi olemasolu volitamata kliendi eest. Veenduge, et konnektori/testi/fikseeritud parameetri sätted on õiged.",
            "code406ErrorMsg": "API tagastas koodi 406 Pole vastuvõetav, API ei leidnud sisu, mis vastaks antud kriteeriumidele. Kontrollige testi / fikseeritud parameetreid.",
            "code415ErrorMsg": "API tagastatud kood 415 – toetamata meediatüüp. Kontrollige API konnektori sätteid. Proovige seadistada sisutüübi kohandatud päis API poolt toetatavale vormingule",
            "code500ErrorMsg": "API tagastas olekukoodi 500 – serveri sisemine viga. Kontrollige testi / fikseeritud parameetrite sätteid.",
            "apiTimeout": "API vastus aegus. API ei pruugi ajutiselt saadaval olla."
        },
        "serializationErrors": {
            "jsonParseError": "API-lt tagastatud JSON-andmeid ei saanud sõeluda, kontrollige API konnektorit / fikseeritud parameetri sätteid."
        },
        "internalErrors": {
            "code500ErrorMsg": "Meie süsteemidega on probleem, proovige mõne aja pärast uuesti."
        },
        "noDataYet": "Andmeid veel pole.",
        "noDataFoundYet": "No data found yet!",
        "save": "Save template without closing window",
        "setATest": "Määra test {name}",
        "infoUrlDefault": "URL API teabelehele",
        "infoUrlTooltip": "Kuidas kasutada allikat",
        "error": "Viga malli hindamisel",
        "httpMethodRequired": "Nõutav HTTP-meetod",
        "nameRequired": "Nimi nõutav",
        "paramType": "Parameetri tüüp",
        "addItem": "Lisa üksus",
        "preview": "Generate preview",
        "template": "Mall | Mallid",
        "templateRequired": "Vajalik mall",
        "reset": "Lähtestage selles mallis pärast viimast salvestamist tehtud muudatused",
        "source": "Allikas | Allikad",
        "sourceRequired": "Nõutav allikas",
        "fixedParameters": "Fikseeritud parameetrid",
        "setAFixed": "Määra fikseeritud {name}",
        "templateExample": "Malli näide",
        "dataExample": "Andmete näidis",
        "httpMethod": "HTTP meetod",
        "markup": "Märgistus",
        "sourceError": "Viga: allika laadimine ebaõnnestus",
        "selectConfigure": "Valige ja konfigureerige mall",
        "failedSource": "Viga: malli allika leidmine ebaõnnestus",
        "failedSourceFetch": "Viga: allikateabe toomine ebaõnnestus",
        "templateSource": "Malli allikas | Mallide allikad",
        "rootName": "Juurenimi",
        "apiConnector": "API Konnector | API Konnectorid",
        "createTemplate": "Loo mall",
        "createWithoutClosing": "Loo mall ilma akent sulgemata",
        "saveWithoutClosing": "Salvestage mall ilma akent sulgemata",
        "fetchApiData": "API andmete toomine",
        "cacheForSeconds": "Vahemällu salvestamine pole lubatud (0 sekundit) | Vahemälu API mall {value} sekundiks | Vahemälu API mall {value} sekundiks",
        "cacheForSecondsError": "Arv peab olema vahemikus 0 kuni 1800",
        "defaultTemplateErrorExplanation": "Teleris kuvatav sisu, kui mall sisaldab vigu."
    },
    "configSchedules": {
        "defaultConfig": "Vaikimisi",
        "dailyOverrides": "Igapäevased alistamised",
        "weeklyOverrides": "Iganädalased alistamised",
        "editTimeslot": "Muutke ajapilu",
        "deleteTimeslot": "Kustuta ajapilu",
        "dragStopTime": "Peatusaja muutmiseks lohistage",
        "defaultContentExplanation": "See konfiguratsioon töötab ekraanil terve päeva, välja arvatud juhul, kui päeva-, nädala- või kalendripõhised konfiguratsioonid seda tühistavad.",
        "editScheduleName": "Muutke ajakava nime",
        "tabDefault": "Vaikimisi",
        "tabDaily": "Igapäevane",
        "tabWeekly": "Iganädalane",
        "tabCalendar": "kalender",
        "addTimeslotHelpText": "Klõpsake uue ajapilu lisamiseks või klõpsake lihtsalt kalendris ava."
    },
    "scheduleMapping": {
        "createNew": "Looge uus ajakava kaardistus",
        "rename": "Nimeta {title} ümber",
        "created": "Uus ajakava kaardistus on loodud",
        "updated": "Ajakava kaardistamist värskendati",
        "currentSchedule": "Praegune ajakava",
        "deleteHeading": "Kas kustutada ajakava kaardistamine {title}?",
        "deleteText": "Kõik selle vastenduse osaks olevad ekraanid teisaldatakse ajakava vaikevastastusrühma.",
        "addMapping": "Lisa kaardistus",
        "screens": "Ekraan | Ekraanid",
        "screensHeadingInfo": "Teleriekraanid reeglistiku abil. Teisaldamiseks paremklõpsake.",
        "moveTo": "Teisalda...",
        "rulesHeading": "Reegleid hinnatakse nende siin ilmumise järjekorras",
        "rulesDescription": "Kohandatud reegleid veel ei toetata.",
        "newRule": "Uus reegel",
        "defaultSchedule": "Vaikimisi ajakava",
        "screensMoved": "Ekraanid teisaldatud",
        "createFailed": "Kaardistust ei saanud luua",
        "updateFailed": "Kaardistust ei saanud värskendada"
    },
    "schedulePlans": {
        "editor": {
            "notInUse": "Ajakava Plaanid ei ole kasutusel. Kasutamise alustamiseks vajutage 'Loo'." +
                "Loome automaatselt vaikimisi ajakava, mis sisaldab lisaks teie loodud uuele ajakavale ka teie praegust ajakava/ekraani seadistust.",
            "newForEach": "Iga ekraani jaoks eraldi ajakava",
            "singleNew": "Jagatud ajakava kõigi ekraanide jaoks",
            "copyExisting": "Kopeerige olemasolev ajakava",
            "removeWhenActive": "<b>See ajakava on praegu Aktiivne</b>. Kui otsustate selle eemaldada, lülitatakse kõik ekraanid üle vaikeplaanile. <br><br> Kas olete kindel, et soovite selle ajakava eemaldada?"
        },
        "screenEditor": {
            "newScheduleForEach": "Looge iga ajakava jaoks uus ajakava",
            "individualSelectionDescription": "Individuaalne valik"
        },
        "type": "Ajakava plaan | Planeerige plaanid",
        "setActive": "Määra aktiivseks",
        "setActiveConfirmTitle": "Kas määrata aktiivne ajakava?",
        "disabledDeleteForDefault": "Ajakava vaikeplaani kustutamine on lubatud ainult siis, kui muid ajakava plaane pole",
        "setActiveConfirmText": "See toiming muudab ajakava „{title}” aktiivseks. Kõigil selle saidi ekraanidel on aktiivseteks ajakavadeks määratud ajakava {title} all. Kas soovite jätkata?",
        "setActiveSuccess": "Ajakava plaan {name} on aktiveeritud",
        "setActiveFailed": "Ajakava plaani {name} ei saanud aktiivseks muuta"
    },
    "screens": {
        "screensMissingSchedulesTitle": "Ekraanidel puuduvad ajakavad",
        "screensMissingSchedulesText": "Järgmistel ekraanidel puuduvad ajakavad. Selle põhjuseks võis olla ekraanide loomise/ teisaldamise kaheastmelise protsessi katkestus.",
        "screenStatus": "Ekraani olek | Ekraani olek",
        "tvScreenStatus": "Teleriekraani olek",
        "signageDisplayStatus": "Signage ekraani staatus",
        "screens": "Ekraanid",
        "livePreview": "Reaalajas eelvaade",
        "displays": "Ekraanid",
        "onlineScreens": "online",
        "screenSetup": "Ekraani seadistamine | Ekraani seadistused",
        "screenSetupCreated": "Ekraani seadistus on loodud",
        "screenSetupUpdated": "Ekraani seadistust värskendati",
        "defaultScreenConfig": "Vaikeseaded",
        "tvScreen": "teleriekraan | TV ekraanid",
        "signageScreen": "Märgistusekraan | Märgistusekraanid",
        "tvType": "TV tüüp | TV tüübid",
        "clientType": "Kliendi tüüp | Klienditüübid",
        "tvSoftware": "TV tarkvara",
        "editName": "Muuda nime",
        "tvClient": "Teleklient | TV kliendid",
        "unknownSetup": "Tundmatu seadistus",
        "createFirstSchedule": "Loo oma esimene ajakava",
        "schedule": "Ajakava | Kavad",
        "copyFromConfig": "Kopeeri seaded (valikuline)",
        "quicklyCopyConfig": "Kopeerige kiiresti teised sedaed",
        "mainMenu": "Peamenüü | Peamenüüd",
        "welcomeMenu": "Tervitusmenüü | Tervitusmenüüd",
        "channelMenu": "Telekanali menüü | Telekanalite menüüd",
        "radioChannelMenu": "Raadiokanali menüü | Raadiokanalite menüüd",
        "startVolume": "Käivituse helitugevus",
        "maxVolume": "Max helitugevus",
        "autoPowerOffHrs": "Automaatne väljalülitus {hrs} tunni pärast",
        "enableAutoPowerOff": "Luba automaatne väljalülitus",
        "audioLanguageMenu": "Heli keele menüü",
        "subtitleLanguageMenu": "Subtiitrite keele menüü",
        "externalInputMenu": "Välise sisendi menüü | Välised sisendmenüüd",
        "noScreenSetups": "Ekraani seadistusi pole veel loodud",
        "selectSlotType": "Valige pesa tüüp",
        "scheduleEditorCalendarHint": "Uue ajapilu lisamiseks klõpsake suvalises kohas kalendris",
        "editSlot": "Muuda pesai",
        "newSlot": "Uus pesa",
        "createNewConfig": "Loo uus seaded",
        "timeslotOverlap": "Valitud algus- ja lõpuaeg kattuvad teise ajapiluga. Proovige uuesti erinevatel aegadel.",
        "volume": "Helitugevus",
        "durationRequired": "Nõutav kestus",
        "durationLongerThan": "Kestus peab olema pikem kui 0 sekundit",
        "messageInJson": "Sõnum JSON-vormingus",
        "validJson": "Peab olema kehtiv JSON",
        "sendMessageToScreen": "Saada {type} sõnum ekraanile {screens} | Saada {type} sõnum {screens} ekraanile",
        "sendMessage": "Saada sõnum",
        "updateStatus": "Uuenda staatust",
        "pushConfig": "Saada uued seaded",
        "pushContent": "Saada uud sisu",
        "upgradeAgentSoftware": "Uuendage agent SW",
        "upgradeAgentSoftwareNotification": "See saadab agendile teate oma tarkvara uuendamiseks." +
            "Uuenduse osana võib agent taaskäivitada, mis toob kaasa agenditeenuse lühiajalise katkestuse." +
            "Pange tähele, et kui värskendusi pole saadaval, siis toiminguid ei tehta.",
        "poweredOn": "ekraan sisse lülitatud",
        "poweredOff": "ooterežiimis",
        "powerOn": "Toide sisse lülitada",
        "powerOff": "Minge ooterežiimi",
        "reboot": "Taaskäivitamine",
        "rebootRequired": "Ekraani pööramise rakendamine nõuab taaskäivitamist.<br><br>Kas soovite nüüd taaskäivitada?",
        "jvmUptime": "JVM-i tööaeg",
        "rebootRequested": "Taaskäivitamise taotlemine",
        "appUpdateRequested": "Taotleti rakenduse värskendust",
        "setVolume": "Helitugevuse määramine",
        "displayMessage": "Kuva sõnum",
        "displayAddress": "Näita aadressi",
        "displayName": "Näita nime",
        "resetSoftAP": "Lähtestage SoftAP parool",
        "displayPage": "Kuva leht",
        "tuneChannel": "Kanali häälestamine",
        "enableDebug": "Luba silumine",
        "reAuthenticate": "Autentige uuesti",
        "reAuthenticateMsg": "Olete ekraani {screen} uuesti autentimas. Jätkamiseks kinnitage.",
        "getScreenshot": "Hangi ekraanipilt",
        "sendPushMessage": "Saada push-sõnum",
        "openClientPreview": "Ava kliendi eelvaade",
        "sendMgmntMessage": "Saada sõnum",
        "moveToLocation": "Liigu asukohta",
        "changeSchedule": "Muuda ajakava",
        "selectSchedule": "Valige ajakava",
        "manage": "Halda",
        "moveScreens": "Teisalda {screens} ekraani | Teisalda {screens} ekraani",
        "deleteScreens": "Kas kustutada {screens} ekraan? | Kas kustutada {screens} ekraani?",
        "setContentScheduleForScreenExistingNameMsg": "Ajakava nimega {screenName} on juba olemas, luuakse uus ajakava nimega {uniqueName}.",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgPreface": "Nimekonflikt järgmistes ajakavaplaanides:<br>",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgIndividualMsg": "<span style='font-weight: bold;'>{schedulePlanName}</span> – luuakse uus ajakava nimega <span style='font-style: italic;'>{uniqueName}</span>.< br>",
        "move": "Nihuta",
        "name": "Nimi",
        "ip": "IP",
        "mac": "MAC",
        "rotation": "Pööramine",
        "deviceType": "Seadme tüüp",
        "appVersion": "Rakenduse versioon",
        "firmwareVersion": "Püsivara versioon",
        "osVersion": "OS versioon",
        "details": "Detailid",
        "occupied": "Hõivatud",
        "online": "Online",
        "offline": "Offline",
        "power": "Toide",
        "state": "Staatus",
        "public": "Public",
        "clientId": "Kliendi ID",
        "setRotation": "Määrake ekraani pööramine | Määrake pööramine {count} ekraani jaoks",
        "id": "Kliendi ID",
        "locationTitle": "Asukoht",
        "previewOnlyOne": "Valige eelvaate kuvamiseks ainult üks",
        "screensMoved": "{screens} ekraan teisaldati teise asukohta | {screens} ekraani teisaldati teise asukohta",
        "installCode": "Installi kood | Installi koodid",
        "createInstallCode": "Loo installi kood",
        "code": "Kood",
        "createSchedule": "Loo ajakava",
        "created": "Loodud",
        "createdBy": "Loodud",
        "expiryDate": "Aegumiskuupäev",
        "configInUse": "Viga: konfiguratsiooni kasutatakse",
        "configInUseDescription": "{name} on kasutusel järgmiste ajakavade järgi:",
        "scheduleInUse": "Viga: ajakava kasutatakse",
        "scheduleInUseDescription": "{name} is in use by the following Schedule Mappings:",
        "configTvSettingsHeading": "TV Sätted",
        "configChannelsHeading": "Kanalid",
        "configMenusHeading": "Menüüd",
        "addConfiguration": "Lisa seaded",
        "groupBy": "Grupeeri",
        "setSchedule": "Määra ajakava...",
        "upgradeApp": "Uuenda rakendust",
        "helpText": "Sisestage enne väljalülitamist kuvatav sõnum.<br>Kui määrate sõnumi puudumise, lülitub ekraan(id) kohe välja.",
        "newTvScreen": "Uus teleriekraan",
        "clientIdRequired": "Nõutav kliendi ID",
        "pickLocation": "Valige asukoht",
        "noLocation": "Saadaolevaid asukohti pole",
        "roomRequired": "Vajalik on tuba",
        "roomSearchPlaceholder": "Start typing to search",
        "tvPreRegisteredOn": "TV eelregistreeritud",
        "tvRegisteredOn": "Teler registreeritud",
        "tvRegisteredCloseText": "Palun sulgege see vahekaart.",
        "pickContext": "Valige kontekst",
        "noContext": "Saadaolevaid kontekste pole",
        "contextRequired": "Nõutav kontekst",
        "clientToken": "Kliendi tunnus",
        "clientTokenRequired": "Nõutav on kliendi tunnus",
        "couldNotFindItemToEdit": "Muudatavat üksust ei leitud",
        "uptime": "Tööaeg",
        "memoryUsage": "Mälu kasutamine",
        "requestScreenshot": "Ekraanipildi taotlemine, palun oodake...",
        "requestScreenshotOffline": "Võrguühenduseta seadmest ei saa ekraanipilti taotleda...",
        "requestScreenshotPoweredOff": "Ooterežiimis olevast seadmest ei saa ekraanipilti taotleda...",
        "requestNewScreenshot": "Klõpsake uue ekraanipildi taotlemiseks...",
        "requestUpdate": "Taotlege värskendust",
        "serial": "Seerianumber",
        "firmware": "Püsivara",
        "resolution": "Lahutusvõime",
        "system": "Süsteem",
        "publicIp": "Public IP",
        "softAP": "Soft AP",
        "currentConfig": "Praegused seaded",
        "rcAddr": "RC addr",
        "network": "Võrk",
        "timeOnDevice": "Aeg seadmes",
        "openPreview": "Ava eelvaade",
        "screenInfo": "Ekraani teave",
        "reloadAllMediaTitle": "Laadige kogu meedium uuesti",
        "reloadAllMediaDescription": "Kas kustutada kogu allalaaditud meedium {amount} seadmest ja laadida uuesti alla? | Kas kustutada kogu allalaaditud meedium {amount} seadmest ja laadida uuesti alla?",
        "moveToScheduleTitle": "Määra ajakava kaardistamine",
        "changeScreenScheduleDescription": "Kas muuta ekraani ajakava ajakavaks {mapping}?",
        "changeScreenScheduleSuccess": "Ekraani ajakava muudeti ajakavaks {mapping}",
        "moveToScheduleDescription": "Kas teisaldada {amount} ekraani, et ajastada {mapping}?",
        "moveToScheduleSuccess": "Uus ajakava määratud {amount} ekraani jaoks",
        "moveToSite": "Teisalda ekraan saidile",
        "moveScreenToSiteMsg": "Hoiatus! Olete teisaldamas selle ekraani teisele saidile, jätkamiseks kinnitage.",
        "moveScreenToSiteSuccess": "Ekraan teisaldati uuele saidile",
        "moveScreenToSiteNotAllowed": "Pole lubatud ekraani sellele saidile teisaldada.",
        "moveScreenToSiteNotAllowedNoLicense": "Pole lubatud ekraani sellele saidile teisaldada: saidil pole aktiveeritud litsentsi {license}.",
        "moveScreenToSiteNotAllowedLimitReached": "Ekraani ei ole lubatud sellele saidile teisaldada: selle ekraanitüübi litsentsi limiit on täis.",
        "createScreenLimitReachedMsg": "Uut ekraani ei saa luua. Selle ekraanitüübi litsentsi limiit on täis",
        "successfullyAuthenticated": "{count} Ekraani uuesti autentimine õnnestus",
        "canNotReAuthenticate": "Juba autentitud ekraani {screen} ei saa uuesti autentida",
        "appMode": "Rakendusrežiim",
        "forceUpdate": "Uuenda kohe",
        "services": "Teenused",
        "upgradablePackages": "Uuendatavad paketid",
        "authCodeNotFound": "Autentimiskood ei kehti või on aegunud. Kontrollige ekraanilt kehtivat koodi.",
        "lastReboot": "Viimane taaskäivitus",
        "storage": "Säilitamine",
        "authCreateNewSchedule": "See loob uue ajakava, millel on loodud kuval sama nimi, ja asetatakse ekraan sellesse ajakavasse.<br>Selle ruudu märke tühistamisel saate lisada ekraani juba olemasolevasse ajakavasse.",
        "authUseExistingSchedule": "Uue ekraani lisamiseks valige juba olemasolev ajakava:",
        "registrationCode": "Ekraani registreerimiskood",
        "registrationCodeLength": "6 numbrit"
    },
    "booking": {
        "booking": "Broneerimine | Broneeringud",
        "numberOfBookings": "{number} broneerimine | {number} broneeringut",
        "book": "Broneerige",
        "bookNights": "Broneerige {nights} ööbimine",
        "night": "Öö | Ööd",
        "stayFromTo": "{from} kuni {to}",
        "stayCheckedIn": "- sisse registreeritud",
        "calendar": "Kalender",
        "availabilityError": "Broneeringu saadavuse andmete toomine ebaõnnestus",
        "bookingsForDate": "Bookings for {date}",
        "noBookingsForDate": "{date} pole broneeringuid",
        "failedToRetrieve": "Broneeringute toomine ebaõnnestus",
        "timeslotTaken": "See ajapilt on hõivatud, proovige teist"
    },
    "guests": {
        "anonymous": "Anonüümne",
        "language": "Keel",
        "unspecified": "Täpsustamata",
        "pickRoom": "Valige vaba tuba",
        "checkInDate": "Check-in date",
        "checkInTime": "Sisseregistreerimise aeg",
        "checkOutDate": "Check-out date",
        "checkOutTime": "Väljaregistreerimise aeg",
        "newCheckIn": "Uus sisseregistreerimine",
        "checkIn": "Sisseregistreerimine",
        "checkInGuest": "Registreeru külaline",
        "editGuest": "Redigeeri külalist",
        "checkOut": "Väljaregistreerimine",
        "getBill": "Võta arve",
        "guestCheckOut": "Vaata külalist",
        "billItemName": "Asja nimi",
        "billItemAmountMax4Chars": "amnt",
        "billItemCurrencyMax3Chars": "cur",
        "billDataError": "Arveteavet ei saanud tuua ({status})",
        "confirmCheckout": "Kinnitage väljamakse",
        "editStay": "Muuda viibimist",
        "editBooking": "Redigeeri broneeringut",
        "newBooking": "Uus broneering",
        "checkInBeforeOut": "Väljaregistreerimine peab toimuma pärast sisseregistreerimist.",
        "noGuests": "Külalisi pole",
        "overrideTvConfig": "Alista teleri konfiguratsioon",
        "setTvConfigConfirm": "Kas alistada valitud {guestCnt} külalise teleri konfiguratsiooni vastendus „{configName}”?<br>See konfiguratsioon ennistatakse väljaregistreerimisel.",
        "clearTvConfigConfirm": "Kas kustutada valitud {guestCnt} külalise alistatud teleri konfiguratsiooni vastendus?<br>See rakendab süsteemi seatud konfiguratsiooni vastenduse.",
        "setTvConfigSuccess": "{amount} telerit on ümber seadistatud|{amount} telerit on ümber seadistatud",
        "sendMessage": "Saada sõnum",
        "guestsNumber": "{number} guest | {number} guests",
        "person": "Isik | Isikud",
        "forceCheckOutTitle": "Sundväljaregistreerimine",
        "forceCheckOutMessage": "See külaline registreeriti automaatselt välise süsteemi kaudu. Väline süsteem vastutab külalise uuesti väljaregistreerimise eest. Selle väljaregistreerimise sundimisel ei kontrollita külalist välisest süsteemist välja ja see võib põhjustada probleeme. Kas jätkata?"
    },
    "guestMessages": {
        "to": "Kellele",
        "unreadMessages": "Lugemata sõnumid",
        "subject": "Teema",
        "create": "Uus sõnum",
        "view": "Vaada sõnumit",
        "createdHeader": "Saadetud",
        "readHeader": "Loetud",
        "displayedHeader": "Teatatud",
        "senderName": "Alates",
        "instant": "Vahetu kuva",
        "messageTemplate": "Sõnumi mall | Sõnumimallid",
        "useTemplate": "Kasutage malli"
    },
    "wakeups": {
        "roomAccount": "Tuba (account)",
        "tvMenu": "Kuvatav teleri menüü",
        "customTvMenu": "Kasutage kuvamiseks kohandatud teleri menüüd",
        "wakeupInFuture": "Äratus peab olema tulevikus",
        "wakeupExists": "Äratus on juba olemas",
        "create": "Loo äratus",
        "daily": "Iga päev",
        "status": "Staatus",
        "delivered": "Tarnitud",
        "notDelivered": "Ei ole tarnitud",
        "displayed": "Kuvatud",
        "notDisplayed": "Ei ole kuvatud",
        "confirmed": "Kinnitatud",
        "notConfirmed": "Kinnitamata",
        "editWakeup": "Redigeeri äratust",
        "deliveredWakeup": "Tarnitud äratus",
        "newWakeup": "Uus äratus"
    },
    "scheduledTasks": {
        "dailyNewSheets": "Iga päev uued linad",
        "roomType": "Ruumi tüüp | Ruumitüübid",
        "allTypes": "Kõik tüübid",
        "roomTypeEmpty": "Selle ülesande määramiseks kõikidele tubadele jätke tühjaks",
        "when": "Kui",
        "createTask": "Loo ülesanne",
        "specificRooms": "Konkreetsed ruumid"
    },
    "issues": {
        "maintenanceType": "Hooldustüüp | Hoolduse tüübid",
        "changeStatus": "Muuda olekut",
        "setIssueTo": "Määra probleemiks...",
        "editIssue": "Redigeeri probleemi",
        "created": "Loodud",
        "closed": "Suletud",
        "issueSetTo": "Probleem ruumis {room} on seatud olekusse {status}",
        "issuesSetTo": "{issues} probleemi olekuks on määratud {status}",
        "newIssue": "Uus number | Uued küsimused",
        "editingIssues": "Probleemide {issues} muutmine",
        "issueUpdated": "Probleem {type} ruumis {room} on värskendatud",
        "issueAdded": "Probleem {type} lisati ruumile {room}",
        "urgent": "Kiireloomuline",
        "flagAsClosed": "Märkige suletuks",
        "maintenanceEdit": "",
        "issueSetup": "Probleemi seadistamine"
    },
    "locations": {
        "location": "Asukoht | Asukohad",
        "createRooms": "Loo ruume",
        "createLocations": "Loo asukohti",
        "occupation": "Hõivatus",
        "locatedIn": "Asub",
        "locationSetTo": "{location} on seatud olekusse {status}",
        "locationsSetTo": "{locations} tuba on seatud olekusse {status}",
        "locationSetup": "Asukoha seadistamine",
        "category": "Kategooria | Kategooriad",
        "setRoomTo": "Määra {room}...",
        "editRoom": "Redigeeri tuba",
        "changeStatus": "Muuda olekut",
        "changeCategory": "Muuda kategooriat",
        "changeLocation": "Muutke asukohta",
        "addTag": "Lisa silt",
        "removeTag": "Eemalda silt",
        "addIssue": "Lisa probleem",
        "tagRemoved": "Märgend {tag} eemaldati ruumist {rooms} | Märgend {tag} eemaldati {rooms} ruumist",
        "tagAdded": "Märk {tag} lisati ruumi {rooms} | Märk {tag} lisati {rooms} ruumile",
        "categoryChange": "Kategooria {room} muudeti kategooriaks {category} | {rooms} muudeti kategooriasse {category}",
        "newRooms": "Uued ruumid",
        "newLocations": "Uued asukohad",
        "roomNumber": "Ruumi number",
        "locationNumber": "Asukoha number",
        "startRange": "Stardivahemik",
        "endRange": "Lõppvahemik",
        "parentLocations": "Vanemate asukohad",
        "startRangeRequired": "Algvahemik on nõutav",
        "startRangeLower": "Algusvahemik peab olema lõppvahemikust madalam",
        "endRangeRequired": "Lõppvahemik on nõutav",
        "endRangeHigher": "Lõppvahemik peab olema algusvahemikust kõrgem",
        "change": "Muuda",
        "newLocation": "New location",
        "changeLocationOf": "Muutke {room} asukohta | Muutke {rooms} ruumi asukohta",
        "locationChangeSuccess": "Asukoha muutmine õnnestus",
        "roomTag": "Ruumi silt | Ruumi sildid",
        "newTag": "Uus silt",
        "newStatus": "New status",
        "issueDeletedFrom": "Probleem kustutati ruumist {room}",
        "locationCategory": "Asukohakategooria | Asukohakategooriad",
        "roomCategory": "Ruumi kategooria | Ruumikategooriad",
        "newCategory": "Uus kategooria",
        "bedroomTv": "Magamistoas televiisor",
        "meetingRoomTV": "Koosolekuruumi televiisor"
    },
    "serviceConfig": {
        "saveConfig": "Salvesta seaded",
        "disableDailyRebootWarning": "Hoiatus. See takistab mõnel seadmel automaatsete tarkvaravärskenduste vastuvõtmist",
        "forceTvMode": "See kuvab teleri sisselülitamisel konfigureeritud peamenüü asemel telekanaleid",
        "rebootTime": "Taaskäivitamise aeg",
        "rebootTimeInvalid": "Taaskäivitusaeg on kehtetu",
        "instantContentPush": "Vahetu sisutõuge",
        "instantContentPushDescription": "Selle lubamisel edastatakse kõik sisumuudatused viivitamata kohe mõjutatud seadmetele."
    },
    "login": {
        "terms": "Tingimused",
        "resetPassword": "Lähtestage parool",
        "language": "Keel",
        "username": "Kasutajanimi",
        "password": "Parool",
        "logIn": "Logi sisse",
        "cloudManagementPortal": "Management portaal",
        "enterUsername": "Sisesta oma kasutajanimi ja parool",
        "enterEmail": "Sisesta oma emaili aadress",
        "invalidEmail": "Invalid email address",
        "resetMessage": "Täiendavad juhised saadeti aadressile {email}",
        "resetFailedMessage": "Parooli lähtestamise taotlus ebaõnnestus: {message}",
        "setNewPassword": "Sisestage uus parool",
        "redirectToLoginPageTitle": "Suuna ümber sisselogimislehele",
        "redirectToLoginPageText": "Nüüd suunatakse teid sisselogimislehele."
    },
    "latLng": {
        "openMap": "Ava",
        "lat": "Laiuskraad",
        "lng": "Pikkuskraad",
        "selectPosition": "Valige asukoht",
        "addressHint": "Saate aadressi käsitsi sisestada",
        "addressWarning": "Veenduge, et laius- ja pikkuskraad vastaksid aadressile",
        "searchFieldLabel": "Otsi aadressi",
        "address": "Aadress",
        "locationAddressErrorMessage": "Aadressiteabe lisamiseks võtke ühendust oma litsentsi administraatoriga",
        "maxLength": "Max pikkus"
    },
    "folder": {
        "newResources": "Uued ressursid",
        "uploadFile": "Faili üles laadima",
        "folder": "Kaust | Kaustad",
        "noFolders": "Ühtegi kausta veel pole",
        "doubleClickSelect": "Valimiseks topeltklõpsake",
        "newFolder": "Uus kaust",
        "copy": "Kopeeri",
        "cut": "Lõika",
        "paste": "Kleebi",
        "selected": "{amount} valitud {type} | {amount} valitud {type}-i",
        "deleting": "Üksuse {amount} kustutamine | {amount} üksuse kustutamine",
        "root": "Juur",
        "home": "Esileht",
        "emptyFolder": "See kaust on tühi",
        "createFolder": "Create folder",
        "deleteFolder": "Kustuta kaust",
        "moveToFolder": "Teisalda kausta",
        "copyToFolder": "Kopeeri kausta",
        "editFolder": "Redigeeri kausta",
        "imageSize": "Pildi suurus",
        "unknownImageSize": "Unknown image size"
    },
    "auth": {
        "loginNotAvailable": "Teenus pole saadaval – proovige hiljem uuesti",
        "wrongUserPass": "Vale kasutajanimi ja/või parool",
        "responseError": "Viga serveri vastuses",
        "noCredentials": "Mandaate pole esitatud – ei saa sisse logida",
        "errorMirageSession": "You have successfully logged in, but there was a problem loading the portal.<br><br>Try reloading or contact your manager/support.",
        "errorMirageSessionPPDS": "Olete edukalt sisse loginud, kuid teie kasutajakontol pole juurdepääsu ühelegi PPDS Wave kliendile.<br><br>Proovige uuesti laadida või võtke ühendust oma halduri/toega.",
        "invalidMirageSession": "Olete edukalt sisse loginud, kuid juurdepääs pilveportaalile on keelatud.<br><br>Proovige mõnda teist sisselogimisteenuse pakkujat või võtke ühendust oma halduri/toega.",
        "invalidMirageSessionPPDS": "Olete edukalt sisse loginud, kuid juurdepääs PPDS Wave Creatorile on keelatud.<br><br>Proovige mõnda teist sisselogimisteenuse pakkujat või võtke ühendust oma halduri/toega.",
        "unknownMirageSession": "Olete edukalt sisse loginud, kuid ilmnes tundmatu viga.<br><br>Proovige mõnda teist sisselogimisteenuse pakkujat või võtke ühendust oma halduri/toega."
    },
    "accessPanel": {
        "resellerTitle": "Edasimüüja kasutajate juurdepääsu haldamine",
        "resellerDescription": "Konfigureerige kasutaja- ja API-juurdepääs, et:<br>a) hallata edasimüüjat saitide ja litsentside hankimiseks (<span style='font-weight: bold;'>Juurdepääs kontole</span>). See EI anna juurdepääsu saitidele.<br>b) Juurdepääs nendele edasimüüjate saitidele (<span style='font-weight: bold;'>Saidi juurdepääs</span>). See annab antud juurdepääsutaseme KÕIGILE sellele edasimüüjale kuuluvatele saitidele.",
        "distributorTitle": "Turustaja kasutajate juurdepääsu haldamine",
        "distributorDescription": "Konfigureerige kasutaja- ja API-juurdepääs, et:<br>a) hallata turustajat, et varustada edasimüüjaid (<span style='font-weight: bold;'>Juurdepääs kontole</span>). See EI anna edasimüüjatele juurdepääsu.<br>b) Juurdepääs sellele turustajate edasimüüjatele (<span style='font-weight: bold;'>Edasimüüja juurdepääs</span>). See annab antud juurdepääsutaseme KÕIGILE edasimüüjatele, kes sellele turustajale kuuluvad.",
        "domainTitle": "Domeeni kasutaja juurdepääsu haldamine",
        "domainDescription": "Konfigureerige kasutaja- ja API-juurdepääs, et:<br>a) hallata domeeni edasimüüjatele (<span style='font-weight: bold;'>Juurdepääs kontole</span>). See EI anna levitajatele juurdepääsu.<br>b) Juurdepääs sellele domeenile (<span style='font-weight: bold;'>Levitaja juurdepääs</span>). See annab antud juurdepääsutaseme KÕIGILE sellesse domeeni kuuluvatele levitajatele.",
        "resellerAccess": "Edasimüüja juurdepääs",
        "sharingContextsAccess": "Kontekstide juurdepääsu jagamine",
        "deploymentAccess": "Juurdepääs saidile",
        "themeAccess": "Theme access",
        "distributorAccess": "Edasimüüja juurdepääs",
        "domainAccess": "Juurdepääs domeenile",
        "accessControl": "Juurdepääsu kontroll",
        "accessControlDescription": "Valige redigeerimiseks edasimüüja",
        "accessError": "Teil pole juurdepääsu ühegi {contextType}-i kasutajate haldusele"
    },
    "theme": {
        "theme": "Teema | Teemad",
        "importWindowHeader": "Määra sihtkaust",
        "editThemeImage": "Redigeerige teemapilti",
        "imagePlaceholder": "Pilti pole määratud",
        "editThemeCard": "Redigeeri teemakaarti",
        "publishSnapshot": "Avalda teema",
        "publishDescription": "Teema avaldamisel kirjutatakse üle kõik varasemad avaldatud versioonid. See ei mõjuta saite, mis importisid eelmised versioonid.",
        "publishSharingDescription": "Avaldatud versiooni jagamine on seadistatud jaotises Avaleht -> Edasimüüja -> Teemad. Selle seadistamiseks vajate edasimüüja jagamisjuurdepääsu.",
        "lastPublished": "Viimati avaldatud",
        "ThemeNotPublished": "Teema pole praegu avaldatud",
        "resourceStats": "Ressursi statistika",
        "editorInfo": "Pange tähele, et peaksite kontrollima piltide autoriõigusi ja kogu elementide kogumi üldist valmisolekut, enne kui klõpsate nupul \"Avalda hetktõmmis\", mis võimaldab kõigil määratud kasutajakontodel teema importida",
        "noFeaturesActivated": "Funktsioone pole aktiveeritud...",
        "importInfo": "Importige täielikud interaktiivsed menüüteemad. Ühtegi olemasolevat sisu ei muudeta",
        "importInfoTheme": "Importige sellesse teemasse muid teemasid.",
        "import": "Import",
        "folderExists": "Kaust on juba olemas",
        "importLoader": "Teie teema importimine õnnestus ja selle leiate jaotisest \"Sisu\"",
        "importSuccess": "Teie teema importimine õnnestus ja selle leiate jaotisest \"Sisu\"",
        "importSuccessAsync": "Imporditöö on alanud. Enne imporditud teemaga töötamist oodake paar minutit.",
        "importError": "Kustutage jaotises \"Sisu\" kaustad nimega {folder} ja proovige uuesti importida",
        "previewConfiguration": "Teema eelvaate seaded",
        "savePreviewError": "Seadeid ei saanud salvestada!",
        "launchPreview": "Käivitage eelvaade",
        "noConfigurationFound": "VIGA: sseadete komplekte ei leitud...",
        "themePublished": "Teema avaldatud",
        "published": "avaldatud",
        "themePublishedError": "Teemat ei saa avaldada",
        "themeSharingSuccess": "Teema jagamine on värskendatud",
        "themeSharingError": "Jagamist ei saa värskendada",
        "themeEmptyError": "Enne teema avaldamist ei saa jagamist määrata",
        "fetchError": "Praeguseid jagamisandmeid ei saa tuua",
        "editTitle": "Pealkirja määramiseks muutke kaarti.",
        "editDescription": "Kirjelduse määramiseks muutke kaarti.",
        "shareWith": "Jagatud:",
        "shareAll": "Kõik",
        "shareSubGroup": "Alam grupp",
        "shareSingle": "Üksik",
        "successfullyImported": "Edukalt imporditud",
        "successfullyImportedAsync": "Import job started",
        "importFailed": "Import ebaõnnestus",
        "newTheme": "Uus teema",
        "preview": "Teema eelvaade",
        "previewNotAvailable": "Eelvaade pole saadaval – võtke ühendust toega",
        "previewInfo": "Teema testimiseks käivitage oma brauseris virtuaalne TV-klient.<br>Sellele eelvaatele ei lisata vesimärki.",
        "loadThemesError": "Teemasid ei saa tuua",
        "themeControl": "Teema juhtimine",
        "themeControlDescription": "Valige redigeeritav teema",
        "enablePublishInfo": "Avaldamise lubamiseks määrake kogu avaldamisteave (pealkiri, kirjeldus ja teemapilt).",
        "enableSharing": "Selle avaldamiseks peate oma teema jaoks määrama vähemalt ühe jagamise",
        "panel": {
            "title": "Edasimüüjate teemahaldus",
            "description": "Teemade seadistamine"
        },
        "themeName": "Teema nimi"
    },
    "sharingContext": {
        "type": "Konteksti jagamine | Kontekstide jagamine",
        "rootFolderPrefix": "Tingimusel",
        "new": "Uus ressursipakkuja",
        "deleteConfirmTitle": "Kustuta ressursipakkuja",
        "deleteConfirmMsg": "Kinnitage, et soovite selle ressursipakkuja kustutada",
        "editorInfo": "Pidage meeles, et kõik selle ressursipakkuja kontekstis meedias tehtud muudatused on potentsiaalselt koheselt kättesaadavad kõigile saitidele, mida see pakkuja jagab.<br>Olge VÄGA ettevaatlik ka üksuste kustutamisel, kuna saidid võivad neid kasutada.",
        "deleteWarning": "HOIATUS! Olge üksuste kustutamisel VÄGA ettevaatlik, kuna saidid võivad neid kasutada",
        "deleteConfirm": "Kinnitage, et soovin selle {type} | kustutada Kinnitage, et soovin need {type} kustutada"
    },
    "signage": {
        "signage": "Signage",
        "schedule": "Ajakava | Ajakavad",
        "addTimeslot": "Lisa ajapilu",
        "contentSchedule": "Sisu ajakava | Sisu ajakava",
        "noSchedules": "Ühtegi ajakava pole paika pandud. Lisage installija koodi abil ekraan või looge ajakava.",
        "mergeDefaultTitle": "Ühendage vaikesisuga",
        "mergeDefaultConfirm": "Kas olete kindel, et soovite selle ajapilu vaikesisuga liita?",
        "mergeDefaultTimeslotTitle": "ÜHENDAMINE VAIKESISUGA"
    },
    "pms": {
        "pmsHeader": "Kinnisvarahaldussüsteemi konfiguratsioon",
        "vendor": "Müüja",
        "accessToken": "Juurdepääsuluba",
        "tigerAccessTokenHint": "TigerTMS API autentimiskood – TigerTMS süsteemides nimetatakse seda ka wsUserKeyks",
        "siteName": "Saidi nimi",
        "tigerSiteNameHint": "Selle hotelli unikaalse identifikaatori loob TigerTMS",
        "directoryId": "Kataloogi ID",
        "soapApiUrl": "SOAP API lõpp-punkt",
        "soapApiUrlHint": "URL suhtlemiseks TigerTMS iLinkiga – tühjaks jätmisel on vaikimisi \"https://checkout.tigertms.com/GenericEXT/\"",
        "linkCheckerIn": "Lubage TigerTMS-iga SISSEtuleva suhtluse oleku jälgimine",
        "linkCheckerOut": "Lubage TigerTMS-iga VÄLJAVAHEV suhtluse oleku jälgimine",
        "enableDailyReset": "Luba igapäevane lähtestamine",
        "pmsLink": "PMS link",
        "lastMsgReceived": "(Viimati saadetud {time} tagasi)",
        "hour": "tund",
        "minute": "minut",
        "forceSync": "sundsünkroonimine",
        "forceSyncSuccess": "Sünkroonimistaotlus edukalt saadetud!",
        "forceSyncError": "Sünkroonimistaotlus ebaõnnestus!",
        "pmsGetVendorError": "{pms} teenusepakkujate loendit ei õnnestunud hankida!",
        "ip": "IP",
        "port": "Port",
        "useChecksum": "Kasutage kontrollsummat",
        "pmsVendor": "PMS vendor| PMS Vendors",
        "defaultTimeoutSeconds": "Sõnumi vaikeaegumine (sekundites)",
        "charSet": "Tähemärkide komplekt"
    },
    "cast": {
        "config": {
            "adminConfigHeader": "Google Casti haldussüsteemi konfiguratsioon",
            "authToken": "Autentimismärk",
            "authTokenHint": "Google Casti haldussüsteemi turvamärk.",
            "networks": {
                "createNew": "Loo uus",
                "addNetworkWithType": "Lisage võrk {type}",
                "addChromecastNetwork": "Lisage Chrome",
                "useExisting": "Kasutage olemasolevat",
                "interfaceName": "Liidese nimi",
                "parentInterface": "Vanemaliides",
                "networkPrefixLength": "Võrgu prefiksi pikkus",
                "vlanTag": "VLAN Tag",
                "chromecastNetworks": "Google Casti võrgud",
                "clientNetworks": "Kliendivõrgud",
                "proxyIp": "Proxy IP",
                "vlanId": "VlanId",
                "interface": "Liides",
                "parentIface": "Vanem Iface",
                "ipAddressHint": "Sisestage selle liidese IP-aadress. See on aadress, kus selle võrgu seadmed saavad selle serveriga ühendust võtta.",
                "ipPlaceholder": "192.168.0.10",
                "networkPrefixPlaceholder": "24",
                "vlanTagPlaceholder": "10"
            },
            "hostPlaceholder": "0.0.0.0",
            "networkProxyHost": "Network Proxy Host",
            "networkProxyHostHint": "Hostiaadress portaali ja Google Casti haldussüsteemi vahelise ühenduse seadistamiseks.",
            "proxy": "Proxy",
            "chromecastProxy": "Google Casti puhverserveri konfiguratsioon",
            "chromecastNetworkType": "Google Casti võrk",
            "chromecastProxyServerNetworkConfig": "Google Casti puhverserveri võrgukonfiguratsioon",
            "chromecastProxyServerStatus": "Google Casti puhverserveri olek",
            "networkInterfaceStatus": "Võrguliidese olek",
            "chromecastProxyServerSignageStatus": "Google Casti puhverserveri märgistuse olek",
            "proxyIsInternetGateway": "Puhverserver on Internetile",
            "internetInterface": "Interneti-liides",
            "isGatewayForChromecasts": "Kas vaikelüüs on Google Castile",
            "isDHCPForChromecasts": "Kas DHCP on Google Castile",
            "isGatewayForClients": "Kas gateway on klientidele",
            "isDHCPForClients": "Kas DHCP on klientidele",
            "chromecastProxyServerForcedReturnPath": "Google Casti puhverserveri sunnitud tagastamise tee",
            "webInterfaceForceReturnPath": "Veebiliidese jõu tagasitee",
            "webInterfaceForceReturnPathHint": "Jätke tühjaks, välja arvatud juhul, kui on mitu kliendivõrku. Seda kasutatakse selleks, et öelda, millise liidese kaudu peaks server tagastama vastuse klientide päringutele.",
            "forcedReturnPathGateway": "Sunniviisilise tagasipöördumise tee värav",
            "forcedReturnPathGatewayPlaceholder": "Vaikelüüs",
            "forcedReturnPathGatewayHint": "Lüüs on ühendatud sunniviisilise tagasisaatmise tee võrguga. Google Casti serveri vaikelüüsi kasutamiseks jätke väli tühjaks",
            "advancedNetworking": "Täiustatud võrgundus",
            "proxyServerConfig": "Proxy Serveri seaded",
            "chromecastConfig": "Google Cast seaded",
            "pairingCodeConfig": "Sidumiskoodi seaded",
            "clientRegistrationHost": "Kliendi registreerimise host",
            "clientRegistrationHostHint": "Sisestage domeen või IP-aadress, mis osutab sellele kliendivõrgu serverile. Ärge lisage http://-d ega ühtegi URL-i skeemi.",
            "clientRegistrationHostPlaceholder": "cast.example.com",
            "guestWifiName": "Külalise WiFi nimi",
            "guestWifiNameHint": "Eraldiseisva režiimi kasutamisel kuvatakse ekraanil WiFi-nimi. Lisateabe saamiseks vaadake kohandamise dokumentatsiooni.",
            "guestWiFiPassword": "Külalise WiFi parool",
            "guestWiFiPasswordHint": "Eraldiseisva režiimi kasutamisel ekraanil kuvatav WiFi parool. Lisateabe saamiseks vaadake kohandamise dokumentatsiooni.",
            "enableSignageIntegration": "Luba märkide integreerimine",
            "globalChromecastName": "Globaalne Google Casti nimi",
            "globalChromecastNameHint": "Google Casti nimi, mida tuleks näidata kõigile külalistele.",
            "globalChromecastNamePlaceholder": "Ruumisisene Google Cast",
            "timeZone": "Ajavöönd",
            "timeZoneHint": "See on kõigi ühendatud Google Castide ajavöönd.",
            "locale": "Lokaal",
            "localeHint": "See on kõigi ühendatud Google Castide kuvakeel.",
            "pairingCodeSet": "Pairing code set",
            "pairingCodeSetHint": "Character set for generating pairing code. Characters entered here will be the only ones to be used to generate the code.",
            "pairingCodeIsCaseSensitive": "Sidumiskood on tõstutundlik",
            "pairingCodeLength": "Sidumiskoodi pikkus",
            "pairingCodeTimeoutMinutes": "Sidumiskoodi ajalõpu minutid",
            "pairingCodeTimeoutMinutesHint": "Kui kaua iga kood peaks kehtima enne uue koodi kuvamist. Kasutamisel kuvatakse sellest hoolimata uus kood. (Vaikimisi 10 minutit)",
            "pairTimeoutHours": "sidumise ajalõputunnid",
            "pairTimeoutHoursHint": "Kui kaua peaks seotud külaline olema seotud enne ühenduse automaatset katkestamist. (Vaikimisi 5 tundi). Määratud aja kasutamiseks päevas kasutage allolevat märkeruutu ja konfigureerige iga päev eraldi.",
            "useCustomTimeoutTimePerDayInstead": "Kasutage selle asemel kohandatud ajalõpuaega päevas",
            "networkProxySaveError": "Viga võrgupuhverserveri konfiguratsiooni salvestamisel",
            "saveError": "Viga seadete salvestamisel",
            "saveSuccess": "Konfiguratsioon salvestatud",
            "connectionSuccess": "Ühendus loodud",
            "connectionFailure": "Ühendust ei õnnestunud luua",
            "interfaceFetchFailure": "Liideseid ei saanud laadida",
            "reloadTooltipDefault": "Lae salvestatud andmed",
            "resetTooltip": "Lähtestab praeguse konfiguratsiooni, tuues salvestatud konfiguratsiooni.",
            "routingOfClientChromecastSessions": "Toetage kliendi Google Casti seansside marsruutimist",
            "routingOfClientNetworkMulticast": "Toetage kliendivõrgu multisaadete marsruutimist",
            "failedToLoadMsg": "Konfiguratsiooni laadimine ebaõnnestus"
        },
        "eventLogs": {
            "typeDescription": "Sündmuste logi",
            "time": "Aeg",
            "eventName": "Sündmuse nimi",
            "castUdn": "Cast UDN",
            "castName": "Cast Nimi",
            "clientVid": "Kliendi VID",
            "additionalData": "Täiendavad andmed"
        },
        "response": {
            "chromecastFetchFailed": "Google Caste ei saanud tuua.",
            "unregisteredCastFetchFailed": "Registreerimata Google Caste ei saanud tuua",
            "activeSessionFetchFailed": "Aktiivseid seansse ei saanud tuua",
            "statusFetchFailed": "Google Casti olekut ei saanud tuua",
            "statisticsFetchFailed": "Statistikat ei saanud tuua",
            "eventLogFetchFailed": "Sündmuste logi ei saanud tuua",
            "chromecastProxyConfigFetchFailed": "Google Casti puhverserveri konfiguratsiooni ei saanud tuua",
            "networksFetchFailed": "Võrke ei saanud tuua",
            "advancedNetworkingFetchFailed": "Täpsemat võrgukonfiguratsiooni ei saanud tuua"
        },
        "status": {
            "reassociationBtnText": "Taasühendus DS-iga",
            "reassociationTitle": "Kas siduda uuesti Signage'iga?",
            "reassociationWarning": "Praegune seos Digital Signage'iga kaob Seda tuleks kasutada ainult juhtudel, kui Cast ei saa Digital Signage'iga registreeruda või kui varem seotud ekraan Digital Signage'iga on kustutatud.\r   Kas luua uus ühendus?",
            "reassociationMsg": "Kas luua uus ühendus?",
            "reassociationSuccess": "Taasseotud DS-iga",
            "reassociationFailed": "DS-iga uuesti seostamine ebaõnnestus",
            "version": "Versioon",
            "serverId": "Server ID",
            "licensedFor": "Litsenseeritud",
            "status": "Staatus",
            "eno1": "eno1",
            "wip58s0": "wip58s0",
            "clientId": "Kliendi ID",
            "clientToken": "Kliendi Token kood",
            "installerToken": "Installeri Token kood",
            "installerCode": "Installeri Kood",
            "connected": "Ühendatud Push Serveriga",
            "authenticated": "Autentitud"
        },
        "chromecasts": {
            "info": {
                "castConfig": "Google Casti seaded",
                "deviceAlias": "Seadme alias",
                "deviceName": "Seadme nimi",
                "uptime": "Tööaeg",
                "isConfigurable": "On muudetav",
                "wifiMac": "WiFi MAC",
                "ssid": "SSID"
            },
            "refreshStatusBtn": "Värskenda olekut",
            "refreshStatusSuccess": "Google Casti andmeid värskendatakse." +
                "See võib võtta minuti, olenevalt sellest, kui palju Google Caste teil on. Värskendatud andmete nägemiseks vajutage nuppu Laadi uuesti",
            "refreshStatusFailed": "Oleku värskendamine ebaõnnestus.",
            "saveSuccess": "Google Casti värskendamine õnnestus",
            "saveFailed": "Google Casti värskendamine ebaõnnestus",
            "unregister": "Tühista registreerimine",
            "showConfig": "Näita seadeid",
            "unregisterConfirmTitle": "Kas tühistada Google Casti registreerimine?",
            "unregisterConfirmMsg": "Kas soovite kindlasti {cast} registreerimise tühistada",
            "unregisterSuccess": "Registreerimata {cast}",
            "unregisterFailed": "{cast} registreerimise tühistamine ebaõnnestus.",
            "updateStatusSuccess": "Google Casti {number} olek värskendatud | Värskendatud olek {number} Google Casti jaoks.",
            "updateStatusFailed": "Oleku värskendamine ebaõnnestus.",
            "showNameSuccess": "Kuvatud on {number} Google Casti nimi | Kuvatakse {number} Google Casti nimed.",
            "showNameFailed": "Nime kuvamine ebaõnnestus.",
            "rebootSuccess": "Taaskäivitatud {number} Google Cast | Taaskäivitatud {number} Google Casti.",
            "rebootFailed": "Google Casti taaskäivitamine ebaõnnestus.",
            "deletePairingsConfirmTitle": "Kustutage sidumised",
            "deletePairingsConfirmMsg": "Kas kustutada ruumi sidumised?",
            "deletePairingsSuccess": "Kustutatud {number} ruumi sidumised | Kustutatud {number} ruumi sidumised.",
            "deletePairingsFailed": "Ruumi {room} sidumiste kustutamine ebaõnnestus.",
            "forceStartCastingFailed": "Cast'i {cast} ülekandmise sunniviisiline alustamine ebaõnnestus.",
            "chromecastPairingCode": "Google Casti sidumiskood"
        },
        "unregisteredCasts": {
            "title": "Registreerimata Cast",
            "register": "Registreeri",
            "registerChromecast": "Registreeri Google Cast",
            "registerSuccess": "Registreeritud Google Cast {cast} ruumis {room}",
            "genericFailure": "Google Casti ei saanud registreerida",
            "limitReachedFailure": "Google Casti registreerimislimiit on täis",
            "deleteCastConfirmTitle": "Kustuta Google Cast",
            "deleteCastConfirmMsg": "Kas olete kindel, et soovite selle Google Casti kustutada?",
            "deleteCastFailed": "Google Casti kustutamine ebaõnnestus",
            "deleteCastSuccess": "Google Cast kustutatud"
        },
        "statistics": {
            "fetch": "Too",
            "usageInHours": "Ülekandekasutus tundides",
            "noOfPairingsPerDay": "Sidumiste arv päevas",
            "appUsageInHours": "Rakenduse kasutus tundides",
            "consecutiveHourFilterLabel": "Sündmuste filtreerimine",
            "consecutiveHourFilterHint": "Filtreerib sündmused, mille tunde on määratud summast rohkem.",
            "downloadVisiblePeriod": "Laadige alla nähtav periood",
            "exportPairings": "Ekspordi sidumised",
            "exportAppUsage": "Ekspordi rakenduse kasutus"
        },
        "activeSessions": {
            "title": "Aktiivne seanss",
            "pinOrUnpin": "Kinnitage või vabastage see sidumine",
            "pinned": "Kinnitatud",
            "pinSuccess": "Klient kinnitatud",
            "unpinSuccess": "Klient on vabastatud",
            "multiplePinUnpinSuccess": "{clients} kinnitatud/kinnitatud",
            "pinFailed": "Klienti ei saanud kinnitada",
            "disconnect": "Katkestatud klient",
            "disconnectConfirmTitle": "Katkestatud klient",
            "disconnectConfirmMsg": "Kas katkestada kliendi ja Google Casti ühendus?",
            "disconnectSuccess": "Katkestatud klient",
            "disconnectFailed": "Klienti ei saanud lahti ühendada"
        },
        "type": "Google Cast | Google Casts",
        "runningApp": "Töötav App",
        "available": "Saadaval",
        "connection": "Conn.",
        "mac": "MAC",
        "macAddress": "MAC Aadress",
        "paired": "Sidumine",
        "udn": "UDN",
        "signalNoiseLevel": "S/N",
        "client": "Cli",
        "configurable": "Seadistatav",
        "connected": "Ühendatud",
        "createdAt": "Loodud alates",
        "actions": {
            "updateStatus": "Uuenda staatust",
            "deletePairings": "Kustutage sidumised",
            "showName": "Näita nime",
            "stopCurrentSession": "Taaskäivitus",
            "generatePairingCode": "Loo sidumiskood",
            "exportSelectedToCsv": "Ekspordi valitud CSV-vormingusse",
            "forceStartCasting": "Sundkäivitus"
        },
        "networkProxySaveConfirmationMsg": "Kas olete kindel, et soovite salvestada?" +
            "Kui konfiguratsioon on valesti seadistatud, katkeb chromecasti funktsioon.",
        "castServiceOfflineMsg": "Google Casti teenus pole praegu saadaval. Võimalik, et server on võrguühenduseta või konfiguratsiooniviga haldus-/Google Casti konfiguratsiooniseadetes."
    },
    "streamManagement": {
        "allocatedStreams": {
            "device": "Seade | Seadmed",
            "allocatedStream": "Eraldatud voog",
            "itemType": "Seade",
            "addDevice": "Lisa seade",
            "editDevice": "Muuda seadet",
            "deallocateStreamTitle": "Kas tühistada voo eraldamine?",
            "deallocateStreamConfirmMsg": "Kas olete kindel, et soovite voo asukohast lahti saada?",
            "editAllocatedStream": "Redigeeri eraldatud voogu",
            "mediaUrl": "Meedia Url",
            "streamAllocatorConfig": "Voo jaoturi konfiguratsioon",
            "deallocate": "Lahti jaotamine",
            "deleteDeviceMsg": "HOIATUS. Seadmete kustutamine lõpetab kohe kõigi eraldatud telerite seansi",
            "deleteDeviceConfirm": "Kas olete kindel, et soovite seadme kustutada?",
            "chromecastIntegrationDescription": "Valige Google Casti integreerimise lubamiseks"
        },
        "config": {
            "minTimeoutWarn": "Minimaalne aeg on 2 minutit"
        },
        "allocationLogEntries": {
            "eventType": {
                "allocationFailure": "Voo eraldamine ebaõnnestus"
            },
            "eventReason": {
                "noAvailableDevices": "Saadaolevaid seadmeid pole"
            },
            "title": "Voo eraldamise logi",
            "itemType": "Voo eraldamise logi kanne"
        }
    },
    "jobs": {
        "jobList": {
            "title": "Märguanded"
        },
        "startMsgs": {
            "imageUpload": "Pildi {title} üleslaadimine algas",
            "fontUpload": "Fondi {title} üleslaadimine algas",
            "themeImport": "Teema {title} importimine algas",
            "logoUpload": "Logo {title} üleslaadimine algas"
        },
        "successMsgs": {
            "imageUpload": "Pildi {title} üleslaadimine õnnestus",
            "fontUpload": "Fondi {title} üleslaadimine õnnestus",
            "logoUpload": "Logo {title} üleslaadimine õnnestus"
        },
        "failedMsgs": {
            "imageUpload": "Pildi {title} üleslaadimine ebaõnnestus",
            "fontUpload": "Fondi {title} üleslaadimine ebaõnnestus",
            "logoUpload": "Logo {title} üleslaadimine ebaõnnestus"
        }
    }
}