import { reactive, ref } from 'vue';
import Storage from "@/lib/helpers/Storage.js";

export const createListHeaderPlugin = () => {
    return ({store}) => {
        store.listHeaders = null;
        store.listId = ref('');

        store.setListId = (id) => {
            store.listId.value = id;
        }

        store.setListHeaders = (headers) => {
            if (!store.listId) {
                console.warn('List ID not set when trying to set list headers!');
                return;
            }
            for (const key in headers) {
                headers[key].enabled = Storage.getBoolean(`listHeader.${store.listId.value}.${key}`, headers[key].initialEnabled);
            }
            store.listHeaders = reactive(headers);
        }

        store.setListHeaderEnabled = (key, enabled) => {
            if (!store.listHeaders[key]) {
                console.warn(`Header key ${key} does not exist`);
                return;
            }
            Storage.set(`listHeader.${store.listId.value}.${key}`, enabled);
            console.log(key, "=", enabled);
            store.listHeaders[key].enabled = enabled;
        }

        store.reset = () => {
            store.listHeaders = {};
            store.listId.value = null;
        }
    }
};
