import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import apis from '@/lib/api/index.js';
import { defineStore } from 'pinia';

const thisModule = 'templateFolders';

// Initialize the BaseCrudPlugin for this store

export const useTemplateFoldersStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });
    return store;
});
