import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import _ from 'lodash';

const thisModule = 'systemContextConfigs';

// Initialize the BaseCrudPlugin for this store

export const useSystemContextConfigsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.byIds = (contextIds) => {
        if(!Array.isArray(contextIds) || contextIds.length === 0) {
            return [];
        }
        const contextMap = _.keyBy(contextIds, item => item);
        return structuredClone(store.items.value.filter(context => contextMap.hasOwnProperty(context.contextId)));
    };

    store.contextIdMap = computed(() => {
        return Map.createFromArray(store.cloneItems(), 'contextId');
    });

    store.deploymentsWithContextInfo = computed(() => {
        const contextMap = useDeploymentsStore().idMap;
        const items = store.cloneItems().filter( item => contextMap.hasOwnProperty(item.contextId) );
        items.forEach(item => {
            if(contextMap.hasOwnProperty(item.contextId)) {
                const ctx = contextMap[item.contextId];
                item.name = ctx.name;
                item.belongsTo = ctx.belongsTo;
                item.managedBy = ctx.managedBy;
            }
        });
        Array.sort(items, 'name');
        return items;
    });

    store.deploymentsWithContextInfoManagedByMap = computed(() => {
        const managedByMap = {};
        store.deploymentsWithContextInfo.value.forEach(context => {
            if (!managedByMap.hasOwnProperty(context.managedBy)) {
                managedByMap[context.managedBy] = [];
            }
            managedByMap[context.managedBy].push(context);
        });
        return managedByMap;
    });

    return store;
});
