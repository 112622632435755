<template>
    <BaseDialog
        v-if="desktop"
        v-model="show"
        :max-width="maxWidth"
        :custom-class="customClass"
        :persistent="unsaved || persistent"
        :hide-cancel-action="hideCancelAction"
        :hide-all-actions="hideAllActions"
        :hide-close="hideClose"
        :cancel-text="cancelText"
        :custom-actions="true"
        :title="title"
        :eager="eager"
        :width="width"
        :retain-focus="retainFocus"
    >
        <template v-slot:topbaractions>
            <slot name="topbaractions"></slot>
        </template>
        <template v-slot:left_aligned_preactions>
            <slot name="left_aligned_preactions"></slot>
        </template>
        <template v-slot:preactions>
            <slot name="preactions"></slot>
        </template>
        <template v-slot:actions>
            <slot name="actions"></slot>
            <v-btn
                color="primary"
                :disabled="!valid || disabled"
                :loading="processing"
                elevation="1"
                @click.stop="done"
                data-testid="beApprove"
            >
                {{doneActionText}}
            </v-btn>
        </template>
        <template v-slot:content>
            <v-form v-model="valid" ref="form" @submit.prevent="done">
                <slot name="content"></slot>
            </v-form>
        </template>
    </BaseDialog>

    <BaseBottomSheet
        v-else
        v-model="show"
        :custom-actions="true"
        :done-action-text="doneActionText"
        :custom-class="customClass"
        :title="title"
        :eager="eager"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
            <v-btn
                color="primary"
                text
                elevation="1"
                :disabled="!valid || disabled"
                :loading="processing"
                @click.stop="done"
            >
                {{doneActionText}}
            </v-btn>
        </template>
        <template v-slot:content>
            <v-form v-model="valid" ref="form" @submit.prevent>
                <slot name="content"></slot>
            </v-form>
        </template>
    </BaseBottomSheet>
</template>

<script>
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import BaseDialog from '@/components/base/popup/BaseDialog.vue';
    import BaseBottomSheet from "@/components/base/popup/BaseBottomSheet.vue";
    import { useAppStateStore } from '@/stores/appState';

    export default {
        name: 'BaseEditor',
        mixins: [VModelShowMixin],
        components: {
            BaseBottomSheet,
            BaseDialog
        },
        props: {
            hideCancelAction: {default: false, type: Boolean},
            hideClose: {default: false, type: Boolean},
            eager: {default: false, type: Boolean},
            persistent: {default: false, type: Boolean},
            hideDoneAction: {default: false, type: Boolean},
            hideAllActions: {default: false, type: Boolean},
            maxWidth: {default: 400, type: [ Number, String ]},
            width: {default: "auto", type: [ String, Number ]},
            doneActionText: {required: true, type: String},
            cancelText: { default: '', type: String },
            title: {required: true, type: String},
            customClass: {default: '', type: String},
            processing: {default: false, type: Boolean},
            disabled: {default: false, type: Boolean},
            retainFocus: {default: true, type: Boolean}
        },
        data() {
            return {
                valid: false,
                unsaved: false
            }
        },
        computed: {
            desktop() {
                return useAppStateStore().desktop;
            }
        },
        methods: {
            done() {
                if (this.$refs.form.validate()) {
                    this.$emit('done');
                }
            },
            resetValidation() {
                this.$refs.form.resetValidation();
            },
            validate() {
                if (this.$refs.form !== undefined) {
                    return this.$refs.form.validate();
                } else {
                    return undefined;
                }
            }
        }
    }
</script>