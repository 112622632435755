import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import apis from '@/lib/api/index.js';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";
import Storage from '@/lib/helpers/Storage.js';
import { AccessUtilities } from '@/lib/auth/AccessUtilities.js';
import BasicCrudApi from '@/lib/api/BasicCrudApi.js';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import { useThemesStore } from '@/stores/contexts/themes.js';
import { useSharingContextsStore } from '@/stores/contexts/sharingContexts.js';
import { useContentProviderContextsStore } from '@/stores/contexts/contentProviderContexts.js';

const resCheck = responseSuccessCheck;

function getLastContext() {
    try {
        const lastCtxt = JSON.parse(Storage.getSession('lastContext', "{}"));
        return lastCtxt || {};
    } catch (e) {
        return {};
    }
}

function setLastContext(ctx) {
    Storage.setSession('lastContext', JSON.stringify(ctx || {}));
}

export const useContextStore = defineStore('context', () => {
    const _initializing = ref(true);
    const _selectedContext = ref({ });
    const _mode = ref('loading');
    const fetching = ref(false);
    const _switchingAvailable = ref(false);
    const _privileges = ref([]);
    const _provisioning = ref([]);
    const _overviewItems = ref([]);
    const _routeIds = ref([]);

    function setLogin() {
        _mode.value = 'login';
        _initializing.value = false;
    }

    const selectedContext = computed(() => {
        return _selectedContext.value === null ? {} : _selectedContext.value;
    });

    const selectedContextId = computed(() => {
        return _selectedContext.value === null ? null : (_selectedContext.value.hasOwnProperty('id') ? _selectedContext.value.id : null);
    });

    const mode = computed(() => {
        console.log("mode", _mode.value)
        return _mode.value;
    });

    const switchingAvailable = computed(() => {
        return _switchingAvailable.value;
    });

    const privileges = computed(() => {
        return _privileges.value;
    });

    const provisioning = computed(() => {
        return _provisioning.value;
    });

    const overviewItems = computed(() => {
        return _overviewItems.value;
    });

    const routeIds = computed(() => {
        return _routeIds.value;
    });

    function setLoading() {
        _mode.value = 'loading';
        _initializing.value = false;
    }

    async function autoSelectContext() {
        const lastCtx = getLastContext();
        let lastContextUsed = null;
        if (lastCtx.id) {
            lastContextUsed = useDeploymentsStore().itemById(lastCtx.id) || useThemesStore().itemById(lastCtx.id);
        }
        if (lastContextUsed) {
            await selectContext(lastContextUsed);
        } else {
            const items = [
                ...useDeploymentsStore().withDirectAccess,
                ...useThemesStore().withDirectAccess,
                ...useSharingContextsStore().withDirectAccess,
                ...useContentProviderContextsStore().withDirectAccess
            ];
            if (items.length === 1) {
                await selectContext(items[0]);
            } else {
                _mode.value = 'home';
                _selectedContext.value = null;
                _initializing.value = false;
            }
        }
    }

    async function loadOverviewContext() {
        fetching.value = true;
        const response = await apis['accountAccesses'].get('/overview');
        if (resCheck(response)) {
            _overviewItems.value = response.data;
            _switchingAvailable.value = response.data.length > 1;
        } else {
            _overviewItems.value = [];
            _mode.value = 'error';
        }
        fetching.value = false;
    }

    async function selectContext(context) {
        fetching.value = true;
        const response = await apis['accountAccesses'].get('/context/' + context.id);
        if (resCheck(response)) {
            _privileges.value = response.data.privileges;
            _provisioning.value = response.data.provisioning;
            _routeIds.value = response.data.routeIds;
            _selectedContext.value = context;
            const contextType = (() => {
                if(context == null){
                    return 'site'
                }
                switch(context.contextType) {
                    case 'Theme':
                        return 'theme'
                    case 'Site':
                        return 'site'
                    case 'Sharing':
                        return 'sharing'
                    case 'Content Provider':
                        return 'content provider'
                    default:
                        return 'site'
                }
            })
            _mode.value = contextType();
        } else {
            _selectedContext.value = null;
            _mode.value = 'home';
            setLastContext(null);
            BasicCrudApi.setContextId(null);
        }
        fetching.value = false;
        setLastContext(_selectedContext.value);
        BasicCrudApi.setContextId(context.hasOwnProperty('id') ? context.id : null);
    }

    function enableContextSwitching() {
        _switchingAvailable.value = true;
    }

    function clearContext() {
        _privileges.value = [];
        _provisioning.value = [];
        _routeIds.value = [];
        _selectedContext.value = null;
        _mode.value = 'home';
        setLastContext(null);
        BasicCrudApi.setContextId(null);
    }

    const hasAnyFeatures = computed(() => AccessUtilities.hasAnyProvisioning(_provisioning.value));

    const featuresAndPrivilegesIntersect = computed(() => AccessUtilities.featuresAndPrivilegesIntersection(_provisioning.value, _privileges.value));

    function privilegeCheckCurrentContext(feature, resource, action = 'read') {
        if (!feature) return false;
        const featureEntry = featuresAndPrivilegesIntersect.value.find(item => item.feature === feature);
        if (!featureEntry) return false;
        if (!resource) return true;
        const resourceEntry = featureEntry.resourceProvisions.find(item => item.resource === resource);
        return resourceEntry?.actions[action] === true;
    }

    function systemPrivilegeCheck(feature, resource, action) {
        const systemCtx = _overviewItems.value.find(item => item.contextType === 'Domain');
        return systemCtx ? AccessUtilities.hasPrivilege(feature, resource, action, systemCtx.privileges) : false;
    }

    function privilegeCheck(feature, resource, action, contextId) {
        const ctx = _overviewItems.value.find(item => item.contextId === contextId);
        return ctx ? AccessUtilities.hasPrivilege(feature, resource, action, ctx.privileges) : false;
    }

    function getLicenseConfiguration(feature, configurationName) {
        if (Array.isArray(_provisioning.value)) {
            const item = Array.findFirstByField(_provisioning.value, 'feature', feature);
            if(item) {
                if (Array.isArray(item.configurationProvisions)) {
                    const resourceProvision = Array.findFirstByField(item.configurationProvisions, 'configurationName', configurationName);
                    if(resourceProvision) {
                        return resourceProvision.values;
                    }
                }
            }
        }
        return [];
    }

    function getLicenseLimit(feature, resource, limit) {
        /* If limit is set we check the system limits for a given resource */
        let res = 0;
        if (Array.isArray(_provisioning.value)) {
            const item = Array.findFirstByField(_provisioning.value, 'feature', feature);
            if(item) {
                if (Array.isArray(item.resourceProvisions)) {
                    const resourceProvision = Array.findFirstByField(item.resourceProvisions, 'resource', resource);
                    if(resourceProvision && Array.isArray(resourceProvision.limits)) {
                        const limitEntry = Array.findFirstByField(resourceProvision.limits, 'limit', limit);
                        if (limitEntry) {
                            res += limitEntry.value;
                        }
                    }
                }
            }
        }
        return res;
    }

    function getLicenseLimitStrings(feature, resource, limit) {
        let res = [];
        if (Array.isArray(_provisioning.value)) {
            const item = Array.findFirstByField(_provisioning.value, 'feature', feature);
            if(item) {
                if (Array.isArray(item.resourceProvisions)) {
                    const resourceProvision = Array.findFirstByField(item.resourceProvisions, 'resource', resource);
                    if(resourceProvision && Array.isArray(resourceProvision.limits)) {
                        const limitEntry = Array.findFirstByField(resourceProvision.limits, 'limit', limit);
                        if(limitEntry && Array.isArray(limitEntry.stringValues)) {
                            res = res.concat(limitEntry.stringValues);
                        }
                    }
                }
            }
        }
        return res;
    }

    function licenseLimitCheck(feature, resource, limit, minimum) {
        /* If limit is set we check the system limits for a given resource */
        return (this.getLicenseLimit(feature, resource, limit) >= parseInt(minimum));
    }

    function featureCheck(feature) {
        for(const provision of _provisioning.value) {
            if (provision.feature === feature) {
                return true;
            }
        }
        return false;
    }

    return {
        mode,
        routeIds,
        fetching,
        switchingAvailable,
        privileges,
        provisioning,
        overviewItems,
        setLogin,
        setLoading,
        autoSelectContext,
        loadOverviewContext,
        selectContext,
        enableContextSwitching,
        clearContext,
        hasAnyFeatures,
        featuresAndPrivilegesIntersect,
        privilegeCheckCurrentContext,
        systemPrivilegeCheck,
        privilegeCheck,
        selectedContext,
        selectedContextId,
        getLicenseConfiguration,
        getLicenseLimit,
        getLicenseLimitStrings,
        licenseLimitCheck,
        featureCheck,
    };
});
