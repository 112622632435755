import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useLocationCategoriesStore } from '@/stores/locations/locationCategories.js';
import { useLocationTagsStore } from '@/stores/locations/locationTags.js';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { useTranslator } from '@/composables/useTranslator.js';
import { useEventServiceConfigStore } from '@/stores/signage/eventServiceConfig.js';
import { useHospitalityServiceConfigStore } from '@/stores/hospitality/hospitalityServiceConfig.js';

const { translateMap } = useTranslator();

const thisModule = 'locations';

export const useLocationsStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule);
    plugin({ store });
    const locationCategoriesStore = useLocationCategoriesStore();
    const locationTagsStore = useLocationTagsStore();

    store.withStatus = computed(() => {
        const data = store.cloneItems();
        if (Array.isArray(data)) {
            const locationCategoriesIdMap = locationCategoriesStore.idMapByGetter('translated');
            const locationTagsIdMap = locationTagsStore.idMapByGetter('translated');

            data.forEach((item) => {
                item.parentLocations = item.locations?.split(',').map(locationId => {
                    const parent = store.itemById(locationId);
                    if (parent) {
                        parent.categoryTitle = Map.retrieveValue(locationCategoriesIdMap, parent.categoryId, 'titleTranslated');
                        parent.titleTranslated = translateMap(parent.title);
                        parent.fullTitle = parent.categoryTitle ? `${parent.categoryTitle} ${parent.titleTranslated}` : parent.titleTranslated;
                        return parent.fullTitle;
                    }
                }) || null;

                item.parentLocation = item.parentLocations?.[0] || 'None';
                item.occupation = item.guests?.length > 0 ? 'Occupied' : 'Vacant';
                item.guests = item.guests || [];

                item.tagTitles = item.tags?.split(',').map(tagId => {
                    const tag = Map.retrieve(locationTagsIdMap, tagId);
                    return tag?.titleTranslated || null;
                }) || null;

                item.categoryTitle = Map.retrieveValue(locationCategoriesIdMap, item.categoryId, 'titleTranslated');
                item.titleTranslated = translateMap(item.title);
                item.fullTitle = item.categoryTitle ? `${item.categoryTitle} ${item.titleTranslated}` : item.titleTranslated;

                item.guestNames = item.guests?.map(g => g.name) || [];
            });
            return data.sort((a, b) => a.fullTitle.localeCompare(b.fullTitle));
        }
        return [];
    });

    store.withFullTitle = computed(() => {
        const data = store.cloneItems();
        if (Array.isArray(data)) {
            const locationCategoriesIdMap = locationCategoriesStore.idMapByGetter('translated');
            data.forEach((item) => {
                item.categoryTitle = item.categoryId ? Map.retrieveValue(locationCategoriesIdMap, item.categoryId, 'titleTranslated') : '';
                item.titleTranslated = translateMap(item.title);
                item.fullTitle = item.categoryTitle ? `${item.categoryTitle} ${item.titleTranslated}` : item.titleTranslated;
            });
            return data.sort((a, b) => a.fullTitle.localeCompare(b.fullTitle));
        }
        return [];
    });

    store.withFullTitleTagsAndParents = computed(() => {
        const data = store.withFullTitle.value;
        const locationTagsIdMap = locationTagsStore.idMapByGetter('translated');
        const locationCategoriesIdMap = locationCategoriesStore.idMapByGetter('translated');

        data.forEach((item) => {
            item.fullTitleWithParent = item.fullTitle;
            if(typeof item.locations === 'string' && item.locations.length > 0){
                item.parentLocations = item.locations?.split(',').map(locationId => {
                    const parent = store.itemById(locationId);
                    if (parent) {
                        parent.categoryTitle = Map.retrieveValue(locationCategoriesIdMap, parent.categoryId, 'titleTranslated');
                        parent.titleTranslated = translateMap(parent.title);
                        parent.fullTitle = parent.categoryTitle ? `${parent.categoryTitle} ${parent.titleTranslated}` : parent.titleTranslated;
                        return parent.fullTitle;
                    }
                }) || null;

                if (item.parentLocations?.length > 0) {
                    item.fullTitleWithParent = `${item.parentLocations[0]}, ${item.fullTitle}`;
                }
            }
            if(typeof item.tags === 'string' && item.tags.length > 0){
                item.tagTitles = item.tags?.split(',').map(tagId => {
                    const tag = Map.retrieve(locationTagsIdMap, tagId);
                    return tag?.titleTranslated || null;
                }) || null;
            }
        });
        return data;
    });

    /**
     * Return locations that are not part of hospitality rooms nor event 'rooms', i.e. floors, buildings, etc.
     */
    store.nonRoomLocations = computed(() => {
        const hospitalityCategories = useHospitalityServiceConfigStore().configValue('locationCategories', []);
        const eventCategories = useEventServiceConfigStore().configValue('locationCategories', []);
        const allCategories = [];
        allCategories.push(hospitalityCategories)
        allCategories.push(eventCategories)
        return store.withFullTitle.value.filter(i => !allCategories.includes(i.categoryId));
    });


    return store;
});
