import { defineStore } from 'pinia';
import { createServiceConfigPlugin } from '@/plugins/store/baseServceConfigPlugin';

const thisModule = 'clientServiceConfig';

export const useClientServiceConfigStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createServiceConfigPlugin(thisModule, '');
    plugin({ store });
    return store;
});
