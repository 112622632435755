import { defineStore } from 'pinia';
import { createBaseApiStorePlugin } from "@/plugins/store/baseApiStorePlugin";

const thisModule = 'embeddedCastConfigs';

export const useEmbeddedCastConfigsStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });
    return store;
});
