import Vue from 'vue';
import { useAuthStore } from '@/stores/auth.js';
import { useContextStore } from '@/stores/context.js';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import { useResellersStore } from '@/stores/contexts/resellers.js';
import { useDistributorsStore } from '@/stores/contexts/distributors.js';
import { useSharingContextsStore } from '@/stores/contexts/sharingContexts.js';
import { useContentProviderContextsStore } from '@/stores/contexts/contentProviderContexts.js';
import { useLegaciesStore } from '@/stores/contexts/legacies.js';
import { useDomainsStore } from '@/stores/admin/domains.js';
import { isEmpty } from '@/lib/helpers/Validator';
import PortalTheme from '@/lib/theme/PortalTheme';
import { useAppStateStore } from '@/stores/appState';

// Mixin for handling routes
// NOTE: Only App.vue should use this
export default {
    data() {
        return {
            ready: false
        }
    },
    created() {
        Vue.prototype.$routeHandler = this;

        this.$router.beforeEach((to, from, next) => {
            if (this.routeAuthCheck(to)) {
                next();
            } else {
                if (this.contextAccess.length > 0 && !this.routeAuthCheck(from)) {
                    this.$router.push('/');
                    //useGlobalModals().getNotification().show('error', this.$t('router.requiredPermissions'));
                }
            }
        });

        this.$router.onReady(() => {
            this.ready = true;
            if (isEmpty(this.$router.history.current.name)) {
                this.$router.push('/');
            } else if (this.contextStore.mode !== 'loading' && !this.authStore.initializing && !this.routeAuthCheck(this.$router.history.current)) {
                this.$router.replace('/');
            }
        });
    },
    computed: {
        domains() {
            return useDomainsStore().sorted;
        },
        legacies() {
            return useLegaciesStore().sorted;
        },
        resellers() {
            return useResellersStore().sorted;
        },
        distributors() {
            return useDistributorsStore().sorted;
        },
        sharingContexts() {
            return useSharingContextsStore().sorted;
        },
        contentProviderContexts() {
            return useContentProviderContextsStore().sorted;
        },
        deployments() {
            return useDeploymentsStore().sorted;
        },
        contextStore() {
            return useContextStore();
        },
        privileges() {
            return this.contextStore.privileges;
        },
        provisioning() {
            return this.contextStore.provisioning;
        },
        routeIds() {
            return this.contextStore.routeIds;
        },
        contextAccess() {
            return this.contextStore.featuresAndPrivilegesIntersect;
        },
        authStore() {
            return useAuthStore();
        },
        accountRootContextId() {
            return useAuthStore().accountRootContextId;
        }
    },
    methods: {
        systemPrivilegeCheck(feature, resource, operation) {
            return this.contextStore.systemPrivilegeCheck(feature, resource, operation);
        },
        routePrivilegeCheck(feature, resource, operation, contextId) {
            return this.contextStore.privilegeCheck(feature, resource, operation, contextId);
        },
        contextsByType(type) {
            switch(type.toLowerCase()) {
                case 'deployment':
                    return this.deployments;
                case 'distributor':
                    return this.distributors;
                case 'reseller':
                    return this.resellers;
                case 'legacy':
                    return this.legacies;
                case 'domain':
                    return this.domains;
                case 'sharingContexts':
                    return this.sharingContexts;
                case 'contentProviderContexts':
                    return this.contentProviderContexts;
            }
            return [];
        },
        checkSystemPrivilege(route, requiredAccess) {
            for (let i = 0; i < requiredAccess.length; i++) {
                switch (typeof requiredAccess[i]) {
                    case 'object':
                        const {feature, resource, operation = 'read'} = requiredAccess[i];
                        if (this.systemPrivilegeCheck(feature, resource, operation)) {
                            return true;
                        }
                        break;
                    default:
                        return false;
                }
            }
            return false;
        },
        routeAuthCheck(route, isDrawerCheck) {
            if (route.path === "/") {
                return true;
            }
            /* Special case for installation */
            if (!isDrawerCheck && this.$router.history.current.path === "/tv/tv-screens") {
                return true;
            }

            if (!this.routeDeviceCheck(route)) {
                return false;
            }
            if(!this.routeWhitelabelCheck(route)) {
                return false;
            }
            if (route.meta.modes === undefined) {
                return false;
            }

            const group = route.meta.group;
            const requiredAccess = isEmpty(group) ?
                route.meta.requiredAccess : route.meta.requiredAccess.concat(group.requiredAccess);

            if (!route.meta.modes.includes(this.contextStore.mode)) {
                return false;
            }

            // Read more about portal access on confluence: Privilege Sets - Roles - RouteId
            // check if this route has a routeId (set in routes.js)
            if (route.meta.routeId != null) {
                // if the user doesn't have the required routeId
                if (!Array.isArray(this.routeIds) || !this.routeIds.includes(route.meta.routeId)) {
                    // check if the user has System access
                    if (!this.checkSystemPrivilege(route, requiredAccess)){
                        return false;
                    }
                }
            }
            // if you have access to the routeId, then continue with the normal authCheck on feature/resource/action

            if (requiredAccess !== undefined) {
                for (let i = 0; i < requiredAccess.length; i++) {
                    switch (typeof requiredAccess[i]) {
                        case 'string':
                            if (!Array.contains(this.contextAccess, 'feature', requiredAccess[i])) {
                                return false;
                            }
                            break;
                        case 'object':
                            const {
                                feature,
                                resource,
                                operation = 'read',
                                systemContext,
                                hasContextType,
                                limit,
                                minimum,
                                rootContext
                            } = requiredAccess[i];
                            let hasLimit = true;
                            let hasAccess = true;
                            if (hasContextType && hasContextType !== '') {
                                /* Overview based - "home" mode */
                                if (this.contextsByType(hasContextType).length === 0) {
                                    return false;
                                }
                            } else if (systemContext === true) {
                                if (!this.systemPrivilegeCheck(feature, resource, operation)) {
                                    return false;
                                }
                            } else if (rootContext === true) {
                                const rootContextId = this.accountRootContextId;
                                if (!this.routePrivilegeCheck(feature, resource, operation, rootContextId)) {
                                    return false;
                                }
                            } else if (feature) {
                                /* Check current context */
                                if (limit !== undefined) {
                                    hasLimit = false;
                                    /* If limit is set we check the system limits for a given resource */
                                    if (Array.isArray(this.provisioning)) {
                                        const item = Array.findFirstByField(this.provisioning, 'feature', feature);
                                        if (item) {
                                            if (Array.isArray(item.resourceProvisions)) {
                                                const resourceProvision = Array.findFirstByField(item.resourceProvisions, 'resource', resource);
                                                if (resourceProvision && Array.isArray(resourceProvision.limits)) {
                                                    const limitEntry = Array.findFirstByField(resourceProvision.limits, 'limit', limit);
                                                    if (limitEntry && limitEntry.value >= parseInt(minimum)) {
                                                        hasLimit = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                /* If not, it's an access check */
                                const item = Array.findFirstByField(this.contextAccess, 'feature', feature);
                                if (!item) {
                                    hasAccess = false;
                                } else {
                                    if (resource) {
                                        if (!Array.isArray(item.resourceProvisions) || !Array.contains(item.resourceProvisions, 'resource', resource)) {
                                            hasAccess = false;
                                        }
                                        if (operation) {
                                            const r = Array.findFirstByField(item.resourceProvisions, 'resource', resource);

                                            if (!r || !r.hasOwnProperty('actions') || !r.actions.hasOwnProperty(operation) || r.actions[operation] !== true) {

                                                hasAccess = false;
                                            }
                                        }
                                    }
                                }
                                // Ensure we iterate the whole requiredAccess list to perform the OR access check
                                if(hasAccess) {
                                    return hasLimit && hasAccess
                                } else if(i === requiredAccess.length - 1) {
                                    return hasLimit && hasAccess
                                }

                            }
                            break;
                        default:
                            return false;
                    }
                }
            }

            if (!isEmpty(group) && group.requiredPrivilege !== undefined && this.privileges !== undefined) {
                let hasAccess = false;
                this.privileges.some(function (priv) {
                    if (priv.feature === group.requiredPrivilege) {
                        hasAccess = true;
                        return true
                    } else {
                        return false
                    }
                });
                if (!hasAccess) {
                    return false;
                }
            }
            return true;
        },
        routeDeviceCheck(route) {
            if (route.meta.device === 'mobile') {
                return !useAppStateStore().desktop;
            }

            if (route.meta.device === 'desktop') {
                return useAppStateStore().desktop;
            }

            if (route.meta.device === 'none') {
                return false;
            }

            return true;
        },
        routeWhitelabelCheck(route) {
            if (!route.meta.hasOwnProperty('requiredWhitelabel') || !Array.isArray(route.meta.requiredWhitelabel)) {
                return true;
            }

            if (PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS) {
                if(route.meta.requiredWhitelabel.indexOf('ppds') >= 0) {
                    return true;
                }
                return false;
            } else {
                if(route.meta.requiredWhitelabel.indexOf('default') >= 0 || route.meta.requiredWhitelabel.length === 0) {
                    return true;
                }
                return false;
            }
        }
    }
};
