import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';

const thisModule = 'areas';

// Initialize the BaseCrudPlugin for this store

export const useAreasStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.byType = (type) => {
        const typeLower = type.toLowerCase();
        return store.sorted.value.filter(area => {
            return area.type.toLowerCase() === typeLower;
        });
    };

    return store;
});
