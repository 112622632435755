import Vue from 'vue';
import 'cropperjs/dist/cropper.css';
import 'vuetify/dist/vuetify.min.css';
import Draggable from 'vuedraggable';
import Vuetify from 'vuetify';
import VueExcelExport from "vue-excel-export";
import VueExcelXlsx from 'vue-excel-xlsx';
import { Multipane, MultipaneResizer } from 'vue-multipane';
import {getLightTheme, getDarkTheme} from './vuetify/themes.js'

Vue.use(Vuetify);
Vue.component('Draggable', Draggable);
Vue.use(VueExcelExport);
Vue.use(VueExcelXlsx);
Vue.component('multipane', Multipane);
Vue.component('multipaneResizer', MultipaneResizer)

export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: getLightTheme(),
            dark: getDarkTheme()
        }
    }
});
