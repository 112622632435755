<template>
    <v-container class="base-dialog-header">
        <v-toolbar class="elevation-0">
            <v-btn
                v-if="closePosition === 'left'"
                icon
                style="margin-right: 2px;"
                @click.stop="$emit('close')"
            >
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
            <v-btn
                v-if="closePosition === 'right'"
                icon
                @click.stop="$emit('close')"
            >
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>

        <SearchField ref="search" v-if="searchEnabled" @change="searchChange"></SearchField>
    </v-container>
</template>

<script>
    import SearchField from "@/components/input/SearchField.vue";
    import { useAppStateStore } from '@/stores/appState';
    export default {
        name: 'BaseDialogHeader',
        components: {SearchField},
        props: {
            title: {
                type: String,
                required: true
            },
            searchEnabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            closePosition() {
                return useAppStateStore().desktop ? 'right' : 'left';
            }
        },
        methods: {
            searchChange(text) {
                this.$emit('search', text);
            },
            setSearch(text) {
                this.$refs.search.setSearch(text);
            }
        }
    }
</script>

<style lang="scss">
    .base-dialog-header {
        width: 100%;
        background: var(--v-background-base);
        position: fixed;
        z-index: 1;
        padding: 0 0 0 0;

        .search-field {
            margin-left: 12px;
            margin-right: 12px;
            margin-bottom: 8px;
        }

        .close-col {
            padding-right: 0;
        }

        .v-toolbar {
            height: 50px !important;
        }

        .v-toolbar__content {
            max-height: 50px;

            .v-toolbar__title {
                font-size: 19px;
                padding-left: 0 !important;
            }
        }
    }
</style>