import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import Vue, { computed } from 'vue';

const thisModule = 'allocationLogEntries';

// Initialize the BaseCrudPlugin for this store

export const useAllocationLogEntriesStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withExplanation = computed(() => {
        const data = [];

        if (Array.isArray(store.items.value) && store.items.value.length > 0) {
            store.sorted.value.forEach((item) => {
                let tempItem = structuredClone(item);
                if (tempItem.event) {
                    switch(tempItem.event){
                        case "allocationFailure": {
                            tempItem.event = Vue.prototype.$app.$t('streamManagement.allocationLogEntries.eventType.allocationFailure');
                        }
                    }
                }

                if (tempItem.reason) {
                    switch (tempItem.reason) {
                        case "noAvailableDevices": {
                            tempItem.reason = Vue.prototype.$app.$t('streamManagement.allocationLogEntries.eventReason.noAvailableDevices');
                        }
                    }
                }
                data.push(tempItem);
            });
        }
        return data;
    });


    return store;
});
