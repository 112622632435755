import { computed } from 'vue';
import { useLicensesStore } from '@/stores/admin/licenses.js';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';

export const createBaseContextStorePlugin = (moduleName) => {
    return ({store}) => {

        const baseApiStorePlugin = createBaseApiStorePlugin(moduleName);
        baseApiStorePlugin({store});

        store.managedBy = (managedBy) => {
            return store.sorted.value.filter(context => context.managedBy === managedBy);
        };

        store.managedByMap = computed(() => {
            const ctxMap = {};
            const legacies = store.items.value;
            legacies.forEach(legacy => {
                if (!ctxMap.hasOwnProperty(legacy.managedBy)) {
                    ctxMap[legacy.managedBy] = [];
                }
                ctxMap[legacy.managedBy].push(legacy);
            });
            return ctxMap;
        });

        /** OPTIMIZATION to have a cached way of getting count based on managedBy */
        store.countManagedByMap = computed(() => {
            const countMap = {};
            const contexts = store.items.value;
            if(Array.isArray(contexts)) {
                contexts.forEach(legacy => {
                    if (countMap.hasOwnProperty(legacy.managedBy)) {
                        countMap[legacy.managedBy]++;
                    } else {
                        countMap[legacy.managedBy] = 1;
                    }
                });
            }
            return countMap;
        });

        store.withDirectAccess = computed(() => {
            const items = store.cloneSortedItems().filter(context => {
                return (context.indirectAccess !== true);
            });
            if(Array.isArray(items)) { Array.sort(items, 'name'); }
            return items;
        });

        store.withLicensesManagedByMap = computed(() => {
            const managedByMap = {};
            const licenseMap = useLicensesStore().byTargetMap;

            store.cloneSortedItems().forEach(context => {
                if (!managedByMap.hasOwnProperty(context.managedBy)) {
                    managedByMap[context.managedBy] = [];
                }
                if (licenseMap.hasOwnProperty(context.id)) {
                    context.licenses = licenseMap[context.id];
                } else {
                    context.licenses = [];
                }
                managedByMap[context.managedBy].push(context);
            });
            return managedByMap;
        });

    }
};
