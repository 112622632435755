import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { useLocationCategoriesStore } from '@/stores/locations/locationCategories.js';
import { computed } from 'vue';
import { translateMap } from '@/i18n/helpers';

const thisModule = 'eventLocations';

// Initialize the BaseCrudPlugin for this store

export const useEventLocationsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withFullTitle = computed(() => {
        const data = store.cloneItems()
        if (Array.isArray(data) && data.length > 0) {
            const locationCategoriesIdMap = useLocationCategoriesStore().idMapByGetter('translated');
            data.forEach((item) => {
                item.categoryTitle = (item.categoryId !== null)
                    ? Map.retrieveValue(locationCategoriesIdMap, item.categoryId, 'titleTranslated')
                    : '';
                item.titleTranslated = translateMap(item.title);
                item.fullTitle = item.categoryTitle === null ? item.titleTranslated :
                    item.categoryTitle + " " + item.titleTranslated;
            });

            Array.sort(data, 'fullTitle');
        }

        return data;
    });

    return store;
});
