<template>
    <v-list-item :disabled="readOnly" v-if="stopClickEvent" v-on="vOnProp" @click.stop="$emit('itemClick', item)">
        <v-list-item-action v-if="isNotEmpty(icon)">
            <v-icon :disabled="readOnly" :color="color">{{icon}}</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{uppercaseTitle ? title.toUpperCase() : title}}</v-list-item-title>
    </v-list-item>
    <v-list-item :disabled="readOnly" v-else v-on="vOnProp" @click="$emit('itemClick', item)">
        <v-list-item-action v-if="isNotEmpty(icon)">
            <v-icon :disabled="readOnly" :color="color">{{icon}}</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{uppercaseTitle ? title.toUpperCase() : title}}</v-list-item-title>
    </v-list-item>
</template>

<script>

import { isNotEmpty } from '@/lib/helpers/Validator';

    export default {
        name: "BaseActionItem",
        props: {
            item: {
                required: false
            },
            icon: {
                default: null,
                type: String
            },
            color: {
                type: String,
                default: ''
            },
            title: {
                required: true,
                type: String
            },
            vOnProp: {
                type: [Boolean, Object]
            },
            stopClickEvent: {
                default: false,
                type: Boolean
            },
            uppercaseTitle: {
                default: false,
                type: Boolean
            },
            readOnly: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                isNotEmpty: isNotEmpty
            }
        },
    }
</script>