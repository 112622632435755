<template>
    <v-bottom-sheet
        v-model="show"
        :eager="eager"
        width="100%"
    >
        <v-card
            :class="`${tabs ? 'tabs' : ''} ${customClass} ${list ? 'list' : ''}`"
            class="base-bottom-sheet"
        >
            <v-card-title>
                <BaseDialogHeader
                    :title="title ? title : ''"
                    @close="show = false"
                >
                    <template v-slot:actions>
                        <slot
                            name="actions"
                            v-if="customActions && !hideAllActions"
                        ></slot>
                        <v-btn
                            v-else-if="!hideAllActions"
                            color="primary"
                            elevation="1"
                            text
                        >
                            {{doneActionText}}
                        </v-btn>
                    </template>
                </BaseDialogHeader>
            </v-card-title>
            <v-card-text>
                <slot name="content"></slot>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
    import BaseDialogHeader from "@/components/base/popup/BaseDialogHeader.vue";
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import PopupHistoryMixin from "@/mixins/popupHistoryMixin.js";

    export default {
        name: 'BaseBottomSheet',
        mixins: [VModelShowMixin, PopupHistoryMixin],
        components: {BaseDialogHeader},
        props: {
            customActions: {
                default: false,
                type: Boolean
            },
            hideAllActions: {
                default: false,
                type: Boolean
            },
            customClass: {
                default: '',
                type: String
            },
            doneActionText: {
                required: false,
                type: String
            },
            title: {
                required: true,
                type: String
            },
            tabs: {
                default: false,
                type: Boolean
            },
            eager: {
                default: false,
                type: Boolean
            },
            list: {
                default: false,
                type: Boolean
            }
        }
    }
</script>

<style lang="scss">
    .base-bottom-sheet {
        width: 100%;
        .v-card__title {
            height: 50px;
            padding: 0 !important;

            .v-toolbar .v-btn--flat {
                margin-right: 0;
            }
        }

        .v-card__text {
            max-height: calc(90vh - 55px);
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            padding: 16px 16px 24px 16px;
        }

        .v-input {
            margin-bottom: 12px;
        }

        .v-btn__content {
            font-size: 15px !important;
        }
    }

    .base-bottom-sheet.tabs {
        .v-window {
            padding-top: 5px;
            max-height: calc(90vh - 55px - 48px - 5px);
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        form {
            padding: 16px 20px 24px 20px;
        }

        .vue-recycle-scroller {
            .v-subheader {
                padding-left: 32px !important;
            }

            padding-bottom: 10px;
            padding-top: 5px;
        }

        .v-card__text {
            padding: 0;
            max-height: 100%;
            overflow-y: hidden;
        }
    }

    .base-bottom-sheet.list {
        .v-card__text {
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
        }

        .v-list {
            padding-top: 0;
        }
    }
</style>