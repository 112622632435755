import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';

const thisModule = 'contextAccess';

// Initialize the BaseCrudPlugin for this store
export const useContextAccessStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.byAccountType = (type) => {
        const typeLower = type.toLowerCase();
        const items = store.cloneItems();

        return items.filter(item => {
            return item.account.accountType === typeLower;
        });
    };

    return store;
});
