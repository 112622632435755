import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import i18n from '@/i18n';
import PortalTheme from '@/lib/theme/PortalTheme';
import Storage from '@/lib/helpers/Storage';
import { useUserAccountStore } from '@/stores/account/userAccount';
import { useFontsStore } from '@/stores/content/fonts';
import moment from '../lib/helpers/moment';
import availableLanguages from '@/i18n/languages';
import vuetify from '@/plugins/plugins.js';

export const useAppStateStore = defineStore('appState', () => {
    const userAccountStore = useUserAccountStore();
    const fontsStore = useFontsStore();

    const drawer = ref(false);
    const darkMode = ref(false);
    const pinMenu = ref(Storage.get('pinnedMenu') !== 'false');
    const language = ref({ code: 'en', name: 'English' });
    const amPmDisplay = ref(false);
    const shiftPressed = ref(false);
    const ctrlPressed = ref(false);
    const desktop = ref(true);
    const loadedFonts = ref([]);
    const innerHeight = ref(window.innerHeight);
    const persistSettings = ref(false);
    const height = ref(window.innerHeight);
    const width = ref(window.innerWidth);

    let mouseY = 0;
    let mouseX = 0;


    window.addEventListener('keydown', (e) => {
        if (e.key === 'Shift') shiftPressed.value = true;
        if (e.key === 'Control') ctrlPressed.value = true;

        //this.$emit('keypress', { key: e.key, ctrl: e.ctrlKey, alt: e.altKey })
    });

    window.addEventListener('visibilitychange', () => {
        if (document.visibilityState !== 'visible') {
            shiftPressed.value = false;
            ctrlPressed.value = false;
        }
    });

    window.addEventListener('keyup', (e) => {
        if (e.key === 'Shift') {
            shiftPressed.value = false;
        }

        if (e.key === 'Control') {
            ctrlPressed.value = false;
        }
    });

    window.addEventListener('mousemove', (e) => {
        mouseX = e.clientX;
        mouseY = e.clientY;
    });

    window.addEventListener('resize', () => {
        height.value = window.innerHeight;
        width.value = window.innerWidth;
        deviceCheck();
    });

    const deviceCheck = () => {
        const ua = navigator.userAgent;
        if (/Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)) {
            desktop.value = false;
        } else {
            if (!desktop.value) {
                setTimeout(() => {
                    drawer.value = true;
                }, 500);
            }
            desktop.value = true;
        }
    }

    const setDarkMode = (val) => {
        darkMode.value = val;
        vuetify.framework.theme.dark = val;
        if(persistSettings.value) {
            userAccountStore.saveSetting({ key: 'darkMode', val: val });
        }
    }

    const setDrawer = (val) => {
        drawer.value = val;
    }

    const setPinMenu = (val) => {
        pinMenu.value = val;
        Storage.set('pinnedMenu', val);
    }

    const setLanguage = (lang) => {
        if (!lang) return;
        language.value = lang;
        i18n.locale = lang.code;
        if (persistSettings.value) {
            userAccountStore.saveSetting({ key: 'language', val: lang.code });
        }
    }

    const setAmPmDisplay = (val) => {
        amPmDisplay.value = val;
        moment.amPmDisplay = val;
        if(persistSettings.value) {
            userAccountStore.saveSetting({ key: 'amPmDisplay', val });
        }
    }

    const getFlagCode = (lang) => {
        if (!lang && language.value) lang = language.value.code;
        if ((PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_UNIGUEST) && lang === 'en') return 'us';
        return lang;
    };

    const setMouseX = (x) => mouseX = x;
    const setMouseY = (y) => mouseY = y;

    const getMouseX = () => mouseX;
    const getMouseY = () => mouseY;

    const appClasses = computed(() => {
        let classes = desktop.value ? 'desktop' : 'mobile';
        classes += ' web';
        if (drawer.value) classes += ' drawer-open';
        classes += ' logged-in';

        switch (PortalTheme.getTheme()) {
            case PortalTheme.availableThemes.THEME_PPDS:
                classes += ' ppds-theme'; break;
            case PortalTheme.availableThemes.THEME_UNIGUEST:
            case PortalTheme.availableThemes.THEME_DEFAULT:
                classes += ' uniguest-theme'; break;
        }

        return classes;
    });


    const languages = computed(() => availableLanguages);

    const portalLanguages = computed(() => availableLanguages.filter(lang => lang.portal === true));

    watch(() => userAccountStore.initialized, (val) => {
        if (val) {
            persistSettings.value = false;
            if (PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS) {
                setDarkMode(false);
            } else {
                setDarkMode(userAccountStore.account.settings.darkMode === true);
            }

            setAmPmDisplay(userAccountStore.account.settings.amPmDisplay);

            language.value = Array.findFirstByField(availableLanguages, 'code', userAccountStore.account.settings.language !== null ? userAccountStore.account.settings.language : i18n.locale);
            if (language.value === null) {
                language.value = availableLanguages[0];
            }
            i18n.locale = language.value.code;

            persistSettings.value = true;

        }
    });

    watch(() => fontsStore.items, (items) => {
        if (Array.isArray(items)) {
            items.forEach((f) => {
                const id = 'font-' + f.id;
                if (!loadedFonts.value.includes(id) && f.filename) {
                    const fontFace = new FontFace(id, `url(${f.fullUrl})`);
                    loadedFonts.value.push(id);
                    fontFace.load().then((loadedFace) => document.fonts.add(loadedFace)).catch(() => {});
                }
            });
        }
    });

    // initial setup
    if (PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS) darkMode.value = false;
    deviceCheck();
    persistSettings.value = true;


    return {
        drawer,
        darkMode,
        pinMenu,
        language,
        setLanguage,
        amPmDisplay,
        height,
        width,
        shiftPressed,
        ctrlPressed,
        desktop,
        loadedFonts,
        languages,
        portalLanguages,
        innerHeight,
        persistSettings,
        getFlagCode,
        appClasses,
        setPinMenu,
        setDrawer,
        setDarkMode,
        setAmPmDisplay,
        getMouseX,
        getMouseY,
        setMouseX,
        setMouseY
    };
});