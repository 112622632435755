<template>
    <div>
        <v-snackbar
            v-if="appReady"
            :color="snackbar.type"
            :value="true"
            bottom
            right
            class="the-notification"
            v-for="(snackbar, i) in queue" :key="snackbar.text + Math.random()"
            :timeout="-1"
            :style="{'padding-bottom':calcPadding(i)}"
            :height="snackbarHeight"
            :data-testid="'tnNotification-'+snackbar.type"
        >
            <v-row dense>
                <v-icon class="notification-icon">{{snackbar.icon}}</v-icon>
                <v-col style="margin: auto;">
                    <b>{{(typeof snackbar.text === 'string') ? (snackbar.text.charAt(0).toUpperCase() + snackbar.text.substr(1)) : ''}}</b>
                </v-col>
                <v-btn icon @click="removeFromQueue(i)" data-testid="tnClose" >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-row>
        </v-snackbar>
    </div>

</template>

<script>
    import { useGlobalModals } from '@/composables/useGlobalModals';
    import { isEmpty } from '@/lib/helpers/Validator';
    import { useAppStateStore } from '@/stores/appState';

    export default {
        name: 'TheNotification',
        data() {
            return {
                text: 'Error occurred',
                type: 'success',
                icon: 'warning',
                appReady: false,
                queue: [],
                timeoutQueue: [],
                snackbarHeight: 64,
                snackbarHeightPadding: 5,
                snackExpireTimeDesktop: 10000,
                snackExpireTimeMobile: 4000,
                queueMaxlength: 4
            }
        },
        created() {
            useGlobalModals().setNotification(this);
        },
        methods: {
            deleted(type, amount = 1) {
                this.show('success', this.$tc('generic.deleted', amount, {type: type}));
            },
            success(action, type, amount = 1) {
                if (action === 'save') {
                    this.saved(type, amount);
                } else {
                    this.created(type, amount);
                }
            },
            calcPadding(i){
                return (i * (this.snackbarHeight + this.snackbarHeightPadding)) + 'px'
            },
            saved(type, amount = 1) {
                this.show('success', this.$tc('generic.saved', amount, {type: type}));
            },
            created(type, amount = 1) {
                this.show('success', this.$tc('generic.created', amount, {type: type}));
            },
            show(type, text, icon = null) {
                if (isEmpty(text)) {
                    return;
                }

                this.appReady = this.$app !== undefined;

                this.init(type, text, icon);
            },
            init(type, text, icon = null) {
                let notification = {}

                if(type === 'info'){
                    notification.type = 'success'
                } else {
                    notification.type = type;
                }
                notification.text = text;

                if (icon === null) {
                    if (type === 'success') {
                        notification.icon = 'check_circle';
                    } else if (type === 'error') {
                        notification.icon = 'warning';
                    } else {
                        notification.icon = 'info';
                    }
                } else {
                    notification.icon = icon;
                }

                this.queue.unshift(notification)

                if(this.queue.length > this.queueMaxlength){
                    this.removeFromQueue()
                }
                this.addTimeout()
            },
            removeFromQueue(index){
                if(index >= 0){
                    this.queue.splice(index,1)
                }
                else{
                    this.queue.pop()
                }
                window.clearTimeout(this.timeoutQueue.pop())
            },
            addTimeout() {
                const timeout = window.setTimeout(() => {
                    this.queue.pop()
                    this.timeoutQueue.pop()
                }, useAppStateStore().desktop ? this.snackExpireTimeDesktop : this.snackExpireTimeMobile)

                this.timeoutQueue.unshift(timeout)
            }
        }
    }
</script>

<style lang="scss">
    .the-notification {
        opacity: 0.9;
        .v-snack__content {
            padding: 14px !important;
            justify-content: normal !important;
        }

        .notification-icon {
            margin-right: 10px;
            color: #000000 !important;
            opacity: 0.5;
        }
    }

    .desktop {
        .the-notification {
            border-top: 64px solid transparent;
        }
    }

    .mobile {
        .the-notification {
            margin: 10px 10px 0 10px;

            .v-snack__wrapper {
                border-radius: 5px !important;
            }
        }
    }
</style>
