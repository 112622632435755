import { nextTick } from 'vue';
import UrlManager from "../../lib/helpers/UrlManager.js";
import { useAuthStore } from '@/stores/auth.js';
import { useContextStore } from '@/stores/context.js';
import { usePushChannel } from '@/composables/usePushChannel';
import _ from 'lodash';
import { isEmpty, isNotEmpty } from '@/lib/helpers/Validator';

const DefaultServerKeepAliveInterval = 30;
const ReconnectInterval = 10 * 1000;
const CLOSING_FOR_RECONNECT = 4000;
const CLOSING_FOR_DISCONNECT = 4001;
const PingMessage = { type: 'ping' };

// TODO: Should come from external configuration
const socketConfiguration = {

    local: {
        wsProtocol: 'ws',
        wsHost: 'localhost',
        wsPort: 1801
    },

    deploy: {
        wsProtocol: 'wss',
        wsHost: UrlManager.getPushServiceHost(),
        wsPort: 443
    }

};
const socketConfig = socketConfiguration.deploy;

export default {

    data() {
        return {
            connected: false,
            socket: null,
            reconnectTimer: null,
            keepAliveTimer: null,
            serverKeepAliveInterval: DefaultServerKeepAliveInterval
        }
    },

    computed: {
        pushChannel() {
            return usePushChannel();
        },
        contextStore() {
            return useContextStore();
        },
        selectedContextPush() {
            return this.contextStore.selectedContext;
        },
        authStore() {
            return useAuthStore();
        },
        sessionId() {
            return useAuthStore().sessionId;
        }
    },

    watch: {
        connected(value) {
            console.log('WebSocket connected state changed to ' + value)
        },
        'authStore.isAuthenticated'(isAuthenticated) {
            if (isAuthenticated)  {
                if(this.sessionId === '') {
                    console.error("Authenticated with no sessionID! Not connecting");
                } else {
                    this.connect();
                }
            } else {
                this.disconnect()
            }
        },

    },

    methods: {

        onConnect: async function(event) {

            this.stopReconnect();

            console.log(`Web socket connected`);

            this.connected = true;

            this.pushChannel.handleConnectionChange({ connected: true });

            await nextTick(); // Allow events depending on connect to happen first

            this.sendRegistration()
        },

        onClosed: async function(event) {

            const code = event.code;
            const reason = event.reason;
            const wasClean = event.wasClean;

            console.log(`Web socket closed (${code}:${reason})`);

            this.stopKeepAlive();
            this.connected = false;
            this.pushChannel.handleConnectionChange({ connected: false });

            await nextTick(); // Allow events depending on closed to happen first

            if (code !== CLOSING_FOR_RECONNECT && code !== CLOSING_FOR_DISCONNECT) {
                this.reconnect(true)
            }

        },

        onMessage(event) {

            const messageString = event.data;

            if (isEmpty(messageString)) {
                console.log("Web socket received empty message data");
                return;
            }

            const message = JSON.parse(messageString);
            if (isEmpty(message.type)) {
                console.log("Web socket received invalid message - type not set");
                return;
            }

            switch (message.type) {

                case 'register':
                    this.handleRegisterMessage(message);
                    this.pushChannel.handleRegister({ registered: message.result === 'success' });
                    break;

                case 'disconnect':
                    console.log("Push-service requested disconnect");
                    this.reconnect(false);
                    break;

                case 'dataSetChanged':
                    if (isEmpty(message.dataSetName)) {
                        console.log("Web socket received invalid data set changed message, data set not specified");
                        return;
                    }
                    this.pushChannel.handleDataSetChanged({
                                dataSet: message.dataSetName,
                                created: message.created,
                                updated: message.updated,
                                deleted: message.deleted
                            });
                    break;

                case 'resourceUpdated':
                    console.log("Resource updated message received");
                    if (isEmpty(message.resource)) {
                        console.log("Web socket received invalid resource updated message, resource not specified");
                        return;
                    }
                    const contextIds = _.get(message, 'updated.contextIds', []);
                    if(this.selectedContextPush.hasOwnProperty('id') && this.selectedContextPush.id !== null && contextIds.length > 0) {
                        if(contextIds.indexOf(this.selectedContextPush.id) === -1) {
                            console.error("Got update resource for wrong contextID!");
                            console.error("Current: " + this.selectedContextPush.id);
                            console.error(contextIds);
                            return;
                        }
                    }
                    this.pushChannel.handleResourceUpdated(
                            {
                                feature: message.feature,
                                resource: message.resource,
                                created: message.created,
                                updated: message.updated,
                                deleted: message.deleted
                            });
                    break;

                case 'pong':
                    //console.log("Web socket received pong");
                    break;

                default:
                    this.handleUnsupportedMessage(message);
                    break;
            }

        },

        onError(event) {

            const errorString = JSON.stringify(event);
            console.log(`Web socket error: ${errorString}`)
        },

        sendMessage(messageData) {

            let data;
            if (typeof messageData === "string") {
                data = messageData
            } else {
                data = JSON.stringify(messageData)
            }

            //console.log(`Web socket sending data: ${data}`);

            this.socket.send(data)
        },

        reconnect: async function(wasClosed) {

            if(this.sessionId === '') {
                console.error("PUSH - reconnect, no session ID!");
                return;
            }

            if (!wasClosed) {
                this.socket.close(CLOSING_FOR_RECONNECT);
                await nextTick()
            }

            if (this.reconnectTimer === null) {
                const interval = Math.floor(Math.random() * ReconnectInterval) + ReconnectInterval;
                console.log("Push - will reconnect in " + interval + "ms");
                this.reconnectTimer = setInterval(() => {
                    this.connect();
                }, interval);
            }
        },

        stopReconnect() {

            if (this.reconnectTimer !== null) {
                clearInterval(this.reconnectTimer);
                this.reconnectTimer = null;
            }

        },

        connect() {
            try {
                const socket = new WebSocket(`${socketConfig.wsProtocol}://${socketConfig.wsHost}:${socketConfig.wsPort}`);

                socket.onopen = this.onConnect;
                socket.onclose = this.onClosed;
                socket.onmessage = this.onMessage;
                socket.onerror = this.onError;

                this.socket = socket;

            } catch (e) {
                console.log(`Web socket connect failed: ${e}`);
                this.reconnect(true)
            }
        },

        disconnect() {

            this.stopReconnect();

            if (this.socket !== null) {
                this.socket.close(CLOSING_FOR_DISCONNECT);
            }

        },

        sendRegistration() {

            const sessionId = this.sessionId;
            const data = {
                type: 'register',
                clientType: 'portal',
                clientId: sessionId ? sessionId : ''
            };

            try {
                this.sendMessage(data);
            } catch (e) {
                console.log(`Web socket send failed: ${e}`)
            }

        },

        sendPing() {

            this.sendMessage(PingMessage)
        },

        startKeepAlive() {

            if (this.keepAliveTimer != null) {
                this.stopKeepAlive()
            }

            const interval = this.serverKeepAliveInterval - 10;
            this.keepAliveTimer = setInterval(this.sendPing.bind(this), interval * 1000)
        },

        stopKeepAlive() {

            clearInterval(this.keepAliveTimer);
            this.keepAliveTimer = null;
        },

        handleRegisterMessage(message) {

            const result = message.result;
            if (result === 'success') {

                if (isNotEmpty(message.keepalive)) {
                    this.serverKeepAliveInterval = message.keepalive;
                }

                this.startKeepAlive()

            } else if (result === 'not-authenticated'){

                console.log(`Web socket registration failed: ${message.reason}`)

            } else {
                // Unknown result
            }

        },

        handleUnsupportedMessage(message) {

            console.log(`Received unsupported message type: ${message.type}`);
        }

    }

}
