import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { useClientsStore } from '@/stores/clients/clients.js';
import { computed } from 'vue';

const thisModule = 'virtualRemoteClients';

// Initialize the BaseCrudPlugin for this store

export const useVirtualRemoteClientsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.activeSessionsOnlyWithClientNames = computed(() => {
        let data = store.cloneItems().map(obj => ({ ...obj, name: 'Unknown', room: 'Unknown' }));
        if(Array.isArray(data)) {
            data = data.filter( it => {
                return it.remoteClientId != null
            });

            const clients = useClientsStore().withProcessedStatusAndOccupancy;

            for (let i = 0; i < data.length; i++) {
                clients.forEach(client => {
                    if (client.id === data[i].screenClientId) {
                        data[i].name = client.name;
                        data[i].room = client.locationTitle;
                        data[i].screenClientOnline = client.online;
                    }
                })
            }
            Array.sort(data, 'room', true);
            return data;
        }
        return null;
    });

    return store;
});
