import { defineStore } from 'pinia';
import { computed } from 'vue';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { useTemplateSourcesStore } from '@/stores/content/templateSources.js';
import { translateMap } from '@/i18n/helpers';

const thisModule = 'templates';

export const useTemplatesStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule);
    plugin({ store });

    store.translated = computed(() => {
        const data = store.cloneItems();
        const templateSourceIdMap = useTemplateSourcesStore().idMap;

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.templateSourceName = Map.retrieveValue(templateSourceIdMap,
                    item.templateSourceId, 'name', '');
                item.nameTranslated = translateMap(item.name);
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    });

    return store;
});
