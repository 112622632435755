<template></template>

<script>
import { useGlobalModals } from '@/composables/useGlobalModals';
import { useAuthStore } from '@/stores/auth.js';
import { useJobsStore } from '@/stores/jobs.js';

export default {
    name: "TheJobHandler",
    computed: {
        globalModals() {
            return useGlobalModals();
        },
        jobs() {
            return useJobsStore().sorted;
        },
        sessionId() {
            return useAuthStore().sessionId;
        },
    },
    watch: {
        jobs: function(newVals, oldVals) {
            const me = this;

            newVals.forEach(function (newVal) {
                const oldVal = oldVals.find(it => it.id === newVal.id);

                if (oldVal && newVal && !oldVal.completed && newVal.completed) {
                    if (me.sessionId !== newVal.sessionId) {
                        return;
                    }

                    if (newVal.state === 'Success') {
                        me.globalModals.getNotification().show('info', me.$t(newVal.successMsg, { title: newVal.title }));
                    } else {
                        me.globalModals.getNotification().show('error', me.$t(newVal.failMsg, { title: newVal.title }));
                    }
                }
            });
        }
    }
}
</script>

<style scoped></style>