import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { useLocationsStore } from '@/stores/locations/locations.js';
import { useHospitalityGuestsStore } from '@/stores/hospitality/hospitalityGuests.js';
import { useScreensStore } from '@/stores/screens/screens.js';
import { useScheduleRuleSetsStore } from '@/stores/screens/scheduleRuleSets.js';
import { isNotEmpty } from '@/lib/helpers/Validator';
import { computed } from 'vue';
import _ from 'lodash';


function getBestNetworkValues(obj) {
    if (obj === null || obj === undefined) {
        return null;
    }
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key].ip !== null && obj[key].ip !== "" && obj[key].ip !== 'unassigned') {
            return obj[key];
        }
    }
    return null;
}

const thisModule = 'clients';

// Initialize the BaseCrudPlugin for this store

export const useClientsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withProcessedStatusAndOccupancy = computed(()=> {
        const data = store.cloneItems();
        if(Array.isArray(data)) {
            const locationsIdMap = useLocationsStore().idMapByGetter('withFullTitleTagsAndParents');
            const guestLocationsIdMap = useHospitalityGuestsStore().idMapByGetter(null, 'locationId', null);
            const screensIdMap = useScreensStore().idMapBy('clientId');
            const scheduleRuleSetsIdMap = useScheduleRuleSetsStore().idMap;
            const currentTimestamp = Math.round((Date.now()/1000));

            data.forEach(item => {
                item.scheduleMappingId = null;
                item.scheduleMappingName = '';
                const screen = Map.retrieve(screensIdMap, item.id);
                if(screen && screen.scheduleRuleSetId) {
                    item.scheduleRuleSetId = screen.scheduleRuleSetId;
                    if(item.scheduleRuleSetId) {
                        const scheduleRuleSet = Map.retrieve(scheduleRuleSetsIdMap, item.scheduleRuleSetId);
                        if(scheduleRuleSet && scheduleRuleSet.name) {
                            item.scheduleMappingName = scheduleRuleSet.name;
                        }
                    }
                }

                item.authentication = item.authenticated ? 'Authenticated' : 'Pending';
                item.locationTitle = Map.retrieveValue(locationsIdMap,
                    item.locationId, 'fullTitle');

                item.locatedIn = Map.retrieveValue(locationsIdMap,
                    item.locationId, 'locations');
                item.locatedInTitle = " ";
                if(item.locatedIn === null) {
                    item.locatedIn = "No parent location";
                    if (item.locationId == null) {
                        item.locatedInTitle = "No parent location";
                    }
                } else {
                    const idArray = item.locatedIn.split(",")
                    if(Array.isArray(idArray) && item.locatedIn.length > 0) {
                        const titleArray = idArray.map((locationId) => {
                            return Map.retrieveValue(locationsIdMap, locationId, 'fullTitleWithParent', "No parent location");
                        });
                        item.locatedInTitle = titleArray.join(", ");
                    } else if (typeof  item.locatedIn === 'string') {
                        item.locatedInTitle = Map.retrieveValue(locationsIdMap,
                            item.locatedIn, 'fullTitleWithParent', "No parent location");
                    }
                }
                item.occupied = Map.retrieve(guestLocationsIdMap, item.locationId) !== null;

                if(isNotEmpty(item.status)) {
                    const deviceType = item.status.deviceType;
                    const deviceModel = item.status.deviceModel;
                    const network = getBestNetworkValues(item.status.network);

                    item.poweredOn = item.online === true && item.status.poweredOn;
                    item.livePreview = item.poweredOn;
                    item.network = JSON.stringify(item.status.network);
                    item.ip = network !== null && network !== undefined ? network.ip : 'Unknown';
                    item.mac = network !== null && network !== undefined ? network.mac : 'Unknown';
                    item.deviceType = deviceType + '/' + deviceModel;
                    item.lastReboot = null;
                    item.extendedId = "N/A";
                    if(item.status.deviceInfo) {
                        item.resolution = item.status.deviceInfo.resolution + " / " + item.status.deviceInfo.realResolution;
                        item.extendedId = _.get(item, 'status.deviceInfo.extid', 'N/A');
                        if( (item.status.deviceInfo.hasOwnProperty('time') || item.status.hasOwnProperty('timestamp') ) && item.status.deviceInfo.hasOwnProperty('uptime')) {
                            let deviceTimestamp = 0;
                            if (item.status.timestamp) {
                                deviceTimestamp = Math.round(item.status.timestamp / 1000);
                            } else {
                                deviceTimestamp = (Date.parse(item.status.deviceInfo.time) / 1000);
                            }
                            let uptimeSeconds = item.status.deviceInfo.uptime;
                            item.lastReboot = Math.abs(currentTimestamp - (deviceTimestamp + uptimeSeconds));
                            if (item.lastReboot >= (60*60*24*365*5)) {
                                item.lastRebootTxt = null;
                            } else if (item.lastReboot >= (60*60*24*365) && item.poweredOn) {
                                item.lastRebootTxt = null;
                            } else if (item.lastReboot >= (60*60*24)) {
                                item.lastRebootTxt = Math.round(item.lastReboot / 60 / 60 / 24) + 'd';
                            } else if (item.lastReboot >= (60*60)) {
                                item.lastRebootTxt = Math.round(item.lastReboot / 60 / 60) + 'h';
                            } else if (item.lastReboot >= 60) {
                                item.lastRebootTxt = Math.round(item.lastReboot / 60) + 'm';
                            } else {
                                item.lastRebootTxt = item.lastReboot + 's';
                            }
                        }
                        if (item.status.deviceInfo.debugInfo) {
                            item.debugInfo = item.status.deviceInfo.debugInfo;
                        }
                    } else {
                        item.resolution = "N/A";
                    }
                    item.firmwareShort = (typeof item.status.firmwareVersion === 'string') ?
                        item.status.firmwareVersion.substr(0, 20) + (item.status.firmwareVersion.length > 20 ? '...' : '') : 'N/A';
                    item.firmwareFull = (typeof item.status.firmwareVersion === 'string') ?
                        item.status.firmwareVersion : 'N/A';
                } else {
                    item.poweredOn = false;
                    item.network = null;
                    item.ip = null;
                    item.mac = null;
                    item.deviceType = null;
                    item.resolution = null;
                    item.firmwareShort = '-';
                    item.firmwareFull = '-';
                    item.lastReboot = null;
                    item.lastRebootTxt = null;
                    item.extendedId = "N/A";
                }
            });
            Array.sort(data, 'locationTitle', true);
            return data;
        }
        return [];
    });

    store.idMapWithLocation = computed(() => {
        const data = store.cloneItems();
        if(Array.isArray(data)) {
            const locationsIdMap = useLocationsStore().idMapByGetter('withFullTitle');

            data.forEach((item) => {

                item.locationTitle = Map.retrieveValue(locationsIdMap,
                    item.locationId, 'fullTitle');

                item.locatedIn = Map.retrieveValue(locationsIdMap,
                    item.locationId, 'locations');
                item.locatedInTitle = "";
                if(item.locatedIn === null) {
                    item.locatedIn = "";
                } else {
                    if(Array.isArray(item.locatedIn) && item.locatedIn.length > 0) {
                        item.locatedInTitle = Map.retrieveValue(locationsIdMap,
                            item.locatedIn[0], 'fullTitle');
                    } else if (typeof  item.locatedIn === 'string') {
                        item.locatedInTitle = Map.retrieveValue(locationsIdMap,
                            item.locatedIn, 'fullTitle');
                    }
                }
            });
            return Map.createFromArray(data, 'id');
        }
        return [];
    });

    store.previewClients = computed(() => {
        return store.withProcessedStatusAndOccupancy.value.filter(c => { return (c.clientType === 'preview') });
    });

    store.agentClients = computed(() => {
        return store.cloneItems().filter(c => {
            return (c.clientType === 'agent')
        });
    });

    store.signageClients = computed(() => {
        return store.withProcessedStatusAndOccupancy.value.filter(c => { return (c.clientType === 'signage') });
    });

    store.signageClientsIdMap = computed(() => {
        return Map.createFromArray(store.signageClients.value, 'id');
    });

    store.tvClients = computed(() => {
        return store.withProcessedStatusAndOccupancy.value.filter(c => { return (c.clientType === 'tv') });
    });

    /* Do we need to clione these? Can we create getters? */
    store.clientsSimple = (type) => {
        return store.cloneItems().filter(c => { return (c.clientType === type) });
    };

    store.clientsPoweredOn = (type) => {
        return store.clientsOnline(type).filter(c => _.get(c, 'status.poweredOn', false) === true);
    };

    store.clientsOnline = (type)  => {
        return store.cloneItems().filter(c => { return (c.clientType === type && c.online === true) });
    };

    store.clientsOffline = (type)  => {
        return store.cloneItems().filter(c => { return (c.clientType === type && c.online === false) });
    };


    return store;
});
