import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useContextTypesStore } from '@/stores/admin/contextTypes.js';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import { useContentProviderContextsStore } from '@/stores/contexts/contentProviderContexts.js';
import { useSharingContextsStore } from '@/stores/contexts/sharingContexts.js';
import { useSystemsStore } from '@/stores/contexts/systems.js';
import { useDistributorsStore } from '@/stores/contexts/distributors.js';
import { useResellersStore } from '@/stores/contexts/resellers.js';
import { useThemesStore } from '@/stores/contexts/themes.js';
import { useLegaciesStore } from '@/stores/contexts/legacies.js';
import { useDomainsStore } from '@/stores/admin/domains.js';

function getModuleByName(moduleName) {
    switch (moduleName) {
        case 'Domain':
            return useDomainsStore();
        case 'System':
            return useSystemsStore();
        case 'Sharing':
            return useSharingContextsStore();
        case 'Content Provider':
            return useContentProviderContextsStore();
        case 'Distributor':
            return useDistributorsStore();
        case 'Reseller':
            return useResellersStore();
        case 'Deployment':
            return useDeploymentsStore();
        case 'Theme':
            return useThemesStore();
        case 'Legacy':
            return useLegaciesStore();
        default:
            console.error("Could not find module for context-type: " + moduleName);
            return null;
    }
}

export const useCommonStore = defineStore('contextStore', () => {

    // Check if any module is processing or fetching
    const processing = (modules) => {
        let processing = false;

        modules.forEach(module => {
            if(typeof module === 'object') {
                /* Pinia store */
                if(module.processing === true || module.fetching === true) {
                    processing = true;
                }
            } else {
                console.error("A string module identifier was passed to processing instead of a module object:", module);
            }
        });
        return processing;
    };

    // Fetch all contexts NOT including legacy
    const allContexts = computed(() => {
        const contextTypes = useContextTypesStore().namesIncludingSystem;
        let items = [];

        contextTypes.forEach(ctxType => {
            if (ctxType !== "Legacy") {
                const contextTypeModule = getModuleByName(ctxType);
                if (contextTypeModule) {
                    items = items.concat(structuredClone(contextTypeModule.items));
                }
            }
        });

        if (Array.isArray(items)) {
            items.sort((a, b) => a.name.localeCompare(b.name));
        }

        return items;
    });

    const allContextsWithLegacy = computed(() => {
        const contextTypes = useContextTypesStore().namesIncludingSystem;
        let items = [];

        contextTypes.forEach(ctxType => {
            const contextTypeModule = getModuleByName(ctxType);
            if (contextTypeModule) {
                items = items.concat(structuredClone(contextTypeModule.items));
            }
        });

        if (Array.isArray(items)) {
            items.sort((a, b) => a.name.localeCompare(b.name));
        }

        return items;
    });

    // Create a map of all contexts by their ID
    const allContextsIdMap = computed(() => {
        return new Map(allContexts.value.map(item => [item.id, item]));
    });

    // Fetch administrative contexts
    const administrativeContexts = computed(() => {
        const administrativeContextTypes = useContextTypesStore().namesOfAdministrativeTypes;
        let items = [];

        administrativeContextTypes.forEach(ctxType => {
            const module = getModuleByName(ctxType);
            if (!module) {
                console.error("Could not map context-type: " + ctxType);
            } else {
                items = items.concat(structuredClone(module.items));
            }
        });

        if (Array.isArray(items)) {
            items.sort((a, b) => a.name.localeCompare(b.name));
        }

        return items;
    });

    // Create a map of all contexts by their ID
    const allContextsWithLegacyIdMap = computed(() => {
        return new Map(allContextsWithLegacy.value.map(item => [item.id, item]));
    });

    return {
        processing,
        allContexts,
        allContextsWithLegacy,
        allContextsIdMap,
        allContextsWithLegacyIdMap,
        administrativeContexts
    };
});
