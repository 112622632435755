<template>
    <v-menu
        left
        bottom
        max-height="100vh"
        min-width="300px"
        transition="slide-x-transition"
        offset-y
        v-model="showView"
    >
        <template v-slot:activator="{on}">
            <v-btn id="contextSelectorButton" icon v-on="on" data-testid="cmmmcsDots">
                <v-icon>mdi-dots-grid</v-icon>
            </v-btn>
        </template>

        <v-list>
            <v-list-item @click="goHome" data-testid="cmmmcsHome">
                <v-list-item-action>
                    <v-icon>home</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $t('generic.home') }}</v-list-item-title>
            </v-list-item>

            <v-menu
                v-if="hasThemes && !$vuetify.breakpoint.mdAndDown"
                right
                bottom
                open-on-hover
                transition="slide-x-transition"
                offset-x
            >
                <template v-slot:activator="{on}">
                    <v-list-item v-on="on" @click.s.stop="">
                        <v-list-item-action>
                            <v-icon>mdi-package-variant</v-icon>
                        </v-list-item-action>
                        <v-list-item-title data-testid="cmmmcsThemes">{{ $tc('generic.theme', 2) }}</v-list-item-title>
                        <v-list-item-action class="justify-end">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>

                <v-card>
                    <div style="padding-top: 6px;" class="ctx-menu-header" v-if="!$vuetify.breakpoint.mdAndDown">
                        {{ $t('appbar.availableThemes') }}
                    </div>
                    <SearchField style="margin-top:10px;" :label="$t('appbar.searchThemes')"
                                 @click.stop=""
                                 @change="themeSearchChange"></SearchField>
                    <v-divider style="margin: 6px 0;"></v-divider>
                    <v-list>
                        <template v-for="(item) in filteredThemes">
                            <v-list-item @click="onSelectContext(item)" data-testid="mmcTheme">
                                <v-list-item-action>
                                    <v-icon>mdi-package-variant</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>{{ getName(item.name) }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>
            </v-menu>
            <v-menu
                    v-if="hasSharingContexts"
                    right
                    bottom
                    open-on-hover
                    transition="slide-x-transition"
                    offset-x
            >
                <template v-slot:activator="{on}">
                    <v-list-item v-on="on" @click.s.stop="">
                        <v-list-item-action>
                            <v-icon>mdi-share-variant</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $tc('generic.sharingContext', 2) }}</v-list-item-title>
                        <v-list-item-action class="justify-end">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                <v-card>
                    <div style="padding-top: 6px;" class="ctx-menu-header">
                        {{ $t('appbar.availableSharingContexts') }}
                    </div>
                    <SearchField style="margin-top:10px;" :label="$t('appbar.searchSharingContexts')"
                                 @click.stop=""
                                 @change="sharingContextSearchChange"></SearchField>
                    <v-divider style="margin: 6px 0;"></v-divider>
                    <v-list>
                        <template v-for="(item) in filteredSharingContexts">
                            <v-list-item @click="onSelectContext(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-share-variant-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>{{ getName(item.name) }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>

            </v-menu>
            <v-menu
                    v-if="hasContentProviderContexts"
                    right
                    bottom
                    open-on-hover
                    transition="slide-x-transition"
                    offset-x
            >
                <template v-slot:activator="{on}">
                    <v-list-item v-on="on" @click.s.stop="">
                        <v-list-item-action>
                            <v-icon>mdi-share-variant</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $tc('generic.contentProviderContext', 2) }}</v-list-item-title>
                        <v-list-item-action class="justify-end">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                <v-card>
                    <div style="padding-top: 6px;" class="ctx-menu-header">
                        {{ $t('appbar.availableContentProvidersContexts') }}
                    </div>
                    <SearchField style="margin-top:10px;" :label="$t('appbar.searchContentProviderContexts')"
                                 @click.stop=""
                                 @change="contentProviderContextSearchChange"></SearchField>
                    <v-divider style="margin: 6px 0;"></v-divider>
                    <v-list>
                        <template v-for="(item) in filteredContentProviderContexts">
                            <v-list-item @click="onSelectContext(item)">
                                <v-list-item-action>
                                    <v-icon>mdi-share-variant-outline</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>{{ getName(item.name) }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card>

            </v-menu>

            <div style="padding-top: 6px;" class="ctx-menu-header" v-if="accessibleSites.length > 0">
                {{ ppdsThemeActive() ? $t('appbar.availableCustomers') : $t('appbar.availableSites') }}
            </div>

            <SearchField style="margin-top:10px;"
                         :label="ppdsThemeActive() ? $t('appbar.searchCustomers') : $t('appbar.searchSites')"
                         @click.stop=""
                         v-if="accessibleSites.length > 0"
                         @change="siteSearchChange"></SearchField>
            <v-divider v-if="accessibleSites.length > 0" style="margin: 6px 0;"></v-divider>
        </v-list>
        <v-list ref="list" style="overflow-y: scroll; max-height: calc(100vh - 350px);" v-if="accessibleSites.length > 0">
            <template v-for="(item) in filteredSites">
                <v-list-item @click="onSelectContext(item)" data-testid="mmcSite">
                    <v-list-item-action>
                        <v-icon>mdi-office-building-marker</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ getName(item.name) }}</v-list-item-title>
                </v-list-item>
            </template>
            <v-subheader class="pa-4" v-if="accessibleSites.length === 0">
                {{ noSitesText }}
            </v-subheader>
            <v-subheader class="pa-4" v-else-if="filteredSites.length === 0">
                {{ $t('generic.searchCriteria', {type: $tc('admin.site', 1)}) }}
            </v-subheader>
        </v-list>
    </v-menu>
</template>

<script>
import SearchField from "@/components/input/SearchField.vue";
import vModelShowMixin from "@/mixins/vModelShowMixin.js";
import { useContextStore } from '@/stores/context.js';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import { useThemesStore } from '@/stores/contexts/themes.js';
import { useSharingContextsStore } from '@/stores/contexts/sharingContexts.js';
import { useContentProviderContextsStore } from '@/stores/contexts/contentProviderContexts.js';
import _ from 'lodash';
import { isEmpty } from '@/lib/helpers/Validator';
import { useAppStateStore } from '@/stores/appState';
import PortalTheme from '@/lib/theme/PortalTheme';

export default {
    name: 'MainMenuContextSelector',
    mixins: [vModelShowMixin],
    components: {
        SearchField
    },
    data() {
        return {
            siteSearch: '',
            themeSearch: '',
            sharingContextSearch: '',
            contentProviderContextSearch: '',
            scrollTop: 0,
            showView: false,
        }
    },
    mounted() {
    },
    computed: {
        themesWithDirectAccess() {
            return useThemesStore().withDirectAccess;
        },
        sharingContextsWithDirectAccess() {
            return useSharingContextsStore().withDirectAccess;
        },
        contentProviderContextsWithDirectAccess() {
            return useContentProviderContextsStore().withDirectAccess;
        },
        deploymentsWithDirectAccess() {
            return useDeploymentsStore().withDirectAccess;
        },
        contextStore() {
            return useContextStore();
        },
        noSitesText() {
            if(this.contextStore.mode === 'site') {
                return this.$tc('generic.noMoreItemsAvailable', 2, {type: this.$tc('admin.site', 1)})
            }
            return this.$tc('generic.madeYet', 2, {type: this.$tc('admin.site', 1)})
        },
        accessibleThemes() {
            return this.themesWithDirectAccess;
        },
        hasThemes() {
            return this.accessibleThemes.length > 0;
        },
        filteredSites() {
            if (isEmpty(this.siteSearch)) {
                return this.accessibleSites;
            }
            return _.filter(this.accessibleSites, i => {
                return _.valuesIn(i).join().toLowerCase().includes(this.siteSearch.toLowerCase());
            });
        },
        filteredThemes() {
            if (isEmpty(this.themeSearch)) {
                return this.accessibleThemes;
            }
            return _.filter(this.accessibleThemes, i => {
                return _.valuesIn(i).join().toLowerCase().includes(this.themeSearch.toLowerCase());
            });
        },
        accessibleSharingContexts() {
            return this.sharingContextsWithDirectAccess;
        },
        accessibleContentProviderContexts() {
            return this.contentProviderContextsWithDirectAccess;
        },
        hasSharingContexts() {
            return this.accessibleSharingContexts.length > 0;
        },
        hasContentProviderContexts() {
            return this.accessibleContentProviderContexts.length > 0;
        },
        filteredSharingContexts() {
            if (isEmpty(this.sharingContextSearch)) {
                return this.accessibleSharingContexts;
            }
            return _.filter(this.accessibleSharingContexts, i => {
                return _.valuesIn(i).join().toLowerCase().includes(this.sharingContextSearch.toLowerCase());
            })
        },

        filteredContentProviderContexts() {
            if (isEmpty(this.contentProviderContextSearch)) {
                return this.accessibleContentProviderContexts;
            }
            return _.filter(this.accessibleContentProviderContexts, i => {
                return _.valuesIn(i).join().toLowerCase().includes(this.contentProviderContextSearch.toLowerCase());
            })
        },

        accessibleSites() {
            const items = this.deploymentsWithDirectAccess;
            const currentContext = this.contextStore.selectedContext.hasOwnProperty('id') ? this.contextStore.selectedContext.id : null;
            if (currentContext === null) {
                return items;
            }
            return items.filter(context => {
                return (context.id !== currentContext);
            });
        }
    },
    methods: {
        ppdsThemeActive() {
            return PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS;
        },
        siteSearchChange(search) {
            this.siteSearch = search;
        },
        themeSearchChange(search) {
            this.themeSearch = search;
        },
        sharingContextSearchChange(search) {
            this.sharingContextSearch = search;
        },
        contentProviderContextSearchChange(search) {
            this.contentProviderContextSearch = search;
        },
        getName(name) {
            const maxLength = 30;
            if (typeof name === 'string') {
                if (name.length > maxLength) {
                    return name.substr(0, maxLength) + '...';
                }
                return name
            }
            return "";
        },
        async goHome() {
            if (this.$vuetify.breakpoint.mdAndDown) {
                useAppStateStore().setDrawer(false);
            }
            await this.contextStore.clearContext();
        },
        async onSelectContext(context) {
            if (this.$vuetify.breakpoint.mdAndDown) {
                useAppStateStore().setDrawer(false);
            }
            this.showView = false;

            await this.contextStore.selectContext(context);
            if(this.$router.currentRoute.path !== "/") {
                try {
                    await this.$router.push("/");
                } catch(e) {
                    console.error("Could not change route to /");
                    console.error(e);
                }
            }
        }
    }
}
</script>

<style lang="scss">
.ctx-menu-header {
    padding: 4px 16px;
    font-weight: bold;
    font-size: 14px;
}

</style>
