import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import moment from 'moment';
import { useHomeSiteHeadersStore } from '@/stores/listHeaders/homeSiteHeaders.js';
import { useOwnersStore } from '@/stores/owners.js';
import { useDistributorsStore } from '@/stores/contexts/distributors.js';
import { useResellersStore } from '@/stores/contexts/resellers.js';
import { useOverviewContextsStore } from '@/stores/contexts/overviewContexts.js';
import { useCommonStore } from '@/stores/common.js';
import _ from 'lodash';
import { useSiteStatsStore } from '@/stores/siteStats';

export const useDashboardStore = defineStore('statistics', () => {
    const summaryContext = ref(null);
    const _contextFilters = ref([]);
    const ownerFilters = ref([]);
    const productFilters = ref([]);
    const propertyFilters = ref([]);
    const statisticsDataCopy = ref([]);
    const statisticsDataProducts = ref([]);
    const statisticsLoading = ref(false);
    const searchFilter = ref("");
    const filteringEnabled = ref(false);

    function setSummaryContext(context) {
        summaryContext.value = context;
    }

    function setStatisticsLoading(loading) {
        statisticsLoading.value = loading;
    }

    function setContextFilters(filters) {
        _contextFilters.value = filters;
    }

    function setOwnerFilters(filters) {
        ownerFilters.value = filters;
    }

    function setSearchFilter(key) {
        searchFilter.value = key === null ? '' : key.toLowerCase();
    }

    function setFilteringEnabled(enabled) {
        filteringEnabled.value = enabled;
    }

    function setProductFilters(filters) {
        productFilters.value = filters;
    }

    function clearAllFilters() {
        _contextFilters.value = [];
        ownerFilters.value = [];
        propertyFilters.value = [];
        searchFilter.value = '';
    }

    async function generateStatisticsData() {
        statisticsDataProducts.value = [...productFilters.value];
        statisticsDataCopy.value = [ ...instancesFiltered.value ];
        console.log("Loading statistics data...");
        useSiteStatsStore().reset();
        await useSiteStatsStore().fetchStatsForDeployments(statisticsDataCopy.value.filter(item => item.products.hasOwnProperty('mirage')).map(item => item.facId));
        useSiteStatsStore().applyStats(statisticsDataCopy.value);
        useOverviewContextsStore().applyLicenses(statisticsDataCopy.value);
        console.log("Statistics data loaded...");
        statisticsLoading.value = false;
    }

    function addPropertyFilter({ key, val }) {
        if (key === undefined || val === undefined) {
            console.error("Invalid filter:", key, val);
            return;
        }
        if (!propertyFilters.value.some(f => f.key === key && JSON.stringify(f.val) === JSON.stringify(val))) {
            propertyFilters.value.push({ key, val: typeof val === 'object' ? JSON.stringify(val) : val });
        }
    }

    function removePropertyFilter({ key, val }) {
        propertyFilters.value = propertyFilters.value.filter(f =>
            !(f.key === key && JSON.stringify(f.val) === JSON.stringify(val))
        );
    }

    function reset() {
        setSummaryContext(null);
    }

    const getSummaryContext = computed(() => {
        return summaryContext.value;
    });

    const isLoaded = computed(() => {
        return (useOwnersStore().isLoaded && useDistributorsStore().isLoaded && useResellersStore().isLoaded);
    });

    const productInstances = computed(() => {
        if(!useOwnersStore().isLoaded) {
            console.log("Owners not loaded....");
            return [];
        }

        if(!useDistributorsStore().isLoaded) {
            console.log("Distributors not loaded....");
            return [];
        }

        if(!useResellersStore().isLoaded) {
            console.log("Resellers not loaded....");
            return [];
        }

        const items = [];

        const ownersMap = useOwnersStore().idMap;
        const overviewItems = useOverviewContextsStore().withStatus;
        const distributorMap = useDistributorsStore().idMap;
        const resellerMap = useResellersStore().idMap;

        overviewItems.forEach(item => {
            const commonData = {
                resellerName: _.get(item, 'mngByName', 'N/A'),
                distributorName: '',
                chainName: '-',
                brandName: '-',
                nick: '',
                notes: '',
                wlbl: '',
                extId: '',
                pvId: null,
                pvTok: null,
                demo: false,
                watermarked: [],
                contractRefs: [],
                maxClientsLic: 0,
                numberOfClients: 0,
                offlineClients: 0,
                tvTypes: {},
                tvMod: {},
                tvFw: {},
                urlName: "",

                sigFw: {},
                sigMod: {},
                sigClientTypes: {},
                numberOfSigClients: 0,
                offlineSigClients: 0,
                numberOfCasts: 0,
                castOnline: false,
                castVersion: "",
                agentOnline: false,
                agentVersion: "",

                ctry: 'N/A',
                facId: '',
                industry: '',
                mngBy: null,
                mngByName: '',
                clientTypeCount: {},
                productType: '',
                roomCnt: 0,
                occupied: 0,
                onl: true,
                lowerCaseName: item.name ? item.name.toLowerCase() : null
            };

            if(Array.isArray(item.owners)) {
                item.owners.forEach(ownerId => {
                    const owner = ownersMap[ownerId];
                    if (owner) {
                        if (owner.type === 'Brand') {
                            commonData.brandName = owner.name;
                            commonData.brandId = owner.id;
                            if (owner && owner.belongsTo) {
                                const ownersOwner = ownersMap[owner.belongsTo];
                                commonData.chainName = ownersOwner ? ownersOwner.name : owner.belongsTo;
                                commonData.chainId = owner.belongsTo;
                            }
                        } else if (owner.type === 'Chain') {
                            commonData.chainName = owner.name;
                            commonData.chainId = owner.id;
                        }
                    }
                });
            }

            for(const productType in item.products) {
                const newItem = {...(structuredClone(commonData)), ...(structuredClone(item))};
                newItem.productType = productType;
                newItem.contextId = item.id;
                newItem.id = item.id + productType;
                newItem.mapLoc.la = (newItem.mapLoc.la == null || isNaN(newItem.mapLoc.la)) ? 59.9088 : parseFloat(newItem.mapLoc.la);
                newItem.mapLoc.ln = (newItem.mapLoc.ln == null || isNaN(newItem.mapLoc.ln)) ? 10.757824 : parseFloat(newItem.mapLoc.ln);

                if(productType !== 'mirage') {
                    if(item.products[productType].licVal !== true && item.products[productType].licVal !== 'true') {
                        newItem.watermarked =  ["license-invalid"];
                    }
                }

                switch(productType) {
                    case 'mirage': {
                        if(resellerMap.hasOwnProperty(item.mngBy)) {
                            const distributor = resellerMap[item.mngBy].managedBy;
                            if (distributorMap.hasOwnProperty(distributor)) {
                                newItem.distributorName = distributorMap[distributor].name;
                            }
                        }
                        newItem.productName = "HUB";
                        newItem.demo = _.get(newItem, 'cfg.demo', false) === true;
                        const watermark = _.get(newItem, 'cfg.watermark', '');
                        newItem.watermarked = (watermark === undefined || watermark === '') ? [] : [watermark];
                        if(item.proDate != null) {
                            newItem.productionDate = item.proDate;
                        }
                        newItem.createdDate = item.creDate;
                        newItem.facId = item.id;
                    }
                        break;
                    case 'enterprise':
                        newItem.productName = "Enterprise";
                        newItem.createdDate = item.products[productType].actDt;
                        newItem.urlName = item.products[productType].urlName;
                        break;
                    case 'ctrl':
                        newItem.productName = "Enterprise CTRL";
                        newItem.createdDate = item.products[productType].actDt;
                        newItem.urlName = item.products[productType].urlName;
                        break;
                    case 'chromecast':
                        newItem.productName = "Chromecast";
                        newItem.watermarked = [];
                        newItem.urlName = item.products[productType].urlName;
                        newItem.standalone = !item.products.hasOwnProperty('ctrl') && !item.products.hasOwnProperty('enterprise');
                }
                if(newItem.hasOwnProperty('productionDate') && newItem.productionDate !== "") {
                    newItem.productionDateString = moment(newItem.productionDate).format('YYYY-MM-DD');
                } else {
                    newItem.productionDateString = "n/a";
                    newItem.productionDate = "";
                }

                if(newItem.hasOwnProperty('createdDate') && newItem.createdDate !== "") {
                    newItem.creationDateString = moment(newItem.createdDate).format('YYYY-MM-DD');
                } else {
                    newItem.creationDateString = "n/a";
                    newItem.createdDate = "";
                }
                items.push({ ...newItem, ...item.products[productType] });
            }
        });
        return items;
    });

    /* Optimization, make getters that does not use the filtered and processed getter */
    const mirageContexts = computed(() => {
        return useOverviewContextsStore().items.filter(s => s.products.hasOwnProperty('mirage'));
    });
    const mirageInstances = computed(() => {
        return productInstances.value.filter(s => s.productType === 'mirage');
    });
    const enterpriseContexts = computed(() => {
        return useOverviewContextsStore().items.filter(s => s.products.hasOwnProperty('enterprise'));
    });
    const ctrlContexts = computed(() => {
        return useOverviewContextsStore().items.filter(s => s.products.hasOwnProperty('ctrl'));
    });
    const  chromecastContexts = computed(() => {
        return useOverviewContextsStore().items.filter(s => s.products.hasOwnProperty('chromecast'));
    });
    const chromecastInstancesFiltered = computed(() => {
        return instancesFiltered.value.filter(s => s.productType === 'chromecast');
    });

    const contextsFilters = computed(() => {
        const filters = [];

        if (_contextFilters.value.length > 0) {
            /* Check for distributors */
            _contextFilters.value.forEach((id) => {
                const context = Array.findFirstByField(useCommonStore().allContextsWithLegacy, 'id', id);
                if (!context) {
                    console.error("Could not find context with ID " + id);
                    filters.push(id);
                } else {
                    if (context.contextType === 'Distributor') {
                        /* Add all resellers */
                        useResellersStore().items.forEach(reseller => {
                            if (reseller.managedBy === context.id) {
                                filters.push(reseller.id);
                            }
                        });
                    } else {
                        filters.push(id);
                    }
                }
            });
        }
        return filters;
    });

    /* Filter based on anything but the freetext bit */
    const contextsFiltered = computed(() => {
        if(!filteringEnabled.value) {
            return [];
        }
        const items = productInstances.value;
        const ctxFilters = contextsFilters.value;
        return items.filter(
            item => {
                if (productFilters.value.length && productFilters.value.indexOf(item.productType) === -1) {
                    return false;
                }

                if(ctxFilters.length && ctxFilters.indexOf(item.mngBy) === -1) {
                    return false;
                }

                if(ownerFilters.value.length && ownerFilters.value.indexOf(item.brandId) === -1 && ownerFilters.value.indexOf(item.chainId) === -1) {
                    return false;
                }

                for (const filter of propertyFilters.value) {
                    if (!item.hasOwnProperty(filter.key)) {
                        return false;
                    }
                    if(typeof filter.val === 'function') {
                        if (!filter.val(item[filter.key])) {
                            return false;
                        }
                    } else {
                        switch (typeof item[filter.key]) {
                            case 'object': {
                                if (JSON.stringify(item[filter.key]) !== filter.val) {
                                    return false;
                                }
                            }
                                break;
                            default: {
                                if (item[filter.key] !== filter.val) {
                                    return false;
                                }
                            }
                        }
                    }
                }
                return true;
            }
        ).sort((i1, i2) => i1.name.localeCompare(i2.name)); /** Sort here as we assume this is called more seldom */

    });


    const instancesFiltered = computed(() => {
        if(!filteringEnabled) {
            return [];
        }

        const items = contextsFiltered.value;
        if (searchFilter.value.length < 3) {
            return items;
        }

        /** Find all active and searchable headers */
        const activeHeaders = {};
        const homeSiteHeaders = useHomeSiteHeadersStore();
        for(const key in homeSiteHeaders.listHeaders) {
            if(homeSiteHeaders.listHeaders[key].searchable === true && homeSiteHeaders.listHeaders[key].enabled) {
                activeHeaders[key] = homeSiteHeaders.listHeaders[key];
            }
        }

        /** Search trough enabled fields */
        return items.filter(
            item => {
                /** Name */
                if(item.lowerCaseName && item.lowerCaseName.indexOf(searchFilter.value) !== -1) {
                    return true;
                }
                /** Active columns */
                for(const field in activeHeaders) {
                    if(item.hasOwnProperty(field)) {
                        if (typeof activeHeaders[field].convertToString === 'function') {
                            if(activeHeaders[field].convertToString(item).toLowerCase().indexOf(searchFilter.value) !== -1) {
                                return true;
                            }
                        } else {
                            if((typeof item[field] === 'string') && item[field].toLowerCase().indexOf(searchFilter.value) !== -1) {
                                return true;
                            }
                        }
                    } else {
                        console.error("Header " + field + " not found!");
                    }
                }
                return false;
            }
        );
    });

    const sitesFilteredIdMap = computed(() => {
        return Map.createFromArray(instancesFiltered.value, 'id');
    });

    const statsSitesFilteredManagedByMap = computed(() => {
        const managedByMap = {};
        statisticsDataCopy.value.forEach(site => {
            if (!managedByMap.hasOwnProperty(site.mngBy)) {
                managedByMap[site.mngBy] = [];
            }
            managedByMap[site.mngBy].push(site);
        });
        return managedByMap;
    });


    return {
        /*
        summaryContext,
        ownerFilters,
        productFilters,
        propertyFilters,
        searchFilter,
        singleSite,
        filteringEnabled,

         */
        statisticsDataProducts,
        statisticsDataCopy,
        mirageInstances,
        mirageContexts,
        enterpriseContexts,
        ctrlContexts,
        chromecastContexts,
        statisticsLoading,
        contextsFiltered,
        contextsFilters,
        setSummaryContext,
        setStatisticsLoading,
        setContextFilters,
        setOwnerFilters,
        setSearchFilter,
        setFilteringEnabled,
        setProductFilters,
        clearAllFilters,
        addPropertyFilter,
        removePropertyFilter,
        reset,
        generateStatisticsData,
        instancesFiltered,
        productInstances,
        sitesFilteredIdMap,
        statsSitesFilteredManagedByMap,
        getSummaryContext,
        isLoaded
    };
});
