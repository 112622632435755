import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import moment from 'moment/moment';
import { computed } from 'vue';
import { useSchedulePlansStore } from '@/stores/signage/schedulePlans.js';

const thisModule = 'contentSchedules';

const createTimestamp = (ts, day = null, tsDate = null) => {
    // const weekDays = ["", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
    const date = tsDate ? tsDate : moment();

    date.hour(parseInt(ts.substr(0, 2)));
    date.minute(parseInt(ts.substr(3, 2)));
    date.isoWeekday(day);

    return date.valueOf();
};

// Initialize the BaseCrudPlugin for this store

export const useContentSchedulesStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.ordered = computed(() => {
        return store.cloneItems().sort((a, b) =>
            a.hasOwnProperty('index') && a.index !== null && b.hasOwnProperty('index') && b.index !== null ? ((a.index < b.index) ? -1 : 1) : 1
        );
    });

    store.forCalendar = computed(() => {
        const data = store.cloneItems();
        if (Array.isArray(data)) {
            data.forEach((schedule) => {
                /* Just convert to todays date */
                schedule.daily.forEach(item => {
                    item.timed = true;
                    item.start = createTimestamp(item.startTime);
                    item.end = createTimestamp(item.endTime);
                    item.color = item.mergeDown ? 'blue darken-4' : '';
                });
                /* Need correct weekday */
                schedule.weekly.forEach(item => {
                    item.timed = true;
                    item.start = createTimestamp(item.startTime, item.day);
                    item.end = createTimestamp(item.endTime, item.day);
                    item.color = item.mergeDown ? 'blue darken-4' : '';
                });
                /* Need to actual date set */
                for(const date in schedule.dates) {
                    if (schedule.dates.hasOwnProperty(date)) {
                        schedule.dates[date].forEach(item => {
                            item.timed = true;
                            const d = moment(date);
                            item.start = createTimestamp(item.startTime, null, d);
                            item.end = createTimestamp(item.endTime, null, d);
                            item.color = item.mergeDown ? 'blue darken-4' : '';
                        });
                    }
                }
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    });

    store.forActiveSchedulePlan = computed(() => {
        const contentSchedules = store.cloneItems();
        const schedulePlans = useSchedulePlansStore().sorted;

        let data = [];
        if (schedulePlans.length > 0) {
            const activeSchedulePlan = schedulePlans.find(it => it.active);
            if (activeSchedulePlan) {
                data = contentSchedules.filter(it => it.schedulePlanId === activeSchedulePlan.id);
            }
        }
        return data;
    });

    return store;
});
