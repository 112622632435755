<template>
    <div
        class="folder-structure-item"
        :style="index % 2 && !desktop ? 'margin-left: 10px' : ''"
        :class="isSelected ? 'selected' : ''"
    >
            <v-card
                class="ma-0"
                :flat="!desktop"
                style="box-sizing: border-box; overflow: clip"
                @contextmenu.prevent=""
                :id="item.id"
                ref="card"
                @dblclick="$emit('itemDoubleClick', item)"
                @click.stop="$emit('itemClick', item)"
                @click.right.stop="$emit('itemRightClick', item)"
            >
                <div v-if="inView">
                    <div v-if="item.isFolder" class="folder-item large-icon">
                        <v-icon>folder</v-icon>

                        <div class="folder-structure-item-title">
                            {{itemNameShort}}
                        </div>
                    </div>
                    <div v-else-if="item.type === 'back'" class="folder-item large-icon">
                        <v-icon>mdi-arrow-left-circle</v-icon>
                    </div>

                    <v-tooltip bottom v-else-if="item.moduleName === 'images' || item.moduleName === 'videos' || item.moduleName === 'logos' || item.moduleName === 'wayfindingIcons'">
                        <template v-slot:activator="{on}">

                            <v-container
                                :class="'pa-0 folder-item resources-' + item.moduleName + '-content ' + (isSelected ? 'selected' : '')"
                                fill-height
                                fluid v-on="on"
                            >
                                <v-row align="center"
                                       justify="center"
                                >
                                    <v-col>
                                        <div class="overlay" v-if="hasLocalResourceSync && !item.shared">
                                            <v-icon v-if="item.synced" size="small" color="success">mdi-circle</v-icon>
                                            <v-icon v-else size="small" color="error">mdi-circle</v-icon>
                                        </div>
                                        <v-img
                                                :src="itemIconBaseUrl + '?rev=' + item.rev + '&session-id=' + sessionId"
                                                contain
                                                style="height: 142px;"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="#777"
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                            <div v-if="item.moduleName === 'videos'">
                                                <v-icon
                                                    color="white"
                                                    style="background-color: rgba(0,0,0,.5);"
                                                >mdi-video</v-icon>
                                                <v-icon
                                                    v-if="item.uploadState === 'DONE'"
                                                    color="white"
                                                    style="background-color: rgba(0,0,0,.5);"
                                                >{{is4k ? 'mdi-video-4k-box' : 'mdi-high-definition-box'}}</v-icon>
                                            </div>
                                        </v-img>
                                        <div class="folder-structure-item-title folder-structure-item-title-bg">
                                            {{itemNameShort}}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>

                        </template>
                        <div v-if="item.width && item.height" style="text-align: center;">
                            {{itemName}}<br>{{item.width}}px x {{item.height}}px
                        </div>
                        <div v-else style="text-align: center;">
                            {{itemName}}
                        </div>
                    </v-tooltip>

                    <v-tooltip bottom v-else-if="item.moduleName.startsWith('documents')">
                        <template v-slot:activator="{on}">
                            <div class="fill-height large-icon folder-item" v-on="on">
                                <v-icon>mdi-file-document</v-icon>

                                <div class="folder-structure-item-title">
                                    {{itemNameShort}}
                                </div>
                            </div>
                        </template>
                        {{itemName}}
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="item.moduleName.startsWith('tables')">
                        <template v-slot:activator="{on}">
                            <div class="fill-height large-icon folder-item" v-on="on">
                                <v-icon>mdi-file-table</v-icon>

                                <div class="folder-structure-item-title">
                                    {{itemNameShort}}
                                </div>
                            </div>
                        </template>
                        {{itemName}}
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="item.moduleName.startsWith('templates')">
                        <template v-slot:activator="{on}">
                            <div class="fill-height large-icon folder-item" v-on="on">
                                <v-icon>mdi-cog-transfer-outline</v-icon>

                                <div class="folder-structure-item-title">
                                    {{itemNameShort}}
                                </div>
                            </div>
                        </template>
                        {{itemName}}
                    </v-tooltip>
                    <v-tooltip bottom v-else-if="item.moduleName.startsWith('playlists')">
                        <template v-slot:activator="{on}">
                            <div class="fill-height large-icon folder-item" v-on="on">
                                <v-icon>mdi-format-list-numbered</v-icon>

                                <div class="folder-structure-item-title">
                                    {{itemNameShort}}
                                </div>
                            </div>
                        </template>
                        {{itemName}}
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                        <template v-slot:activator="{on}">
                            <div class="overlay" v-if="hasLocalResourceSync && !item.shared">
                                <v-icon v-if="item.synced" size="small" color="success">mdi-circle</v-icon>
                                <v-icon v-else size="small" color="error">mdi-circle</v-icon>
                            </div>
                            <div class="fill-height" v-on="on">
                                <div
                                    class="resources-font-content"
                                    :style="`font-family: 'font-${item.id}' !important;`"
                                >
                                    {{$t('resources.quickBrownFox')}}.
                                </div>
                                <div class="folder-structure-item-title" :style="`font-family: 'font-${item.id}' !important;`">
                                    {{itemNameShort}}
                                </div>
                            </div>
                        </template>
                        {{itemName}}
                    </v-tooltip>
                </div>
                <div v-else class="placeholder-div"></div>
            </v-card>
    </div>
</template>

<script>
    import { useAuthStore } from '@/stores/auth.js';
    import { isFile4kResolution } from '@/lib/helpers/Validator';
    import { useAppStateStore } from '@/stores/appState';

    export default {
        name: "FolderStructureItem",
        props: {
            item: {
                required: true,
                type: Object
            },
            index: {
                required: true,
                type: Number
            },
            selected: {
                required: true,
                type: Object
            },
            isFolder: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        computed: {
            desktop() {
                return useAppStateStore().desktop;
            },
            sessionId() {
                return useAuthStore().sessionId;
            },
            hasLocalResourceSync() {
                return this.$app.accessCheck('AgentManagement', 'ResourceSyncServiceConfigurations', 'read')
            },
            isSelected() {
                if(this.selected && this.item && this.item.id) {
                    return this.selected.hasOwnProperty(this.item.id);
                }
                return false;
            },
            inView() {
                //TODO virtual scroll?
                return true;
            },
            itemNameShort() {
                if(typeof this.itemName !== 'string') {
                    return "";
                }
                return this.itemName.substring(0, 75);
            },
            itemName() {
                if(!this.item) {
                    return '';
                }
                return this.item.name;
            },
            itemIconBaseUrl() {
                const hasThumbNail = (this.item.moduleName === 'images' || this.item.moduleName === 'videos');
                if (hasThumbNail === true) {
                    return this.item.resourceThumbnailUrl;
                } else {
                    return this.item.resourceUrl;
                }
            },
            is4k() {
              return isFile4kResolution(this.item.width, this.item.height);
            }
        }
    }
</script>

<style lang="scss">

    .v-application {
        .selected {
            .folder-item {
                background-color: var(--v-accent-base) !important;
                border-radius: 4px;
            }

            .resources-font-content {
                background-color: var(--v-accent-base) !important;
                border-radius: 4px;
                color: white !important;
            }
        }
    }

    .folder-structure-item {
        position: relative;
        width: 180px;
        height: 180px;
        margin: 4px;
        transition: border 0.2s ease-in-out;

        .v-card {
            height: 100%;
            width: 100%;

            > div {
                height: 100%;
                width: 100%;
            }
        }

        .resources-wayfindingIcons-content {
            background-color: var(--v-thumbnailBackground-base);
        }

        .resources-logos-content {
            background-color: var(--v-thumbnailBackground-base);
        }

        .resources-font-content {
            font-size: 18px;
            padding: 12px;
            background: var(--v-background-base);
            color: black;
            height: 100%;
        }

        .folder-structure-item-title {
            position: absolute;
            text-align: center;
            min-height: 45px;
            width: 100%;
            left: 0;
            bottom: 0;
            background: rgba(66, 66, 66, 0.8);
            color: white;
            overflow: hidden;
            padding: 4px 10px;
            overflow-wrap: anywhere;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .folder-item {
            height: 100%;
            width: 100%;
        }

        .large-icon {
            i {
                height: 100%;
                width: 100%;
                padding-bottom: 30px;
                font-size: 100px;
            }
        }

        .placeholder-div {
            width: 100%;
            height: 100%;
            opacity: 0.6;
            background: var(--v-accent-base);
            -webkit-animation-name: placeholderPulse;
            -webkit-animation-duration: 3s;
            -webkit-animation-iteration-count: infinite;
            animation-name: placeholderPulse;
            animation-duration: 3s;
            animation-iteration-count: infinite;
        }

        @keyframes placeholderPulse {
            from {
                background: var(--v-accent-base);
            }
            to {
                background: var(--v-primary-base);
            }
        }

        @-webkit-keyframes placeholderPulse {
            from {
                background: var(--v-accent-base);
            }
            to {
                background: var(--v-primary-base);
            }
        }
    }

    .mobile .folder-structure-item {
        width: calc(50vw - 20px);
        height: calc(50vw - 20px);
        margin-right: 0;
    }
    .overlay {
        font-size: 2px;
        display: inline-block;
        z-index: 9;
        white-space: nowrap;
        position: absolute;
        left: 5px;
        top: 5px;
    }
    .online {
        color: #709a41;
    }

    .offline {
        color: #b62325;
    }
</style>
