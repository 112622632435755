import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAllocatedStreamsStore } from '@/stores/streamallocator/allocatedStreams.js';
import { useClientsStore } from '@/stores/clients/clients.js';
import { useRegisteredCastsStore } from '@/stores/cast/registeredCasts.js';

const thisModule = 'streamDevices';

// Initialize the BaseCrudPlugin for this store

export const useStreamDevicesStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withAllocatedStream = computed(() => {
        const data = [];

        if (Array.isArray(store.items.value) && store.items.value.length > 0) {

            const allocatedStreams = useAllocatedStreamsStore().sorted;
            const clientIdMap = useClientsStore().sorted;
            const casts = useRegisteredCastsStore().sorted;
            const getCastName = (item) => {
                let cast = casts.find(c => c.id == item.chromecastId);
                if (cast) {
                    return cast.chromecast.name;
                }
                else return ""
            }

            store.items.value.forEach((item) => {

                const allocatedStream = allocatedStreams.filter(it => it.deviceId === item.id)[0];
                item.chromecastName = getCastName(item);

                if (allocatedStream) {
                    const client = clientIdMap.filter(it => it.id === allocatedStream.clientId)[0];

                    const extendedItem = {
                        ...item,
                        allocatedStreamId: allocatedStream.id,
                        allocated: !!allocatedStream,
                        clientName: client.name,
                        clientId: client.id,
                        lastAlive: allocatedStream.lastAlive
                    }
                    data.push(extendedItem);

                } else {
                    data.push(item);
                }
            });
        }

        return data;
    });

    return store;
});
