import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import moment from '@/lib/helpers/moment.js';
import { computed } from 'vue';
import { useLanguageInfoStore } from '@/stores/contextManagement/languageInfo.js';
import { useHospitalityLocationsStore } from '@/stores/hospitality/hospitalityLocations.js';

const thisModule = 'hospitalityGuests';

// Initialize the BaseCrudPlugin for this store

export const useHospitalityGuestsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withLocationTitle = computed(() => {
        const data = store.cloneItems();
        const locationsIdMap = useHospitalityLocationsStore().idMapByGetter('withLocationAndGuestInfo');

        if (Array.isArray(data)) {

            data.forEach((item) => {
                item.locationTitle = Map.retrieveValue(locationsIdMap, item.locationId, 'fullTitle');

                const langs = useLanguageInfoStore().languages.value;
                const lang = Array.findFirstByField(langs, 'code', item.language);
                if(lang && (typeof lang.name === 'string')) {
                    item.languageName = lang.name.capitalizeFirst();
                } else if(typeof item.language === 'string') {
                    item.languageName = item.language.capitalizeFirst();
                } else {
                    item.languageName = "";
                }

                item.checkInDateFormatted = moment.toDisplayFormatDateOnly(item.checkInDate);
                item.checkOutDateFormatted = moment.toDisplayFormatDateOnly(item.checkOutDate);

            });
            Array.sort(data, 'locationTitle', true);
            return data;
        }
        return [];
    });

    store.uniqueLocations = computed(() => {
        const map = store.idMapByGetter('withLocationTitle', 'locationId', null);
        let data = [];
        for(const entry in map) {
            if(map.hasOwnProperty(entry)) {
                data.push({ id: entry, locationTitle: map[entry].locationTitle });
            }
        }
        return data;
    });

    store.uniqueLocationCount = computed(() => {
        const map = store.idMapByGetter(null, 'locationId', null);
        let count = 0;
        for(const entry in map) {
            if(map.hasOwnProperty(entry)) {
                count++;
            }
        }
        return count;
    });

    return store;
});
