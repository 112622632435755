import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { useLocationsStore } from '@/stores/locations/locations.js';
import { useHospitalityGuestsStore } from '@/stores/hospitality/hospitalityGuests.js';
import { computed } from 'vue';

const thisModule = 'hospitalityLocations';

// Initialize the BaseCrudPlugin for this store

export const useHospitalityLocationsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withLocation = computed(() => {
        const data = [];

        if (Array.isArray(store.items.value) && store.items.value.length > 0) {

            const locationsIdMap = useLocationsStore().idMapByGetter('withFullTitleTagsAndParents');
            store.items.value.forEach((item) => {

                const itemLocation = Map.retrieve(locationsIdMap, item.id) || {};
                let parentLocation = 'None'; // TODO: Translate
                if (Array.isArray(itemLocation.parentLocations) && itemLocation.parentLocations.length > 0) {
                    parentLocation = itemLocation.parentLocations[0];
                }

                const extendedItem = {
                    ...item,
                    occupation: item.occupied === true ? 'Occupied' : 'Vacant', // TODO: Translated versions!
                    titleTranslated: itemLocation.titleTranslated || '',
                    categoryTitle: itemLocation.categoryTitle || '',
                    fullTitle: itemLocation.fullTitle || '',
                    parentLocations: itemLocation.parentLocations,
                    parentLocation: parentLocation
                };

                data.push(extendedItem);
            });
        }
        return data;
    });

    store.withLocationAndGuestInfo = computed(() => {
        const data = store.withLocation.value;

        if (Array.isArray(data) && data.length > 0) {

            const guestsLocationIdMap = useHospitalityGuestsStore().idMapByGetter(null, 'locationId', true);
            data.forEach((item) => {

                let guestNames = [];
                const guests = Map.retrieve(guestsLocationIdMap, item.id);
                if (Array.isArray(guests) && guests.length > 0) {
                    guests.forEach(g => {
                        guestNames.push(g.name);
                        if (typeof g.groupName === 'string' && g.groupName.length > 0) {
                            item.group = g.groupName;
                        }else{
                            item.group = '';
                        }
                    });
                }
                item.guestNames = guestNames;
            });

        }
        return data;
    });


    return store;
});
