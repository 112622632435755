import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { isEmpty } from '@/lib/helpers/Validator';
import { useFeaturesStore } from '@/stores/features.js';

const thisModule = 'products';

// Initialize the BaseCrudPlugin for this store

export const useProductsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.featureListing = computed(() => {
        const data = store.sorted.value;
        return data.map(function (product) {
            if (isEmpty(product.featureProvisions)) {
                return {id: product.id, name: product.name, features: [], contextType: product.contextType}
            } else {
                const featureNames = product.featureProvisions.map(function (featureProvision) {
                    return featureProvision.feature
                });
                return {
                    ...product,
                    features: featureNames
                }
            }
        });
    });

    /**
     * Make sure all the needed provisioning data-structures are present
     */
    store.sanitizedItem = (id) => {
        const entry = store.itemById(id);

        if(!entry) {
            return null;
        }
        const items = Array.sortWithReturn(entry.featureProvisions, ['feature'], ['asc'])
        /* Make sure all provisions are included */
        items.forEach( item => {
            if(!Array.isArray(item.configurationProvisions)) { item.configurationProvisions = [] }
            if(!Array.isArray(item.resourceProvisions)) { item.resourceProvisions = [] }
            const feature = useFeaturesStore().idMapBy("feature")[item.feature];
            if(feature) {
                feature.configurationSpecifications.forEach(configurationSpecification => {
                    if(!Array.findFirstByField(item.configurationProvisions, 'configurationName', configurationSpecification.configurationName)) {
                        console.log(item.feature + " : Configuration NOT FOUND. Adding : " + configurationSpecification.configurationName);
                        item.configurationProvisions.push({
                            configurationName: configurationSpecification.configurationName,
                            values: []
                        });
                    }
                });
                /* Make sure all limits are in place */
                item.resourceProvisions.forEach(resourceProvision => {
                    let r = Array.findFirstByField(feature.resourceSpecifications, 'resource', resourceProvision.resource);
                    if(r) {
                        r.limits.forEach(resource => {
                            if(!Array.findFirstByField(resourceProvision.limits, "limit", resource)) {
                                console.log("Adding missing limit " + resource);
                                resourceProvision.limits.push({
                                    limit: resource,
                                    value: 0
                                });
                            }
                        });
                    }
                });

            } else {
                console.error("Could not find feature " + item.feature);
            }

        });
        return entry;
    };


    return store;
});
