<template>
    <v-menu
            :close-on-content-click="false"
            offset-y
    >
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on" data-testid="mmlsLanguageSelector">
                <v-avatar v-on="on" size="24px">
                    <img :src="`/icon/language/${getFlagCode()}.png`" :alt="selectedLangCode">
                </v-avatar>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                    v-for="lang in languages"
                    :key="lang.code"
                    @click="selectLanguage(lang)"
                    :data-testid="'mmlsLanguageCode-'+lang.code"
            >
                <v-list-item-action>
                    <v-avatar size="24">
                        <img :src="`/icon/language/${getFlagCode(lang.code)}.png`" :alt="lang.code">
                    </v-avatar>
                </v-list-item-action>
                <v-list-item-title>{{$tc('language.' + lang.name, 2)}}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>


import { useAppStateStore } from '@/stores/appState';

export default {
        name: "MainMenuLanguageSelector",
        computed: {
            languages() {
                return useAppStateStore().portalLanguages;
            },
            selectedLangCode() {
                return useAppStateStore().language ? useAppStateStore().language.code : 'en';
            }
        },
        methods: {
            getFlagCode(lang) {
                return useAppStateStore().getFlagCode(lang);
            },
            selectLanguage(lang) {
                useAppStateStore().setLanguage(lang);
            }
        }
    }
</script>