<template>
    <BaseDialog
        v-model="open"
        :max-width="500"
        :title="title"
        :hide-close="true"
        :custom-actions="true"
        :hide-cancel-action="true"
        :click-outside-function="refute"
    >
        <template v-slot:content>
            <span v-html="text"></span>
            <v-checkbox v-if="displayConfirmation" v-model="confirmationChecked" :label="confirmationText" data-testid="tcConfirm"></v-checkbox>
        </template>
        <template v-slot:actions>
            <div>
                <v-btn
                        text
                        elevation="1"
                        v-if="refuteFunction !== null"
                        @click.stop="refute"
                        data-testid="tcCancel"
                >
                    {{ (displayConfirmation) ? $t('generic.cancel') : refuteText }}
                </v-btn>
                <v-btn
                        :color="confirmColor"
                        elevation="1"
                        @click.stop="confirm"
                        :disabled="displayConfirmation && !confirmationChecked"
                        data-testid="tcApprove"
                >
                    {{ confirmText }}
                </v-btn>
            </div>
        </template>
    </BaseDialog>
</template>

<script>
    import BaseDialog from '@/components/base/popup/BaseDialog.vue';
    import _ from 'lodash';
    import { useGlobalModals } from '@/composables/useGlobalModals';

    export default {
        name: 'TheConfirm',
        components: {
            BaseDialog
        },
        data() {
            return {
                title: '',
                text: '',
                confirmFunction: null,
                refuteFunction: null,
                displayConfirmation: false,
                confirmText: '',
                refuteText: '',
                confirmationChecked: false,
                confirmationText: '',
                confirmColor: 'error',
                open: false
            }
        },
        created() {
            this.confirmText = this.$t('generic.yes');
            useGlobalModals().setConfirmModal(this);
        },
        methods: {
            async confirm() {
                this.open = false;
                this.displayConfirmation = false;
                this.confirmationChecked = false;

                await this.confirmFunction();
            },
            async refute() {
                this.open = false;
                this.displayConfirmation = false;
                this.confirmationChecked = false;

                if (this.refuteFunction !== null) {
                    await this.refuteFunction();
                }
            },
            delete(itemType, amount, deleteFunction, refuteFunction = null) {
                this.open = true;
                this.title = this.$tc('generic.deleteWithType', amount, {type: itemType, amount: amount});
                this.text = this.$tc('generic.deleteConfirm', amount, {type: itemType, amount: amount});
                this.confirmText = this.$t('generic.delete');
                this.refuteText = this.$t('generic.no');
                this.confirmColor = 'error';
                this.confirmFunction = deleteFunction;
                this.refuteFunction = refuteFunction;
            },
            deleteWithCustomText(title, text, deleteFunction, refuteFunction = null) {
                this.open = true;
                this.title = title;
                this.text = text;
                this.confirmText = this.$t('generic.delete');
                this.refuteText = this.$t('generic.no');
                this.confirmColor = 'error';
                this.confirmFunction = deleteFunction;
                this.refuteFunction = refuteFunction;
            },
            deleteWithConfirm(itemType, amount, deleteFunction, refuteFunction = null) {
                this.open = true;
                this.displayConfirmation = true;
                this.confirmationText = this.$tc('generic.deleteUserConfirmation', amount,  {type: itemType, amount: amount });
                this.title = this.$tc('generic.deleteWithType', amount, {type: itemType, amount: amount});
                this.text = this.$tc('generic.deleteConfirm', amount, {type: itemType, amount: amount});
                this.confirmText = this.$t('generic.delete');
                this.refuteText = this.$t('generic.no');
                this.confirmColor = 'error';
                this.confirmFunction = deleteFunction;
                this.refuteFunction = refuteFunction;
            },
            deleteWithConfirmAndCustomText(title, text, confirmationText, confirmFunction, refuteFunction = null) {
                this.open = true;
                this.displayConfirmation = true;
                this.confirmationText = confirmationText;
                this.confirmText = this.$t('generic.delete');
                this.refuteText = this.$t('generic.no');
                this.title = title;
                this.text = text;
                this.confirmColor = 'error';
                this.confirmFunction = confirmFunction;
                this.refuteFunction = refuteFunction;
            },
            showWithConfirmAndCustomText(title, text, confirmationText, confirmFunction, refuteFunction = null, config) {
                this.open = true;
                this.displayConfirmation = true;
                this.confirmationText = confirmationText;
                this.title = title;
                this.text = text;
                this.confirmColor = _.get(config, 'confirmColor', 'error');
                this.confirmText = _.get(config, 'confirmText', this.$t('generic.yes'));
                this.confirmFunction = confirmFunction;
                this.refuteFunction = refuteFunction;
            },
            show(title, text, confirmFunction, refuteFunction = null, config) {
                this.open = true;
                this.title = title;
                this.text = text;
                this.confirmColor = _.get(config, 'confirmColor', 'primary');
                this.confirmText = _.get(config, 'confirmText', this.$t('generic.yes'));
                this.refuteText = _.get(config, 'refuteText', this.$t('generic.no'));
                this.confirmFunction = confirmFunction;
                this.refuteFunction = refuteFunction;
            },
            alert(title, text, confirmFunction) {
                this.open = true;
                this.title = title;
                this.text = text;
                this.confirmText = 'OK';
                this.refuteText = this.$t('generic.no');
                this.confirmFunction = confirmFunction;
                this.refuteFunction = null;
            }
        }
    }
</script>