import { defineStore } from 'pinia';
import { computed } from 'vue';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';

const thisModule = 'documents';

export const useDocumentsStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule);
    plugin({ store });

    store.withMedia = computed(() => {
        const data = store.cloneItems();
        if (Array.isArray(data)) {
            data.forEach((item) => {
                if(typeof item.content === 'string') {
                    item.images = item.content.split('<img').length - 1;
                    item.videos = item.content.split('<video').length - 1;
                    item.headers = item.content.split('<h').length - 1;
                } else {
                    item.images = 0;
                    item.videos = 0;
                    item.headers = 0;
                }
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    });

    return store;
});
