import Vue from 'vue';
import './lib/extenders/index.js';
import vuetify from './plugins/plugins.js';
import App from './App.vue';
import i18n from './i18n/index.js';
import router from './router/index.js';
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'reset-css';
import './plugins/leaflet.js';
import English from './i18n/lang/en/translations.js';
import DumpMissingTranslations from './i18n/dumpMissingTranslations.js';
import JsonCSV from 'vue-json-csv';
import { useKeycloak } from '@/composables/useKeycloak';
import { createPinia, PiniaVuePlugin } from "pinia";
import VueCompositionAPI from '@vue/composition-api';
import AuthManager from "@/lib/helpers/AuthManager.js";
import { Storage } from './lib/helpers/index.js';

Vue.config.productionTip = false;
Vue.prototype.$vuetify = vuetify; /* Will be replaced by useDisplay composable in vutify 3 */

Vue.component('downloadCsv', JsonCSV);

if ((import.meta.env.MODE === "production" || import.meta.env.MODE === "pre-production") && location.hostname.indexOf('localhost') === -1) {
    window.originalLog = console.log;
    console.log = () => {
    };
}

function startMiragePortal() {

    Vue.use(VueCompositionAPI);
    Vue.use(PiniaVuePlugin);
    const pinia = createPinia();

    new Vue({
        pinia,
        vuetify,
        i18n,
        router,
        render: h => h(App),
    }).$mount('#app');
/*
    if (import.meta.env.MODE !== "production") {
        window.dumpTranslations = () => {
            return English;
        };
        window.dumpMissingTranslations = () => {
            return DumpMissingTranslations();
        }
    }
 */
}


// Init keycloak

const keycloak = useKeycloak();

let kcIdpHint = '';
const hostName = window.location.hostname;
if (hostName.includes('wave')) {
    kcIdpHint = 'wave';
}

const updateKeycloakToken = () => {
    if (keycloak != null) {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                console.log('Keycloak token refreshed: ' + refreshed);
            } else {
                console.log('Keycloak token is still valid for another ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds, no need to refresh');
            }
        }).catch((e) => {
            console.error('Failed to refresh token: ', e);
        });
    }
};

keycloak.onAuthRefreshError = () => {
    alert("You session has expired, redirecting to login page.");
    location.reload();
};

keycloak.init({
    checkLoginIframe: false
}).then((authenticated) => {
    console.log('Authenticated to Keycloak? ', authenticated);
    if (authenticated) {
        Storage.set("keycloakToken", keycloak.token);
        startMiragePortal();

        // Interval for token refresh
        setInterval(() => {
            updateKeycloakToken();
        }, (60 * 1000 * 5));
        addEventListener('focus', (event) => {
            updateKeycloakToken();
        });
    } else {
        if (AuthManager.getEnableAutoSSO() === "false") {
            keycloak.login();
        } else {
            keycloak.login({idpHint: kcIdpHint, scope: 'profile email phone'});
        }
    }
});

