<template>
    <BaseDialog
        v-if="desktop"
        v-model="show"
        :tabs="true"
        :eager="eager"
        :custom-actions="true"
        :title="title"
        :max-width="maxWidth"
        :hide-all-actions="hideAllActions"
        :custom-class="customClass + ' tab-modal'"
        :cancel-text="cancelText"
        :persistent="persistent"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>
        <template v-slot:content>
            <slot name="tabs"></slot>
        </template>
    </BaseDialog>

    <BaseBottomSheet
        :eager="eager"
        v-else
        v-model="show"
        :tabs="true"
        :custom-actions="true"
        :title="title"
        :custom-class="customClass"
        :done-action-text="doneActionText"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>
        <template v-slot:content>
            <slot name="tabs"></slot>
        </template>
    </BaseBottomSheet>
</template>

<script>
    import BaseDialog from "@/components/base/popup/BaseDialog.vue";
    import BaseBottomSheet from "@/components/base/popup/BaseBottomSheet.vue";
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import { useAppStateStore } from '@/stores/appState';

    export default {
        name: 'BaseTabModal',
        mixins: [VModelShowMixin],
        components: {BaseBottomSheet, BaseDialog},
        props: {
            title: {required: true},
            persistent: {default: false, type: Boolean},
            customClass: {default: '', type: String},
            maxWidth: {default: 500, type: Number},
            hideAllActions: {default: false, type: Boolean},
            eager: {default: false, type: Boolean},
            cancelText:  {required: false, type: String},
            doneActionText: {required: false, type: String}
        },
        computed: {
            desktop() {
                return useAppStateStore().desktop;
            }
        }
    }
</script>

<style lang="scss">
    .tab-modal {
        .v-list-item {
            padding: 0 34px 0 34px;
        }

        .create-new-button {
            padding-left: 28px !important;
        }
    }

    .mobile {
        .v-tab {
            margin-left: 0 !important;
        }
    }
</style>