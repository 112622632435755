import axios from "axios";
import { useGlobalModals } from '@/composables/useGlobalModals';

export default {
    data() {
    },
    computed: {
        globalModals() {
            return useGlobalModals();
        }
    },
    methods: {
        appendFileEndingByMimeType(name, mime) {
            if(typeof mime !== 'string' || typeof name !== 'string') {
                return "";
            }
            if(mime.startsWith('image/')) {
                if(name.endsWith(mime.substring(6))) {
                    return name;
                }

                /* Special jpeg vs jpg */
                if(mime === "image/jpeg" || mime === "image/jpg") {
                    if(name.endsWith(".jpg") || name.endsWith(".jpeg")) {
                        return name;
                    } else {
                        return name + ".jpg";
                    }
                }

                /* special svg */
                if (mime === "image/svg+xml") {
                    if(name.endsWith(".svg")) {
                        return name;
                    } else {
                        return name + ".svg";
                    }
                }

                return (name + "." + mime.substring(6));
            } else if(mime.startsWith('video/')) {
                return name + ".mp4";
            } else if(mime.startsWith('font/')) {
                if(name.endsWith(mime.substring(6))) {
                    return name;
                } else {
                    return (name + "." + mime.substring(5));
                }
            }
            return name;
        },
        async downloadFile(url, name, appendFiletype) {
            try {
                const response = await axios({
                    method: 'get',
                    timeout: 300000,
                    url: url,
                    responseType: 'arraybuffer'
                });
                if(appendFiletype && response.headers.hasOwnProperty('content-type')) {
                    name = this.appendFileEndingByMimeType(name, response.headers['content-type']);
                }
                const urlObj = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urlObj;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.globalModals.getNotification().show('success', this.$t('resources.downloadingName', { name: name }));
            } catch(e) {
                this.globalModals.getNotification().show('error', this.$t('resources.downloadFailed'));
                console.error(e);
            }
        }
    }
};