import {apiConfigurations} from '@/lib/api/index.js';
import { useTemplateFoldersStore } from '@/stores/content/templateFolders.js';
import { useDocumentFoldersStore } from '@/stores/content/documentFolders.js';
import { useResourceFoldersStore } from '@/stores/content/resourceFolders.js';
import { usePlaylistFoldersStore } from '@/stores/content/playlistFolders.js';
import { useLogoFoldersStore } from '@/stores/content/logoFolders.js';
import { useWayfindingIconFoldersStore } from '@/stores/content/wayfindingIconFolders.js';
import { useInteractiveMenuPageFoldersStore } from '@/stores/content/interactiveMenuPageFolders.js';
import { useCompositeMenuPageFoldersStore } from '@/stores/content/compositeMenuPageFolders.js';
import { useLocationCategoriesStore } from '@/stores/locations/locationCategories.js';
import { useLocationTagsStore } from '@/stores/locations/locationTags.js';
import { useLocationsStore } from '@/stores/locations/locations.js';
import { useUserAccountStore } from '@/stores/account/userAccount.js';
import { useContextStore } from '@/stores/context.js';
import { useCompositeMenuPagesStore } from '@/stores/content/compositeMenuPages.js';
import { useInteractiveMenuPagesStore } from '@/stores/content/interactiveMenuPages.js';
import { useDocumentsStore } from '@/stores/content/documents.js';
import { usePlaylistsStore } from '@/stores/content/playlists.js';
import { useTemplatesStore } from '@/stores/content/templates.js';
import { useTemplateSourcesStore } from '@/stores/content/templateSources.js';
import { useLogosStore } from '@/stores/content/logos.js';
import { useWayfindingIconsStore } from '@/stores/content/wayfindingIcons.js';
import { useFontsStore } from '@/stores/content/fonts.js';
import { useImagesStore } from '@/stores/content/images.js';
import { useVideosStore } from '@/stores/content/videos.js';
import { useTablesStore } from '@/stores/content/tables.js';
import { useMediaChannelsStore } from '@/stores/tvchannels/mediaChannels.js';
import { useGlobalChannelsStore } from '@/stores/tvchannels/globalTables.js';
import { useChannelTablesStore } from '@/stores/tvchannels/channelTables.js';
import { useTableItemsStore } from '@/stores/content/tableItems.js';
import { useTableSchemasStore } from '@/stores/content/tableSchemas.js';
import { useIntegrationLocationsStore } from '@/stores/locations/integrationLocations.js';
import { useExternalLocationsStore } from '@/stores/locations/externalLocations.js';
import { useScreenSetupsStore } from '@/stores/screens/screenSetups.js';
import { useScreenContentsStore } from '@/stores/screens/screenContents.js';
import { useCurrentScreenContentsStore } from '@/stores/screens/currentScreenContents.js';
import { usePushChannel } from '@/composables/usePushChannel';
import { useAuthStore } from '@/stores/auth.js';
import { useMenuListsStore } from '@/stores/content/menuLists.js';
import { useScreenWayfindingConfigurationsStore } from '@/stores/screens/screenWayfindingConfigurations.js';
import { useInstallCodesStore } from '@/stores/screens/installCodes.js';
import { useSchedulePlansStore } from '@/stores/signage/schedulePlans.js';
import { useScheduleRuleSetsStore } from '@/stores/screens/scheduleRuleSets.js';
import { useScheduleRuleSetsOverridesStore } from "@/stores/screens/scheduleRuleSetsOverrides";
import { useConfigSchedulesStore } from '@/stores/screens/configSchedules.js';
import { useGuestMessageTemplatesStore } from '@/stores/hospitality/guestMessageTemplates.js';
import { useEventIntegrationsStore } from '@/stores/signage/eventIntegrations.js';
import { useEventIntegrationConfigurationsStore } from '@/stores/signage/eventIntegrationConfigurations.js';
import { useEventsStore } from '@/stores/signage/events.js';
import { useRegisteredCastsStore } from '@/stores/cast/registeredCasts.js';
import { useJobsStore } from '@/stores/jobs.js';
import { useEventLocationsStore } from '@/stores/signage/eventLocations.js';
import { useTimeZonesStore } from '@/stores/timeZones.js';
import { useNetworkProxiesStore } from '@/stores/clients/networkProxies.js';
import { useAccountsStore } from '@/stores/account/accounts.js';
import { useApiAccountsStore } from '@/stores/account/apiAccounts.js';
import { useSnapshotsStore } from '@/stores/contexts/snapshots.js';
import { useSharedContextsStore } from '@/stores/contexts/sharedContexts.js';
import { usePmsStatusStore } from '@/stores/hospitality/pmsStatus.js';
import { useRolesStore } from '@/stores/access/roles.js';
import { useAllocatedStreamsStore } from '@/stores/streamallocator/allocatedStreams.js';
import { useDeletedContextsStore } from '@/stores/contexts/deletedContexts.js';
import { usePrivilegeSetsStore } from '@/stores/access/privilegeSets.js';
import { useSystemConfigStore } from '@/stores/systemConfig.js';
import { useFeaturesStore } from '@/stores/features.js';
import { useManagementStore } from '@/stores/admin/management.js';
import { useContextAccessStore } from '@/stores/access/contextAccess.js';
import { useAreasStore } from '@/stores/admin/areas.js';
import { useContextTypesStore } from '@/stores/admin/contextTypes.js';
import { useProductsStore } from '@/stores/admin/products.js';
import { useHospitalityServiceConfigStore } from '@/stores/hospitality/hospitalityServiceConfig.js';
import { useSignageServiceConfigStore } from '@/stores/signage/signageServiceConfig.js';
import { useEventServiceConfigStore } from '@/stores/signage/eventServiceConfig.js';
import { usePmsServiceConfigStore } from '@/stores/hospitality/pmsServiceConfig.js';
import { useTablesConfigurationsStore } from '@/stores/content/tablesConfigurations.js';
import { usePlaylistConfigurationsStore } from '@/stores/content/playlistConfigurations.js';
import { useCastProxyConfigurationStore } from '@/stores/cast/castProxyConfiguration.js';
import { useNetworkProxyConfigurationsStore } from '@/stores/cast/networkProxyConfigurations.js';
import { useEmbeddedCastConfigsStore } from '@/stores/cast/embeddedCastConfigs.js';
import { useAdvancedNetworkConfigurationsStore } from '@/stores/cast/advancedNetworkConfigurations.js';
import { useResourceServerConfigurationsStore } from '@/stores/resourceServerConfigurations.js';
import { useClientServiceConfigStore } from '@/stores/clients/clientServiceConfig.js';
import { useAllocationLogEntriesStore } from '@/stores/streamallocator/allocationLogEntries.js';
import { useCurrentVendorConfigurationStore } from '@/stores/contextManagement/currentVendorConfiguration.js';
import { useLanguageInfoStore } from '@/stores/contextManagement/languageInfo.js';
import { useContentSchedulesStore } from '@/stores/signage/contentSchedules.js';
import { useOwnersStore } from '@/stores/owners.js';
import { useLicensesStore } from '@/stores/admin/licenses.js';
import { useHospitalityGuestsStore } from '@/stores/hospitality/hospitalityGuests.js';
import { useHospitalityLocationsStore } from '@/stores/hospitality/hospitalityLocations.js';
import { useRoomAccountsStore } from '@/stores/hospitality/roomAccounts.js';
import { useWakeupsStore } from '@/stores/hospitality/wakeups.js';
import { useGuestMessagesStore } from '@/stores/hospitality/guestMessages.js';
import { useScreensStore } from '@/stores/screens/screens.js';
import { useClientsStore } from '@/stores/clients/clients.js';
import { useVirtualRemoteClientsStore } from '@/stores/hospitality/virtualRemoteClients.js';
import { useStreamDevicesStore } from '@/stores/streamallocator/streamDevices.js';
import { useDuplicateRegisteredClientsStore } from '@/stores/clients/duplicateRegisteredClients.js';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import { useThemesStore } from '@/stores/contexts/themes.js';
import { useContentProviderContextsStore } from '@/stores/contexts/contentProviderContexts.js';
import { useSystemsStore } from '@/stores/contexts/systems.js';
import { useResellersStore } from '@/stores/contexts/resellers.js';
import { useDistributorsStore } from '@/stores/contexts/distributors.js';
import { useLegaciesStore } from '@/stores/contexts/legacies.js';
import { useSharingContextsStore } from '@/stores/contexts/sharingContexts.js';
import { useDomainsStore } from '@/stores/admin/domains.js';
import { useSystemContextConfigsStore } from '@/stores/admin/systemContextConfigs.js';
import { useOverviewContextsStore } from '@/stores/contexts/overviewContexts.js';
import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';

export default {

    data() {
        return {
            resourceTimeouts: {},
            batchTimeout: 2000,
            isSuperuser: false,
            isDomainUser: false,
            batchData: {
                update: {
                    'overviewContexts': {
                        timeout: 60,
                        timer: null,
                        pendingIds: [],
                        firstInstant: false,
                        lastInstantId: null
                    },
                    'clients': {
                        timeout: 5,
                        timer: null,
                        pendingIds: [],
                        firstInstant: true,
                        lastInstantId: null
                    }
                },
                create: {
                    'mediaChannels': {
                        timeout: 8,
                        timer: null,
                        pendingIds: [],
                        firstInstant: false,
                        lastInstantId: null
                    }
                }
            }
        }
    },
    computed: {
        authStore() {
            return useAuthStore();
        },
        contextStore() {
            return useContextStore();
        },
        pushChannel() {
            return usePushChannel();
        },
        systemsStore() {
            return useSystemsStore();
        },
        domainsStore() {
            return useDomainsStore();
        }
    },
    watch: {
        'pushChannel.resourceUpdated.value'({feature, resource, created, updated, deleted}) {
            this.handleResourceUpdated(feature, resource, created, updated, deleted);
        },
        async 'systemsStore.items'(items) {
            this.isSuperuser = items.length > 0;
            if(this.isSuperuser && this.contextMode === 'home') {
                return Promise.all([
                    useApiAccountsStore().fetch(),
                    useAccountsStore().fetch(),
                ]);
            }
        },
        async 'domainsStore.items'(items) {

            this.isDomainUser = items.length > 0;

            if (this.isDomainUser) {
                await useSystemContextConfigsStore().fetch();
                if(this.contextMode === 'home') {
                    return Promise.all( [
                        useDeletedContextsStore().fetch(),
                        useApiAccountsStore().fetch(),
                        useAccountsStore().fetch(),
                    ]);
                }
            }
        },
        async 'authStore.isAuthenticated'(authenticated) {
            if (authenticated) {
                /* Stores to ALWAYS load when authenticated */
                return Promise.all([
                    useUserAccountStore().fetch(),
                    useContextStore().loadOverviewContext(),
                    useDeploymentsStore().fetch(),
                    useThemesStore().fetch(),
                    useSharingContextsStore().fetch(),
                    useContentProviderContextsStore().fetch(),
                    //await this.$store.dispatch('legacies/fetch');
                    //await this.$store.dispatch('resellers/fetch');
                    //await this.$store.dispatch('distributors/fetch');
                    useSystemsStore().fetch(),
                    useDomainsStore().fetch(),
                    useContextTypesStore().fetch(),
                ]);
            }
        },
        'contextStore.selectedContext'(contextId) {
            try {
                for(const operation of Object.keys(this.batchData)) {
                    if(this.batchData.hasOwnProperty(operation)) {
                        for (const store of Object.keys(this.batchData[operation])) {
                            console.log(store);
                            if(this.batchData[operation].hasOwnProperty(store) && this.batchData[operation][store].pendingIds.length > 0) {
                                console.log("RESETTING " + store);
                                this.batchData[operation][store].pendingIds = [];
                            }
                        }
                    }
                }
            } catch(e) {
                console.error(e);
            }
        }
    },

    methods: {
        getAssociatedStore(feature, resource) {

            /* Include feature in check here if there are name-clashes */
            const entry = apiConfigurations.find(api => api.feature === feature && (api.virtualResource ? api.virtualResource === resource : api.resource === resource));
            if(entry) {
                const name = entry.moduleName ? entry.moduleName : entry.apiName;
                /* pinia stores */
                switch(name) {
                    case 'screens':
                        return useScreensStore();
                    case 'compositeMenuPageFolders':
                        return useCompositeMenuPageFoldersStore();
                    case 'interactiveMenuPageFolders':
                        return useInteractiveMenuPageFoldersStore();
                    case 'templateFolders':
                        return useTemplateFoldersStore();
                    case 'documentFolders':
                        return useDocumentFoldersStore();
                    case 'resourceFolders':
                        return useResourceFoldersStore();
                    case 'playlistFolders':
                        return usePlaylistFoldersStore();
                    case 'logoFolders':
                        return useLogoFoldersStore();
                    case 'wayfindingIconFolders':
                        return useWayfindingIconFoldersStore();
                    case 'locationCategories':
                        return useLocationCategoriesStore();
                    case 'locationTags':
                        return useLocationTagsStore();
                    case 'locations':
                        return useLocationsStore();
                    case 'images':
                        return useImagesStore();
                    case 'videos':
                        return useVideosStore();
                    case 'fonts':
                        return useFontsStore();
                    case 'templates':
                        return useTemplatesStore();
                    case 'templateSources':
                        return useTemplateSourcesStore();
                    case 'documents':
                        return useDocumentsStore();
                    case 'compositeMenuPages':
                        return useCompositeMenuPagesStore();
                    case 'interactiveMenuPages':
                        return useInteractiveMenuPagesStore();
                    case 'playlists':
                        return usePlaylistsStore();
                    case 'logos':
                        return useLogosStore();
                    case 'wayfindingIcons':
                        return useWayfindingIconsStore();
                    case 'tables':
                        return useTablesStore();
                    case 'tableItems':
                        return useTableItemsStore();
                    case 'tableSchemas':
                        return useTableSchemasStore();
                    case 'mediaChannels':
                        return useMediaChannelsStore();
                    case 'globalChannels':
                        return useGlobalChannelsStore();
                    case 'channelTables':
                        return useChannelTablesStore();
                    case 'externalLocations':
                        return useExternalLocationsStore();
                    case 'integrationLocations':
                        return useIntegrationLocationsStore();
                    case 'screenSetups':
                        return useScreenSetupsStore();
                    case 'screenContents':
                        return useScreenContentsStore();
                    case 'currentScreenContents':
                        return useCurrentScreenContentsStore();
                    case 'menuLists':
                        return useMenuListsStore();
                    case 'screenWayfindingConfigurations':
                        return useScreenWayfindingConfigurationsStore();
                    case 'installCodes':
                        return useInstallCodesStore();
                    case 'schedulePlans':
                        return useSchedulePlansStore();
                    case 'scheduleRuleSets':
                        return useScheduleRuleSetsStore();
                    case 'scheduleRuleSetsOverrides':
                        return useScheduleRuleSetsOverridesStore();
                    case 'configSchedules':
                        return useConfigSchedulesStore();
                    case 'guestMessageTemplates':
                        return useGuestMessageTemplatesStore();
                    case 'eventIntegrations':
                        return useEventIntegrationsStore();
                    case 'eventIntegrationConfigurations':
                        return useEventIntegrationConfigurationsStore();
                    case 'events':
                        return useEventsStore();
                    case 'registeredCasts':
                        return useRegisteredCastsStore();
                    case 'jobs':
                        return useJobsStore();
                    case 'eventLocations':
                        return useEventLocationsStore();
                    case 'timeZones':
                        return useTimeZonesStore();
                    case 'networkProxies':
                        return useNetworkProxiesStore();
                    case 'snapshots':
                        return useSnapshotsStore();
                    case 'pmsStatus':
                        return usePmsStatusStore();
                    case 'roles':
                        return useRolesStore();
                    case 'allocatedStreams':
                        return useAllocatedStreamsStore();
                    case 'privilegeSets':
                        return usePrivilegeSetsStore();
                    case 'systemConfig':
                        return useSystemConfigStore();
                    case 'features':
                        return useFeaturesStore();
                    case 'management':
                        return useManagementStore();
                    case 'contextAccess':
                        return useContextAccessStore();
                    case 'areas':
                        return useAreasStore();
                    case 'products':
                        return useProductsStore();
                    case 'hospitalityServiceConfig':
                        return useHospitalityServiceConfigStore();
                    case 'signageServiceConfig':
                        return useSignageServiceConfigStore();
                    case 'eventServiceConfig':
                        return useEventServiceConfigStore();
                    case 'pmsServiceConfig':
                        return usePmsServiceConfigStore();
                    case 'tablesConfigurations':
                        return useTablesConfigurationsStore();
                    case 'playlistConfigurations':
                        return usePlaylistConfigurationsStore();
                    case 'castProxyConfiguration':
                        return useCastProxyConfigurationStore();
                    case 'networkProxyConfigurations':
                        return useNetworkProxyConfigurationsStore();
                    case 'embeddedCastConfigs':
                        return useEmbeddedCastConfigsStore();
                    case 'advancedNetworkConfigurations':
                        return useAdvancedNetworkConfigurationsStore();
                    case 'clientServiceConfig':
                        return useClientServiceConfigStore();
                    case 'allocationLogEntries':
                        return useAllocationLogEntriesStore();
                    case 'currentVendorConfiguration':
                        return useCurrentVendorConfigurationStore();
                    case 'languageInfo':
                        return useLanguageInfoStore();
                    case 'contentSchedules':
                        return useContentSchedulesStore();
                    case 'owners':
                        return useOwnersStore();
                    case 'licenses':
                        return useLicensesStore();
                    case 'hospitalityGuests':
                    case 'guests':
                        return useHospitalityGuestsStore();
                    case 'hospitalityLocations':
                        return useHospitalityLocationsStore();
                    case 'roomAccounts':
                        return useRoomAccountsStore();
                    case 'wakeups':
                        return useWakeupsStore();
                    case 'guestMessages':
                        return useGuestMessagesStore();
                    case 'clients':
                        return useClientsStore();
                    case 'virtualRemoteClients':
                        return useVirtualRemoteClientsStore();
                    case 'streamDevices':
                        return useStreamDevicesStore();
                    case 'deployments':
                        return useDeploymentsStore();
                    case 'themes':
                        return useThemesStore();
                    case 'contentProviderContexts':
                        return useContentProviderContextsStore();
                    case 'systems':
                        return useSystemsStore();
                    case 'resellers':
                        return useResellersStore();
                    case 'distributors':
                        return useDistributorsStore();
                    case 'legacies':
                        return useLegaciesStore();
                    case 'deletedContexts':
                        return useDeletedContextsStore();
                    case 'apiAccounts':
                        return useApiAccountsStore();
                    case 'accounts':
                        return useAccountsStore();
                    case 'sharedContexts':
                        return useSharedContextsStore();

                    default:
                        return name;
                }
            }
            console.log("Could not find " + feature + "/" + resource + " in API definitions... using old mapping");

            // Separate Hospitality/Locations from ContextLocations/Locations
            switch (feature) {
                case 'Hospitality':
                    switch (resource) {
                        case 'Locations':
                            return useHospitalityLocationsStore();
                        case 'GuestMessages':
                            return useGuestMessagesStore();
                        case 'GuestMessageTemplates':
                            return useGuestMessageTemplatesStore();
                        case 'FeatureConfigurations':
                            return useHospitalityLocationsStore(); /* Is this right? */
                        case 'Guests':
                            return useHospitalityGuestsStore();
                    }
                    break;
                case 'Pms':
                    switch (resource) {
                        case 'FeatureConfigurations':
                            return usePmsStatusStore();
                        case 'Statuses':
                            return usePmsStatusStore();
                    }
                    break;
                case "NetworkManagement":
                    switch (resource) {
                        case 'Proxies':
                            return useNetworkProxiesStore();
                    }
                    break;
                case "ContextManagement":
                    switch (resource) {
                        case 'SystemContextConfigurations':
                            return useSystemContextConfigsStore();
                        case 'Deployments':
                            return useDeploymentsStore();
                        case 'LegacyContexts':
                            return useLegaciesStore();
                        case 'Themes':
                            return useThemesStore();
                        case 'Resellers':
                            return useResellersStore();
                        case 'Distributors':
                            return useDistributorsStore();
                    }
                    break;
                case "Clients":
                    switch (resource) {
                        case 'InstallCodes':
                            return useInstallCodesStore();
                        case "Clients":
                            return useClientsStore();
                    }
                    break;
                case "LicenseManagement":
                    switch (resource) {
                        case "Licenses":
                            return useLicensesStore();
                    }
                    break;
                case "Overview":
                    switch (resource) {
                        case "Contexts":
                            return useOverviewContextsStore();
                    }
                    break;
                case "Screens":
                    switch (resource) {
                        case "ContentSchedules":
                            return useContentSchedulesStore();
                        case "ScreenContents":
                            return useScreenContentsStore();
                        case "CurrentContent":
                            return useCurrentScreenContentsStore();
                        case "SchedulePlans":
                            return useSchedulePlansStore();
                        case "Screenshots":
                            return null; /* Not store related */
                    }
                    break;
                case "ResourceManagement":
                    switch (resource) {
                        case 'Playlists':
                            return usePlaylistsStore();
                        case 'PlaylistFolders':
                            return usePlaylistFoldersStore();
                        case 'Logos':
                            return useLogosStore();
                        case 'LogoFolders':
                            return useLogoFoldersStore();
                        case 'WayfindingIcons':
                            return useWayfindingIconsStore();
                        case 'WayfindingIconFolders':
                            return useWayfindingIconFoldersStore();
                        case 'Tables':
                            return useTablesStore();
                        case 'TableItems':
                            return useTableItemsStore();
                        case 'TableSchemas':
                            return useTableSchemasStore();
                    }
                    break;
                case "StreamAllocator":
                    switch (resource) {
                        case 'StreamDevices':
                            return useStreamDevicesStore();
                        case 'AllocatedStreams':
                            return useAllocatedStreamsStore();
                        case 'AllocationLogEntries':
                            return useAllocationLogEntriesStore();
                    }
                    break;
                case "JobManagement": {
                    switch (resource) {
                        case 'Jobs':
                            return useJobsStore();
                    }
                    break;
                }
                case "EventManagement": {
                    switch (resource) {
                        case 'Events':
                            return useEventsStore();
                        case 'FeatureConfigurations':
                            return useEventServiceConfigStore();
                        case 'Locations':
                            return useEventLocationsStore();
                        case 'ScreenWayfindingConfigurations':
                            return useScreenWayfindingConfigurationsStore();
                        case 'Integrations':
                            return useEventIntegrationConfigurationsStore();
                        case 'IntegrationLocations':
                            return useIntegrationLocationsStore();
                        case 'ExternalLocations':
                            return useExternalLocationsStore();
                    }
                    break;
                }
                case "Remote": {
                    switch (resource) {
                        case 'RemoteSessions':
                            return useVirtualRemoteClientsStore();
                    }
                    break;
                }
                case "AgentManagement": {
                    switch (resource) {
                        case "ResourceServerConfigurations":
                            return useResourceServerConfigurationsStore();
                    }
                    break;
                }
            }

            console.error("Could not find " + feature + "/" + resource + " in feature/resource mapping ... using old mapping");

            // Oldie goldie switch... should really check feature
            switch(resource) {
                case 'Folders': return useResourceFoldersStore();
                case 'Channels': return useMediaChannelsStore();
                case 'Clients': return useClientsStore();
                case 'WakeUps': return useWakeupsStore();
                case 'ConfigurationSchedules': return useConfigSchedulesStore();
                case 'Locations': return useLocationsStore();
                //case 'ClientStatuses': return useClientStatusesStore();
                case 'Images': return useImagesStore();
                case 'Videos': return useVideosStore();
                case 'Fonts': return useFontsStore();
                case 'Templates': return useTemplatesStore();
                case 'TemplateFolders': return useTemplateFoldersStore();
                case 'Documents': return useDocumentsStore();
                case 'DocumentFolders': return useDocumentFoldersStore();
                case 'Guests': return useHospitalityGuestsStore();
                case 'Screens': return useScreensStore();
                case 'InteractiveMenuPages': return useInteractiveMenuPagesStore();
                case 'InteractiveMenuPageFolders': return useInteractiveMenuPageFoldersStore();
                case 'CompositeMenuPages': return useCompositeMenuPagesStore();
                case 'CompositeMenuPageFolders': return useCompositeMenuPageFoldersStore();
                case 'ScheduleRuleSets': return useScheduleRuleSetsStore();
                case 'ScheduleRuleSetsOverrides': return useScheduleRuleSetsOverridesStore();
                case 'ScreenConfigurations' : return useScreenSetupsStore();
                case 'MediaChannels': return useMediaChannelsStore();
                case 'Screenshots': return null;
                case 'RoomAccounts': return useRoomAccountsStore();
                case 'SharedContexts': return useSharedContextsStore();
                case 'Accounts': return useAccountsStore();
                case 'Roles': return useRolesStore();
                case 'Management': return useManagementStore();
            }
            console.error("Could not find store for " + feature + "/" + resource);
            return null; /* not supported */
        },

        operationOnIds(data, store, command) {
            if (isNotEmpty(data) && isNotEmpty(data.ids)) {
                for(let i = 0; i < data.ids.length; i++) {
                    const id = data.ids[i];
                    if(isNotEmpty(id)) {
                        console.log("Updating single item: " + id + " : " + command + " in " + store.name);
                        command = command.replace('/', '');
                        if(typeof store[command] === 'function') {
                            store[command]({id: id});
                        } else {
                            console.error("Invalid command " + command + " for store " + store.name);
                        }
                    } else {
                        console.error("no ID for " + command);
                        console.error(data);
                    }
                }
            }
        },

        handleBatchOperation(operation, store, storeName, items) {
            if(!this.batchData.hasOwnProperty(operation) || !this.batchData[operation].hasOwnProperty(storeName)) {
                console.error("handleBatchOperation: no entry for " + operation + "/" + storeName);
                return;
            }
            const entry = this.batchData[operation][storeName];

            /* Allow consecutive single fetches of same id since these are most likely user initiated */
            if (entry.firstInstant && (entry.timer === null || entry.lastInstantId === items.ids[0])) {
                /* Always update first entry instant */
                entry.lastInstantId = items.ids[0];
                console.log("Fetching " + items.ids[0] + " instant");
                this.operationOnIds(items, store, '/fetchSingle');
            } else {
                items.ids.forEach(id => {
                    if (!entry.pendingIds.indexOf(id) >= 0) {
                        //console.log("Adding ID " + id);
                        entry.pendingIds.push(id);
                    } else {
                        console.log("ID " + id + " already pending");
                    }
                });
            }

            if (entry.timer === null) {
                console.log("Start batch timeout " + entry.timeout + " for " + operation + " on " + storeName);
                entry.timer = setTimeout(() => {
                    if(entry.pendingIds.length > 0) {
                        console.log("Batch updating operation " + operation + " for store " + store);
                        store.fetchMultiple({ids: entry.pendingIds});
                        entry.pendingIds = [];
                    }
                    entry.timer = null;
                }, entry.timeout * 1000);
            }

        },

        handleResourceUpdated(feature, resource, created, updated, deleted) {

            if(!this.contextStore.privilegeCheckCurrentContext(this.capitalizeFirstLetter(feature), this.capitalizeFirstLetter(resource))) {
                console.log("No access to updated feature/resource: " + feature + "/" + resource);
                return;
            }

            const store = this.getAssociatedStore(feature, resource);
            if(store === null || store === "") { return; }

            const storeName = store.name;
            const storesToLoadFull = [ 'contentProviderContexts', useSharedContextsStore(), usePmsStatusStore(), useEventServiceConfigStore() ];

            if(storesToLoadFull.indexOf(store) >= 0) {
                this.handleUpdateOnId(store, updated);
            } else {
                if(created && Array.isArray(created.ids) && created.ids.length > 0 && this.batchData.create.hasOwnProperty(storeName)) {
                    //console.log("Batching update for " + store);
                    this.handleBatchOperation('create', store, storeName, created);
                } else {
                    this.operationOnIds(created, store, '/fetchSingle');
                }

                if(updated && Array.isArray(updated.ids) && updated.ids.length > 0 && this.batchData.update.hasOwnProperty(storeName)) {
                    /* No pinia stores here yet */
                    //console.log("Batching update for " + store);
                    this.handleBatchOperation('update', store, storeName, updated);
                } else {
                    this.operationOnIds(updated, store, '/fetchSingle');
                }

                this.operationOnIds(deleted, store, '/deleteLocal');
                //console.log(`default resource handler received data set changed message for ${feature}/${resource}`);
            }

            /**
             * The system does not yet support sending the data via the push channel
             */
            if (typeof created === 'object') {
                if (isNotEmpty(created.items)) {
                    for(let i = 0; i < created.items.length; i++) {
                        const id = created.items[i].id;
                        if(isNotEmpty(id)) {
                            console.log("Creating new item in " + store);
                            store.createLocal({ item: created.items[i] });
                        } else {
                            console.error("Got CREATED item with no ID!");
                            console.error(created.items[i]);
                        }
                    }
                }
            }
            if (typeof updated === 'object') {
                if (isNotEmpty(updated.items)) {
                    for(let i = 0; i < updated.items.length; i++) {
                        const id = updated.items[i].id;
                        if(isNotEmpty(id)) {
                            console.log("Updating item in " + store);
                            store.updateLocal({ item: updated.items[i] });
                        } else {
                            console.error("Got UPDATED item with no ID!");
                            console.error(updated.items[i]);
                        }
                    }
                }
            }
        },

        /**
         * Old fallback for reloading full store
         * @param store
         * @param resource
         */
        handleUpdateOnId(store, resource) {
            if(isEmpty(store) || isEmpty(resource)) {
                console.error("handleUpdateOnId: invalid params");
                return;
            }
            if(isNotEmpty(this.resourceTimeouts[resource])) {
                clearTimeout(this.resourceTimeouts[resource]);
            }
            this.resourceTimeouts[resource] = setTimeout(() => {
                this.resourceTimeouts[resource] = null;
                console.log("Refreshing store " + store);
                store.fetch({ ignoreStale: true });
            }, this.batchTimeout);
        },
        capitalizeFirstLetter(s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
        },
        hasAccessToModule(name) {
            const whitelist = [ 'languageInfo', 'roles' ];
            if(whitelist.indexOf(name) !== -1) {
                return true;
            }
            const entry = apiConfigurations.find(api => api.moduleName ?
                (api.moduleName === name ? true : api.apiName === name) :
                api.apiName === name);
            if(entry) {
                //console.log("Found " + name + " feature: " + entry.feature + " resource: " + entry.resource);
                return this.contextStore.privilegeCheckCurrentContext(entry.feature, entry.resource);
            }
            console.error("Module access - could not find " + name);
            return true;
        },
        autoLoadStores() {
            let checkAccess = true; /* Not checking access for HOME */
            let modulesToLoad = [];
            switch(this.contextMode) {
                case 'theme':
                    modulesToLoad = [
                        useLocationsStore(),
                        useWakeupsStore(),
                        useGuestMessagesStore(),
                        useGuestMessageTemplatesStore(),
                        useClientsStore(),
                        useImagesStore(),
                        useFontsStore(),
                        useVideosStore(),
                        useTemplatesStore(),
                        useTemplateFoldersStore(),
                        useDocumentsStore(),
                        useDocumentFoldersStore(),
                        useResourceFoldersStore(),
                        useTemplateSourcesStore(),
                        useInteractiveMenuPageFoldersStore(),
                        useCompositeMenuPageFoldersStore(),
                        useScreenSetupsStore(),
                        useChannelTablesStore(),
                        useHospitalityGuestsStore(),
                        useMediaChannelsStore(),
                        useGlobalChannelsStore(),
                        useRoomAccountsStore(),
                        useInteractiveMenuPagesStore(),
                        useCompositeMenuPagesStore(),
                        useInstallCodesStore(),
                        useSignageServiceConfigStore(),
                        useHospitalityLocationsStore(),
                        useClientServiceConfigStore(),
                        useHospitalityServiceConfigStore(),
                        useRolesStore(),
                        usePrivilegeSetsStore(),
                        useContextAccessStore(),
                        useLocationTagsStore(),
                        useLocationCategoriesStore(),
                        useScreensStore(),
                        useLanguageInfoStore(),
                        useSnapshotsStore(),
                        useOwnersStore(),
                        useAreasStore(),
                        useMenuListsStore(),
                        usePmsServiceConfigStore(),
                        usePlaylistsStore(),
                        usePlaylistFoldersStore(),
                        usePlaylistConfigurationsStore(),
                        useAllocationLogEntriesStore(),
                        useJobsStore(),
                        useScreenWayfindingConfigurationsStore(),
                        useEventLocationsStore(),
                        useEventServiceConfigStore(),
                        useLogosStore(),
                        useLogoFoldersStore(),
                        useWayfindingIconsStore(),
                        useWayfindingIconFoldersStore(),
                        useEventIntegrationsStore(),
                        useEventIntegrationConfigurationsStore(),
                        useIntegrationLocationsStore(),
                        useExternalLocationsStore(),
                        useSharedContextsStore(),
                        useVirtualRemoteClientsStore(),
                        useContentSchedulesStore(),
                        useCurrentScreenContentsStore(),
                        useTablesStore(),
                        useTableItemsStore(),
                        useTableSchemasStore(),
                        useTablesConfigurationsStore(),
                    ];
                    break;
                case 'site':
                    modulesToLoad = [
                        useLocationsStore(),
                        useWakeupsStore(),
                        useGuestMessagesStore(),
                        useGuestMessageTemplatesStore(),
                        useClientsStore(),
                        useCurrentScreenContentsStore(),
                        useImagesStore(),
                        useFontsStore(),
                        useVideosStore(),
                        useTemplatesStore(),
                        useTemplateFoldersStore(),
                        useDocumentsStore(),
                        useDocumentFoldersStore(),
                        useResourceFoldersStore(),
                        useTemplateSourcesStore(),
                        useInteractiveMenuPageFoldersStore(),
                        useCompositeMenuPageFoldersStore(),
                        useScreenSetupsStore(),
                        useChannelTablesStore(),
                        useHospitalityGuestsStore(),
                        useMediaChannelsStore(),
                        useGlobalChannelsStore(),
                        useRoomAccountsStore(),
                        useInteractiveMenuPagesStore(),
                        useCompositeMenuPagesStore(),
                        useConfigSchedulesStore(),
                        useContentSchedulesStore(),
                        useInstallCodesStore(),
                        useSignageServiceConfigStore(),
                        useClientServiceConfigStore(),
                        useHospitalityLocationsStore(),
                        useHospitalityServiceConfigStore(),
                        usePmsServiceConfigStore(),
                        usePmsStatusStore(),
                        useRolesStore(),
                        usePrivilegeSetsStore(),
                        useContextAccessStore(),
                        useLocationTagsStore(),
                        useLocationCategoriesStore(),
                        useCurrentVendorConfigurationStore(),
                        useScreensStore(),
                        useLanguageInfoStore(),
                        useScheduleRuleSetsStore(),
                        useScheduleRuleSetsOverridesStore(),
                        useMenuListsStore(),
                        useNetworkProxiesStore(),
                        useScreenContentsStore(),
                        useRegisteredCastsStore(),
                        useAllocatedStreamsStore(),
                        useStreamDevicesStore(),
                        usePlaylistsStore(),
                        usePlaylistFoldersStore(),
                        usePlaylistConfigurationsStore(),
                        useAllocationLogEntriesStore(),
                        useJobsStore(),
                        useLogosStore(),
                        useLogoFoldersStore(),
                        useWayfindingIconsStore(),
                        useWayfindingIconFoldersStore(),
                        useScreenWayfindingConfigurationsStore(),
                        useEventLocationsStore(),
                        useEventServiceConfigStore(),
                        useEventIntegrationsStore(),
                        useEventIntegrationConfigurationsStore(),
                        useIntegrationLocationsStore(),
                        useExternalLocationsStore(),
                        useSharedContextsStore(),
                        useSchedulePlansStore(),
                        useVirtualRemoteClientsStore(),
                        useTablesStore(),
                        useTableItemsStore(),
                        useTableSchemasStore(),
                        useTablesConfigurationsStore(),
                    ];
                    break;
                case 'sharing':
                    modulesToLoad = [
                        useLogosStore(),
                        useLogoFoldersStore(),
                        useFontsStore(),
                        useImagesStore(),
                        useVideosStore(),
                        useJobsStore(),
                        useResourceFoldersStore(),
                        useWayfindingIconsStore(),
                        useWayfindingIconFoldersStore()
                    ];
                    break;
                case 'content provider':
                    modulesToLoad = [
                        useLocationsStore(),
                        useWakeupsStore(),
                        useGuestMessagesStore(),
                        useGuestMessageTemplatesStore(),
                        useClientsStore(),
                        useImagesStore(),
                        useFontsStore(),
                        useVideosStore(),
                        useTemplatesStore(),
                        useTemplateFoldersStore(),
                        useDocumentsStore(),
                        useDocumentFoldersStore(),
                        useResourceFoldersStore(),
                        useTemplateSourcesStore(),
                        useInteractiveMenuPageFoldersStore(),
                        useCompositeMenuPageFoldersStore(),
                        useScreenSetupsStore(),
                        useChannelTablesStore(),
                        useHospitalityGuestsStore(),
                        useMediaChannelsStore(),
                        useGlobalChannelsStore(),
                        useRoomAccountsStore(),
                        useInteractiveMenuPagesStore(),
                        useCompositeMenuPagesStore(),
                        useInstallCodesStore(),
                        useSignageServiceConfigStore(),
                        useHospitalityLocationsStore(),
                        useClientServiceConfigStore(),
                        useHospitalityServiceConfigStore(),
                        useRolesStore(),
                        usePrivilegeSetsStore(),
                        useContextAccessStore(),
                        useLocationTagsStore(),
                        useLocationCategoriesStore(),
                        useScreensStore(),
                        useLanguageInfoStore(),
                        useSnapshotsStore(),
                        useOwnersStore(),
                        useAreasStore(),
                        useManagementStore(),
                        useMenuListsStore(),
                        usePmsServiceConfigStore(),
                        usePlaylistsStore(),
                        usePlaylistFoldersStore(),
                        usePlaylistConfigurationsStore(),
                        useAllocationLogEntriesStore(),
                        useJobsStore(),
                        useScreenWayfindingConfigurationsStore(),
                        useEventLocationsStore(),
                        useEventServiceConfigStore(),
                        useLogosStore(),
                        useLogoFoldersStore(),
                        useWayfindingIconsStore(),
                        useWayfindingIconFoldersStore(),
                        useEventIntegrationsStore(),
                        useEventIntegrationConfigurationsStore(),
                        useIntegrationLocationsStore(),
                        useExternalLocationsStore(),
                        useSharedContextsStore(),
                        useVirtualRemoteClientsStore(),
                        useContentSchedulesStore(),
                        useCurrentScreenContentsStore(),
                        useTablesStore(),
                        useTableItemsStore(),
                        useTableSchemasStore(),
                        useTablesConfigurationsStore(),
                    ];
                    break;
                default:
                    checkAccess = false;
                    modulesToLoad = [
                        useOverviewContextsStore(),
                        useLicensesStore(),
                        useProductsStore(),
                        useLegaciesStore(),
                        useResellersStore(),
                        useDistributorsStore(),
                        useOwnersStore(),
                        useAreasStore(),
                        useManagementStore(),
                        useTimeZonesStore(),
                        useRolesStore(),
                        usePrivilegeSetsStore(),
                        useFeaturesStore(),
                        useSystemConfigStore(),
                    ];

                    if(this.isSuperuser || this.isDomainUser) {
                        modulesToLoad.push(useDeletedContextsStore());
                        modulesToLoad.push(useDuplicateRegisteredClientsStore());
                        modulesToLoad.push(useAccountsStore());
                        modulesToLoad.push(useApiAccountsStore());
                    }
            }

            for (const module of modulesToLoad) {
                if (!checkAccess || this.hasAccessToModule(module.name)) {
                    try {
                        module.fetch({ ignoreStale: true });
                    } catch (error) {
                        console.error(error);
                        console.error("Could not load module " + module.name);
                    }
                }
            }
        },

        resetStores() {

            const modulesToReset = [
                useLocationsStore(),
                useTemplateFoldersStore(),
                useDocumentFoldersStore(),
                useResourceFoldersStore(),
                usePlaylistFoldersStore(),
                useLogoFoldersStore(),
                useWayfindingIconFoldersStore(),
                useInteractiveMenuPageFoldersStore(),
                useCompositeMenuPageFoldersStore(),
                useLocationCategoriesStore(),
                useLocationTagsStore(),
                useCompositeMenuPagesStore(),
                useInteractiveMenuPagesStore(),
                useDocumentsStore(),
                usePlaylistsStore(),
                useTemplatesStore(),
                useTemplateSourcesStore(),
                useImagesStore(),
                useVideosStore(),
                useFontsStore(),
                useLogosStore(),
                useWayfindingIconsStore(),
                useTablesStore(),
                useMediaChannelsStore(),
                useChannelTablesStore(),
                useTableItemsStore(),
                useTableSchemasStore(),
                useIntegrationLocationsStore(),
                useExternalLocationsStore(),
                useScreenSetupsStore(),
                useScreenContentsStore(),
                useCurrentScreenContentsStore(),
                useMenuListsStore(),
                useInstallCodesStore(),
                useScreenWayfindingConfigurationsStore(),
                useSchedulePlansStore(),
                useConfigSchedulesStore(),
                useEventIntegrationsStore(),
                useEventIntegrationConfigurationsStore(),
                useGuestMessageTemplatesStore(),
                useJobsStore(),
                useEventLocationsStore(),
                useNetworkProxiesStore(),
                usePmsStatusStore(),
                useScheduleRuleSetsStore(),
                useScheduleRuleSetsOverridesStore(),
                useRolesStore(),
                useAllocatedStreamsStore(),
                usePrivilegeSetsStore(),
                useContextAccessStore(),
                useHospitalityServiceConfigStore(),
                useSignageServiceConfigStore(),
                usePmsServiceConfigStore(),
                useTablesConfigurationsStore(),
                usePlaylistConfigurationsStore(),
                useClientServiceConfigStore(),
                useAllocationLogEntriesStore(),
                useCurrentVendorConfigurationStore(),
                useLanguageInfoStore(),
                useContentSchedulesStore(),
                useClientsStore(),
                useRoomAccountsStore(),
                useScreensStore(),
                useGuestMessagesStore(),
                useHospitalityGuestsStore(),
                useClientsStore(),
                useVirtualRemoteClientsStore(),
                useStreamDevicesStore(),
            ];
            for (let module of modulesToReset) {
                module.reset();
            }
        },

    }

}
