import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useRoomAccountsStore } from '@/stores/hospitality/roomAccounts.js';
import moment from 'moment/moment';
import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';

/**
 * Figure out when the alarm was last supposed to be triggered.
 * Check if the timestamp passed is after the last alarm
 * @param time the timestamp to check to be more recent then the last alarm
 * @param alarm the set time for the alarm. Only hours and minutes count
 * @param now servers now time
 * @returns {boolean}
 */
const validRecurring = (time, alarm, now) => {
    let lastAlarm = structuredClone(now);
    lastAlarm.setHours(alarm.getHours());
    lastAlarm.setMinutes(alarm.getMinutes());
    lastAlarm.setSeconds(0);
    if(lastAlarm.getTime() > now.getTime()) {
        /* Step back one day */
        lastAlarm.setDate(lastAlarm.getDate() - 1);
    }
    return (time.getTime() > lastAlarm.getTime());
};

const thisModule = 'wakeups';

// Initialize the BaseCrudPlugin for this store

export const useWakeupsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withLocationTitle = computed(() => {
        const data = store.cloneItems();
        const accountsIdMap = useRoomAccountsStore().idMapByGetter('withLocation');

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.locationTitle = Map.retrieveValue(accountsIdMap, item.roomAccountId, 'locationTitle');
                item.dateFormatted = item.daily ? "Daily" : moment(item.timeToWake).format('MMMM Do YYYY');
                item.timeFormatted = moment(item.timeToWake).format('HH:mm');
            });

            Array.sort(data, 'timeToWake', false);
            return data;
        }
        return [];
    });

    store.pending = computed(() => {
        const data = store.cloneItems();
        const pending = [];

        data.forEach(item => {
            if (item.daily || isEmpty(item.deliveredTimeUtc)) {
                pending.push(item);
            }
        });
        return pending;
    });

    store.withLocationTitleAndDelivery = computed(() => {
        const items = store.withLocationTitle.value;
        items.forEach(item => {
            if(!item.daily) {
                /* One time alarm, just check if set */
                item.isDelivered = isNotEmpty(item.deliveredTimeUtc);
                item.isDisplayed = item.isDelivered && isNotEmpty(item.displayedTimeUtc);
                item.isConfirmed = item.isDelivered && isNotEmpty(item.confirmedTimeUtc);
            } else {
                item.isDelivered = false;
                item.isDisplayed = false;
                item.isConfirmed = false;

                try {
                    const now = new Date(item.nowUtc);
                    const alarm = new Date(item.timeToWakeUtc);
                    if (isNotEmpty(item.deliveredTimeUtc)) {
                        item.isDelivered = validRecurring(new Date(item.deliveredTimeUtc), alarm, now);
                    }
                    if (isNotEmpty(item.displayedTimeUtc)) {
                        item.isDisplayed = validRecurring(new Date(item.displayedTimeUtc), alarm, now);
                    }
                    if (isNotEmpty(item.confirmedTimeUtc)) {
                        item.isConfirmed = validRecurring(new Date(item.confirmedTimeUtc), alarm, now);
                    }
                } catch(e) {
                    console.error("Error calculating wakeup delivery");
                    console.error(e);
                }
            }
        });
        return items;
    });

    return store;
});
