<template>
    <BaseDialog
        v-model="open"
        max-width="800"
        :title="title"
        :eager="true"
        :hide-close="true"
        :hide-all-actions="true"
        :persistent="true"
    >
        <template v-slot:content>
            <v-card
                height="360px"
                elevation="0"
            >
                <div v-html="eula" style="height: 100%; width: 100%; overflow-y: scroll;" @scroll="eulaScroll">
                </div>
            </v-card>

            <v-container fluid>
                <v-row no-gutters>
                    <v-btn
                        elevation="1"
                        color="primary"
                        @click="done"
                        :disabled="!eulaRead"
                    >{{$t('generic.accept')}}
                    </v-btn>
                </v-row>
            </v-container>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/popup/BaseDialog.vue';
import axios from 'axios';
import PortalTheme from "@/lib/theme/PortalTheme.js";
import { useUserAccountStore } from '@/stores/account/userAccount.js';
import { useGlobalModals } from '@/composables/useGlobalModals';
import { useAppStateStore } from '@/stores/appState';

export default {
    name: 'TheAcceptUpdatedEula',
    components: {
        BaseDialog
    },
    data() {
        return {
            open: false,
            progress: 1,
            eula: '',
            eulaRead: false,
            newEulaVersionText: this.$t('eula.updated.text')
        }
    },
    mounted() {
       useGlobalModals().setAcceptUpdatedEula(this);
    },
    computed: {
        title() {
            return this.$t('eula.updated.title');
        },
        languages() {
            return useAppStateStore().portalLanguages;
        },
    },
    methods: {
        async show() {
            this.open = true;
            try {
                let response;
                switch (PortalTheme.getTheme()) {
                    case PortalTheme.availableThemes.THEME_UNIGUEST:
                        response = await axios.get("/eula_ug.html", {});
                        break;
                    default:
                        response = await axios.get("/eula.html", {});
                        break;
                }
                this.eula = this.newEulaVersionText + response.data;
            } catch(e) {
                this.eula = "COULD NOT LOAD END USER LICENCE AGREEMENT..."
                this.eulaRead = true;
            }
        },
        done() {
            this.open = false;
            let eulaVersion = parseInt(import.meta.env.VITE_EULA_VERSION, 10);
            useUserAccountStore().saveSetting([ { key: 'acceptedEulaVersion', val: eulaVersion } ]);
        },
        eulaScroll(e) {
            if(e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50) {
                this.eulaRead = true;
            }
        },
        selectLanguage(lang) {
            useAppStateStore().setLanguage(lang);
            //this.progress = 2;
        }
    }
}
</script>