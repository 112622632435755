<!--suppress JSUnfilteredForInLoop -->
<template>
    <BaseTabModal
        v-model="show"
        :title="title"
        :done-action-text="doneText"
        :max-width="600"
        custom-actions
        custom-class="account-editor"
        :hide-all-actions="true"
        :persistent="persistent && processing"
    >
        <template v-slot:tabs>
            <v-tabs
                v-model="currentTab"
                color="accent"
                :grow="!desktop"
                mandatory
            >
                <v-tab ripple key="general" data-testid="uaeTabGeneral">
                    {{ $t('generic.general') }}
                </v-tab>
                <v-tab ripple key="password" data-testid="uaeTabPassword">
                    {{$t('admin.password')}}
                </v-tab>
                <!--RDS-4703>-->
<!--                <v-tab ripple key="email" data-testid="uaeTabEmail">-->
<!--                    {{$t('admin.email')}}-->
<!--                </v-tab>-->
                <v-tabs-items v-model="currentTab">
                    <v-tab-item key="general" eager>
                        <v-form
                            v-model="generalValid"
                            lazy-validation
                            ref="generalForm"
                        >
                            <v-alert
                                v-if="isLoggedInWithSSO"
                                type="info"
                            >
                                {{ $t('admin.formDisabledSSO') }}: {{ keycloak.tokenParsed.identity_provider }}
                            </v-alert>
                            <v-text-field
                                v-model="account.contactInformation.name"
                                :label="$t('admin.displayName')"
                                :rules="[v => !!v || $t('generic.fieldRequired'), v => !!v && v.length < 100 || $tc('generic.inputShorterThan', 2, {length: 100}), v => !!v && v.length > 2  || $tc('generic.inputLongerThan', 2, {length: 3})]"
                                data-testid="uaeDisplayName"
                                :disabled="isLoggedInWithSSO"
                            ></v-text-field>
                            <v-text-field
                                v-model="account.contactInformation.phone"
                                :label="$t('admin.phone')"
                                data-testid="uaePhoneNumber"
                                :disabled="isLoggedInWithSSO"
                            ></v-text-field>
                            <v-card
                                disabled
                                flat
                            >
                                <p>
                                    {{ lastLogin }}<br>
                                    {{ idp }}
                                </p>
                            </v-card>
                            <v-card flat>
                                <v-btn @click="copyAuthTokenToClipboard">Copy authToken</v-btn>
                            </v-card>
                            <v-container fluid>
                                <v-row no-gutters>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="cancel"
                                        elevation="1"
                                        :loading="processing"
                                        data-testid="uaeCancelGeneral"
                                    >
                                        {{ $t('generic.cancel') }}
                                    </v-btn>
                                    &nbsp; &nbsp;
                                    <v-btn
                                        color="primary"
                                        elevation="1"
                                        @click="saveGeneral"
                                        :loading="processing"
                                        data-testid="uaeSaveGeneral"
                                        :disabled="isLoggedInWithSSO || !generalValid"
                                    >
                                        {{$t('generic.save')}}
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item key="password" eager>

                        <v-dialog persistent max-width="600" v-model="showConfirmPassword">
                            <v-card>
                                <v-card-title class="headline">{{$t('admin.userUpdatePasswordTitle')}}</v-card-title>
                                <v-card-text>{{$t('admin.userUpdatePasswordDescription')}}</v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        elevation="1"
                                        @click.stop="cancelPasswordUpdate"
                                        data-testid="uaeCancelPasswordUpdate"
                                    >
                                        {{ $t('generic.no') }}
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        elevation="1"
                                        @click.stop="confirmPasswordUpdate"
                                        data-testid="uaeConfirmPasswordUpdate"
                                    >
                                        {{ $t('generic.yes') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-form
                            v-model="generalValid"
                            lazy-validation
                            ref="generalForm"
                        >
                            <v-alert
                                v-if="isLoggedInWithSSO"
                                type="info"
                            >
                                {{ $t('admin.formDisabledSSO') }}: {{ keycloak.tokenParsed.identity_provider }}
                            </v-alert>
                            <v-card elevation="0">
                                {{$t('admin.changeYourPassword')}}
                            </v-card>
                            <v-text-field
                                v-model="oldPassword"
                                ref="oldPasswordField"
                                :label="$t('admin.oldPassword')"
                                clearable
                                validate-on-blur
                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                @click:append="showPassword = !showPassword"
                                :type="showPassword ? 'text' : 'password'"
                                :rules="[validateOldPassword]"
                                v-if="!apiAccount"
                                :disabled="isLoggedInWithSSO"
                                data-testid="uaeOldPassword"
                            ></v-text-field>
                            <PasswordField
                                v-model="newPassword1"
                                ref="passwordField1"
                                v-if="!apiAccount"
                                :label="$t('admin.newPassword')"
                                :hint="$t('admin.passwordHint')"
                                :disabled="isLoggedInWithSSO"
                                data-testid="uaeNewPassword1"
                            ></PasswordField>
                            <PasswordField
                                v-model="newPassword2"
                                :match="password1"
                                ref="passwordField2"
                                v-if="!apiAccount"
                                :label="$t('admin.repeatNewPassword')"
                                :disabled="isLoggedInWithSSO"
                                data-testid="uaeNewPassword2"
                            ></PasswordField>
                            <v-container fluid>
                                <v-row no-gutters>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="cancel"
                                        elevation="1"
                                        :loading="processing"
                                        data-testid="uaeCancelPassword"
                                    >
                                        {{ $t('generic.cancel') }}
                                    </v-btn>
                                    &nbsp; &nbsp;
                                    <v-btn
                                        color="primary"
                                        elevation="1"
                                        @click="savePassword"
                                        :loading="processing"
                                        :disabled="!newPasswordLengthOk || (newPassword1 !== newPassword2)"
                                        data-testid="uaeSavePassword"
                                    >
                                        {{$t('admin.changePassword')}}
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-tab-item>
                    <!--RDS-4703>-->
<!--                    <v-tab-item key="email" eager>-->
<!--                        <v-form-->
<!--                            v-model="generalValid"-->
<!--                            lazy-validation-->
<!--                            ref="generalForm"-->
<!--                        >-->
<!--                            <v-alert-->
<!--                                v-if="isLoggedInWithSSO"-->
<!--                                type="info"-->
<!--                            >-->
<!--                                {{ $t('admin.formDisabledSSO') }}: {{ keycloak.tokenParsed.identity_provider }}-->
<!--                            </v-alert>-->
<!--                            <v-card elevation="0">-->
<!--                                {{$t('admin.userChangeEmailHeader')}}-->
<!--                            </v-card>-->
<!--                            <v-text-field-->
<!--                                v-model="account.name"-->
<!--                                :label="$t('admin.email')"-->
<!--                                :rules="[v => !!v || $t('generic.fieldRequired')]"-->
<!--                                :disabled="isLoggedInWithSSO"-->
<!--                                data-testid="uaeEmail"-->
<!--                            ></v-text-field>-->
<!--                            <v-text-field-->
<!--                                v-model="emailPassword"-->
<!--                                ref="emailPasswordField"-->
<!--                                :label="$t('admin.password')"-->
<!--                                clearable-->
<!--                                validate-on-blur-->
<!--                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"-->
<!--                                @click:append="showPassword = !showPassword"-->
<!--                                :type="showPassword ? 'text' : 'password'"-->
<!--                                v-if="!apiAccount"-->
<!--                                :disabled="isLoggedInWithSSO"-->
<!--                                data-testid="uaePassword"-->
<!--                                :rules="[v => !!v || $t('generic.fieldRequired')]"-->
<!--                            ></v-text-field>-->
<!--                            <v-container fluid>-->
<!--                                <v-row no-gutters>-->
<!--                                    <v-spacer></v-spacer>-->
<!--                                    <v-btn-->
<!--                                        @click="cancel"-->
<!--                                        elevation="1"-->
<!--                                        :loading="processing"-->
<!--                                        data-testid="uaeCancelEmail"-->
<!--                                    >-->
<!--                                        {{ $t('generic.cancel') }}-->
<!--                                    </v-btn>-->
<!--                                    &nbsp; &nbsp;-->
<!--                                    <v-btn-->
<!--                                        color="primary"-->
<!--                                        @click="saveEmail"-->
<!--                                        elevation="1"-->
<!--                                        :loading="processing"-->
<!--                                        :disabled="!emailPasswordEmpty"-->
<!--                                        data-testid="uaeUpdateEmail"-->
<!--                                    >-->
<!--                                        {{$t('admin.updateEmail')}}-->
<!--                                    </v-btn>-->
<!--                                </v-row>-->
<!--                            </v-container>-->
<!--                        </v-form>-->
<!--                    </v-tab-item>-->
                </v-tabs-items>
            </v-tabs>
        </template>
    </BaseTabModal>
</template>

<script>
import BaseTabModal from "@/components/base/popup/BaseTabModal.vue";
import VModelShowMixin from "@/mixins/vModelShowMixin.js";
import openEditorMixin from "@/mixins/openEditorMixin.js";
import PasswordField from "@/components/input/PasswordField.vue";
import AuthManager from "@/lib/helpers/AuthManager.js";
import { useGlobalModals } from '@/composables/useGlobalModals';
import { useKeycloak } from '@/composables/useKeycloak';
import { useUserAccountStore } from '@/stores/account/userAccount.js';
import { useAuthStore } from '@/stores/auth.js';
import { useContextStore } from '@/stores/context.js';
import moment from '@/lib/helpers/moment';
import { responseSuccessCheck } from '@/lib/helpers/ApiHelper';
import { isNotEmpty } from '@/lib/helpers/Validator';
import { useAppStateStore } from '@/stores/appState';

const defaultAccount = {
    name: '',
    type: 'user',
    apiKey: null,
    contactInformation: {
        name: null,
        email: null,
        phone: null
    },
};

export default {
    name: 'UserAccountEditor',
    mixins: [VModelShowMixin, openEditorMixin],
    components: {PasswordField, BaseTabModal},
    props: {
        hideAccessTab: {
            default: false,
            type: Boolean
        },
        persistent: {
            default: true,
            type: Boolean
        },
        apiAccount: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            account: structuredClone(defaultAccount),
            generalValid: false,
            password1: null,
            password2: null,
            showPassword: false,
            emailPassword: '',
            currentTab: 0,
            processing: false,
            oldPassword: '',
            newPassword1: '',
            newPassword2: '',
            showConfirmPassword: false,
            lastLogin: "",
            domain: "",
            idp: ""
        }
    },
    computed: {
        keycloak() {
            return useKeycloak();
        },
        desktop() {
            return useAppStateStore().desktop;
        },
        globalModals() {
            return useGlobalModals();
        },
        title() {
            return this.$t('admin.myAccount');
        },
        authStore() {
            return useAuthStore();
        },
        contextStore() {
            return useContextStore();
        },
        doneText() {
            return this.$t('generic.save');
        },
        newPasswordLengthOk () {
            return this.newPassword1.length > 7;
        },
        isLoggedInWithSSO() {
            return (this.keycloak.tokenParsed.identity_provider != null && this.keycloak.tokenParsed.identity_provider.toString().length > 0)
        },
        userAccountStore() {
            return useUserAccountStore();
        },
        emailPasswordEmpty(){
            return (this.emailPassword !== null && this.emailPassword.length > 0)
        }
    },
    watch: {
        async show(show) {
            if (show) {
                // this.keycloak.accountManagement();
                setTimeout(() => {
                    if (this.$refs.generalForm) {
                        this.$refs.generalForm.resetValidation();
                    }
                    if(this.$refs.passwordField1) {
                        this.$refs.passwordField1.resetPassword();
                    }
                    if(this.$refs.passwordField2) {
                        this.$refs.passwordField2.resetPassword();
                    }
                    if(this.$refs.oldPasswordField){
                        this.$refs.oldPasswordField.reset();
                    }
                    if(this.$refs.emailPasswordField){
                        this.$refs.emailPasswordField.reset();
                    }
                }, 100);
                this.currentTab = 0;
                this.account = structuredClone(this.userAccountStore.account);
                if(this.account.lastLoggedIn){
                    const format = useAppStateStore().amPmDisplay ? "Do MMM YYYY h:mm A" : "Do MMM YYYY hh:mm";
                    const formattedDate = moment.utc(this.account.lastLoggedIn).local().format(format);
                    this.lastLogin = this.$t('generic.lastLoggedIn') + ": " + formattedDate;
                } else {
                    this.lastLogin = this.$t('generic.lastLoggedIn') + ": " + this.$t('generic.unknown');
                }
                this.domain = 'Domain context: ' + (this.keycloak.tokenParsed.domain.toString() || "otrum");
                this.idp = 'Login method: ' + ( this.keycloak.tokenParsed.identity_provider?.toString().capitalize() || "Username/password");
            }
        },
    },
    methods: {
        validateOldPassword(password) {
            return isNotEmpty(password) ? true : '';
        },
        validatePassword1(password) {
            return isNotEmpty(password) && this.newPasswordLengthOk ? true : this.$t('admin.passwordLength');
        },
        validatePassword2(password) {
            return password === this.newPassword1 || this.$t('admin.passwordMatch');
        },
        cancel() {
            this.processing = false;
            this.show = false;
        },
        async saveGeneral() {
            this.processing = true;
            const response = await useUserAccountStore().saveNameAndPhone({
                name: this.account.contactInformation.name.trim(),
                phone: this.account.contactInformation.phone
            });

            if (responseSuccessCheck(response)) {
                this.globalModals.getNotification().saved(this.$tc('generic.updated', 1, {type: 'Contact info'}), 1);
            } else {
                this.globalModals.getNotification().show('error', this.$t('admin.userSaveFailedGeneral'));
            }
            this.processing = false;
        },
        cancelPasswordUpdate() {
            this.showConfirmPassword = false;
            this.processing = false;
        },
        async confirmPasswordUpdate() {
            this.showConfirmPassword = false;

            const response = await useUserAccountStore().changePassword({
                oldPassword: this.oldPassword,
                newPassword: this.newPassword1
            });

            this.oldPassword = '';
            this.newPassword1 = '';
            this.newPassword2 = '';
            if (responseSuccessCheck(response)) {
                this.globalModals.getNotification().saved(this.$tc('generic.updated', 1, {type: 'Password'}), 1);
                setTimeout(async () => {
                    await this.authStore.logoutKeycloak(this.keycloak);
                    await this.authStore.logout();
                    await this.contextStore.setLogin();
                }, 3000);
            } else {
                this.globalModals.getNotification().show('error', this.$t('admin.userSaveFailedPassword'));
            }
            this.processing = false;
        },
        savePassword() {
            this.processing = true;
            this.showConfirmPassword = true;
        },
        async saveEmail() {

            this.processing = true;
            const response = await useUserAccountStore().changeEmail({
                email: this.account.name,
                password: this.emailPassword
            });

            if (responseSuccessCheck(response)) {
                this.globalModals.getNotification().saved(this.$tc('generic.updated', 1, {type: 'E-Mail'}), 1);
                this.emailPassword = '';
            } else {
                this.globalModals.getNotification().show('error', this.$t('admin.userSaveFailedEmail'));
            }
            this.processing = false;
        },
        async copyAuthTokenToClipboard() {
            try {
                await navigator.clipboard.writeText(AuthManager.getAuthenticationToken());
                this.globalModals.getNotification().show('success', this.$t('admin.copiedAuthToken'));
            } catch (e) {
                console.error('Failed to copy authToken to clipboard: ', e)
            }
        }
    }
}
</script>
