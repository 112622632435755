import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useRoomAccountsStore } from '@/stores/hospitality/roomAccounts.js';
import moment from 'moment/moment';

const thisModule = 'guestMessages';

// Initialize the BaseCrudPlugin for this store

export const useGuestMessagesStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.processedWithLocation = computed(() => {
        const data = store.cloneItems();
        const accountsIdMap = useRoomAccountsStore().idMapByGetter('withLocation');

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.locationTitle = Map.retrieveValue(accountsIdMap, item.receiverRoomAccountId, 'locationTitle');
                item.createdDateFormatted = item.createdDate ? moment(item.createdDate).format('MMMM Do YYYY HH:mm') : '';
                item.displayedDateFormatted = item.displayedDate ? moment(item.displayedDate).format('MMMM Do YYYY HH:mm') : '';
                item.readDateFormatted = item.readDate ? moment(item.readDate).format('MMMM Do YYYY HH:mm') : 'Not read';
            });

            //Array.sort(data, 'createdDate', false);
            return data;
        }
        return [];
    });

    store.unread = computed(() => {
        return store.cloneItems().filter(message => message.readDate === null);
    });

    return store;
});
