import { defineStore } from 'pinia';
import { computed } from 'vue';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { useContextStore } from '@/stores/context.js';
import { useGlobalChannelsStore } from '@/stores/tvchannels/globalTables.js';

const thisModule = 'mediaChannels';

export const useMediaChannelsStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule);
    plugin({ store });

    store.withImageUrl = computed(() => {
        const data = store.cloneItems();
        const globalMediaChannels = useGlobalChannelsStore().idMap;
        if (Array.isArray(data)) {
            data.forEach((item) => {
                const image = Map.retrieveValue(globalMediaChannels, item.globalId, 'image', null);
                item.imageUrl = image === null ? null : 'https://image.otrum.net/mediachannel/' + image;
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    });

    store.withImageUrlAndMediaChannelId = computed(() => {
        const data = store.cloneItems();
        const globalMediaChannels = useGlobalChannelsStore().idMap;
        if (Array.isArray(data)) {
            data.forEach((item) => {
                const image = Map.retrieveValue(globalMediaChannels, item.globalId, 'image', null);
                item.imageUrl = image === null ? null : 'https://image.otrum.net/mediachannel/' + image;
                item.mediaChannelId = item.id;
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    });

    store.withImageUrlAndMediaChannelIdByType = (type) => {
        const data = store.withImageUrlAndMediaChannelId;
        const typeLower = type.toLowerCase();

        return data.filter(c => c.type === typeLower);
    };

    store.buildMediaChannelOfType = ({type}) => {
        const mediaChannel = {
            type: type.toLowerCase(),
            tuningParameters: {},
            contextId: useContextStore().selectedContextId,
            name: '',
            globalId: null,
            tableId: Math.floor(Math.random() * 1000)
        };

        switch(mediaChannel.type) {
            case 'udp':
                mediaChannel.tuningParameters['ipAddress'] = null;
                mediaChannel.tuningParameters['ipPort'] = null;
                mediaChannel.tuningParameters['serviceId'] = null;
                mediaChannel.tuningParameters['audioOnly'] = 'false';
                mediaChannel.tuningParameters['rtp'] = 'false';
                mediaChannel.tuningParameters['drm'] = 'false';
                break;
            case 'dvbc':
                mediaChannel.tuningParameters['frequency'] = null;
                mediaChannel.tuningParameters['modulation'] = null;
                mediaChannel.tuningParameters['symbolRate'] = null;
                mediaChannel.tuningParameters['serviceId'] = null;
                mediaChannel.tuningParameters['drm'] = 'false';
                break;
            case 'dvbs':
                mediaChannel.tuningParameters['frequency'] = null;
                mediaChannel.tuningParameters['satelliteId'] = null;
                mediaChannel.tuningParameters['polarization'] = null;
                mediaChannel.tuningParameters['programNumber'] = null;
                mediaChannel.tuningParameters['serviceId'] = null;
                mediaChannel.tuningParameters['symbolRate'] = null;
                mediaChannel.tuningParameters['s2'] = 'false';
                mediaChannel.tuningParameters['ptc'] = null;
                break;
            case 'dvbt':
                mediaChannel.tuningParameters['frequency'] = null;
                mediaChannel.tuningParameters['modulation'] = null;
                mediaChannel.tuningParameters['serviceId'] = null;
                mediaChannel.tuningParameters['drm'] = 'false';
                break;
            case 'pal':
                mediaChannel.tuningParameters['frequency'] = null;
                break;
            case 'http':
                mediaChannel.tuningParameters['type'] = null;
                mediaChannel.tuningParameters['url'] = null;
                mediaChannel.tuningParameters['audioOnly'] = 'false';
                break;
        }
        return mediaChannel;
    };


    return store;
});
