// Try to use `window.download` if available
let download = window.download ?? null;

// Provide a fallback download function
if (!download || typeof download !== "function") {
    console.warn("browser-downloads is not loaded. Using fallback.");

    download = function (blob, filename, mime) {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
}

// ✅ Export `download` as the default export
export default download;
