import { defineStore } from 'pinia';
import apis from '@/lib/api/index.js';
import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";
import { useContextStore } from '@/stores/context.js';

const resCheck = responseSuccessCheck;

export const useCurrentVendorConfigurationStore = defineStore('vendorConfig', {
    state: () => ({
        name: 'currentVendorConfiguration',
        initialized: false,
        fetching: false,
        vendorConfig: null
    }),

    getters: {
        configuration: (state) => state.vendorConfig
    },

    actions: {
        async fetch() {
            const result = { success: false, errorMessage: '' };
            const contextStore = useContextStore();

            if (isEmpty(contextStore.selectedContext) || isEmpty(contextStore.selectedContextId)) {
                result.errorMessage = 'Context ID not set - unable to get config';
                return result;
            }

            this.fetching = true;

            try {
                const response = await apis['currentVendorConfiguration'].get(`/${contextStore.selectedContextId}`);

                if (resCheck(response)) {
                    if (isNotEmpty(response.data)) {
                        this.vendorConfig = response.data;
                        this.initialized = true;
                        result.success = true;
                    } else {
                        result.errorMessage = "Empty response from server";
                        this.vendorConfig = null;
                        this.initialized = false;
                    }
                } else {
                    result.errorMessage = 'An error occurred - please try again later';
                    this.vendorConfig = null;
                    this.initialized = false;
                    console.error(`Vendor config load failed: ${response.status} "${response.message}"`);
                }
            } catch (error) {
                console.error('API fetch error:', error);
                result.errorMessage = 'An unexpected error occurred.';
            } finally {
                this.fetching = false;
            }

            return result;
        },
        reset() {
            this.vendorConfig = null;
            this.initialized = false;
        }
    }
});
