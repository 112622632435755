import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes.js';

Vue.use(Router);

// ✅ Preload all views using `import.meta.glob()`
const viewComponents = import.meta.glob('../views/**/*.vue');

// ✅ Helper function to dynamically import views
const loadView = (viewPath) => {
    const filePath = `../views/${viewPath}.vue`;
    if (viewComponents[filePath]) {
        return viewComponents[filePath]; // Return the correct import function
    } else {
        console.error(`View not found: ${viewPath}`);
        return Promise.reject(new Error(`View not found: ${viewPath}`));
    }
};

// ✅ Function to create individual routes
function createRoute(path, view, nameKey, options = {}) {
    return {
        name: nameKey || view,
        path: path,
        meta: {
            group: options.group || null,
            device: options.device || null,
            requiredAccess: options.requiredAccess || [],
            modes: options.modes || [],
            icon: options.icon || null,
            routeId: options.routeId || null,
            additionalAccessToRoutesIds: options.additionalAccessToRoutesIds || null,
            requiredWhitelabel: options.requiredWhitelabel || null
        },
        component: loadView(view) // ✅ Uses `import.meta.glob()` for dynamic imports
    };
}

// ✅ Process routes dynamically
const preparedRoutes = [];

routes.forEach((route) => {
    if (route.views && route.views.length > 0) {
        route.views.forEach((view) => {
            preparedRoutes.push(createRoute(
                route.path + view.path,
                view.view,
                view.nameKey,
                {
                    requiredAccess: view.requiredAccess,
                    group: route,
                    device: view.device,
                    modes: view.modes,
                    routeId: view.routeId,
                    additionalAccessToRoutesIds: view.additionalAccessToRoutesIds,
                    requiredWhitelabel: view.requiredWhitelabel
                }
            ));
        });
    } else {
        preparedRoutes.push(createRoute(
            route.path,
            route.view,
            route.nameKey,
            {
                requiredAccess: route.requiredAccess,
                icon: route.icon,
                device: route.device,
                modes: route.modes,
                requiredWhitelabel: route.requiredWhitelabel
            }
        ));
    }
});

// ✅ Vue Router instance
const router = new Router({
    mode: 'history',
    routes: preparedRoutes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { top: 0, left: 0 };
    }
});

export default router;
