import { defineStore } from 'pinia';
import apis from '@/lib/api/index.js';
import languageList from '@/i18n/languages';
import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";
import { useContextStore } from '@/stores/context.js';
import { useHospitalityServiceConfigStore } from '@/stores/hospitality/hospitalityServiceConfig.js';

const resCheck = responseSuccessCheck;

export const useLanguageInfoStore = defineStore('language', {
    state: () => ({
        name: 'languageInfo',
        initialized: false,
        fetching: false,
        languages: [],
        defaultLanguage: 'en'
    }),
    actions: {
        async fetch() {
            const result = { success: false, errorMessage: '' };
            const contextStore = useContextStore();

            if (isEmpty(contextStore.selectedContext) || isEmpty(contextStore.selectedContextId)) {
                result.errorMessage = 'Context ID not set - unable to get languages';
                return result;
            }

            let apiEndpoint;
            switch (contextStore.selectedContext.contextType) {
                case 'Deployment':
                    apiEndpoint = 'languageInfoDeployment';
                    break;
                case 'Theme':
                    apiEndpoint = 'languageInfoTheme';
                    break;
                case 'Content Provider':
                    apiEndpoint = 'languageInfoContentProvider';
                    break;
                default:
                    throw new Error(`Unknown context type: ${contextStore.selectedContext.contextType}`);
            }

            const response = await apis[apiEndpoint].get('', {}, contextStore.selectedContextId);

            if (resCheck(response)) {
                if (isNotEmpty(response.data)) {
                    this.languages = response.data.map(item => {
                        const entry = languageList.find(lang => lang.code === item['iso-639-1']);
                        return entry ? {
                            code: item['iso-639-1'],
                            label: item.label,
                            name: entry.name
                        } : null;
                    }).filter(Boolean);
                    this.defaultLanguage = response.data.find(item => item.isDefault)?.['iso-639-1'] || 'en';
                    this.initialized = true;
                    result.success = true;
                } else {
                    result.errorMessage = "Empty response from server";
                    this.reset();
                }
            } else {
                result.errorMessage = 'An error occurred - please try again later';
                this.reset();
                console.log('Vendor config load failed:', response.status, response.message);
            }
            return result;
        },
        reset() {
            this.languages = [];
            this.defaultLanguage = 'en';
            this.initialized = false;
        }
    },
    getters: {
        checkinLanguages: (state) => {
            const languages = state.languages;
            const languageConfig = useHospitalityServiceConfigStore();

            if (languages.length > 0  && languageConfig && languageConfig.fetched && languageConfig.config.availableLanguages) {
                const availableLanguages = languageConfig.config.availableLanguages;
                return languages.filter((it) => availableLanguages.find(({ code }) => it.code === code));
            }
            return [];
        }
    }
});