import moment from 'moment';

const emailRegularExpression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ipAddressRegularExpression = /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;

const nameRegularExpression = /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒæÆČŠŽ∂ð '-]+$/;

const isEmpty = (value) => {
    switch (value) {
        // case 0:
        // case '0':
        case '':
        case null:
        case false:
        case undefined:
            return true;
        default:
            if (Array.isArray(value)) {
                return value.length === 0;
            } else if (value.constructor === Object) {
                return Object.entries(value).length === 0;
            } else {
                return false;
            }
    }
};

const isStringOfMaxLength = (value, maxLength) => {
    if (!value || typeof value !== 'string') {
        return true;
    }

    return value.length <= maxLength;
};

const isEmptyOrBlank = (value) =>{
    if (value === null || typeof value === 'undefined') {
        return true;
    } else {
        return isEmpty(value.trim());
    }
};

/**
 * This function allows us to quickly check if a video has a 4K resolution across multiple areas of the code.
 * @param width - The width of the video to compare
 * @param height - The height of the video to compare
 * @returns {boolean} - True if either width or height are above standard HD resolution sizing.
 */
const isFile4kResolution = (width, height) => {
    return (width >= 3840 || height >= 2160);
};

const isIdInDataUpdate = (operation, id) => {
    return (
        typeof operation === 'object' &&
        !isEmpty(operation.ids) &&
        operation.ids.indexOf(id) >= 0
    );
};

const isArray = (value) => {
    return Array.isArray(value);
};

const isNotEmpty = (value) => {
    return !isEmpty(value);
};

const isEmail = (value) => {
    return emailRegularExpression.test(value);
};

const isValidName = (value) => {
    return nameRegularExpression.test(value);
};

const isDataStale = (fetched, staleLimit) => {
    if (fetched === null) {
        return true;
    }

    const duration = moment.duration(
        fetched.diff(moment().subtract(staleLimit, 'minutes'))
    );
    return duration.asMinutes() >= staleLimit;
};

const isIpAddress = (value) => {
    if (!value) {
        return true;
    }
    return ipAddressRegularExpression.test(value);
};

const isIpPort = (value) => {
    if (!value) {
        return true;
    }
    const numberValue = Number(value);
    return numberValue > 0 && numberValue <= 65535;
};

const isFrequency = (value) => {
    if (!value) {
        return true;
    }
    const numberValue = Number(value);

    return numberValue > 0 && numberValue <= 99999999;
};

const arraysEqual = (arr1, arr2) => {
    if (!arr1 || !arr2) {
        return -1;
    }
    if (arr1.length !== arr2.length) return false;

    return arr1.every((obj1, index) => {
        const obj2 = arr2[index];
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    });
};

// Checks major.minor.patch versioning, ignores -<CR/GA> suffix
const isVersionSameOrHigher = (versionToCheck, baseVersion) => {
    if (!versionToCheck || !baseVersion) {
        return false;
    }

    const parseVersion = (version) => {
        const cleanVersion = version.split('-')[0];
        return cleanVersion.split('.').map(Number);
    };

    const [majorCheck, minorCheck, patchCheck] = parseVersion(versionToCheck);
    const [majorBase, minorBase, patchBase] = parseVersion(baseVersion);

    if (majorCheck > majorBase) {
        return true;
    } else if (majorCheck === majorBase) {
        if (minorCheck > minorBase) {
            return true;
        } else if (minorCheck === minorBase) {
            return patchCheck >= patchBase;
        }
    }

    return false;
};

export {
    isEmpty,
    isStringOfMaxLength,
    isEmptyOrBlank,
    isFile4kResolution,
    isIdInDataUpdate,
    isArray,
    isNotEmpty,
    isEmail,
    isValidName,
    isDataStale,
    isIpAddress,
    isIpPort,
    isFrequency,
    arraysEqual,
    isVersionSameOrHigher
};