import { defineStore } from 'pinia';
import { computed } from 'vue';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { useInteractiveMenuPageFoldersStore } from '@/stores/content/interactiveMenuPageFolders.js';

const thisModule = 'interactiveMenuPages';

export const useInteractiveMenuPagesStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule);
    plugin({ store });

    store.withPath = computed(() => {
        const data = store.cloneItems();
        const folders = useInteractiveMenuPageFoldersStore().idMapByGetter('translated');

        data.forEach(item => {
            let fullPath = item.name;
            let count = 0;

            if (typeof item.folder === 'string' && item.folder.length > 0) {
                let currFolder = folders.hasOwnProperty(item.folder) ? folders[item.folder] : null;
                while (currFolder && count < 5) {
                    fullPath = currFolder.nameTranslated + " / " + fullPath;
                    if (typeof currFolder.parent === 'string') {
                        currFolder = folders.hasOwnProperty(currFolder.parent) ? folders[currFolder.parent] : null;
                    } else {
                        currFolder = null;
                    }
                    count++;
                }
            }
            item.nameWithPath = fullPath;
        });
        return data;
    });

    store.byCategory = (category) => {
        if (!category) {
            return store.withPath.value;
        }
        const data = store.withPath.value;

        Array.sort(data, 'nameWithPath');

        return data.filter(item => {
            return (Array.isArray(item.categories) && item.categories.includes(category));
        });
    };

    return store;
});
