import { ref } from 'vue';
import apis from '@/lib/api/index.js';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";

const resCheck = responseSuccessCheck;

// State
const dataSetChanged = ref({ dataSet: "", created: null, updated: null, deleted: null });
const resourceUpdated = ref({ resource: "", created: null, updated: null, deleted: null });
const registered = ref(false);
const connected = ref(false);
const subscribedContext = ref(null);

export function usePushChannel() {

    // Mutations (setters)
    function setDatasetChanged(data) {
        dataSetChanged.value = data;
    }

    function setResourceUpdated(resource) {
        resourceUpdated.value = resource;
    }

    function setRegistered(value) {
        registered.value = value;
    }

    function setConnected(value) {
        connected.value = value;
    }

    function setSubscribedContext(context) {
        subscribedContext.value = context;
    }

    // Actions
    function reset() {
        setDatasetChanged({ dataSet: "", created: null, updated: null, deleted: null });
        setResourceUpdated({ resource: "", created: null, updated: null, deleted: null });
        setRegistered(false);
        setConnected(false);
        setSubscribedContext(null);
    }

    function handleDataSetChanged({ dataSet, created, updated, deleted }) {
        setDatasetChanged({ dataSet, created, updated, deleted });
    }

    function handleResourceUpdated({ feature, resource, created, updated, deleted }) {
        console.log("Resource updated message received");
        setResourceUpdated({ feature, resource, created, updated, deleted });
    }

    function handleRegister({ registered }) {
        setRegistered(registered);
    }

    function handleConnectionChange({ connected }) {
        setConnected(connected);
    }

    async function subscribeContextEvents(contextId) {
        const response = await apis['session'].put({ contextId }, 'subscribe');
        if (resCheck(response)) {
            setSubscribedContext(contextId);
        } else {
            setSubscribedContext(null);
        }
    }

    async function pushMessage({ screens, messageType, messageData }) {
        const clientIds = screens.filter(elem => elem?.online === true).map(s => s.id);
        if (clientIds.length === 0) {
            console.error("No online screens in push request!");
            return;
        }

        const pushRequest = {
            clients: clientIds,
            messageType,
            messageData
        };

        const response = await apis['pushMessage'].post(pushRequest);
        // Handle response if needed
    }

    return {
        dataSetChanged,
        resourceUpdated,
        registered,
        connected,
        subscribedContext,
        reset,
        handleDataSetChanged,
        handleResourceUpdated,
        handleRegister,
        handleConnectionChange,
        subscribeContextEvents,
        pushMessage
    };
}