import { isEmpty } from '@/lib/helpers/Validator';

export default {
    data() {
        return {
            editor: {
                show: false,
                item: {},
                items: [],
                data: {}
            }
        }
    },
    methods: {
        openEditor(item, items, data) {
            this.editor = {
                show: true,
                item: isEmpty(item) ? {} : item,
                items: Array.isArray(items) ? items : [],
                data: data
            };
        }
    }
}
