<template>
    <div v-show="showMessagesButton">
        <v-menu v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" data-testid="mmMaintenanceMessages">
                    <v-icon>{{ notificationIcon }}</v-icon>
                    {{ totalMessageCount }}
                </v-btn>

                <div>
                    <v-snackbar
                        v-model="showSnackbar"
                        :multi-line="true"
                        :timeout="-1"
                        app
                        elevation="24"
                        :vertical="false"
                        centered
                        :top="true"
                        color="info"
                    >
                        <div style="display: flex; justify-content: space-between; align-items: end;">
                            <span></span>
                            <v-hover v-slot="{ hover }">
                                <v-icon
                                    :color="hover ? 'grey lighten-1' : 'primary'"
                                    @click="showSnackbar=false"
                                    style="cursor: pointer;transition: color 0.3s ease;">
                                    mdi-close
                                </v-icon>
                            </v-hover>
                        </div>
                        <v-container>
                            <v-row no-gutters style="flex-wrap: nowrap">
                                <v-col style="text-align: center; font-size: large">
                                    <strong>{{ bannerHeaderText }}</strong>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col style="text-align: center">
                                    <div>{{ bannerText }}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-snackbar>
                </div>
            </template>

            <v-card>
                <v-card-text>
                    <v-expansion-panels popup>
                        <v-expansion-panel v-for="(msg) in this.messages" :key="msg.fromUtc">
                            <v-expansion-panel-header>
                                <v-row no-gutters>
                                    <v-col cols="1">
                                        <v-icon :color="getMessageColor(msg.severity)" left>
                                            {{ getMessageIcon(msg.severity) }}
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="9">
                                        {{ msg.name }}
                                    </v-col>
                                    <v-col cols="2">
                                        <v-spacer></v-spacer>
                                        {{ msg.severity }}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row no-gutters>
                                    {{ msg.message }}
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card-text>
            </v-card>
            <div v-if="this.debugMode === true">
                <v-card>
                    <v-card-title>
                        Just for debugging purposes.
                    </v-card-title>
                    <v-card-text>
                        <v-btn class="primary" text @click="resetMsgState()">
                            Reset Localstorage
                        </v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </v-menu>
    </div>

</template>

<script>
import { useKeycloak } from '@/composables/useKeycloak';
import { useUserAccountStore } from '@/stores/account/userAccount.js';
import { responseSuccessCheck } from '@/lib/helpers/ApiHelper';
import apis from '@/lib/api/index.js';

export default {
    name: 'MainMenuManagementMessages',
    data() {
        return {
            totalMessageCount: 0,
            seenMessageCount: 0,
            fetchInterval: 60 * 1000,
            showSnackbar: false,
            notificationIcon: 'notifications',
            bannerSeverity: 'info',
            bannerColor: 'yellow',
            bannerIcon: 'info',
            bannerText: '',
            bannerHeaderText: '',
            messages: [],
            debugMode: false,
            menu: false,
            showMessagesButton: true,
            modules: [useUserAccountStore()]
        };
    },
    mounted() {
        setTimeout(this.fetchUpcomingMessages, 1000);
    },
    computed: {
        userAccountStore() {
            return useUserAccountStore();
        }
    },
    methods: {
        resetMsgState() {
            this.saveToLocalStorage([]);
            this.menu = false;
        },
        saveToLocalStorage(msgList) {
            let sn = 'message_state_' + this.userId();
            localStorage.setItem(sn, JSON.stringify(msgList));
        },
        loadFromLocalStorage() {
            let sn = 'message_state_' + this.userId();
            const lm = JSON.parse(localStorage.getItem(sn));
            if (lm != null) {
                return lm;
            }
            return [];
        },
        userId() {
            if (this.userAccountStore.account) {
                return this.userAccountStore.account.id;
            }
            return 'ERROR';
        },
        domainName() {
            return useKeycloak().tokenParsed.domain.toString() || '';
        },
        updateMessageCountState() {
            this.totalMessageCount = this.messages.length;
            const localstorageMessages = this.loadFromLocalStorage();
            this.seenMessageCount = localstorageMessages.filter(item => item.seen === true).length;

            this.showMessagesButton = true;
            this.notificationIcon = 'notifications';

            if (this.messages.length === 0) {
                this.showMessagesButton = false;
            }
            if (this.seenMessageCount < this.messages.length) {
                this.notificationIcon = 'notifications_active';
            }
        },
        checkForMessagesToDisplay() {
            const localStorageMessages = this.loadFromLocalStorage();
            localStorageMessages.every(msg => {
                if (msg.seen === false) {
                    this.showMessage(msg.id);
                    return false;
                }
                return true;
            });
        },
        getMessageColor(status) {
            switch (status.toLowerCase()) {
                case 'info':
                    return 'info';
                case 'warning':
                    return 'warning';
                case 'error':
                    return 'error';
                case 'critical':
                    return 'error';
                default:
                    return 'info';
            }
        },
        getMessageIcon(status) {
            switch (status.toLowerCase()) {
                case 'info':
                    return 'info';
                case 'warning':
                    return 'warning';
                case 'error':
                    return 'error';
                case 'critical':
                    return 'error_outline';
                default:
                    return 'question';
            }
        },
        async fetchUpcomingMessages() {
            if (this.menu === false) {
                this.messages = [];
                const response = await apis.management.get(`/getUpcoming`);
                if (responseSuccessCheck(response)) {
                    if (response.data.length > 0) {
                        let localStorageMessages = this.loadFromLocalStorage();
                        response.data.forEach(message => {
                            let foundMsg = localStorageMessages.find(m => m.id === message.id);
                            if (foundMsg === undefined) {
                                if (this.showForThisDomain(message.domains)) {
                                    localStorageMessages.push({ id: message.id, seen: false });
                                }
                            }
                        });

                        const idxToRemove = [];
                        let i = 0;
                        localStorageMessages.forEach(localMsg => {
                            let msg = response.data.find(m => m.id === localMsg.id);
                            if (msg === undefined || this.showForThisDomain(msg.domains) === false) {
                                idxToRemove.push(i);
                            }
                            i++;
                        });
                        idxToRemove.forEach(idx => {
                            localStorageMessages.splice(idx, 1);
                        });

                        this.saveToLocalStorage(localStorageMessages);

                        response.data.forEach(message => {
                            if (this.showForThisDomain(message.domains)) {
                                this.messages.push(message);
                            }
                        });
                    }
                }
            }

            this.updateMessageCountState();

            if (this.messages.length === 0) {
                this.saveToLocalStorage([]);
            }

            setTimeout(() => this.fetchUpcomingMessages(), this.fetchInterval);

            if (this.showSnackbar === false) {
                setTimeout(this.checkForMessagesToDisplay, 1000);
            }
        },
        showMessage(id) {
            if (id !== null && this.showSnackbar === false) {
                const idx = this.messages.findIndex(m => m.id === id);
                if (idx !== -1) {
                    this.bannerHeaderText = this.messages[idx].name;
                    this.bannerText = this.messages[idx].message;
                    this.bannerColor = this.getMessageColor(this.messages[idx].severity);
                    this.bannerSeverity = this.messages[idx].severity;
                    this.bannerIcon = this.getMessageIcon(this.messages[idx].severity);
                    const localStorageMessages = this.loadFromLocalStorage();
                    const msg = localStorageMessages.find(m => m.id === id);
                    if (msg !== undefined) {
                        msg.seen = true;
                    }
                    this.saveToLocalStorage(localStorageMessages);
                    this.showSnackbar = true;
                }
            }
        },
        showForThisDomain(domains) {
            if (this.domainName().toLowerCase() === 'system') {
                return true;
            }
            const validDomains = domains.filter(domain => {
                return domain.toLowerCase().includes(this.domainName().toLowerCase());
            });
            return validDomains.length > 0;
        }
    },
    watch: {
        showSnackbar: {
            handler(showing) {
                if (showing === false) {
                    setTimeout(this.checkForMessagesToDisplay, 1000);
                }
            }
        }
    }
};
</script>

<style scoped>
.v-menu__content {
    width: 40%;
}
</style>
