<template>
    <v-menu
            :close-on-content-click="true"
            offset-y
    >
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on" data-testid="mmhHelp">
                <v-icon>help</v-icon>
            </v-btn>
        </template>

        <v-list>
            <template>
                <div v-for="item in helpOptions" :key="item.id">
                    <v-divider v-if="item.divider === true"></v-divider>
                    <v-list-item
                        @click="selectOption(item)"
                        :data-testid="item.seleniumId"
                    >
                        <v-list-item-title>{{translate(item.title)}}</v-list-item-title>
                    </v-list-item>
                </div>
            </template>
        </v-list>
    </v-menu>
</template>

<script>
    import { useGlobalModals } from '@/composables/useGlobalModals';
    import UrlManager from '@/lib//helpers/UrlManager.js';
    import { useAppStateStore } from '@/stores/appState';

    export default {
        name: "MainMenuHelp",
        data: () => ({
            helpOptions: [{
                title: 'Site Information',
                cb: () => {
                    useGlobalModals().getHelpGetSiteInfo().show();
                },
                id: 5,
                seleniumId: "mmhSiteInfo"
            }, {
                title: 'Documentation',
                link: UrlManager.getSupportPortalUrl(),
                id: 1,
                seleniumId: "mmhDocumentation"
            }, /*{
                title: 'Tutorials',
                id: 2
            }, */{
                title: 'Service Status',
                link: 'https://status.thecloudportal.com/?system=mirage',
                id: 4,
                divider: false
            }, {
                title: 'Contact Support',
                cb: () => {
                    useGlobalModals().getHelpGetSupport().show();
                },
                id: 3,
                seleniumId: "mmhContactSupport",
                divider: true
            }
            ]
        }),
        computed: {
            languages() {
                return useAppStateStore().portalLanguages;
            }
        },
        methods: {
            translate(value){
                switch (value){
                    case 'Documentation':
                        return this.$t('help.documentation')
                    case 'Contact Support':
                        return this.$t('help.contactSupport')
                    default:
                        return value
                }
            },
            selectOption(item) {

                if (item.link) {
                    window.open(item.link, '_blank');
                } else if (item.cb) {
                    item.cb(this);
                }
            }
        }
    }
</script>