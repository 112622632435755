import { defineStore } from 'pinia';
import { computed } from 'vue';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';

const thisModule = 'playlists';

export const usePlaylistsStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule);
    plugin({ store });

    store.withDurationById = computed(() => {
        const data = store.cloneItems();

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.duration = 0;
                if(Array.isArray(item.elements)) {
                    item.elements.forEach(element => {
                        item.duration += parseInt(element.duration);
                    });
                }
            });
            Array.sort(data, 'name');
            return Map.createFromArray(data, 'id');
        }
        return {};
    });

    return store;
});
