import { defineStore } from 'pinia';
import { createBaseContextStorePlugin } from '@/plugins/store/baseContextStorePlugin';

const thisModule = 'distributors';

// Initialize the BaseCrudPlugin for this store

export const useDistributorsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseContextStorePlugin(thisModule);
    plugin({ store });
    return store;
});
