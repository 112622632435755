import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { isNotEmpty } from '@/lib/helpers/Validator';
import { computed } from 'vue';
import { useCommonStore } from '@/stores/common.js';

function getBestNetworkValues(obj) {
    if (obj === null || obj === undefined) {
        return null;
    }
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key].ip !== null && obj[key].ip !== "") {
            return obj[key];
        }
    }
    return null;
}

const thisModule = 'duplicateRegisteredClients';

// Initialize the BaseCrudPlugin for this store

export const useDuplicateRegisteredClientsStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withStatus = computed(() => {
        const contextIdMap = Map.createFromArray(useCommonStore().allContextsWithLegacy, 'id');

        const data = store.cloneItems();
        if(Array.isArray(data)) {

            data.forEach(item => {
                item.firstTry = item.firstDuplicateRegister;
                item.lastTry = item.lastDuplicateRegister;

                const site = contextIdMap[item.contextId];

                if (site) {
                    item.siteName = site.name;
                }
                if(isNotEmpty(item.status)) {
                    const deviceType = item.status.deviceType;
                    const deviceModel = item.status.deviceModel;
                    const network = getBestNetworkValues(item.status.network);

                    item.poweredOn = item.online === true && item.status.poweredOn;
                    item.network = JSON.stringify(item.status.network);
                    item.ip = network !== null && network !== undefined ? network.ip : 'Unknown';
                    item.mac = network !== null && network !== undefined ? network.mac : 'Unknown';
                    item.deviceType = deviceType + '/' + deviceModel;
                    item.firmwareShort = (typeof item.status.firmwareVersion === 'string') ?
                        item.status.firmwareVersion.substr(0, 20) + (item.status.firmwareVersion.length > 20 ? '...' : '') : 'N/A';
                } else {
                    item.poweredOn = false;
                    item.network = null;
                    item.ip = null;
                    item.mac = null;
                    item.deviceType = null;
                    item.resolution = null;
                    item.firmwareShort = '-';
                    item.lastReboot = null;
                    item.lastRebootTxt = null;
                }
            });
            Array.sort(data, 'name', true);
            return data;
        }
        return [];
    });


    return store;
});
