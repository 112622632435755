import lodash from "lodash";

Map.retrieve = (map, key, nullValue = null) => {
    const item = map[key];
    return item === undefined ? nullValue : item;
};

Map.retrieveValue = (map, key, valueField, nullValue = null) => {
    const item = Map.retrieve(map, key);
    if (item === null) return nullValue;
    return item[valueField] === undefined ? nullValue : item[valueField];
};

Map.createFromArray = (array, field) => {
    if(!array) {
        return {};
    }
    return lodash.keyBy(array, o => o[field]);
};