import colors from 'vuetify/lib/util/colors';
import PortalTheme from '@/lib/theme/PortalTheme.js';

export function getLightTheme() {
    switch (PortalTheme.getTheme()) {
        case PortalTheme.availableThemes.THEME_PPDS: {
            return ppdsTheme;
        }
        case PortalTheme.availableThemes.THEME_UNIGUEST:
        case PortalTheme.availableThemes.THEME_DEFAULT: {
            return uniguestLightTheme;
        }
        default: {
            return defaultLightTheme;
        }
    }
}

export function getDarkTheme() {
    switch (PortalTheme.getTheme()) {
        case PortalTheme.availableThemes.THEME_PPDS: {
            return ppdsTheme;
        }
        case PortalTheme.availableThemes.THEME_UNIGUEST:
        case PortalTheme.availableThemes.THEME_DEFAULT: {
            return uniguestDarkTheme;
        }
        default: {
            return defaultDarkTheme;
        }
    }
}

const defaultLightTheme = {
    primary: '#4a1d61',
    accent: '#6cacd1',
    secondary: '#bc59cb',
    tertiary: '#806092',
    hover: '#eee9f6',
    info: '#6cacd1',
    warning: colors.amber.base,
    error: '#b71c1c',
    success: '#6f9a3c',
    toolbar: '#ffffff',
    background: '#ffffff',
    backgroundAlt: '#fafafa',
    appbarBackground: '#f5f5f5',
    thumbnailBackground: '#eee',
    text: '#000000',
    selected: '#f2eef9',
    scrollbarThumb: '#b2b4b5',
    scrollbarThumbHover: '#939596'
}

const defaultDarkTheme = {
    primary: '#a080d7',
    accent: '#6cacd1',
    secondary: '#bc59cb',
    tertiary: '#806092',
    hover: '#4a4a4a',
    info: '#6cacd1',
    warning: colors.amber.base,
    error: '#cd1f1f',
    success: '#6f9a3c',
    toolbar: '#424242',
    background: '#424242',
    backgroundAlt: '#4a4a4a',
    selected: '#222222',
    appbarBackground: '#272727',
    text: '#ffffff',
    scrollbarThumb: '#818384',
    scrollbarThumbHover: '#9b9d9e'
}

const ppdsTheme = {
    primary: '#0f5291',
    accent: '#6cacd1',
    secondary: '#F1F8FE',
    tertiary: '#0f5291',
    info: '#6cacd1',
    hover: '#F1F8FE',
    warning: colors.amber.base,
    error: '#b71c1c',
    success: '#6f9a3c',
    toolbar: '#ffffff',
    background: '#ffffff',
    backgroundAlt: '#fafafa',
    appbarBackground: '#f5f5f5',
    text: '#000000',
    thumbnailBackground: '#eee',
    selected: '#F1F8FE',
    scrollbarThumb: '#b2b4b5',
    scrollbarThumbHover: '#939596'
}

const uniguestLightTheme = {
    primary: '#1FA0DC',
    accent: '#39557C',
    secondary: '#1FA0DC',
    tertiary: '#1FA0DC',
    info: '#2261CF',
    hover: '#F1F8FE',
    warning: '#EB9C1A',
    error: '#E0090D',
    success: '#48AC68',
    toolbar: '#ffffff',
    background: '#ffffff',
    backgroundAlt: '#fafafa',
    appbarBackground: '#f5f5f5',
    text: '#222222',
    thumbnailBackground: '#eee',
    selected: '#F1F8FE',
    scrollbarThumb: '#b2b4b5',
    scrollbarThumbHover: '#939596'
}

const uniguestDarkTheme = {
    primary: '#39557C',
    accent: '#39557C',
    secondary: '#39557C',
    tertiary: '#39557C',
    hover: '#4a4a4a',
    info: '#6cacd1',
    warning: '#EB9C1A',
    error: '#E0090D',
    success: '#48AC68',
    toolbar: '#424242',
    background: '#424242',
    backgroundAlt: '#4a4a4a',
    selected: '#222222',
    appbarBackground: '#272727',
    text: '#ffffff',
    scrollbarThumb: '#818384',
    scrollbarThumbHover: '#9b9d9e'
}