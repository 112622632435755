import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';

export default class Translator {
    static translateMap(map, lang, returnValue = '') {
        if (isEmpty(map)) {
            return returnValue;
        }

        if (isEmpty(map[lang])) {
            if (lang !== 'en' && isNotEmpty(map['en'])) {
                return map['en'];
            }

            for (let key in map) {
                // noinspection JSUnfilteredForInLoop
                if (isNotEmpty(map[key])) {
                    // noinspection JSUnfilteredForInLoop
                    return map[key];
                }
            }

            return returnValue;
        }

        return map[lang];
    }
}