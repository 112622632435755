<!--suppress JSUnfilteredForInLoop -->
<template>
    <v-app
        :class="appClasses"
    >
        <TheScroller></TheScroller>
        <TheLanguageSelector></TheLanguageSelector>
        <TheHelpMessage></TheHelpMessage>
        <TheNotification></TheNotification>
        <TheWelcome></TheWelcome>
        <TheAcceptUpdatedEula></TheAcceptUpdatedEula>
        <TheResourceUploader></TheResourceUploader>
        <TheResourceViewer></TheResourceViewer>
        <TheMapLocationSelector></TheMapLocationSelector>
        <TheFolderStructureSelector></TheFolderStructureSelector>
        <TheClipBoardCopier></TheClipBoardCopier>
        <HelpGetSupport></HelpGetSupport>
        <HelpGetSiteInfo></HelpGetSiteInfo>
        <ThePrompt></ThePrompt>
        <TheConfirm></TheConfirm>
        <TheJobHandler></TheJobHandler>

        <template v-if="(contextMode === 'loading' || isAuthenticated)">
            <TheAppBar></TheAppBar>
            <div v-if="isAuthenticatedMirage">
                <TheMainDrawer v-model="drawer"></TheMainDrawer>
                <TheMainView v-if="contextMode !== 'loading'" ></TheMainView>
                <v-container style="height: calc(100vh - 100px);" v-else>
                    <v-row>
                        <v-col style="height: 100%; text-align: center; padding-top: 35%;">
                            <v-progress-circular
                                indeterminate
                                size="50"
                                width="5"
                            ></v-progress-circular><br>
                            Loading...
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div v-else-if="contextMode === 'error'">
                <TheInvalidMirageSession
                    :text="keycloak.tokenParsed.domain === 'ppds' ? $t('auth.errorMirageSessionPPDS') : $t('auth.errorMirageSession')"
                    :contextMode="contextMode"
                ></TheInvalidMirageSession>
            </div>
            <div v-else-if="contextMode !== 'loading'">
                <TheInvalidMirageSession
                        :text="keycloak.tokenParsed.domain === 'ppds' ? $t('auth.invalidMirageSessionPPDS') : $t('auth.invalidMirageSession')"
                    :contextMode="contextMode"
                ></TheInvalidMirageSession>
            </div>
        </template>
        <template v-else>
            <TheInvalidMirageSession
                :text="$t('auth.unknownMirageSession')"
                :contextMode="contextMode"
            ></TheInvalidMirageSession>
        </template>
    </v-app>
</template>

<script>
import Vue from 'vue';
import TheMainDrawer from '@/components/core/TheMainDrawer.vue';
import TheMainView from '@/components/core/TheMainView.vue';
import TheNotification from '@/components/core/TheNotification.vue';
import TheScroller from '@/components/core/TheScroller.vue';
import ThePrompt from '@/components/core/ThePrompt.vue';
import TheConfirm from '@/components/core/TheConfirm.vue';
import TheResourceUploader from "@/components/content/resource/TheResourceUploader.vue";
import TheResourceViewer from "@/components/content/resource/TheResourceViewer.vue";
import routeHandlerMixin from "@/mixins/app/theRouteHandlerMixin.js";
import thePushChannelMixin from "@/mixins/app/thePushChannelMixin.js";
import theResourceUpdateHandlerMixin from "@/mixins/app/theResourceUpdateHandlerMixin.js";
import TheLanguageSelector from "./components/core/TheLanguageSelector.vue";
import TheHelpMessage from "./components/core/TheHelpMessage.vue";
import TheFolderStructureSelector from "./components/folderstructure/TheFolderStructureSelector.vue";
import TheMapLocationSelector from "./components/core/TheMapLocationSelector.vue";
import TheAppBar from "./components/core/TheAppBar.vue";
import TheClipBoardCopier from "./components/core/TheClipBoardCopier.vue";
import TheWelcome from "./components/core/TheWelcome.vue";
import TheAcceptUpdatedEula from "./components/core/TheAcceptUpdatedEula.vue";
import HelpGetSupport from "./components/help/HelpGetSupport.vue";
import HelpGetSiteInfo from "./components/help/HelpGetSiteInfo.vue";
import TheInvalidMirageSession from "./components/core/TheInvalidMirageSession.vue";
import TheJobHandler from "@/components/core/TheJobHandler.vue";
import { useGlobalModals } from '@/composables/useGlobalModals';
import { useKeycloak } from '@/composables/useKeycloak';
import { useUserAccountStore } from '@/stores/account/userAccount.js';
import { useAuthStore } from '@/stores/auth.js';
import { useContextStore } from '@/stores/context.js';
import { usePushChannel } from '@/composables/usePushChannel';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import { useThemesStore } from '@/stores/contexts/themes.js';
import { useSharingContextsStore } from '@/stores/contexts/sharingContexts.js';
import { useContentProviderContextsStore } from '@/stores/contexts/contentProviderContexts.js';
import { useDashboardStore } from '@/stores/dashboard.js';
import { isNotEmpty } from '@/lib/helpers/Validator';
import { useAppStateStore } from '@/stores/appState';
import PortalTheme from '@/lib/theme/PortalTheme';
import apis from '@/lib/api/index.js';

export default {
        name: 'App',
        mixins: [
            routeHandlerMixin,
            theResourceUpdateHandlerMixin,
            thePushChannelMixin
        ],
        components: {
            TheJobHandler,
            HelpGetSupport,
            HelpGetSiteInfo,
            TheWelcome,
            TheClipBoardCopier,
            TheAppBar,
            TheMapLocationSelector,
            TheFolderStructureSelector,
            TheHelpMessage,
            TheLanguageSelector,
            TheResourceViewer,
            TheResourceUploader,
            TheMainDrawer,
            TheMainView,
            ThePrompt,
            TheConfirm,
            TheNotification,
            TheScroller,
            TheAcceptUpdatedEula,
            TheInvalidMirageSession
        },
        metaInfo() {
            return {
                title: this.navPath
            }
        },
        data() {
            return {
                floatingHints: [],
                displayingFloatingHint: false,
                didAutoSelection: false,
                screenPreview: null,
                acctInit: false,
                errorCnt: 0,
                firstContextSelection: true,
                drawer: useAppStateStore().drawer
            }
        },
        computed: {
            keycloak() {
                return useKeycloak();
            },
            globalDrawer() {
                return useAppStateStore().drawer;
            },
            appClasses() {
                return useAppStateStore().appClasses;
            },
            globalModals() {
                return useGlobalModals();
            },
            dashboardStore() {
                return useDashboardStore();
            },
            contentProvidersStore() {
                return useContentProviderContextsStore();
            },
            contentProviderContexts() {
                return this.contentProvidersStore.items;
            },
            contentProvidersLoaded() {
                return this.contentProvidersStore.isLoaded;
            },
            themesStore() {
                return useThemesStore();
            },
            themes() {
                return this.themesStore.items;
            },
            themesLoaded() {
                return this.themesStore.isLoaded;
            },
            sharingContextsStore() {
                return useSharingContextsStore();
            },
            sharingContexts() {
                return this.sharingContextsStore.items;
            },
            sharesLoaded() {
                return this.sharingContextsStore.isLoaded;
            },
            deploymentsStore() {
                return useDeploymentsStore();
            },
            deployments() {
                return this.deploymentsStore.items;
            },
            deploymentsLoaded() {
                return this.deploymentsStore.isLoaded;
            },
            pushChannel() {
                return usePushChannel();
            },
            contextStore() {
                return useContextStore();
            },
            selectedContext() {
                return this.contextStore.selectedContext;
            },
            provisioning() {
                return this.contextStore.provisioning;
            },
            contextMode() {
                return this.contextStore.mode;
            },
            authStore() {
                return useAuthStore();
            },
            userAccount() {
                return useUserAccountStore();
            },
            isAuthenticated() {
                return this.keycloak.authenticated;
            },
            isAuthenticatedMirage() {
                return this.authStore.isAuthenticated && this.contextMode !== 'error';
            },
            navPath() {
                let heading = "Portal ";

                if (this.contextMode === 'site' || this.contextMode === 'theme') {
                    heading = this.selectedContext ? this.selectedContext.name : '';
                }
                try {
                    if (this.$route.meta.group && this.$route.meta.group.nameKey && this.$t('router.' + this.$route.meta.group.nameKey)) {
                        heading += '- ' + this.$t('router.' + this.$route.meta.group.nameKey);
                    }
                } catch(e) {
                    console.log(e);
                }
                if(this.$route.name && this.$t('router.' + this.$route.name)) {
                    try {
                        heading += '- ' + this.$t('router.' + this.$route.name);
                    } catch(e) {
                        console.log(e);
                    }
                }
                return heading;
            }
        },
        watch: {
            globalDrawer(val) {
                if(val !== this.drawer) {
                    this.drawer = val;
                }
            },
            drawer(val) {
                useAppStateStore().setDrawer(val);
            },
            'userAccount.initialized'(val) {
                this.acctInit = val;
            },
            'pushChannel.registered.value'(val) {
                if(val === true) {
                    if(isNotEmpty(this.selectedContext) && isNotEmpty(this.selectedContext.id)) {
                        console.log("Autoload on PUSH connect");
                        this.autoLoadStores();
                    }
                }
            },
            async 'deploymentsStore.items'(data) {
                if(this.themesLoaded && this.sharesLoaded && this.contentProvidersLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async 'themesStore.items'(data) {
                if(this.deploymentsLoaded && this.sharesLoaded && this.contentProvidersLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async 'sharingContextsStore.items'(data) {
                if(this.deploymentsLoaded && this.themesLoaded && this.contentProvidersLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async 'contentProvidersStore.items'(data) {
                if(this.deploymentsLoaded && this.themesLoaded && this.sharesLoaded) {
                    await this.onContextsLoaded();
                }
            },
            async 'contextMode'(mode, oldMode) {
                if(oldMode !== 'loading' && mode !== oldMode) {
                    const query = this.$router.history.current.query;
                    if (oldMode === 'login' && this.$router.history.current.path === "/screens/tv-screens" && query.clientToken !== undefined && query.id !== undefined) {
                        return;
                    }
                    await this.$router.replace('/').catch(e => {});
                    if(oldMode === 'home') {
                        this.setSearchFilter("");
                        this.setContextFilters([]);
                        this.dashboardStore.setSummaryContext(null);
                    }
                }
            },
            async 'contextStore.selectedContext'(context) {
                if(this.firstContextSelection) {
                    this.firstContextSelection = false;
                    console.log("Ignoring initial context selection");
                    return;
                }
                console.log("Context changed: ", context);
                await this.pushChannel.subscribeContextEvents(context.hasOwnProperty('id') ? context.id : null);
                if (this.$routeHandler.ready && !this.$routeHandler.routeAuthCheck(this.$router.history.current)) {
                    await this.$router.replace('/');
                }
                this.resetStores();
                this.autoLoadStores();
                this.globalModals.getHelpMessage().show('mobileDrawerButton', this.$t('help.mobileNavigationTip'), 'mobile', {
                    id: 'mobileDrawerBtn', icon: 'arrow_downward', pos: { top: '-45px' }
                }, false);

                this.showEula();
            },
            floatingHints(newHints, oldHints) {
                if (!this.displayingFloatingHint && newHints.length >= oldHints.length && newHints.length > 0) {
                    const hint = newHints.pop();
                    hint.show();
                    this.displayingFloatingHint = true;
                }
            },
            displayingFloatingHint(displaying) {
                if (!displaying && this.floatingHints.length > 0) {
                    const hint = this.floatingHints.pop();
                    hint.show();
                    this.displayingFloatingHint = true;
                }
            }
        },
        async created() {
            Vue.prototype.$app = this;

            const favicon = document.getElementById("favicon");
            switch (PortalTheme.getTheme()) {
                case PortalTheme.availableThemes.THEME_PPDS:
                    favicon.href = "/img/icons/mirage_portal_icon_wave.png";
                    document.title = "Wave Creator";
                    break;
                case PortalTheme.availableThemes.THEME_UNIGUEST:
                case PortalTheme.availableThemes.THEME_DEFAULT:
                default: {
                    favicon.href = import.meta.env.MODE === "production" ?
                        "/img/icons/mirage_portal_icon_uniguest.png" :
                        "/img/icons/mirage_portal_icon_uniguest_test.png";
                    const domain = this.keycloak.tokenParsed.domain.toString() || "otrum";
                    const environmentPrefix = import.meta.env.MODE === "production" ? "" : " TEST";
                    if (domain === "system") {
                        document.title = "SYSTEM" + environmentPrefix + " - Hub";
                    } else {
                        document.title = "Hub" + environmentPrefix;
                    }
                }
            }
            const response = await this.authStore.validateLogin();
            if (response !== true) {
                this.contextStore.setLogin();
            }

            const errorHandler = (e) => {
                let errMsg;
                if(e.hasOwnProperty('lineno')) {
                    errMsg = e["filename"] + " line " + e["lineno"] + " : " + e["message"];
                } else {
                    errMsg = e["message"] + '\n' + e['stack'];
                }
                console.error("Error occured(" + this.errorCnt + "): " + errMsg);
                console.error(e);
                if(this.errorCnt < 30) {
                    try {
                        apis.logEntries.post({level: "error", message: errMsg});
                    } catch (e) {
                        console.error("Could not log message to server: " + e);
                    }
                } else {
                    console.log("Not logging error");
                }
                this.errorCnt++;
                return false;
            };
            window.addEventListener("error", errorHandler);
            Vue.config.errorHandler = errorHandler;
        },
        methods: {
            setContextFilters(...args) {
                return this.dashboardStore.setContextFilters(...args);
            },
            setSearchFilter(...args) {
                return this.dashboardStore.setSearchFilter(...args);
            },
            showEula() {
                if (this.acctInit === true && PortalTheme.getTheme() !== PortalTheme.availableThemes.THEME_PPDS) {
                    let acctSettings = useUserAccountStore().account.settings;
                    let eulaVersion = parseInt(import.meta.env.VITE_EULA_VERSION, 10);

                    if (acctSettings.welcomeDisplayed === false ||
                            location.search.indexOf('welcome') !== -1) {
                        this.globalModals.getWelcome().show();

                    } else if (eulaVersion && acctSettings.acceptedEulaVersion !== eulaVersion) {
                        this.globalModals.getAcceptUpdatedEula().show();
                    }
                }
            },
            accessCheck(feature, resource, access = 'read') {
                return this.contextStore.privilegeCheckCurrentContext(feature, resource, access);
            },
            async onContextsLoaded() {
                const query = this.$router.history.current.query;
                if (query.auth !== undefined && query.auth.length > 0) {
                    this.didAutoSelection = true;
                    await this.contextStore.autoSelectFirstContext();
                } else if (!this.didAutoSelection) {
                    this.didAutoSelection = true;
                    await this.contextStore.autoSelectContext();
                }
                if ((this.deployments.length + this.themes.length + this.sharingContexts.length + this.contentProviderContexts.length) > 1) {
                    await this.contextStore.enableContextSwitching();
                }
            }
        }
    }
</script>

<style lang="scss">

    @font-face {
        font-family: "ProximaVara";
        src: url("/fonts/ProximaVara.woff2");
    }

    .v-application {
        background-color: var(--v-background-base) !important;
    }

    .uniguest-theme {
        font-family: ProximaVara;

        .v-btn:hover:not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.error--text):not(.v-btn--rounded) {
            background-color: var(--v-accent-base) !important;
            transition: background-color .28s;
        }

        .v-btn:hover:is(.error--text):not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.v-btn--rounded) {
            background-color: var(--v-error-darken4) !important;
            transition: background-color .28s;
        }

        .v-btn:hover:is(.error):not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.v-btn--rounded) {
            background-color: var(--v-error-darken4) !important;
            transition: background-color .28s;
        }

        .v-btn:not(.v-btn--icon):not(.dashboard-toggle-btn):not(.v-btn--disabled):not(.base-dialog-btn-cancel):not(.v-btn--rounded) {
            color: white !important;
            background-color: var(--v-primary-base) !important;
        }

        .base-dialog-btn-cancel {
            box-shadow: none !important;
            border: 1px solid #BFBFBF !important;
        }
    }

    .application {
        background-color: var(--v-background-base) !important;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-text-fill-color: inherit !important;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 30px var(--v-background-base) inset !important;
        }
    }

    .theme--dark {
        .v-data-table,
        .v-card,
        .v-list {
            background-color: var(--v-background-base) !important;
        }
        .v-expansion-panel {
            background-color: #4a4a4a !important;
        }
        .apexcharts-menu {
            background: #333 !important;
        }
        .apexcharts-tooltip {
            background: #333 !important;
        }
        .apexcharts-theme-light .apexcharts-menu-item:hover {
            background: #111 !important;
        }
        .apexcharts-tooltip-title {
            background: #333 !important;
        }
    }

    body {
        background: var(--v-background-base) !important;
    }
</style>
