import { isNotEmpty, isEmpty } from '@/lib/helpers/Validator';

export function useTranslator() {

    function translateMap(map, lang, returnValue = '') {
        if (isEmpty(map)) {
            return returnValue;
        }

        if (isEmpty(map[lang])) {
            if (lang !== 'en' && isNotEmpty(map['en'])) {
                return map['en'];
            }

            for (let key in map) {
                if (isNotEmpty(map[key])) {
                    return map[key];
                }
            }

            return returnValue;
        }

        return map[lang];
    }

    return { translateMap };
}