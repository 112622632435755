import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import apis from '@/lib/api/index.js';
import { responseSuccessCheck } from '@/lib/helpers/ApiHelper';
import _ from 'lodash';

export const useSiteStatsStore = defineStore('siteStats', () => {
    const items = ref([]);
    let loadedIds = [];

    const reset = () => {
        items.value = [];
        loadedIds = [];
    };

    const fetchStatsForDeployments = async (siteIds) => {
        let chunkSize = 500;
        const notLoaded = siteIds.filter(item => loadedIds.indexOf(item) === -1);

        if (notLoaded.length === 0) {
            return;
        }
        /* To prevent multiple loads starting at the same time */
        loadedIds.push(...notLoaded);

        //console.log("Need to load stats for: ", notLoaded);
        /* Set all at once when loading multiple chunks */
        const tmpItems = [];
        for (let i = 0; i < notLoaded.length; i += chunkSize) {
            const res = await fetchStats(notLoaded.slice(i, i + chunkSize), tmpItems);
            if(!res) {
                const removeSet = new Set(notLoaded.slice(i, i + chunkSize));
                loadedIds = loadedIds.filter(item => !removeSet.has(item));
            }
        }
        items.value.push(...tmpItems);
    };

    const idMap = computed(() => {
        const map = {};
        items.value.forEach(item => {
            map[item.id] = item;
        });
        return map;
    });


    const fetchStats = async (ids, resItems) => {
        //console.log("Fetching stats for ", ids);
        const resp = await apis['overviewContexts'].post(ids, "/stats");
        if (responseSuccessCheck(resp)) {
            resItems.push(...resp.data);
            return true;
        } else {
            return false;
        }
    };

    const applyStats = ((contexts) => {
        contexts.forEach(item => {
            if(!item.products.hasOwnProperty("mirage")) {
                //console.log("Skipping item without mirage: ", item);
                return;
            }
            const id = item.facId || item.id;
            const s = idMap.value[id];
            if (s) {
                const stat = s.statusByProduct.mirage;
                //Occupancy
                item.roomCnt = _.get(stat, 'hospLocStat.totLocs', 0);
                item.occupied = _.get(stat, 'hospLocStat.occLocs', 0);

                //Client software
                item.cliStat = _.get(stat, 'cliStat');

                //Tv Clients
                item.numberOfClients = _.get(stat, 'cliStat.totClis', 0);
                item.offlineClients = _.get(stat, 'cliStat.olClis', 0);
                const tvDeviceTypes = _.get(stat, 'cliStat.devCnt', {});
                item.tvTypes = {};
                item.tvMod = {};
                item.tvFw = {};

                //Signage Clients
                item.numberOfSigClients = _.get(stat, 'cliStat.totSigClis', 0);
                item.sigMod = {};
                item.sigClientTypes = {};
                item.offlineSigClients = _.get(stat, 'cliStat.olSigClis', 0);
                const signageDeviceTypes = _.get(stat, 'cliStat.sigDevCnt', {});
                const signageFw = _.get(stat, 'cliStat.sigFw');
                item.sigFw = [];
                if (Array.isArray(signageFw) && signageFw.length > 0) {
                    signageFw.forEach( function (it) {
                        if (it !== "-") {
                            item.sigFw.push(it);
                        }
                    });
                }

                //Cast
                item.numberOfCasts = _.get(stat, 'castStat.registeredChromecasts');
                item.castVersion = _.get(stat, 'castStat.castVersion');
                item.castOnline = _.get(stat, 'castStat.online');

                //Agent Client
                item.agentOnline = _.get(stat, 'cliStat.agentOnline');
                item.agentVersion = _.get(stat, 'cliStat.agentVersion');


                if (Array.isArray(signageFw) && signageFw.length > 0) {
                    signageFw.forEach( function (it) {
                        if (it !== "-") {
                            item.sigFw.push(it);
                        }
                    });
                }

                for (const key in tvDeviceTypes) {
                    const parts = key.split(',');
                    if (parts.length > 0 && typeof parts[0] === 'string') {
                        const [vendor, deviceType] = parts[0].split('/');
                        //vendor = vendor === '-' ? 'UNKNOWN' : vendor;
                        if (vendor && vendor !== '-' && vendor !== 'BROWSER') {
                            if (!item.tvTypes.hasOwnProperty(vendor)) {
                                item.tvTypes[vendor] = tvDeviceTypes[key];
                            } else {
                                item.tvTypes[vendor] += tvDeviceTypes[key];
                            }
                        }
                        if (deviceType === 'TV' && parts.length > 1 && parts[1] !== '-') {
                            if (!item.tvMod.hasOwnProperty(parts[1])) {
                                item.tvMod[parts[1]] = tvDeviceTypes[key];
                            } else {
                                item.tvMod[parts[1]] += tvDeviceTypes[key];
                            }
                        }
                    }
                }

                for (const key in signageDeviceTypes) {
                    const parts = key.split(',');
                    if (parts.length > 0 && typeof parts[0] === 'string') {
                        const [vendor, deviceType] = parts[0].split('/');
                        //vendor = vendor === '-' ? 'UNKNOWN' : vendor;
                        if (vendor && vendor !== '-' && vendor !== 'BROWSER') {
                            if (!item.sigClientTypes.hasOwnProperty(vendor)) {
                                item.sigClientTypes[vendor] = signageDeviceTypes[key];
                            } else {
                                item.sigClientTypes[vendor] += signageDeviceTypes[key];
                            }
                        }
                        if (deviceType === 'SIGNAGE' && parts.length > 1 && parts[1] !== '-') {
                            if (!item.sigMod.hasOwnProperty(parts[1])) {
                                item.sigMod[parts[1]] = signageDeviceTypes[key];
                            } else {
                                item.sigMod[parts[1]] += signageDeviceTypes[key];
                            }
                        }
                    }
                }

            } else {
                console.log("Could not find item for stat id: " + item.id);
            }
        });
    });


    return {
        reset,
        items,
        applyStats,
        fetchStatsForDeployments
    };
});
