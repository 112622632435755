import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useProductsStore } from '@/stores/admin/products.js';
import moment from '@/lib/helpers/moment.js';
import { useDeploymentsStore } from '@/stores/contexts/deployments.js';
import { useCommonStore } from '@/stores/common.js';

const thisModule = 'licenses';

// Initialize the BaseCrudPlugin for this store

export const useLicensesStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.deploymentLicenses = computed(() => {
        const items =  store.cloneItems();
        const deployments = useDeploymentsStore().idMap;
        return items.filter(i => deployments.hasOwnProperty(i.targetContextId));
    });

    store.byTargetMap = computed(() => {
        const items = store.deploymentLicenses.value;

        const contexts = useCommonStore().allContextsIdMap;
        const products = useProductsStore().idMap;
        const targetMap = {};

        items.forEach((license) => {
            let context;

            if (contexts.hasOwnProperty(license.targetContextId)) {
                context = contexts[license.targetContextId];
            } else {
                context = {name: "Unknown"};
            }

            let product;
            if (products.hasOwnProperty(license.productId)) {
                product = products[license.productId];
            } else {
                product = {
                    name: "Unknown",
                    itemId: ""
                };
            }

            if (!targetMap.hasOwnProperty(license.targetContextId)) {
                targetMap[license.targetContextId] = [];
            }

            targetMap[license.targetContextId].push({
                ...license,
                fromDateFormatted: moment.toDisplayFormatDateOnly(license.fromDate),
                toDateFormatted: moment.toDisplayFormatDateOnly(license.toDate),
                contextName: context.name,
                productName: product.name,
                productItemId: product.itemId
            });
        });
        return targetMap;
    });

    store.allByTargetMap = computed(() => {
        const items = store.cloneItems();

        const contexts = useCommonStore().allContextsWithLegacyIdMap;
        const products = useProductsStore().idMap;
        const targetMap = {};

        items.forEach((license) => {
            let context;

            if (contexts.hasOwnProperty(license.targetContextId)) {
                context = contexts[license.targetContextId];
            } else {
                context = {name: "Unknown"};
            }

            let product;
            if (products.hasOwnProperty(license.productId)) {
                product = products[license.productId];
            } else {
                product = {
                    name: "Unknown",
                    itemId: ""
                };
            }

            if (!targetMap.hasOwnProperty(license.targetContextId)) {
                targetMap[license.targetContextId] = [];
            }
            targetMap[license.targetContextId].push({
                ...license,
                fromDateFormatted: moment.toDisplayFormatDateOnly(license.fromDate),
                toDateFormatted: moment.toDisplayFormatDateOnly(license.toDate),
                contextName: context.name,
                productName: product.name,
                productItemId: product.itemId
            });
        });
        return targetMap;
    });


    /** OPTIMIZATION to have a cached way of getting count based on contextId */
    store.licenseCountByTargetMap = computed(() => {
        const countMap = {};
        const licenses = store.deploymentLicenses.value;
        licenses.forEach(license => {
            if(countMap.hasOwnProperty(license.contextId)) {
                countMap[license.contextId]++;
            } else {
                countMap[license.contextId] = 1;
            }
        });
        return countMap;
    });

    return store;
});
