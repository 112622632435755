import { ref, computed } from 'vue';
import moment from 'moment';
import apis from '@/lib/api/index.js';
import { useTranslator } from '@/composables/useTranslator.js';
import { responseSuccessCheck } from "@/lib/helpers/ApiHelper.js";
import { isNotEmpty, isEmpty, isDataStale } from '@/lib/helpers/Validator';

const resCheck = responseSuccessCheck;
const { translateMap } = useTranslator();

/**
 * This base plugin does not utilize the pinia state, actions and getters but rather just defines properties and functions directly.
 * This might not be the best approach but it is a simple way to create a base store plugin that can be used in any Vue 3 project.
 *
 * Consider refactoring this to use the pinia store API.
 */

export const createBaseApiStorePlugin = (moduleName, fetchEndpoint = '', cacheKey = null) => {
    return ({ store }) => {

        if(!apis.hasOwnProperty(moduleName)) {
            console.error(`API not found for module: ${moduleName}`);
            return;
        }
        // Shared state
        store.api = apis[moduleName];
        store.name = moduleName;
        store.processing = ref(false);
        store.fetching = ref(false);
        store.fetched = ref(null);
        store.error = ref(null);
        store.consecutiveErrorCnt = ref(0);
        store.items = ref([]);
        store.data = ref({});
        store.change = ref(null);
        store.cacheKey = cacheKey ? `storeCache.${cacheKey}` : null;
        store.enableFetchRetry = false;
        store.currRetries = 0;

        store.setEnableFetchRetry = (enable) => {
            store.enableFetchRetry = enable;
        };

        // Getters - should we put this in the getters object?
        store.fetchCompleted = computed(() => {
            return !store.fetching.value && (store.fetched.value !== null || store.error.value !== null);
        });

        store.isLoaded = computed(() => {
            return !store.fetching.value && store.fetched.value !== null;
        });

        store.isLoading = computed(() => store.fetching.value);

        store.idMap = computed(() => Map.createFromArray(store.items.value, 'id'));

        store.idMapBy = (field) => Map.createFromArray(store.items.value, field);

        store.idMapByGetter = (getter, field = 'id', asList = false) => {
            switch(typeof store[getter]) {
                case 'function':
                    return Map.createFromArray(store[getter](), field, asList);
                case 'object':
                    return Map.createFromArray(store[getter].value, field, asList);
                default:
                    return Map.createFromArray(store.items.value, field, asList);
            }
        };

        store.itemById = (id, getter, idField = 'id') => {
            switch(typeof store[getter]) {
                case 'function':
                    return Map.retrieve(Map.createFromArray(store[getter](), idField), id);
                case 'object':
                    return Map.retrieve(Map.createFromArray(store[getter].value, idField), id);
                default:
                    return Map.retrieve(Map.createFromArray(store.items.value, idField), id);
            }
        };

        store.filtered = (field, val) => {
            return store.cloneSortedItems().filter((item) => item[field] === val);
        };

        store.withParsedTimestamp = (field, sorted = '') => {
            const data = sorted === '' ? store.cloneItems() : store.sortedBy(field, sorted !== 'desc');
            if (Array.isArray(data)) {
                data.forEach((item) => {
                    if (item.hasOwnProperty(field)) {
                        item[`${field}Obj`] = moment.utc(item[field]).local();
                    }
                });
                return data;
            }
            return [];
        };

        store.translated = computed(() => {
            const data = store.cloneItems();
            if (Array.isArray(data)) {
                data.forEach((item) => {
                    item.nameTranslated = isNotEmpty(item.name) ? item.name : '';
                    item.titleTranslated = isNotEmpty(item.title) ? translateMap(item.title) : '';
                });
                return data;
            }
            return [];
        });

        store.translatedAndSortedBy = (field, asc = true) => {
            const data = store.translated.value;
            if (Array.isArray(data)) {
                Array.sort(data, field, asc);
                return data;
            }
            return [];
        };

        store.sorted = computed(() => {
            const data = store.cloneItems();
            if (Array.isArray(data)) {
                Array.sort(data, 'name');
                return data;
            }
            return [];
        });

        store.sortedBy = (field, asc = true) => {
            const data = store.cloneItems();
            if (Array.isArray(data)) {
                Array.sort(data, field, asc);
                return data;
            }
            return [];
        };

        store.cloneSortedItems = () => structuredClone(store.sorted.value);

        store.cloneItems = () => structuredClone(store.items.value);

        // Actions
        store.fetch = async ({ ignoreStale = false, pathExtension = '', requestConfig = {}, contextId = null } = {}) => {
            if (store.fetching.value) return;

            if (!isDataStale(store.fetched.value, 10) && !ignoreStale) return;

            store.fetching.value = true;

            if (store.cacheKey && store.fetched.value === null && sessionStorage[store.cacheKey]) {
                try {
                    const cachedData = JSON.parse(sessionStorage[store.cacheKey]);
                    console.log("***************** RETURNING CACHED DATA FOR ", store.cacheKey);
                    store.fetchSuccess(cachedData);
                } catch (error) {
                    console.error(`Could not parse cached data for ${store.cacheKey}:`, error);
                    delete sessionStorage[store.cacheKey];
                }
            }

            const response = await store.api.get(fetchEndpoint + pathExtension, requestConfig, contextId);
            if (resCheck(response)) {
                store.fetchSuccess(response.data);
                if (store.cacheKey) {
                    try {
                        console.log("CACHING " + store.cacheKey);
                        sessionStorage[store.cacheKey] = JSON.stringify(response.data);
                    } catch (e) {
                        console.error(`Could not cache data for ${store.cacheKey}:`, e);
                    }
                }
            } else {
                store.fetchError(response);
            }
            return response;
        };

        store.fetchSingle = async ({ id, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.get(`${fetchEndpoint}${pathExtension}/${id}`, requestConfig);
            if (resCheck(response)) {
                store.fetchSingleSuccess(response.data);
            } else {
                store.fetchSingleError(response);
            }
            return response;
        };

        store.fetchMultiple = async ({ ids, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.post(ids, `${fetchEndpoint}${pathExtension}/get`, requestConfig);
            if (resCheck(response)) {
                store.fetchMultipleSuccess(response.data);
            } else {
                store.fetchMultipleError(response);
            }
            return response;
        };

        store.create = async ({ item, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.post(item, pathExtension, requestConfig);
            if (resCheck(response)) {
                store.createSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            return response;
        };

        store.createLocal = ({ item }) => store.createSuccess(item);

        store.createMultiple = async ({ items, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.postMultiple(items, requestConfig);
            if (resCheck(response)) {
                store.createMultipleSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            return response;
        };

        store.update = async ({ item, pathExtension = '', requestConfig = {} }) => {
            let response;
            if(pathExtension !== '' && pathExtension !== null) {
                response = await store.api.putWithPathExtension(item, item.id, pathExtension, requestConfig);
            } else {
                response = await store.api.put(item, item.id, requestConfig);
            }
            if (resCheck(response)) {
                store.updateSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            return response;
        };

        store.updateLocal = ({ item }) => store.updateSuccess(item);

        store.updateMultiple = async ({ items, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.putMultiple(items, requestConfig);
            if (resCheck(response)) {
                store.updateMultipleSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            return response;
        };

        store.delete = async ({ item, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.delete(item.id, pathExtension, requestConfig);
            if (resCheck(response)) {
                store.deleteSuccess(item);
            } else {
                store.error.value = response.data;
            }
            return response;
        };

        store.patch = async ({ item, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.patch(item, item.id, requestConfig);
            if (resCheck(response)) {
                store.patchSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            return response;
        };

        store.deleteMultiple = async ({ items, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.deleteMultiple(items, requestConfig);
            if (resCheck(response)) {
                store.deleteMultipleSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            return response;
        };

        store.patchMultiple = async ({ items, pathExtension = '', requestConfig = {} }) => {
            const response = await store.api.patchMultiple(items, requestConfig);
            if (resCheck(response)) {
                store.patchMultipleSuccess(response.data);
            } else {
                store.error.value = response.data;
            }
            return response;
        }

        store.deleteLocal = ({ id }) => {
            store.items.value = store.items.value.filter((i) => i.id !== id);
        };

        store.reset = () => {
            store.processing.value = false;
            store.fetching.value = false;
            store.fetched.value = null;
            store.error.value = null;
            store.consecutiveErrorCnt.value = 0;
            store.items.value = [];
            store.data.value = {};
            store.change.value = null;
        };

        // Success handlers for actions
        store.fetchSuccess = (data) => {
            if(Array.isArray(data)) {
                store.items.value = isEmpty(data) ? [] : [...data]; // Ensure a new array reference
            } else {
                store.items.value = [];
                store.data.value = data;
            }
            store.fetched.value = moment();
            store.fetching.value = false;
            store.consecutiveErrorCnt.value = 0;

        };

        store.fetchError = (response) => {
            store.fetching.value = false;
            if(store.enableFetchRetry) {
                if(store.currRetries < 3) {
                    console.log("Retrying fetch for " + store.name + " in 5 seconds...");
                    store.currRetries++;
                    setTimeout(() => {
                        console.log("Retrying fetch for " + store.name);
                        store.fetch({ ignoreStale: true });
                    }, 5000);
                    return;
                } else {
                    console.error("Failed to fetch " + store.name + " after 3 retries.");
                }
            }
            store.error.value = response.data;
            store.consecutiveErrorCnt.value++;
            store.items.value = [];
        };

        store.fetchMultipleSuccess = (data) => {
            if(!Array.isArray(data)) {
                console.error('Expected array of items:', data);
                return;
            }
            data.forEach((item) => {
                const index = store.items.value.findIndex((i) => i.id === item.id);
                if (index !== -1) {
                    store.items.value.splice(index, 1, { ...store.items.value[index], ...item });
                } else {
                    store.items.value.push({ ...item });
                }
            });
        }

        store.fetchMultipleError = (response) => {
            console.error('Error fetching multiple items:', response.data);
            store.error.value = response.data;
            store.fetching.value = false;
        }

        store.fetchSingleSuccess = (item) => {
            const index = store.items.value.findIndex((i) => i.id === item.id);
            store.fetching.value = false;
            if (index !== -1) {
                // Ensures Vue tracks the change correctly
                store.items.value.splice(index, 1, { ...store.items.value[index], ...item });
            } else {
                // If item does not exist, add it to the list
                store.items.value.push({ ...item });
            }
        };

        store.fetchSingleError = (response) => {
            console.error('Error fetching single item:', response.data);
            store.error.value = response.data;
            store.fetching.value = false;
        };

        store.createSuccess = (newItem) => {
            store.processing.value = false;
            /* Check if item was already added - notification service can be fast... */
            const index = store.items.value.findIndex((i) => i.id === newItem.id);
            if (index !== -1) {
                store.items.value.splice(index, 1, { ...store.items.value[index], ...newItem });
            } else {
                store.items.value.push({ ...newItem });
            }
            store.consecutiveErrorCnt.value = 0;
        };

        store.createMultipleSuccess = (responseData) => {
            responseData.forEach(item => {
                const index = store.items.value.findIndex((i) => i.id === item.id);
                if (index !== -1) {
                    store.items.value.splice(index, 1, { ...store.items.value[index], ...item });
                } else {
                    store.items.value.push(item)
                }
            });
            store.consecutiveErrorCnt.value = 0;
        };

        store.updateSuccess = (updatedItem) => {
            store.processing.value = false;
            /* Find and update item */
            const index = store.items.value.findIndex((i) => i.id === updatedItem.id);
            if (index !== -1) {
                store.items.value.splice(index, 1, { ...store.items.value[index], ...updatedItem });
            } else {
                console.error(store.name, ' Could not find item to update:', updatedItem);
                /* Add item if not found */
                store.items.value.push({ ...updatedItem });
            }
        };

        store.patchSuccess = (patchedItem) => {
            /* Find and update item */
            const index = store.items.value.findIndex((i) => i.id === patchedItem.id);
            if (index !== -1) {
                store.items.value.splice(index, 1, { ...store.items.value[index], ...patchedItem });
            } else {
                console.error('Could not find item to patch:', patchedItem);
            }
        };

        store.updateMultipleSuccess = (updatedItems) => {
            updatedItems.forEach((item) => {
                const index = store.items.value.findIndex((i) => i.id === item.id);
                if (index !== -1) {
                    store.items.value.splice(index, 1, { ...store.items.value[index], ...item });
                } else {
                    console.error('Could not find item to update:', item);
                }
            });
        };

        store.deleteSuccess = (item) => {
            const index = store.items.value.findIndex(i => i.id === item.id);
            if (index !== -1) {
                store.items.value.splice(index, 1); // Removes item without replacing the entire array
            }
        };

        store.deleteMultipleSuccess = (items) => {
            for (let i = store.items.value.length - 1; i >= 0; i--) {
                if (items.includes(store.items.value[i].id)) {
                    store.items.value.splice(i, 1); // Maintains reactivity
                }
            }
        };

        store.patchMultipleSuccess = (items) => {
            items.forEach((patchedItem) => {
                const index = store.items.value.findIndex(i => i.id === patchedItem.id);
                if (index !== -1) {
                    store.items.value[index] = { ...store.items.value[index], ...patchedItem }; // Merge changes
                }
            });
        };
    };
};
