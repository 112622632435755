import { defineStore } from 'pinia';
import { computed } from 'vue';
import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { useContextStore } from '@/stores/context.js';

const thisModule = 'templateSources';

export const useTemplateSourcesStore = defineStore(thisModule, () => {
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule);
    plugin({ store });

    store.withDeleteInfo = computed(() => {
        const data = store.cloneItems();
        data.forEach((item) => {
            item.canBeDeleted = item.contextId === useContextStore().selectedContextId;
            item.canBeEdited = item.contextId === useContextStore().selectedContextId;
        });
        return data;
    });
    return store;
});
