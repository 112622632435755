import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { useConfigSchedulesStore } from '@/stores/screens/configSchedules.js';
import { isEmpty } from '@/lib/helpers/Validator';
import { computed } from 'vue';
import { useClientsStore } from '@/stores/clients/clients.js';

const thisModule = 'screens';

// Initialize the BaseCrudPlugin for this store

export const useScreensStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.withLabelAndRoom = computed(() => {
        const clientsIdMap = useClientsStore().idMapWithLocation;
        const schedulesIdMap = useConfigSchedulesStore().idMap;
        const screens = store.cloneItems();

        screens.forEach(item => {
            const client = Map.retrieve(clientsIdMap, item.clientId);
            const schedule = Map.retrieve(schedulesIdMap, item.currentScheduleId);
            item.locationTitle = '';
            if (client) {
                item.name = isEmpty(client.name) ? client.id : client.name;
                item.locationTitle = client.locationTitle;
            } else {
                item.name = item.clientId;
                console.log("Client not found for " + item.clientId);
            }
            if (schedule) {
                item.currentScheduleName = schedule.name;
            } else {
                item.currentScheduleName = '-';
            }
        });
        return screens;
    });

    store.mapByCurrentSchedule = computed(() => {
        const screens = store.cloneItems();
        const map = {};

        screens.forEach(screen => {
            if(screen.currentScheduleId !== null) {
                if (!map.hasOwnProperty(screen.currentScheduleId)) {
                    map[screen.currentScheduleId] = [];
                }
                map[screen.currentScheduleId].push(screen);
            }
        });
        return map;
    });

    store.byScheduleRuleSet = (scheduleRuleSetId) => {
        const items = store.withLabelAndRoom.value;
        return items.filter(item => {
            return (item.scheduleRuleSetId === scheduleRuleSetId);
        });
    };

    store.byScheduleRuleSetSorted = (scheduleRuleSetId) => {
        const items = store.byScheduleRuleSet(scheduleRuleSetId);
        Array.sort(items, "name");
        return items;
    };

    return store;
});
