import English from './en/translations.js';
import Norwegian from './no/translations.js';
import French from './fr/translations.js';
import German from  './de/translations.js';
import Spanish from './es/translations.js';
import Estonian from './et/translations.js';
import Japanese from './ja/translations.js';

const translations = {
  en: English,
  no: Norwegian,
  fr: French,
  de: German,
  es: Spanish,
  et: Estonian,
  ja: Japanese
};

export default translations;
