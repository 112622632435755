import { reactive } from 'vue';

const modals = reactive({
    acceptUpdatedEula: null,
    confirm: null,
    clipboardCopy: null,
    folderStructureSelector: null,
    helpGetSiteInfo: null,
    helpGetSupport: null,
    helpMessage: null,
    mapLocationSelector: null,
    mediaChannels: null,
    notification: null,
    prompt: null,
    resourceUploader: null,
    resourceViewer: null,
    scroller: null,
    welcome: null
});

export function useGlobalModals() {

    const setAcceptUpdatedEula = (modal) => {
        modals.acceptUpdatedEula = modal;
    }
    const getAcceptUpdatedEula = () => {
        return modals.acceptUpdatedEula;
    }

    const setConfirmModal = (modal) => {
        modals.confirm = modal;
    }
    const getConfirmModal = () => {
        return modals.confirm;
    }

    const setClipboardCopy = (modal) => {
        modals.clipboardCopy = modal;
    }
    const getClipboardCopy = () => {
        return modals.clipboardCopy;
    }

    const setFolderStructureSelector = (modal) => {
        modals.folderStructureSelector = modal;
    }
    const getFolderStructureSelector = () => {
        return modals.folderStructureSelector;
    }

    const setHelpGetSiteInfo = (modal) => {
        modals.helpGetSiteInfo = modal;
    }
    const getHelpGetSiteInfo = () => {
        return modals.helpGetSiteInfo;
    }

    const setHelpGetSupport = (modal) => {
        modals.helpGetSupport = modal;
    }
    const getHelpGetSupport = () => {
        return modals.helpGetSupport;
    }

    const setHelpMessage = (modal) => {
        modals.helpMessage = modal;
    }
    const getHelpMessage = () => {
        return modals.helpMessage;
    }

    const setMapLocationSelector = (modal) => {
        modals.mapLocationSelector = modal;
    }
    const getMapLocationSelector = () => {
        return modals.mapLocationSelector;
    }

    const setMediaChannels = (modal) => {
        modals.mediaChannels = modal;
    }
    const getMediaChannels = () => {
        return modals.mediaChannels;
    }

    const setNotification = (modal) => {
        modals.notification = modal;
    }
    const getNotification = () => {
        return modals.notification;
    }

    const setPrompt = (modal) => {
        modals.prompt = modal;
    }
    const getPrompt = () => {
        return modals.prompt;
    }

    const setResourceUploader = (modal) => {
        modals.resourceUploader = modal;
    }
    const getResourceUploader = () => {
        return modals.resourceUploader;
    }

    const setResourceViewer = (modal) => {
        modals.resourceViewer = modal;
    }
    const getResourceViewer = () => {
        return modals.resourceViewer;
    }

    const setScroller = (modal) => {
        modals.scroller = modal;
    }
    const getScroller = () => {
        return modals.scroller;
    }

    const setWelcome = (modal) => {
        modals.welcome = modal;
    }
    const getWelcome = () => {
        return modals.welcome;
    }

    return {
        setAcceptUpdatedEula,
        getAcceptUpdatedEula,
        setConfirmModal,
        getConfirmModal,
        setClipboardCopy,
        getClipboardCopy,
        setFolderStructureSelector,
        getFolderStructureSelector,
        setHelpGetSiteInfo,
        getHelpGetSiteInfo,
        setHelpGetSupport,
        getHelpGetSupport,
        setHelpMessage,
        getHelpMessage,
        setMapLocationSelector,
        getMapLocationSelector,
        setMediaChannels,
        getMediaChannels,
        setNotification,
        getNotification,
        getPrompt,
        setPrompt,
        getResourceUploader,
        setResourceUploader,
        getResourceViewer,
        setResourceViewer,
        setScroller,
        getScroller,
        setWelcome,
        getWelcome
    };
}