<template>
    <BaseDialog
            v-model="open"
            max-width="800"
            :title="title"
            :eager="true"
            :hide-close="true"
            :hide-all-actions="true"
            :persistent="true"
    >
        <template v-slot:content>
            <v-stepper v-model="progress">
                <v-stepper-header>
                    <v-stepper-step :complete="progress > 1" step="1">{{$t('welcome.languageHeader')}}
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="progress > 2" step="2">Dashboards</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3">EULA</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card
                                height="360px"
                                elevation="0"
                        >
                            <div v-html="$t('welcome.languageText')"></div>
                            <v-list>
                                <v-list-item
                                        v-for="lang in languages"
                                        :key="lang.code"
                                        @click="selectLanguage(lang)"
                                >
                                    <v-list-item-action>
                                        <v-avatar size="24">
                                            <img :src="`/icon/language/${getFlagCode(lang.code)}.png`"
                                                 :alt="lang.code">
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$tc('language.' + lang.name, 2)}}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>

                        </v-card>

                        <v-container fluid>
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="primary"
                                        elevation="1"
                                        @click="progress++"
                                >
                                    {{$t('generic.continue')}}
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                                height="360px"
                                elevation="0"
                        >
                            <div style="height: 100%; width: 100%; overflow-y: scroll;" v-html="$t('welcome.homeScreenText')"></div>
                        </v-card>
                        <v-container fluid>
                            <v-row no-gutters>
                                <v-btn
                                    elevation="1"
                                        @click="progress--"
                                >
                                    {{$t('generic.back')}}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    elevation="1"
                                    color="primary"
                                    @click="progress++"
                                >
                                    {{$t('generic.continue')}}
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card
                            height="360px"
                            elevation="0"
                        >
                            <div v-html="eula" style="height: 100%; width: 100%; overflow-y: scroll;" @scroll="eulaScroll">
                            </div>
                        </v-card>

                        <v-container fluid>
                            <v-row no-gutters>
                                <v-btn
                                    elevation="1"
                                    @click="progress--"
                                >
                                    {{$t('generic.back')}}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    elevation="1"
                                    color="primary"
                                    @click="done"
                                    :disabled="!eulaRead"
                                >{{$t('generic.accept')}}
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </template>
    </BaseDialog>
</template>

<script>
    import BaseDialog from '@/components/base/popup/BaseDialog.vue';
    import axios from 'axios';
    import PortalTheme from "@/lib/theme/PortalTheme.js";
    import { useGlobalModals } from '@/composables/useGlobalModals';
    import { useUserAccountStore } from '@/stores/account/userAccount.js';
    import { useAuthStore } from '@/stores/auth.js';
    import { useAppStateStore } from '@/stores/appState';

    export default {
        name: 'TheWelcome',
        components: {
            BaseDialog
        },
        data() {
            return {
                open: false,
                progress: 1,
                eula: '',
                eulaRead: false
            }
        },
        mounted() {
            useGlobalModals().setWelcome(this);
        },
        computed: {
            authStore() {
                return useAuthStore();
            },
            title() {
                return this.$t('welcome.title', {user: this.authStore.account.displayName});
            },
            languages() {
                return useAppStateStore().portalLanguages;
            },
        },
        methods: {
            async show() {
                this.open = true;
                try {
                    let response;
                    switch (PortalTheme.getTheme()) {
                        case PortalTheme.availableThemes.THEME_UNIGUEST:
                            response = await axios.get("/eula_ug.html", {});
                            break;
                        default:
                            response = await axios.get("/eula.html", {});
                            break;
                    }
                    this.eula = response.data;
                } catch(e) {
                    this.eula = "COULD NOT LOAD END USER LICENCE AGREEMENT..."
                    this.eulaRead = true;
                }
            },
            getFlagCode(lang) {
                return useAppStateStore().getFlagCode(lang);
            },
            done() {
                this.open = false;
                let eulaVersion = parseInt(import.meta.env.VITE_EULA_VERSION, 10);
                useUserAccountStore().saveSetting([ { key: 'welcomeDisplayed', val: true }, { key: 'acceptedEulaVersion', val: eulaVersion } ]);
            },
            eulaScroll(e) {
                if(e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50) {
                    this.eulaRead = true;
                }
            },
            selectLanguage(lang) {
                useAppStateStore().setLanguage(lang);
                //this.progress = 2;
            }
        }
    }
</script>