<template>
    <v-tooltip
        bottom
        :disabled="tooltip === null"
    >
        <template v-slot:activator="{on}">
            <v-btn
                v-show="autoShrink && !search && !focused"
                icon
                @click.stop="expand()"
                data-testid="cisfSearchBtn"
            >
                <v-icon>search</v-icon>
            </v-btn>
            <v-text-field
                v-show="!autoShrink || search || focused"
                v-on="on"
                :prepend-inner-icon="prependIcon"
                :label="label !== null ? label : $t('generic.search')"
                :clearable="clearable"
                single-line
                hide-details
                v-model="search"
                ref="textfield"
                @click.stop=""
                @focus="focused=true"
                @blur="focused=false"
                @click:clear="$refs.textfield.blur()"
                :class="cssClasses"
                data-testid="cisfSearch"
            ></v-text-field>
        </template>
        {{tooltip}}
    </v-tooltip>
</template>

<script>
    import { nextTick } from 'vue';
    import { isEmpty } from '@/lib/helpers/Validator';

    export default {
        name: 'SearchField',
        props: {
            searchText: {
                default: "",
                type: String
            },
            autoShrink: {
                default: false,
                type: Boolean
            },
            prependIcon: {
                type: String,
                default: 'search'
            },
            label: {
                type: String,
                default: null
            },
            tooltip: {
                default: null,
                type: String
            },
            clearable: {
                default: true,
                type: Boolean
            },
            delayChangeEvent: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                search: this.searchText,
                focused: false
            }
        },
        watch: {
            search() {
                clearTimeout(this.timeout);

                if (this.delayChangeEvent) {
                    this.timeout = setTimeout(() => {
                        this.$emit('change', this.search);
                    }, 200);
                } else {
                    this.$emit('change', this.search);
                }
            }
        },
        computed: {
            cssClasses() {
                return 'search-field' + (this.autoShrink ? ' auto-shrink' : '') + (isEmpty(this.search) ? '' : ' active-search');
            }
        },
        methods: {
            clear() {
                this.search = '';
            },
            expand() {
                this.focused = true;
                nextTick(() =>
                    this.$refs.textfield.focus()
                );
            },
            setSearch(text) {
                this.search = text;
            }
        }
    }
</script>

<style lang="scss">

    .search-field.auto-shrink {
        max-width: 40px;
        cursor: pointer;
    }

    .search-field {
        padding: 3px 8px 3px 8px !important;
        margin: 0 5px 0 5px;
        background-color: #f1f3f4;
        border-radius: 20px;
        transition: all 0.2s ease-in-out;
        max-width: 300px;

        .v-input__slot::before, .v-input__slot::after {
            display: none !important;
        }
    }

    .auto-shrink.v-input--is-focused {
        cursor: text;
        max-width: 300px !important;
    }

    .auto-shrink.active-search {
        cursor: text;
        max-width: 300px !important;
    }

    .theme--dark {
        .search-field {
            background-color: var(--v-background-lighten1);
        }
    }
</style>
