
export function useClipboard() {

    const copyToClipboard = (toCopy) => {
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute('value', toCopy);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        return true;
    };


    return {
        copyToClipboard
    };
}