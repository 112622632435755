import { createBaseApiStorePlugin } from '@/plugins/store/baseApiStorePlugin';
import { defineStore } from 'pinia';
import { computed } from 'vue';

const thisModule = 'systemConfig';

// Initialize the BaseCrudPlugin for this store
export const useSystemConfigStore = defineStore(thisModule, () => {
    // Initialize the base store logic
    const store = {};
    const plugin = createBaseApiStorePlugin(thisModule, '');
    plugin({ store });

    store.systemConfig = computed(() => {
        if (Array.isArray(store.items.value) && store.items.value.length > 0) {
            return store.items.value[0];
        }
        return {};
    });

    return store;
});
