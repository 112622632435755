<template>
    <div class="invalidIdp">
        <v-icon>info</v-icon>
        <br><br>
        <p v-html="text"></p>
        <p v-if="contextMode" class="small">
            Details: context.mode => {{ contextMode }}<br>
            Account: {{ kcEmail }}<br>
            Domain context: {{ kcDomain }}<br>
            Identity provider: {{ kcIdp }}
        </p>
    </div>
</template>

<script>
import { useKeycloak } from '@/composables/useKeycloak';
const keycloak = useKeycloak();

export default {
    name: "TheInvalidMirageSession",
    props: {
        text: {
            required: true,
            type: String
        },
        contextMode: {
            required: false,
            type: String
        }
    },
    data() {
        return {
            kcEmail: keycloak.tokenParsed.email,
            kcDomain: keycloak.tokenParsed.domain,
            kcIdp: keycloak.tokenParsed.identity_provider || "TheCloudPortal direct login",
        }
    }
}
</script>

<style lang="scss">
.invalidIdp {
    display: block;
    position: relative;
    top: 100px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-size: 2em;
}

.invalidIdp .v-icon {
    font-size: 4em;
}

.invalidIdp .small {
    margin-top:40px;
    font-size:0.8rem;
    color:gray;
}
</style>