<template>
    <v-main>
        <router-view
            class="mirage-main-view"
            :style='ppdsThemeActive() ? "height: " + mainViewHeightStyle + "px" : ""'
            :key="$route.path"/>
    </v-main>
</template>

<script>
import PortalTheme from '@/lib/theme/PortalTheme';

export default {
    name: 'TheMainView',
    data() {
        return {
            windowHeight: window.innerHeight
        }
    },
    computed: {
        mainViewHeightStyle() {
            return (this.windowHeight - 100);
        }
    },
    methods: {
        ppdsThemeActive() {
            return PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS;
        }
    }
}
</script>

<style lang="scss">
    .tablet {
        .v-main {
            padding-left: 240px !important;
        }
    }

    div[id^=__lpform_] {
        display: none;
    }
</style>
