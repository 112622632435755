<!--suppress JSUnfilteredForInLoop -->
<template>
    <BaseEditor
        v-model="showUploader"
        custom-class="resource-uploader"
        :max-width="550"
        :title="
            fileType !== null
                ? $tc('resources.uploadFileType', 2, { type: fileType })
                : $t('resources.uploadResource')
        "
        :done-action-text="$t('resources.upload')"
        @done="upload"
        :disabled="uploading || files.length < 1 || !readyToUpload"
        :persistent="uploading || uploadProcessing"
        :processing="uploadProcessing"
        :hide-close="uploading || uploadProcessing"
        :hide-cancel-action="uploading || uploadProcessing"
    >
        <template v-slot:actions v-if="uploading || uploadProcessing">
            <v-btn
                color="primary"
                elevation="1"
                @click.stop="cancelUpload"
                data-testid="beCancel"
            >
                Cancel Upload
            </v-btn>
        </template>

        <template v-slot:content>
            <label
                :for="uploaderId + 'fileInput'"
                class="file-upload-label v-btn"
                :class="
                    uploading || uploadProcessing
                        ? 'v-btn--disabled'
                        : 'elevation-5'
                "
            >
                <v-icon
                    style="margin-top: -1px"
                    :style="
                        uploading || uploadProcessing
                            ? 'color: rgba(0,0,0,.26) !important;'
                            : 'color: white;'
                    "
                    >add
                </v-icon>
                {{ $t('resources.addResources') }}
            </label>
            <input
                :id="uploaderId + 'fileInput'"
                name="file"
                type="file"
                ref="fileInput"
                multiple="multiple"
                :accept="allowedFileTypes"
                @change="fileSelected"
                :disabled="uploading"
            />

            <BaseSizedBox :height="30"></BaseSizedBox>

            <v-subheader class="pa-0">
                {{
                    files.length === 0
                        ? fileType !== null
                            ? $tc('resources.addType', 2, { type: fileType })
                            : addFileTypeDescription
                        : $t('resources.pressUpload')
                }}
            </v-subheader>

            <BaseSizedBox :height="15"></BaseSizedBox>

            <template v-for="(file, index) in files">
                <!--                <video-->
                <!--                    :src="file.src"-->
                <!--                    style="max-width: 100%; max-height: 300px;"-->
                <!--                    controls-->
                <!--                    v-if="file.resourceType === 'video'"-->
                <!--                ></video>-->

                <v-row style="width: 100%; margin: 0 0 10px 0">
                    <v-col
                        class="shrink pa-0"
                        style="padding-right: 10px !important"
                        v-if="file.resourceType === 'image'"
                    >
                        <v-layout align-center justify-center fill-height>
                            <v-img
                                max-height="54"
                                max-width="54"
                                :src="file.src"
                            ></v-img>
                        </v-layout>
                    </v-col>
                    <v-col class="pa-1">
                        <v-text-field
                            v-model="file.name"
                            :label="$t('generic.name')"
                            hide-details
                            :loading="file.uploading"
                            :readonly="file.success"
                            :disabled="
                                file.uploading ||
                                file.success ||
                                uploadProcessing
                            "
                        ></v-text-field>
                    </v-col>
                    <v-col
                        class="shrink pa-1"
                        style="padding-left: 10px !important"
                        v-if="file.uploading"
                    >
                        <v-layout align-center justify-center fill-height>
                            <v-progress-circular
                                :indeterminate="file.uploadProgress === false"
                                :value="parseInt(file.uploadProgress)"
                                :rotate="270"
                                color="primary"
                            >
                                <span style="font-size: 12px">{{
                                    file.uploadProgress !== false
                                        ? parseInt(file.uploadProgress)
                                        : ''
                                }}</span>
                            </v-progress-circular>
                        </v-layout>
                    </v-col>
                    <v-col class="shrink" v-if="file.success">
                        <v-layout align-center justify-center fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="success" icon>
                                        <v-icon>check_circle</v-icon>
                                    </v-btn>
                                </template>
                                {{ $t('resources.uploadSuccessfulHover') }}
                            </v-tooltip>
                        </v-layout>
                    </v-col>
                    <v-col class="shrink pa-0" v-if="file.error">
                        <v-layout align-center justify-center fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="error" icon>
                                        <v-icon>error</v-icon>
                                    </v-btn>
                                </template>
                                {{ file.errorText }}
                            </v-tooltip>
                        </v-layout>
                    </v-col>
                    <v-col
                        class="shrink pa-0"
                        v-if="
                            !file.success &&
                            !file.uploading &&
                            file.resourceType === 'image'
                        "
                    >
                        <v-layout align-center justify-center fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        icon
                                        color="primary"
                                        @click="editImage(file)"
                                    >
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                {{ $t('resources.editImageBefore') }}
                            </v-tooltip>
                        </v-layout>
                    </v-col>
                    <v-col
                        class="shrink pa-1"
                        v-if="
                            !file.success &&
                            !file.uploading &&
                            file.resourceType === 'image' &&
                            file.largerThan4K
                        "
                    >
                        <v-layout align-center justify-center fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        icon
                                        :color="file.largerThan4K ? 'red' : ''"
                                        @click="editImage(file)"
                                    >
                                        <v-icon>{{
                                            file.largerThan4K
                                                ? 'mdi-image-size-select-large'
                                                : ''
                                        }}</v-icon>
                                    </v-btn>
                                </template>
                                <span
                                    v-html="
                                        getToolTipContent(
                                            file.width,
                                            file.height
                                        )
                                    "
                                ></span>
                            </v-tooltip>
                        </v-layout>
                    </v-col>
                    <v-col
                        class="shrink pa-0"
                        v-if="
                            !file.success &&
                            !file.uploading &&
                            file.resourceType === 'video'
                        "
                    >
                        <v-layout align-center justify-center fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        :disabled="!file.is4k"
                                        icon
                                    >
                                        <v-btn
                                            icon
                                            :disabled="!file.is4k"
                                            :color="
                                                file.is4k
                                                    ? file.transcode4k
                                                        ? 'green'
                                                        : 'black'
                                                    : 'grey'
                                            "
                                            @click.stop="
                                                file.transcode4k =
                                                    !file.transcode4k
                                            "
                                        >
                                            <v-icon>
                                                {{
                                                    file.transcode4k
                                                        ? 'mdi-video-4k-box'
                                                        : 'mdi-high-definition-box'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </v-btn>
                                </template>
                                {{
                                    file.is4k
                                        ? file.transcode4k
                                            ? $t('resources.transcodeVideo4K')
                                            : $t('resources.transcodeVideoHD')
                                        : ''
                                }}
                            </v-tooltip>
                        </v-layout>
                    </v-col>
                    <v-col
                        class="shrink pa-0"
                        v-if="!file.success && !file.uploading"
                    >
                        <v-layout align-center justify-center fill-height>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        icon
                                        color="error"
                                        @click="files.splice(index, 1)"
                                    >
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </template>
                                {{ $t('generic.remove') }}
                            </v-tooltip>
                        </v-layout>
                    </v-col>
                </v-row>

                <!--                <BaseSizedBox v-if="file.resourceType === 'video'" :height="20"></BaseSizedBox>-->
            </template>

            <v-subheader
                v-show="isNotEmpty(errorMessages)"
                v-for="(error, index) in errorMessages"
                :key="index"
                class="pa-0 error--text"
            >
                <v-icon color="red" style="margin-right: 5px">warning</v-icon>
                {{ error }}
            </v-subheader>

            <BaseSizedBox :height="30"></BaseSizedBox>

            <v-select
                v-if="fileType !== 'PDF'"
                :items="availableFolders"
                item-text="name"
                item-value="id"
                v-model="folder"
                label="Destination folder"
                :disabled="uploading || uploadProcessing"
                hide-details
                outlined
                dense
            ></v-select>

            <v-text-field
                v-if="fileType === 'PDF'"
                v-model="elementDuration"
                :label="$t('resources.displayDuration')"
                hide-details
                outlined
                dense
            ></v-text-field>

            <ImageEditor
                v-model="imageEditor.show"
                :image="imageEditor.image"
                @done="imageEditDone"
            ></ImageEditor>
        </template>
    </BaseEditor>
</template>

<script>
import BaseEditor from '@/components/base/popup/BaseEditor.vue';
import BaseSizedBox from '@/components/base/BaseSizedBox.vue';
import ImageEditor from '@/components/content/images/ImageEditor.vue';
import ResourceTypes from '@/lib/definitions/ResourceTypes.js';
import AuthManager from '@/lib/helpers/AuthManager.js';
import { useContextStore } from '@/stores/context.js';
import { useResourceFoldersStore } from '@/stores/content/resourceFolders.js';
import _, { isEmpty } from "lodash";
import { useGlobalModals } from '@/composables/useGlobalModals';
import { responseSuccessCheck } from '@/lib/helpers/ApiHelper';
import { isFile4kResolution, isNotEmpty } from '@/lib/helpers/Validator';
import apis from '@/lib/api/index.js';

const unsupportedFileMimeExtensions = [];
const supportedImageFileMimeExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'pdf',
    'svg',
    'webp'
];
const supportedFontFileMimeExtensions = [
    'ttf',
    'otf',
    'font-woff',
    'woff',
    'woff2'
];
const supportedVideoFileMimeExtensions = ['mp4', 'quicktime']; // TODO add more here

export default {
    name: 'TheResourceUploader',
    components: { ImageEditor, BaseSizedBox, BaseEditor },
    data() {
        return {
            files: [],
            errorMessages: [],
            folder: null,
            folderStore: null,
            uploaderId: Math.floor(Math.random() * 100000000),
            imageEditor: {
                show: false,
                image: null
            },
            showUploader: false,
            uploadProcessing: false,
            generatePlaylist: false,
            fileType: null,
            mediaType: null,
            elementDuration: 10,
            isNotEmpty: isNotEmpty
        };
    },
    created() {
        this.globalModals.setResourceUploader(this);
    },
    computed: {
        globalModals() {
            return useGlobalModals();
        },
        addFileTypeDescription() {
            return this.$app.accessCheck('ResourceManagement', 'Videos', 'read')
                ? this.$t('resources.addOneOrMore')
                : this.$t('resources.addOneOrMoreExcludeVideos');
        },
        allowedFileTypes() {
            if (this.fileType && this.fileType.toLowerCase() === 'pdf') {
                return '.pdf';
            } else if (
                this.$app.accessCheck('ResourceManagement', 'Videos', 'read')
            ) {
                return 'image/*, video/*, .ttf, .otf, .woff, .woff2, .pdf';
            } else {
                return 'image/*, .ttf, .otf, .woff, .woff2, .pdf';
            }
        },
        uploading() {
            let uploading = false;

            this.files.forEach((f) => {
                if (f.uploading) {
                    uploading = true;
                }
            });

            return uploading;
        },
        readyToUpload() {
            let ready = false;

            this.files.forEach((f) => {
                if (!f.success) {
                    ready = true;
                }
            });

            return ready;
        },
        availableFolders() {
            const folderList = [{ name: 'No folder', id: null }];
            if (this.folderStore) {
                const items = this.folderStore.translated;
                if (Array.isArray(items)) {
                    items.forEach((i) => {
                        if (!i.shared) {
                            folderList.push({
                                name: i.nameTranslated,
                                id: i.id
                            });
                        }
                    });
                }
            }
            return folderList;
        }
    },
    watch: {
        showUploader(show) {
            if (!show) {
                this.files = [];
                this.errorMessages = [];
                this.fileType = null;
                this.mediaType = null;
                this.generatePlaylist = false;
            }
        }
    },
    methods: {
        show(folder, folderStore) {
            this.folder = folder;
            this.folderStore = folderStore;
            this.showUploader = true;
        },
        showCustom(folder, folderStore, options) {
            this.folder = folder;
            this.folderStore = folderStore;
            this.showUploader = true;

            const hasOptions = isNotEmpty(options);

            this.fileType =
                hasOptions && isNotEmpty(options.fileType)
                    ? options.fileType
                    : null;
            this.mediaType =
                hasOptions && isNotEmpty(options.mediaType)
                    ? options.mediaType
                    : null;
            this.generatePlaylist =
                hasOptions &&
                isNotEmpty(options.generatePlaylist)
                    ? options.generatePlaylist
                    : false;
        },
        editImage(image) {
            this.imageEditor = {
                show: true,
                image: image
            };
        },
        imageEditDone(blob) {
            this.imageEditor.image.file = blob;

            const reader = new FileReader();

            reader.onloadend = () => {
                this.imageEditor.image.src = reader.result;
            };

            reader.readAsDataURL(blob);
        },
        getResourceTypeFromFileObject(file) {
            const fileType = _.get(file, 'file.type', '');

            if (fileType === 'application/font-woff') {
                file.file = new File([file.file], file.name + '.woff', {
                    type: 'font/woff'
                });
                return 'fonts';
            } else if (fileType === 'application/font-woff2') {
                file.file = new File([file.file], file.name + '.woff2', {
                    type: 'font/woff2'
                });
                return 'fonts';
            } else {
                // HACK: !!!! TERRIBLE ASSUMPTION !!!!
                return file.resourceType + 's';
            }
        },
        async fileSelected(input) {
            this.errorMessages = [];
            const files = input.target.files;
            const maxVideoFileSize = 3.5 * 1024 * 1024 * 1024; // 3.5 GB in bytes
            for (const file of files) {
                let extension =
                    file.type.split('/')[file.type.split('/').length - 1];
                let type = file.type.split('/')[0];

                if (this.mediaType !== null) {
                    type = this.mediaType;
                }

                // Ugly hack! for otf
                if (file.name.split('.').pop().toLowerCase() === 'otf') {
                    extension = 'otf';
                    type = ResourceTypes.Font;
                }

                // Ugly hack! for svg+xml
                if (file.name.split('.').pop().toLowerCase() === 'svg') {
                    extension = 'svg';
                    type = ResourceTypes.Image;
                }
                console.log(extension);
                // if the type is empty, check the file name for extension instead
                if (isEmpty(file.type)) {
                    extension = file.name.split('.').pop().toLowerCase();
                    // check filetype
                    if (supportedImageFileMimeExtensions.includes(extension)) {
                        type = ResourceTypes.Image;
                    } else if (
                        supportedFontFileMimeExtensions.includes(extension)
                    ) {
                        type = ResourceTypes.Font;
                    } else if (
                        supportedVideoFileMimeExtensions.includes(extension)
                    ) {
                        type = ResourceTypes.Video;
                    } else {
                        this.errorMessages.push(
                            this.$t('resources.unsupportedFile', {
                                type: extension
                            })
                        );
                        return;
                    }
                }
                // end hack
                if (
                    type === ResourceTypes.Video &&
                    file.size > maxVideoFileSize
                ) {
                    this.errorMessages.push(
                        this.$t('resources.unsupportedFileNameAndSize', {
                            fileName: file.name,
                            size: '2GB'
                        })
                    );
                    return;
                }

                if (
                    unsupportedFileMimeExtensions.includes(extension) ||
                    (!supportedImageFileMimeExtensions.includes(extension) &&
                        !supportedFontFileMimeExtensions.includes(extension) &&
                        !supportedVideoFileMimeExtensions.includes(extension))
                ) {
                    this.errorMessages.push(
                        this.$t('resources.unsupportedFile', {
                            type: extension
                        })
                    );
                } else if (file.size > 50000000 && extension === 'pdf') {
                    this.errorMessages.push(
                        this.$t('resources.unsupportedFileSize', {
                            size: '50mb'
                        })
                    );
                } else {
                    const fileNameWithoutExtension = file.name.substring(
                        0,
                        file.name.lastIndexOf('.')
                    );
                    let fileNameAsciiOnly = fileNameWithoutExtension.replace(
                        /[^\x00-\x7F]/g,
                        ''
                    );
                    if (fileNameAsciiOnly.length <= 0) {
                        fileNameAsciiOnly = 'default';
                    }

                    // changing the original filename by creating a new file (file is readonly)
                    const fileWithAsciiOnlyName = new File(
                        [file],
                        fileNameAsciiOnly + '.' + extension,
                        { type: file.type }
                    );

                    const newFile = {
                        id: Math.floor(Math.random() * 100000), // Random id until we get a real id from the server
                        name: fileNameWithoutExtension,
                        file: fileWithAsciiOnlyName,
                        resourceType: type,
                        uploading: false,
                        request: null,
                        uploadProgress: false,
                        error: false,
                        success: false,
                        is4kVideo: false,
                        transcode4k: false,
                        originalFileName: file.name,
                        uploadComplete: false
                    };

                    if (type === ResourceTypes.Image) {
                        const reader = new FileReader();

                        reader.onloadend = () => {
                            newFile.src = reader.result;
                            let image = new Image();
                            image.src = newFile.src;
                            const self = this;
                            image.onload = function () {
                                //Render the image and see if it's 4K or not and append the result to the newFile.
                                newFile.is4k =
                                    isFile4kResolution(
                                        this.width,
                                        this.height
                                    );
                                newFile.transcode4k = newFile.is4k;
                                newFile.width = this.width;
                                newFile.height = this.height;
                                newFile.largerThan4K =
                                    (this.width > 3840 && this.height > 2160) ||
                                    (this.width > 2160 && this.height > 3840);
                                self.files.push(newFile);
                            };
                        };
                        if (fileWithAsciiOnlyName) {
                            reader.readAsDataURL(fileWithAsciiOnlyName);
                        }
                    } else if (type === ResourceTypes.Video) {
                        newFile.src = URL.createObjectURL(file);

                        //To obtain the video metadata, we must create a video element with the blob and append it to the newFile.
                        const videoFile = Object.assign(
                            document.createElement('video'),
                            {
                                preload: 'metadata',
                                src: newFile.src
                            }
                        );
                        const self = this;
                        videoFile.addEventListener(
                            'loadedmetadata',
                            function () {
                                //Append if the video is 4K and if so, set the UI to transcode as such by default.
                                newFile.is4k =
                                    isFile4kResolution(
                                        this.videoWidth,
                                        this.videoHeight
                                    );
                                newFile.transcode4k = newFile.is4k;
                                newFile.width = this.videoWidth;
                                newFile.height = this.videoHeight;
                                newFile.type = file.type;
                                self.files.push(newFile);
                            }
                        );
                    } else if (extension === ResourceTypes.PDF) {
                        const fileReader = new FileReader();
                        fileReader.readAsBinaryString(file);
                        fileReader.onloadend = function () {
                            const pageCount = fileReader.result.match(
                                /\/Type[\s]*\/Page[^s]/g
                            ).length;
                            if (pageCount > 30) {
                                this.errorMessages.push(
                                    this.$t('resources.exceedingMaxPdfPages', {
                                        limit: '30'
                                    })
                                );
                            } else {
                                this.files.push(newFile);
                            }
                        }.bind(this);
                    } else {
                        this.files.push(newFile);
                    }
                }
            }
            this.$refs.fileInput.value = '';
        },
        allFilesUploaded() {
            return this.files.every(
                (file) => file.uploadComplete && file.uploadProgress === 100
            );
        },
        finalizeUpload(hadErrors) {
            this.uploadProcessing = false;
            if (!hadErrors && this.allFilesUploaded()) {
                // Check again to ensure all files are fully uploaded
                setTimeout(() => {
                    this.showUploader = false;
                }, 1500);
            }
        },
        async upload() {
            this.uploadProcessing = true;
            let hadErrors = false;
            let completedUploads = 0;

            const totalFiles = this.files.filter(
                (file) => !file.success && !file.uploadComplete
            ).length;
            const uploadTasks = this.files
                .filter((file) => !file.success && !file.uploadComplete)
                .map((file, index) => {
                    return this.uploadFile(file, index)
                        .then(() => {
                            completedUploads++; // Increment on success
                            if (
                                completedUploads === totalFiles &&
                                this.allFilesUploaded()
                            ) {
                                this.finalizeUpload(hadErrors);
                            }
                        })
                        .catch((error) => {
                            hadErrors = true;
                            completedUploads++; // Ensure increment even on error
                            if (completedUploads === totalFiles) {
                                this.finalizeUpload(hadErrors);
                            }
                        });
                });

            const results = await Promise.allSettled(uploadTasks);

            hadErrors = results.some(
                (result) =>
                    result.status === 'rejected' ||
                    (result.value && result.value.error)
            );

            // Start the timeout logic after upload processing has finished
            setTimeout(() => {
                if (!hadErrors && this.allFilesUploaded) {
                    this.showUploader = false;
                }
            }, 1500);
        },
        async uploadFile(file, index, hadErrors) {
            file.uploading = true;
            const encodedFilename = encodeURIComponent(file.name);
            const origEncodedFilename = encodeURIComponent(
                file.originalFileName
            );
            let folderString = '';
            if (this.folder != null) {
                folderString = `folder=${typeof this.folder === 'object' ? this.folder.id : this.folder}`;
            }

            if (file.file.type !== 'application/pdf') {
                const resourceType = this.getResourceTypeFromFileObject(file);
                const startMsg = `jobs.startMsgs.${file.resourceType}Upload`;
                const successMsg = `jobs.successMsgs.${file.resourceType}Upload`;
                const failMsg = `jobs.failedMsgs.${file.resourceType}Upload`;
                const is4K = file.transcode4k;
                const resourceDimensions =
                    is4K && file.width && file.height
                        ? `&width=${file.width}&height=${file.height}`
                        : '';

                const response = await apis[resourceType].get(
                    `/upload?name=${encodedFilename}&${folderString}&is4k=${is4K}&startMsg=${startMsg}&successMsg=${successMsg}&failMsg=${failMsg}${resourceDimensions}&origFileName=${origEncodedFilename}`
                );
                if (response !== null && responseSuccessCheck(response)) {
                    file.uploading = true;
                    return this.handleFileUpload(file, response.data);
                } else {
                    hadErrors = true;
                    file.error = true;
                    file.uploading = false;
                }
            } else {
                let imageFolderId = '';
                let playlistFolderId = '';
                if (this.folder != null) {
                    playlistFolderId = `&playlistFolderId=${typeof this.folder === 'object' ? this.folder.id : this.folder}`;
                }

                if (this.fileType !== null) {
                    const date = new Date().toISOString().split('.')[0];
                    const createFolderResponse =
                        await useResourceFoldersStore().create({
                            item: {
                                name: 'PDF: ' + file.name + ' ' + date,
                                contextId: useContextStore().selectedContextId,
                                parent: null
                            }
                        });

                    if (responseSuccessCheck(createFolderResponse)) {
                        imageFolderId =
                            '&imageFolderId=' + createFolderResponse.data.id;
                    } else {
                        this.globalModals.getNotification().show(
                            'error',
                            this.$t('resources.pdfUploadFolderError')
                        );
                    }
                } else {
                    // PDF Upload from Resource uploader and not playlist. Folder id to be set as image folder
                    if (this.folder != null) {
                        imageFolderId = '&imageFolderId=' + this.folder;
                    }
                }

                return new Promise((resolve, reject) => {
                    const form = document.createElement('form');
                    form.method = 'post';
                    form.enctype = 'multipart/form-data';

                    const request = new XMLHttpRequest();
                    const formData = new FormData(form);
                    formData.append('file', file.file, file.name);

                    request.open(
                        'POST',
                        apis['images'].baseUrl +
                            '/convert-from?contextId=' +
                            useContextStore().selectedContextId +
                            '&generatePlaylist=' +
                            this.generatePlaylist +
                            '&elementDuration=' +
                            this.elementDuration +
                            playlistFolderId +
                            imageFolderId,
                        true
                    );
                    const authentication = AuthManager.getAuthenticationToken();
                    request.setRequestHeader(
                        'Authorization',
                        'Bearer ' + authentication
                    );
                    request.upload.addEventListener('progress', (event) => {
                        if (event.lengthComputable) {
                            file.uploadProgress = Math.round(
                                (event.loaded / event.total) * 100
                            );
                            // Check if the upload is complete
                            if (file.uploadProgress === 100) {
                                file.uploadComplete = true;
                            }
                        }
                    });

                    request.onreadystatechange = () => {
                        if (request.readyState === XMLHttpRequest.DONE) {
                            file.uploading = false;
                            if (request.status === 200) {
                                file.success = true;
                                file.error = false;
                                resolve(); // Resolve when the upload is successful
                            } else {
                                file.error = true;
                                switch (request.status) {
                                    case 400:
                                        file.errorText = this.$t(
                                            'resources.exceedingMaxPdfPages',
                                            { limit: '30' }
                                        );
                                        break;
                                    case 413:
                                        file.errorText = this.$t(
                                            'resources.unsupportedFileSize',
                                            { size: '50mb' }
                                        );
                                        break;
                                    default:
                                        file.errorText = this.$t(
                                            'resources.uploadError'
                                        );
                                }
                                reject(
                                    new Error(
                                        'Upload failed with status: ' +
                                            request.status
                                    )
                                );
                            }
                        }
                    };
                    request.onerror = () => {
                        file.error = true;
                        switch (request.status) {
                            case 400:
                                file.errorText = this.$t(
                                    'resources.exceedingMaxPdfPages',
                                    { limit: '30' }
                                );
                                break;
                            case 413:
                                file.errorText = this.$t(
                                    'resources.unsupportedFileSize',
                                    { size: '50mb' }
                                );
                                break;
                            default:
                                file.errorText = this.$t(
                                    'resources.uploadError'
                                );
                        }
                        reject(new Error('Network error'));
                    };

                    request.send(formData);
                }).finally(() => {
                    file.uploading = false;
                });
            }
        },

        async handleFileUpload(file, responseData) {
            const form = document.createElement('form');
            form.action = responseData.action;
            form.method = responseData.method;
            form.enctype = responseData.encType;

            Object.entries(responseData.formFields).forEach(([key, value]) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = key === 'x-amz-meta-name' ? file.name : value;
                form.appendChild(input);
            });

            return new Promise((resolve, reject) => {
                const formData = new FormData(form);
                formData.append('file', file.file, file.file.name);
                const xhr = new XMLHttpRequest();
                file.request = xhr; // For possible cancellation
                file.id = responseData.resourceId; // Attach resource ID

                xhr.upload.addEventListener('progress', (event) => {
                    if (event.lengthComputable) {
                        file.uploadProgress = Math.round(
                            (event.loaded / event.total) * 100
                        );
                        // Check if the upload is complete
                        if (file.uploadProgress === 100) {
                            file.uploadComplete = true;
                        }
                    }
                });
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === XMLHttpRequest.DONE) {
                        file.uploading = false;
                        if (xhr.status === 200) {
                            file.success = true;
                            file.error = false;
                            resolve(); // Resolve when the upload is successful
                        } else {
                            file.error = true;
                            file.errorText = this.$t('resources.uploadError');
                            reject(
                                new Error(
                                    'Upload failed with status: ' + xhr.status
                                )
                            );
                        }
                    }
                };
                xhr.onerror = () => {
                    reject(new Error('Network error'));
                };
                xhr.open(responseData.method, responseData.action);
                xhr.send(formData);
            }).finally(() => {
                file.uploading = false;
            });
        },
        getToolTipContent(width, height) {
            let toolTipKey = `resources.resizeImage4K`;
            return this.$t(toolTipKey, { width: width, height: height });
        },
        async cancelUpload() {
            // Find the number of remaining uploads
            const remainingUploads = this.files.filter(
                (file) => file.uploading
            ).length;

            // Are you sure?
            this.globalModals.getConfirmModal().show(
                this.$tc(
                    'resources.cancelUploadConfirmTitle',
                    remainingUploads,
                    { count: remainingUploads }
                ),
                this.$t('resources.cancelUploadConfirmMessage'),
                async () => {
                    // Loop through the files and cancel the upload
                    for (const file of this.files) {
                        if (file.uploading && file.request !== null) {
                            file.request.abort();
                            file.uploading = false;

                            // Delete the file object from the databsae
                            const resourceType =
                                this.getResourceTypeFromFileObject(file);
                            await apis[resourceType].delete(file.id);
                        }
                    }
                    // Uploading should be done, close the dialog
                    this.showUploader = false;
                    this.uploadProcessing = false;
                },
                () => {}
            );
        }
    }
};
</script>

<style lang="scss">
.resource-uploader {
    input[type='file'] {
        display: none;
    }

    .file-upload-label {
        cursor: pointer;
        padding: 20px 30px 20px 30px;
        color: white;
        background: var(--v-accent-base);
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        border-radius: 5px;
    }

    .file-upload-label.v-btn--disabled {
        background: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
    }
}
</style>
